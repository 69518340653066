import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Select from '../../../components/forms/Select'
import supplierData from '../../../data/globaldata.json'

const Bedtype = () => {

    const bedscountData = Array.from({ length: 20 }, (_, index) => ({
        title: (index + 1).toString(),
        value: index + 1
    }));

    return (
        <Row className='fs-5 align-items-center'>
            <Col xl="9" className='my-2'>
                <Select data={supplierData.beds} />
            </Col>
            <Col xl="3" className='my-2'>
                <Select data={bedscountData} />
            </Col>
        </Row>
    )
}

export default Bedtype