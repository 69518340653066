import { Panel } from 'rsuite';
import { Star } from 'lucide-react'
import FilterMap from '../../components/FilterMap'
import FilterPrice from '../../components/FilterPrice';

const Filters = () => {

    const departure = [
        { name: "Morning : 8am - 12pm" },
        { name: "Mid-Day : 12pm - 4pm" },
        { name: "Evening : 4pm - 8pm" },
        { name: "Night : 8pm - 12am" },
    ]

    const category = [
        { stars: 5 },
        { stars: 4 },
        { stars: 3 },
        { stars: 2 },
        { stars: 1 }
    ]

    const company = [
        { name: "TGV inOui" },
        { name: "Ouigo" },
        { name: "SNCF" },
    ]


    return (
        <>
            <div className='d-none d-xl-block position-relative'>
                <FilterMap/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <FilterPrice/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Duration</span>} collapsible>
                    {company.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center my-1'><input type='checkbox' /><span className='mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Category</span>} collapsible>
                    {category.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center my-1'>
                            <div className='d-flex align-items-center'><input type='checkbox' />
                                <div className='mx-3 fw-light'>
                                    {Array.from({ length: item.stars }).map((_, starIndex) => (
                                        <span className="me-1" key={starIndex}>
                                            <Star color='#F8D448' fill='#F8D448' size={13} />
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Departure</span>} collapsible>
                    {departure.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>
        </>
    )
}

export default Filters