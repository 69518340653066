import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../slide/652.png";
import Button from 'react-bootstrap/Button';



function Join2(){
    return(
            <div className="qsd11">
            <Container style={{paddingTop:'6vw',paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6">
                        <img className="img-fluid" src={SD}  alt="hotelidh" />
                    </Col>
                    <Col xs="12"  xl="6" className="j1" style={{ marginTop:'1vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976',marginBottom:'1rem'}}>Travel agencies</p>

                            <h2 className="h2join">Join our big  <br/>Team of Agencies</h2>
                       
                        </div> 
                        <div style={{marginBottom:'32px',float:'right',paddingLeft:'0vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw'}}>
                                The Hotelidh Platform is an essential tool for global hotel reservations and tourist services, offering travel agencies customized packages for unique trips.
                            
                            <br/><br/>
                                Book hotel rooms, tours, excursions, plane tickets, as well as transfers and vehicle rentals to ensure a comprehensive and personalized travel experience.
                            </p>

                            <p className="joinp1" style={{padding:'1vw'}}>
                                This approach simplifies your booking process by providing a single reference for all your transactions, with the guarantee of a commission.
                            
                            <br/><br/>
                                Trust Hotelidh to simplify and optimize your booking management, offering a transparent and efficient experience.
                            </p>
                        </div>
                        <div style={{float:'right'}}>
                        <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                    color: white;
                                   
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                          
                                    background-color:#772828;
                                    padding: 8px 40px;
                                    border-radius: 0px;
                                    font-family: 'Cormorant Garamond';
                                    
                                    }

                                    `}
                                    </style>

                                    <Button id="btnid" style={{margin:'auto', fontSize:'0.9rem',fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                       <a href="SignUp">Sign Up</a>
                                    </Button>
                                </>
                                </div>
                    </Col>
                </Row>
            </Container>
            </div>
        
    );
}

export default Join2;