import { Row, Col } from 'react-bootstrap';
import { Play } from 'lucide-react'
import React, { useState, useEffect } from 'react';
import VideoContainer from '../components/VideoContainer';
import TopBanner from '../components/TopBanner';
import { useTranslation } from 'react-i18next';

function About() {

    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showModal]);

    return (

        <section>

            {showModal ? <VideoContainer closeModal={closeModal} /> : ''}

            <TopBanner data={t('about')} cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container py-5'>
                <div className='position-relative'>
                    <img className='rounded-5' width="100%" height="100%" src='https://modmixmap.travelerwp.com/wp-content/uploads/2022/04/image-1.png' style={{ minHeight: '450px', objectFit: "cover" }} alt="illustration d'une équipe de collégues qui travaillent de des idées de voyages" loading='lazy' />
                    <div className='w-100 h-100 position-absolute d-flex justify-content-center align-items-center' style={{ top: "0", left: "0" }}>
                        <div title='Play video' onClick={openModal} role='button' className='p-4 rounded-circle bg-white'><Play color='#0D6EFD' fill='#0D6EFD' /></div>
                    </div>
                </div>

                <div className='px-md-5'>
                    <div className='my-5'>
                        <span className='fs-2 fw-bold text-dark'>{t('about_us')}</span>
                        <p className='my-3 fs-6'>{t('about_text')}</p>
                    </div>

                    <div className='my-5'>
                        <span className='fs-5 fw-bold text-dark'>{t('our_mission')}</span>
                        <Row>
                            <Col lg='6'>
                                <p className='my-3 fs-6' dangerouslySetInnerHTML={{__html:t('ourmission_text1')}}></p>
                            </Col>
                            <Col lg='6'>
                                <p className='my-3 fs-6' dangerouslySetInnerHTML={{__html:t('ourmission_text2')}}></p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default About;