import { X } from 'lucide-react'
import YouTube from 'react-youtube';

const VideoContainer = ({ closeModal }) => {
    const opts = {

        width: "430",
        height: "360",
        playerVars: {
            autoplay: true,
            controls: 0
        },
    };

    const videoId = 'bEAm2PHhvpo';


    return (
        <div className="modal">
            <div role='button' onClick={closeModal} className='position-absolute' style={{ top: "20px", right: "20px" }}><X size={40} color='white' /></div>
            <div className="d-flex justify-content-center">
                <YouTube videoId={videoId} opts={opts} />
            </div>
        </div>
    )
}

export default VideoContainer