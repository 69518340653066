import { Col, Row } from "react-bootstrap"
import BlogItem from '../data/blogdata.json'
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Articles = () => {
    const [blogData, setRandomData] = useState([]);
    const [articles, setArticles] = useState([]);
    const { t } = useTranslation();


    useEffect(() => {
        setArticles(BlogItem.articles);
    }, []);

    useEffect(() => {

        const shuffledData = [...BlogItem.articles].sort(() => Math.random() - 0.5);

        const slicedData = shuffledData.slice(0, 3);
        setRandomData(slicedData);

    }, []);


    return (
        <section>
            <div className="text-center">
                <h2 className='text-dark fs-1 fw-medium'>{t('get_inspiration_for_your_next_trip')}</h2>
                <p className="my-1 fs-6">{t('blog_title')}</p>
            </div>
            <Row className="my-5">
                {blogData.map((article,index) =>(
                    <Col key={index} lg="4" className="position-relative">
                        <a href={`/blog/${article.id}`} className="text-dark">
                            <img className="rounded-4" width="100%" height="100%" alt="article illustration" src={article.img} style={{maxHeight:"310px"}} />
                            <div className="my-3">
                                <div className="fs-6">{article.date}</div>
                                <div className="fs-5 fw-medium title-blue">{article.name}</div>
                            </div>
                            <span className="position-absolute top-0 start-0 m-4 bg-white p-2 px-3 rounded-5">{article.type}</span>
                        </a>
                    </Col>
                ))}
            </Row>
        </section>
    )
}

export default Articles