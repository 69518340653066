import React, { useState } from 'react'
import RoomOption from './RoomOption'
import { Col, Row } from 'react-bootstrap'
import Select from '../../../components/forms/Select'
import supplierData from '../../../data/globaldata.json'

const DetailsBathroom = () => {
    const [bathroomType, setBathroomType] = useState('shared');


    const handleBathroomType = (e) => {
        setBathroomType(e.target.value);
    };

    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    return (
        <>

            <Row className='fs-5 align-items-center'>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <span className='fs-6 fw-medium text-dark'>Is there a private or shared bathroom?</span>
                </Col>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <div className='d-flex align-items-center mx-3'>
                        <input value="private" checked={bathroomType === 'private'} onChange={handleBathroomType} id='private' type="radio" name="bathroom" />
                        <label htmlFor='private' className='mx-2'>Private</label>
                    </div>

                    <div className='d-flex align-items-center mx-3'>
                        <input value="shared" checked={bathroomType === 'shared'} onChange={handleBathroomType} id='shared' type="radio" name="bathroom" />
                        <label htmlFor='shared' className='mx-2'>Shared</label>
                    </div>
                </Col>
            </Row>

            {bathroomType === 'private' ?
                <Row className='my-3 align-items-center'>
                    <Col md="6" className='my-2'>
                        <span className='fs-6 fw-medium text-dark'>What kind of private bathroom is it?</span>
                    </Col>
                    <Col md="6" className='my-2'>
                        <Select data={supplierData.bathroom} />
                    </Col>

                </Row>
                : ''
            }

            <RoomOption />

            <div className='my-3 fs-6'>
                <span className='fs-6 fw-medium text-blue'>Bathroom facilities</span>
                <>

                    {supplierData.bath_facilities.map((check, index) => (
                        <div key={index} className='my-3'>
                            <>
                                <div className='d-flex align-items-center'>
                                    <input
                                        id={check.value}
                                        type="checkbox"
                                        value={check.value}
                                        checked={checkedItems[check.value] || false}
                                        onChange={() => handleCheckboxChange(check.value)}
                                    />
                                    <label htmlFor={check.value} className='mx-2'>
                                        {check.title}
                                    </label>
                                </div>
                            </>

                            {checkedItems[check.value] && (
                                <>

                                    {['napkins', 'bathrobes', 'heating floor', 'bathroom Essentials', 'hair dryer', 'bidet', 'outdoor shower', 'toothpaste'].includes(check.value) && (
                                        <RoomOption />
                                    )}

                                </>
                            )}

                        </div>
                    ))}
                </>
            </div>
        </>
    )
}

export default DetailsBathroom