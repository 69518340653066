import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Account from '../Account'
import { useState } from 'react';

function Offers() {

   const { t } = useTranslation();
   const [showAccountModal, setAccountModal] = useState(false);
   const handleCloseAccountModal = () => setAccountModal(false);
   const handleShowAccountModal = () => setAccountModal(true);

   return (
      <section className='container-lg py-5'>
         <Row>
            <Col className='my-3' lg="6" style={{ overflow: "hidden" }}>
               <div style={{ overflow: "hidden" }} className='rounded-4 d-flex justify-content-between align-items-center pt-4 px-5 bg-blue text-white h-100'>
                  <div className='py-3'>
                     <span className='fs-4 fw-medium'>{t('particular')}</span>
                     <p className='my-3'>{t('particular_text')}</p>
                     <button className='bg-white text-dark p-3 rounded' onClick={handleShowAccountModal}>{t('become_particular')}</button>
                  </div>
                  <img alt="Réduction exclusive sur les hébérgements" style={{ objectFit: "cover" }} src="https://superio-appdir.vercel.app/_next/image?url=%2Fimages%2Fresource%2Fcandidate.png&w=256&q=75" />
               </div>
            </Col>
            <Col className='my-3' lg="6" style={{ overflow: "hidden" }}>
               <a href='/supplier'>
                  <div style={{ overflow: "hidden" }} className='rounded-4 d-flex justify-content-between align-items-center pt-4 px-5 bg-orange text-dark h-100'>
                     <div className='py-3'>
                        <span className='fs-4 fw-medium'>{t('supplier')}</span>
                        <p className='my-3'>{t('supplier_text')}</p>
                        <button className='bg-white p-3 rounded'>{t('become_supplier')}</button>
                     </div>
                     <img alt="Réduction exclusive sur les hébérgements" style={{ objectFit: "cover" }} src="https://superio-appdir.vercel.app/_next/image?url=%2Fimages%2Fresource%2Femploy.png&w=256&q=75" />
                  </div>
               </a>
            </Col>
         </Row>
         <Account showAccountModal={showAccountModal} handleCloseAccountModal={handleCloseAccountModal} handleShowAccountModal={handleShowAccountModal} />
      </section>
   );
}

export default Offers;