import { MapPin } from 'lucide-react';
import HotelItem from '../../data/availability.json'
import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap'

const LastResearch = () => {

    const [hotelData, setRandomData] = useState([]);
    const [hotels, setArticles] = useState([]);

    useEffect(() => {
        setArticles(HotelItem.hotels);
    }, []);

    useEffect(() => {

        const shuffledData = [...HotelItem.hotels].sort(() => Math.random() - 0.5);

        const slicedData = shuffledData.slice(0, 3);
        setRandomData(slicedData);

    }, []);

    return (
        <section>
            <h2 className="fs-5 text-dark">Your latest research</h2>
            <div className='my-2'>
                <Row>
                    {hotelData.map((hotel, index) => (
                        <Col key={index} lg="4">
                            <div className='border rounded-3 p-2' style={{ overflow: "hidden" }}>
                                <Row key={index} className='align-items-center'>
                                    <Col xs="4">
                                        <img alt={`Illusatrtion de l'article - ${hotel.chain_id}`} width="80" height="80" src={hotel.image} />
                                    </Col>
                                    <Col xs="8">
                                        <div>
                                            <span className='fs-6 fw-medium title-blue'>{hotel.name}</span>
                                            <div><MapPin size={15} /> {hotel.address}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </section>
    )
}

export default LastResearch