import { useState } from 'react';
import Input from './Input';
import { Plus, Trash2 } from 'lucide-react';

const Highlights = ({ state, handleChange }) => {

    const [forms, setForms] = useState([]);

    const addForm = () => {
        if (forms.length === 0) {
            setForms([{ id: 1, value: '' }]);
        } else if (forms.length < 3) {
            const newId = forms[forms.length - 1].id + 1;
            setForms([...forms, { id: newId, value: '' }]);
        }
    };

    const removeForm = (id) => {
        const updatedForms = forms.filter(form => form.id !== id);
        setForms(updatedForms);
    };

    // const handleInputChange = (id, value) => {
    //     const updatedForms = forms.map(form =>
    //         form.id === id ? { ...form, value } : form
    //     );
    //     setForms(updatedForms);
    // };


    return (
        <div className='mt-1'>
            <div className='my-3 d-flex flex-column'>
                <Input value={state[0]}
                    type="text"
                    handleChange={handleChange('SET_HIGHLIGHT', 0)}
                    placeholder="Write 3-5 short highlights about what makes your product special"
                />
            </div>
            {forms.map(form => (
                <div className='my-3 d-flex flex-column' key={form.id}>
                    <Input value={state[form.id]}
                        type="text"
                        handleChange={handleChange('SET_HIGHLIGHT', form.id)}
                    />
                    <div role='button' className='align-self-end mt-2 border text-danger p-2 rounded-5' onClick={() => removeForm(form.id)}><Trash2 size={20} /> <span className='text-dark'>Supprimer</span></div>
                </div>
            ))}
            {forms.length < 3 &&
                <span role='button' className='border rounded-5 text-dark text-center p-2 px-3' onClick={addForm}><Plus size={20} /> Add highlight</span>
            }
        </div>
    )
}

export default Highlights