import { RangeSlider, Panel } from 'rsuite';
import { useState } from 'react';
import FilterMap from '../../components/FilterMap'

const Filters = () => {

    const [PriceValue, setPriceValue] = useState([30, 40]);

    const handleSliderChange = (values) => {
        setPriceValue(values);
    };

    const category = [
        { name: "Convertibles" },
        { name: "Minibus " },
        { name: "Coach" },
        { name: "Minivan" },
        { name: "Suv" },
    ]

    const capacity = [
        { name: "2 à 5 passagers" },
        { name: "6 passagers ou plus " },
    ]

    return (
        <>
            <div className='d-none d-xl-block position-relative'>
                <FilterMap/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Filter price</span>} collapsible>
                    <div>
                        <div className='my-4'>
                            <RangeSlider min={20} max={50} defaultValue={PriceValue} constraint={([start, end]) => start <= 40 && end >= 10} onChange={handleSliderChange} />
                        </div>
                        <div className='d-flex justify-content-around  align-items-center'>
                            <div className="border bg-white rounded-3 py-1 px-3">
                                <div>Min price</div>
                                <div className="text-dark">{PriceValue[0]} DZD</div>
                            </div>
                            <span className='mx-1'>-</span>
                            <div className="border bg-white rounded-3 py-1 px-3">
                                <div>Max price</div>
                                <div className="text-dark">{PriceValue[1]} DZD</div>
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Category</span>} collapsible>
                    {category.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Capacity</span>} collapsible>
                    {capacity.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

        </>
    )
}

export default Filters