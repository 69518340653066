import { Clock, Flag, Users } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react';

const Recap = () => {

    const [values, setValues] = useState({
        package_name: '',
        package_location: '',
        package_type: '',
        package_groupsize: null,
        package_duration: '',
        package_intro: '',
        package_meeting_point:'',
        package_guide:''
    });


    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedData = {
                package_name: localStorage.getItem('package_name') || 'Not assigned',
                package_location: localStorage.getItem('package_location') || 'Not assigned',
                package_type: localStorage.getItem('package_type') || 'Not assigned',
                package_groupsize: localStorage.getItem('package_groupsize') || 0,
                package_duration: localStorage.getItem('package_duration') || 'Not assigned',
                package_intro: localStorage.getItem('package_intro') || 'Not assigned',
                package_meeting_point: localStorage.getItem('package_meeting_point') || ['Not assigned'],
                package_guide: localStorage.getItem('package_guide') || 'Not assigned',
            };
            setValues(savedData);

        }, 10);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <section>
            <div className='border element rounded-4'>
                <div className='p-3'>
                    <div className='fs-5 fw-bold text-dark'>{values.package_name}</div>
                    <div className='fs-6 fw-medium'>{values.package_location}</div>
                </div>
                <div className='my-3 fs-6'>
                    <Row>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Users size={30} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.package_groupsize}</div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Clock size={30} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.package_duration}</div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Flag size={30} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.package_type}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span className='text-dark'>Introduction</span>
                    <span className='ps-3'>{values.package_intro}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span className='text-dark'>Meeting point</span>
                    <span className='ps-3'>{values.package_meeting_point}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Guide</span>
                    <span>{values.package_guide}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Price</span>
                    <span>$25,34,000</span>
                </div>
                
            </div>

        </section>
    )
}

export default Recap