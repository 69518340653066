import { useParams } from "react-router-dom";
import details from '../../data/transportation.json'
import { Armchair, ChevronRight, MinusCircle, MoveRight, PlusCircle } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import TopBanner from '../../components/TopBanner'
import Recap from "./Recap";
import BookingForm from "./BookingForm";
import { useState } from "react";
import Checkbox from "../../components/forms/Checkbox";
import Offcanvas from 'react-bootstrap/Offcanvas'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

function Booking() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [luggage, setLuggage] = useState(0)

    const handleIncrement = () => {
        setLuggage(luggage + 1)
    };

    const handleDecrement = () => {
        setLuggage(luggage - 1)
    };

    const { id } = useParams();
    let item = details.transportations.filter(objet => objet.id === parseInt(id))[0];

    const [seats, setSeats] = useState(item.seats);
    const [selectedSeats, setSelectedSeats] = useState([]);

    const handleSeatToggle = (index) => {
        const updatedSeats = [...seats];
        const seat = updatedSeats[index];

        if (seat.availability) {
            seat.isChecked = !seat.isChecked;

            if (seat.isChecked) {
                setSelectedSeats([...selectedSeats, seat.name]);
            } else {
                setSelectedSeats(selectedSeats.filter(selectedSeat => selectedSeat !== seat.name));
            }

            setSeats(updatedSeats);
        }
    };

    return (
        <div>
             <SubHeaderPlatform />
                            <HeaderDetails />
                            <section>
            <TopBanner data="Booking" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='my-5 container'>
                <Row>
                    <Col lg="7" xl="8">
                        <>
                            <fieldset>
                                <legend className='text-dark fs-3 fw-bold'>Booking Submission</legend>
                                <BookingForm />
                                <div role="button" className={`my-2 rounded border p-4 d-flex justify-content-between align-items-center text-dark ${selectedSeats.length > 0 ? 'border-success' : 'border-danger'}`} onClick={handleShow}>
                                    <div className="d-flex align-items-center">
                                        <Armchair size={80} />
                                        <div className="mx-2">
                                            <div className="fs-5 fw-medium">Select your seats</div>
                                            <div>From: {item.price}$</div>
                                        </div>
                                    </div>
                                    <ChevronRight />
                                </div>
                                <Row className="my-2 py-4 justify-content-center align-items-center text-dark">
                                    <Col sm="6" md="8" className="my-2">
                                        <div className="mx-2">
                                            <div className="fs-5 fw-medium">Additional luggage</div>
                                            <div>01 hand lugagge included</div>
                                        </div>
                                    </Col>
                                    <Col sm="6" md="4" className="my-2">
                                        <div className="d-flex align-items-center">
                                            <button type='button' className="mx-3 bg-white" onClick={handleDecrement} disabled={luggage < 1}><MinusCircle color="#1a536d" strokeWidth={1} size={40} /></button>

                                            <span className='border-0 text-center fs-6' style={{ width: "30px", height: "30px" }}>{luggage}</span>

                                            <button type='button' className="mx-3 bg-white" onClick={handleIncrement} disabled={luggage > 7}><PlusCircle size={40} color="#1a536d" strokeWidth={1} /></button>
                                        </div>
                                    </Col>
                                </Row>
                                <Offcanvas show={show} onHide={handleClose} placement="end">
                                    <div className="border m-3 rounded-4">
                                        <Offcanvas.Header>
                                        <div className="d-flex justify-content-between w-100">
                                                    <img width="80" height="80" src="https://dk0aj6exl04pb.cloudfront.net/shop/68472595450e732ceb91b0410b8e5d5f.png" alt="Transportation wheel illustration" />
                                                
                                                    <img width="120" height="80" src=" https://dk0aj6exl04pb.cloudfront.net/shop/9aa573b26889d8e74149ecbe4f6aa822.svg" alt="Transportation wheel illustration" /></div>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body>
                                        
                                           
                                            <Row>
                                                {seats.map((seat, index) => (
                                                    <Col xs="4" md="3" key={index} className="my-2">
                                                        <div className={`rounded ${!seat.availability ? 'bg-danger-subtle ' : 'bg-success-subtle'}`} style={{ cursor: `${!seat.availability ? 'cell' : 'button'}` }}>
                                                            <Checkbox isChecked={seat.isChecked} title={seat.name} onChange={() => handleSeatToggle(index)} icon={<Armchair />} />
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Offcanvas.Body>
                                    </div>
                                </Offcanvas>
                            </fieldset>
                        </>
                    </Col>
                    <Col lg="5" xl="4">
                        <Recap name={item.name} image={item.img} country={item.country} type={item.type} checkin={item.check_in} checkout={item.check_out} depart_time={item.depart_time} return_time={item.arrival_time} travelers={item.group_size} duration={item.duration} price={item.price * selectedSeats.length} />
                    </Col>
                </Row>

                <hr className='border-secondary' />

                <div className='my-3 fs-6 d-flex align-items-center'>
                    <input id='terms' type='checkbox' />
                    <label className='mx-2' htmlFor='terms'>I have read and accept the terms and conditions and Privacy Policy</label>
                </div>

                <div className="my-5">
                    <a className='btn-blue p-3 px-4 rounded-5 mt-3 fs-6 text-white' href={`/booking/cart/${id}/transportation`}>
                        Submit <MoveRight />
                    </a>
                </div>
            </div>
        </section>
        </div>
        
    );
}

export default Booking;