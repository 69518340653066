import React, { useState, useEffect } from 'react';
import { ChevronUp } from 'lucide-react'

function BackTop() {
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  };

  return (
    <button title='Go to top' style={{ background: "#861e12", zIndex: '4', position: 'fixed', right: '50px', bottom: '50px' }} className={`p-2 rounded ${isVisible ? 'd-block' : 'd-none'}`} onClick={scrollToTop}>
      <ChevronUp color='#ffffff' />
    </button>
  );
}

export default BackTop;