import { Wifi, AirVent, Luggage, Plug, Cast, Accessibility, Utensils, Usb, Bed, Soup, PersonStanding, CreditCard } from 'lucide-react'
import Recap from './Recap'
import { Row, Col } from 'react-bootstrap'
import { useReducer } from 'react';
import Checkbox from "../../../components/forms/Checkbox";
import { useState } from 'react';
import TopBanner from '../../../components/TopBanner';
import Input from '../../../components/forms/Input';

const attributes = {
    transportation_departure: "",
    transportation_arriving: "",
    transportation_facilities: [],
};

const reducer = (state, action) => {
    switch (action.type) {

        case 'SET_DEPARTURE':
            localStorage.setItem('transportation_departure', action.payload);
            return { ...state, transportation_departure: action.payload };

        case 'SET_ARRIVING':
            localStorage.setItem('transportation_arriving', action.payload);
            return { ...state, transportation_arriving: action.payload };


        case 'TOGGLE_FACILITY':
            const updated_transportation_facilities = state.transportation_facilities.includes(action.payload)
                ? state.transportation_facilities.filter(facility => facility !== action.payload)
                : [...state.transportation_facilities, action.payload];
            localStorage.setItem('transportation_facilities', JSON.stringify(updated_transportation_facilities));
            return { ...state, transportation_facilities: updated_transportation_facilities };

        default:
            return state;
    }
};

const Attributes = () => {

    const [state, dispatch] = useReducer(reducer, attributes);

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    const [checkboxes, setCheckboxes] = useState([
        { id: 1, title: "Wifi", isChecked: false, value: 'wifi', icon: <Wifi strokeWidth={1.2} size={35} /> },
        { id: 2, title: "Screen", isChecked: false, value: 'screen', icon: <Cast strokeWidth={1.2} size={35} /> },
        { id: 3, title: "Plug", isChecked: false, value: 'plug', icon: <Plug strokeWidth={1.2} size={35} /> },
        { id: 4, title: "Conditioner", isChecked: false, value: 'conditioner', icon: <AirVent strokeWidth={1.2} size={35} /> },
        { id: 5, title: "Storage", isChecked: false, value: 'storage', icon: <Luggage strokeWidth={1.2} size={35} /> },
        { id: 6, title: "Restaurant", isChecked: false, value: 'restaurant', icon: <Utensils strokeWidth={1.2} size={35} /> },
        { id: 7, title: "Usb ports", isChecked: false, value: 'usb ports', icon: <Usb strokeWidth={1.2} size={35} /> },
        { id: 8, title: "Berth compartments", isChecked: false, value: 'berth compartments', icon: <Bed strokeWidth={1.2} size={35} /> },
        { id: 9, title: "Plug", isChecked: false, value: 'plug', icon: <Plug strokeWidth={1.2} size={35} /> },
        { id: 10, title: "Meal", isChecked: false, value: 'meal', icon: <Soup strokeWidth={1.2} size={35} /> },
        { id: 11, title: "Toilets", isChecked: false, value: 'toilets', icon: <PersonStanding strokeWidth={1.2} size={35} /> },
        { id: 12, title: "Electronic payment", isChecked: false, value: 'electronic payment', icon: <CreditCard strokeWidth={1.2} size={35} /> },
        { id: 20, title: "Assistance", isChecked: false, value: 'assistance', icon: <Accessibility strokeWidth={1.2} size={35} /> },
    ]);

    const toggleCheckbox = (id, value) => {
        dispatch({ type: 'TOGGLE_FACILITY', payload: value });
        setCheckboxes(prevState =>
            prevState.map(checkbox =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };

    return (
        <section>
            <TopBanner data="Attributes" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/transportation/additional'>
                            <fieldset>
                                <legend className='text-dark fs-2 fw-medium'>Tell travelers what equipment does your transportation have?</legend>
                                <p className='fs-6 my-3'>Inform travelers even more about your transportation,select from this list everything that your accommodation contains in terms of services and equipment </p>

                                <div className='my-3'>

                                    <Row>
                                        {checkboxes.map((checkbox, index) => (
                                            <Col key={index} lg="3" className='my-3'>
                                                <Checkbox
                                                    key={checkbox.id}
                                                    id={checkbox.id}
                                                    isChecked={checkbox.isChecked}
                                                    value={checkbox.value}
                                                    onChange={toggleCheckbox}
                                                    title={checkbox.title}
                                                    icon={checkbox.icon}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </div>

                                <Row>
                                    <Col md="6" className='my-3'>
                                        <Input value={state.transportation_departure} type="text" handleChange={handleChange('SET_DEPARTURE')} placeholder="Departure place" maxlength="100" />
                                    </Col>

                                    <Col md="6" className='my-3'>
                                        <Input value={state.transportation_arriving} type="text" handleChange={handleChange('SET_ARRIVING')} placeholder="Arriving place" maxlength="30" />
                                    </Col>
                                </Row>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5 text-white'>Continue</button>
                            </fieldset>

                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>


            </div>
        </section>
    )
}

export default Attributes