import { useParams } from "react-router-dom";
import details from '../../data/rentalsdata.json'
import { Star, CalendarClock, MapPin, CalendarCheck, Heart, Users2, Bath, BedSingle, DoorOpen, ChevronDown, Check } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import React, { useState } from 'react';
import Reviews from '../../components/Reviews'
import WriteReview from '../../components/WriteReview'
import ImagesCarousel from '../../components/ImagesCarousel'
import GuestsCount from './forms/GuestsCount';
import TopBanner from "../../components/TopBanner";
import { DateRangePicker } from "rsuite";

function Details() {

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const { id } = useParams();
    let rental = details.rentals.filter(objet => objet.id === parseInt(id))[0];

    const [showAllAmenties, setShowAllAmenties] = useState(false);
    let amenitiesArray = Object.entries(rental.amenities || {});

    const amenitiesToShow = showAllAmenties ? amenitiesArray : amenitiesArray.slice(0, 5);


    const [adultCount] = useState(1);
    const [childCount] = useState(0);
    const [infantCount] = useState(0);

    const totalCount = parseInt(adultCount) + parseInt(childCount) + parseInt(infantCount)

    const [showActivityDetails, setShowActivityDetails] = useState(false);

    const toggleActivityDetails = () => {
        setShowActivityDetails(!showActivityDetails);
    };

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <section>
            <TopBanner data="Details" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />
            <div className="container-xl p-3">
                <span className="text-uppercase text-secondary fw-bold">{rental.type}</span>
                <h2 className="text-dark">{rental.availability} - {rental.name}</h2>
                <div className="my-3">
                    <div className='d-flex align-items-center'>
                        {Array.from({ length: rental.note }).map((_, starIndex) => (
                            <div key={starIndex} className='me-1'><Star color='#F8D448' fill='#F8D448' size={13} /></div>
                        ))}
                        <span className="mx-2 fw-medium fs-6 text-dark">{rental.note} / 5</span>
                        <span className="mx-2 fw-medium text-blue text-decoration-underline">{rental.reviews} reviews</span>
                    </div>
                </div>

                {/* Image carousel */}
                <ImagesCarousel showModal={showModal} closeModal={closeModal} item={rental} />

                <div role="button" onClick={openModal} className="position-relative">
                    <Row className="my-3">
                        <Col lg="6" className="px-1">
                            <img width="100%" height="100%" alt={`Ilustration 1 de l'activité`} src={rental.images[0]} style={{ maxHeight: "400px", minHeight: "450px" }} />
                        </Col>
                        <Col lg="6" className="d-none d-lg-block">
                            <Row style={{ rowGap: '10px' }}>
                                <Col md="6" className="px-1">
                                    <img width="100%" height="100%" alt={`Ilustration 2 de l'activité`} src={rental.images[1]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                </Col>
                                <Col md="6" className="px-1">
                                    <img width="100%" height="100%" alt={`Ilustration 2 de l'activité`} src={rental.images[2]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                </Col>
                                <Col md="6" className="px-1">
                                    <img width="100%" height="100%" alt={`Ilustration 3 de l'activité`} src={rental.images[3]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                </Col>
                                <Col md="6" className="px-1">
                                    <img width="100%" height="100%" alt={`Ilustration 4 de l'activité`} src={rental.images[4]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <button className="bg-white rounded position-absolute top-0 end-0 m-3 p-2 text-dark fw-medium fs-6">
                        Add to wishlist <Heart size={20} />
                    </button>

                    <button className="bg-white rounded position-absolute top-0 start-0 m-3 p-2 text-dark fw-medium fs-6">
                        View all {rental.images.length} images
                    </button>
                </div>

                <Row className="my-3">
                    <Col lg="8">
                        <>
                            <p className="fs-6 fw-bold text-dark">{rental.introduction}</p>
                            <div className="fs-4 text-dark fw-bold mt-3">À propos de ce logement</div>
                            <div className="my-3 text-dark">
                                <div className="d-flex my-4">
                                    <DoorOpen />
                                    <div className="mx-2">
                                        <div className="fs-6 fw-bold text-dark">Bedrooms x {rental.rooms}</div>
                                        <p className="fs-6 fw-light">The rooms that the host rents out</p>
                                    </div>
                                </div>
                                <div className="d-flex my-4">
                                    <Users2 />
                                    <div className="mx-2">
                                        <div className="fs-6 fw-bold text-dark">Travelers x {rental.travelers}</div>
                                        <p className="fs-6 fw-light">The maximum number of people that the accommodation can accommodate</p>
                                    </div>
                                </div>
                                <div className="d-flex my-4">
                                    <Bath />
                                    <div className="mx-2">
                                        <div className="fs-6 fw-bold text-dark">Salles de bain x {rental.bathroom}</div>
                                        <p className="fs-6 fw-light">The number of bathrooms at your disposal</p>
                                    </div>
                                </div>
                                <div className="d-flex my-4">
                                    <BedSingle />
                                    <div className="mx-2">
                                        <div className="fs-6 fw-bold text-dark">Lits de chambre x {rental.beds}</div>
                                        <p className="fs-6 fw-light">The number of beds available</p>
                                    </div>
                                </div>
                            </div>
                        </>

                        <div id="availability" className="rounded-4 p-3" style={{ background: "#1A2B49" }}>
                            <span className="fs-5 text-white fw-bold">Select participants and date</span>
                            <Row className="mt-3 align-items-center">
                                <Col lg="6" className="my-2">
                                    <div className="bg-white p-2 rounded-5">
                                        <GuestsCount />
                                    </div>
                                </Col>
                                <Col lg={!showActivityDetails ? "3" : "6"} className="my-2">
                                    <div className="p-2 bg-white rounded-5 d-flex justify-content-center">
                                        <DateRangePicker />
                                    </div>
                                </Col>
                                {!showActivityDetails ?
                                    <Col lg="3" className="my-2">
                                        <button className="btn-blue p-3 w-100 rounded-5" onClick={toggleActivityDetails}>Check availability</button>
                                    </Col>

                                    : ''
                                }
                            </Row>
                        </div>

                        <div className="my-4 text-dark fw-bold">
                            {showActivityDetails && (
                                <div className="border rounded-4 border-primary border-2 element" style={{ overflow: "hidden" }}>
                                    <div className="p-3">
                                        <span className="fs-5">{rental.name}</span>

                                        <div className="d-flex align-items-center my-3 text-blue">
                                            <MapPin />
                                            <span className="mx-2 text-decoration-underline">{rental.location}</span>
                                        </div>

                                        <hr className="my-3 border-secondary" />

                                        <div className="fs-6">Starting date</div>
                                        <div className="fs-6 fw-medium mt-2">Sat, 06 march 2023</div>

                                        <hr className="my-3 border-secondary" />

                                        <Row>
                                            <Col md="6" className="my-2">
                                                <div className="d-flex">
                                                    <CalendarCheck size={25} />
                                                    <p className="fs-6 mx-2">Cancel before 8:00 AM on December 29 for a full refund</p>
                                                </div>
                                            </Col>
                                            <Col md="6" className="my-2">
                                                <div className="fs-6">
                                                    <div>Price breakdown</div>
                                                    <p className="text-secondary mt-2">Adult1 × € 76.72</p>
                                                </div>
                                            </Col>
                                            <Col md="6" className="my-2">
                                                <div className="d-flex">
                                                    <CalendarClock size={25} />
                                                    <p className="fs-6 mx-2">You can reserve now & pay later with this activity option.</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="p-3" style={{ background: "#EBEEF1" }}>
                                        <Row className="justify-content-between align-items-center">
                                            <Col md="6" className="my-2">
                                                <div>
                                                    <div>Total price</div>
                                                    <div className="fs-4 text-warning">€ {parseFloat((rental.price * totalCount).toFixed(2))}</div>
                                                    <div className="my-1">
                                                        <span className="text-decoration-line-through">€ 85.24</span> <span className="text-warning mx-2">-10%</span>
                                                    </div>
                                                    <div className="fw-medium">All taxes and fees included</div>
                                                </div>
                                            </Col>
                                            <Col md="3" className="my-2">
                                                <div>
                                                    <a href={`/booking/rental/${rental.id}`} className="btn-blue p-3 text-white rounded-5">Add to cart</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                </div>
                            )}
                        </div>

                        <div className="mt-3">
                            <div className="fs-4 text-dark fw-bold">Ce que propose ce logement</div>

                            <Row className="my-3 fs-6 fw-medium text-dark">
                                <Col md="3">
                                    <span className="fs-6 fw-bold">Description</span>
                                </Col>
                                <Col md="9"> 
                                    <p dangerouslySetInnerHTML={{ __html: rental.description}}></p>
                                </Col>
                            </Row>

                            <hr className="border-secondary my-3" />

                            <Row className="my-3 fs-6 fw-medium text-dark">
                                <Col md="3">
                                    <span className="fs-6 fw-bold">Amenties</span>
                                </Col>
                                <Col md="9">
                                    <div className='text-dark my-3' style={{ fontSize: "16px" }}>
                                        {amenitiesToShow.map(([index, amenity]) => (
                                            <div key={index} xl="2" className='my-2 d-flex align-items-center'>
                                                <div className='bg-success-subtle text-success rounded-circle p-1'>
                                                    <Check size={20} strokeWidth={1} />
                                                </div>
                                                <span className='mx-3'>{amenity}</span>
                                            </div>
                                        ))}
                                        {!showAllAmenties && (
                                            <div role="button" className='fs-6 fw-medium m-4 text-blue' onClick={() => setShowAllAmenties(true)}>
                                                <span>View more</span> <ChevronDown />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                            <hr className="border-secondary my-3" />

                            <Row className="my-3 fs-6 fw-medium text-dark">
                                <Col md="3">
                                    <span className="fs-6 fw-bold">Location
                                    </span>
                                </Col>
                                <Col md="9">
                                    <div>
                                        <p>{rental.location}</p>
                                        <div className="mt-3">
                                            <a href="/map" className="text-blue">Open in Google Maps</a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="d-flex flex-column border border-2 p-3 text-dark">
                            <div className="text-white bg-danger align-self-start p-1 rounded">
                                Likely to sell out
                            </div>
                            <div className="d-flex align-items-center justify-content-between my-4">
                                <div>
                                    <div className="fs-6 fw-bold">From</div>
                                    <div className="fs-4 fw-bold">€ {rental.price}</div>
                                    <div className="fs-6 fw-bold">per person</div>
                                </div>
                                <button className="btn-blue px-5 p-3 rounded-5" onClick={() => scrollToSection('availability')}>Check availability</button>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <CalendarClock />
                                </div>
                                <p className="mx-3 fw-bold"><span className="text-blue text-decoration-underline">Reserve now & pay later</span> to book your spot and pay nothing today</p>
                            </div>
                        </div>
                        <div>
                            <Reviews />
                        </div>
                    </Col>
                </Row>

                <hr className="border-secondary my-4" />

                <div>
                    <WriteReview />
                </div>

            </div>

        </section>

    );

}

export default Details;
