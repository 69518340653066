
const Register = () => {
    return (
        <form>
            <fieldset className="p-3 d-flex flex-column">
                <legend className="fw-bold text-dark">Create an account</legend>
                <div className="mt-3">
                    <div className="my-3">
                        <input className="p-3 input-blue w-100 rounded border" placeholder="Full name *" />
                    </div>
                    <div className="my-3">
                        <input className="p-3 input-blue w-100 rounded border" placeholder="Email *" />
                    </div>
                    <div className="my-3">
                        <input className="p-3 input-blue w-100 rounded border" type="password" placeholder="Password" />
                    </div>
                </div>
                <button type="submit" className="btn-blue p-3 rounded-5 fw-bold">Register</button>
                <div className="d-flex align-items-center mt-4">
                    <input id="SignInCheck" type="checkbox" />
                    <label htmlFor="SignInCheck" className="mx-3 fs-6 text-secondary">I have read and accepted the <span style={{ color: "var(--primary-blue)" }}>privacy policy</span></label>
                </div>
            </fieldset>
        </form>
    )
}

export default Register