export const services = {
    url: {
        hotel: {
            destination: '/destinations',
            promotions: '/hotel/promotions',
            load: '/hotel/load',
            detail: '/hotel'
        }

    },
    method: {
        hotel: {
            destination: 'get',
            promotions: 'get',
            load: 'post',
            detail: 'get'
        }
    }
}; 