import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { baseUrl } from "../global/constants"
import profileReducer from "./reducers/profileReducer"
import hotelReducer from "./reducers/hotelReducer"

const persistConfig = {
    key: "root",
    whitelist: ["profile", 'hotel'],
    storage,
};
const rootReducer = combineReducers({
    profile: profileReducer,
    hotel: hotelReducer
});
const appReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        storage.removeItem(baseUrl + ":state");
        return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, appReducer);
export default persistedReducer;
