import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import SubHeaderPlatform from '../components/SubHeaderPlatform'
import HeaderDetails from '../components/HeaderDetails'


function HelpDesk(){
    return(
            <div>

                <SubHeaderPlatform />
                <HeaderDetails />
                <div style={{marginBottom:'4vw'}} >
                    <div className="bci">
                        <h3 className="spancd" style={{fontFamily:'Cormorant Garamond'}}>Get In Touch</h3> 
                    </div>
                  
                    <div style={{backgroundColor:''}}>
                        <Container  style={{paddingTop:'5vw', paddingBottom:'5vw'}}>
                            <Row> 
                                
                                <Col className="ContSp" lg="4" md="12" style={{display:'flex', flexDirection:'column'}}>
                                    <h3 className="colh3"> Accommodation </h3>
                                    <p style={{textAlign:'left',fontSize:'16px'}}>
                                        If you need helpdesk assistance for our platform don't hesitate to reach out. Our support team is available around the clock to ensure your questions are <br/>
                                        addressed promptly and effectively
                                        
                                        <br/><br/></p>
                                    
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
                                        <br/> 
                                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                                            reservations@hotelidh.com
                                            
                                        </p>
                                    </span>
                                    
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone-call"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/><path d="M14.05 2a9 9 0 0 1 8 7.94"/><path d="M14.05 6A5 5 0 0 1 18 10"/></svg><p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                                        (+213) 555 056 638                        
                                        </p>
                                    </span>

                                </Col>

                                <Col className="ContSp" lg="4" md="12" style={{display:'flex', flexDirection:'column'}}>
                                    <h3 className="colh3"> Flight </h3>
                                    <p style={{textAlign:'left',fontSize:'16px'}}>

                                Whether you have inquiries, or new product suggestions we welcome your feedback at any time. Contact us and our team will address your concerns and consider your valuable input     
                                    <br/><br/></p>
                                    
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
                                        <br/> 
                                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                                            helpdesk2@idhtours.com
                                        
                                        </p>
                                    </span>
                                    
                                    <span>

                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone-call"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/><path d="M14.05 2a9 9 0 0 1 8 7.94"/><path d="M14.05 6A5 5 0 0 1 18 10"/></svg><p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                                        (+213) 560 954 739
                                        
                                        </p>
                                    </span>                    </span>
                                </Col>
                                
                                <Col className="ContSp" lg="4" md="12" style={{display:'flex', flexDirection:'column'}}>
                                    <h3 className="colh3"> Transfers </h3>
                                    <p style={{textAlign:'left',fontSize:'16px'}}>

                                        Whether you have inquiries, or new product suggestions we welcome your feedback at any time. Contact us and our team will address your concerns and consider your valuable input     
                                    <br/><br/></p>
                                    
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
                                        <br/> 
                                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                                            contact@hotelidh.com
                                        
                                        </p>
                                    </span>
                                    
                                    <span>
                                        
                                        
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone-call"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/><path d="M14.05 2a9 9 0 0 1 8 7.94"/><path d="M14.05 6A5 5 0 0 1 18 10"/></svg><p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                                        (+213) 560 954 737
                                        
                                        </p>
                                    </span>
                                    </span>
                                </Col>

                            </Row>
                        </Container>
                    </div>     
                </div >
             
            </div >
    );
}

export default HelpDesk;