import Home from './components/home/Home'
import { Route, Routes } from 'react-router-dom'
import FlightMain from './pages/flights/Main'
import HotelMain from './pages/hotels/Main'
import RentalMain from './pages/rentals/Main'
import ActivityMain from './pages/activities/Main'
import TransfertMain from './pages/transferts/Main'
import CarMain from './pages/cars/Main'
import BookingMain from './pages/booking/Main'
import PackageMain from './pages/packages/Main'
import TransportationMain from './pages/transportations/Main'
import BlogMain from './pages/blog/Main'
import Conditions from './pages/conditions'
import Faq from './pages/faq'
import DashboardMain from './pages/dashboard/Main'
import Supplier from './pages/Supplier'
import HelpDesk from './components/HelpDesk'
import User from './components/User'
import Password from './components/Password'
import Panier from './components/Panier'
import Product from './components/Product'
import Product1 from './components/Product1'
import Product2 from './components/Product2'
import Product3 from './components/Product3'
import Users from './components/Users'
import AdminUser from './components/AdminUser'
import AddUser from './components/AddUser'
import Reservations from './components/Reservations'
import Reservation from './components/Reservation'
import Reservation2 from './components/Reservation2'
import Reservation3 from './components/Reservation3'
import Reservation4 from './components/Reservation4'
import Cancell from './components/Cancell'
import Removed from './components/Removed'
import Trash from './components/Trash'
import About from './components/About'
import About1 from './components/About1'
import About2 from './components/About2'
import About3 from './components/About3'
import About4 from './components/About4'
import About5 from './components/About5'
import Services from './components/Services'
import Service1 from './components/Service1'
import Service2 from './components/Service2'
import Service3 from './components/Service3'
import Join from './components/Join'
import Join1 from './components/Join1'
import Join2 from './components/Join2'
import Join3 from './components/Join3'
import Join4 from './components/Join4'
import Contact from './components/Contact'
import Contact1 from './components/Contact1'
import Contact2 from './components/Contact2'
import Contact3 from './components/Contact3'
import Contactf from './components/Contactf'
import SignUp from './components/SignUp'
import HeaderSign from './components/HeaderSign'
import Agency from './components/Agency'
import HeaderConn from './components/HeaderConn'
import Platform from './components/Platform'
import Background from './components/Background'
import MainForm from './components/MainForm'
import Connexion from './components/Connexion'
import FullDetails from './components/FullDetails'

function RouteComponents() {
    return (
        <Routes>
            <Route path='/' exact element={<Home />} />

            <Route path="/flights">
                <Route path='' element={<FlightMain component="home" />} />
                <Route path=':id' element={<FlightMain component="details" />} />
                <Route path='search' element={<FlightMain component="search" />} />
            </Route>

            <Route path="/hotels">
                <Route path='' element={<HotelMain component="home" />} />
                <Route path=':id' element={<HotelMain component="details" />} />
                <Route path='search' element={<HotelMain component="search" />} />
            </Route>

            <Route path="/rentals">
                <Route path='' element={<RentalMain component="home" />} />
                <Route path=':id' element={<RentalMain component="details" />} />
                <Route path='search' element={<RentalMain component="search" />} />
            </Route>

            <Route path="/activities">
                <Route path='' element={<ActivityMain component="home" />} />
                <Route path='search' element={<ActivityMain component="search" />} />
                <Route path=':id' element={<ActivityMain component="details" />} />
            </Route>

            <Route path="/transferts">
                <Route path='' element={<TransfertMain component="home" />} />
                <Route path='search' element={<TransfertMain component="search" />} />
            </Route>

            <Route path="/cars">
                <Route path='' element={<CarMain component="home" />} />
                <Route path='search' element={<CarMain component="search" />} />
                <Route path=':id' element={<CarMain component="details" />} />
            </Route>

            <Route path="/packages">
                <Route path='' element={<PackageMain component="home" />} />
                <Route path='search' element={<PackageMain component="search" />} />
                <Route path=':id' element={<PackageMain component="details" />} />
            </Route>

            <Route path="/transportations">
                <Route path='' element={<TransportationMain component="home" />} />
                <Route path='search' element={<TransportationMain component="search" />} />
                <Route path=':id' element={<TransportationMain component="details" />} />
            </Route>

            <Route path="/blog">
                <Route path=':articletId' element={<BlogMain component="details" />} />
                <Route path='' element={<BlogMain component="search" />} />
                <Route path='type/:articleType' element={<BlogMain component="search_type" />} />
            </Route>

            <Route path="/booking">
                <Route path='wishlist' element={<BookingMain component="wishlist" />} />
                <Route path='hotel/:id' element={<BookingMain component="book_hotel" />} />
                <Route path='rental/:id' element={<BookingMain component="book_rental" />} />
                <Route path='flight/:id' element={<BookingMain component="book_flight" />} />
                <Route path='activity/:id' element={<BookingMain component="book_activity" />} />
                <Route path='package/:id' element={<BookingMain component="book_package" />} />
                <Route path='car/:id' element={<BookingMain component="book_car" />} />
                <Route path='transportation/:id' element={<BookingMain component="book_transportation" />} />
                <Route path='cart/:id/:type' element={<BookingMain component="cart" />} />
                <Route path='payment' element={<BookingMain component="payment" />} />
                <Route path='voucher/:hotelId' element={<BookingMain component="voucher" />} />
            </Route>

            <Route path="/dashboard">
                <Route path='' element={<DashboardMain component="home" />} />
                <Route path='activity'>
                    <Route path='' element={<DashboardMain component="activity_create" />} />
                    <Route path='informations' element={<DashboardMain component="activity_informations" />} />
                    <Route path='attributes' element={<DashboardMain component="activity_attributs" />} />
                    <Route path='additional' element={<DashboardMain component="activity_additional" />} />
                    <Route path='photos' element={<DashboardMain component="activity_photos" />} />
                    <Route path='review' element={<DashboardMain component="activity_review" />} />
                </Route>
                <Route path='accommodation'>
                    <Route path='' element={<DashboardMain component="accomodation_create" />} />
                    <Route path='informations' element={<DashboardMain component="accomodation_informations" />} />
                    <Route path='attributes' element={<DashboardMain component="accommodation_attributes" />} />
                    <Route path='additional' element={<DashboardMain component="accommodation_additional" />} />
                    <Route path='photos' element={<DashboardMain component="accommodation_photos" />} />
                    <Route path='review' element={<DashboardMain component="accommodation_review" />} />
                </Route>
                <Route path='car'>
                    <Route path='' element={<DashboardMain component="car_create" />} />
                    <Route path='informations' element={<DashboardMain component="car_informations" />} />
                    <Route path='attributes' element={<DashboardMain component="car_attributes" />} />
                    <Route path='photos' element={<DashboardMain component="car_photos" />} />
                    <Route path='review' element={<DashboardMain component="car_review" />} />
                </Route>

                <Route path='package'>
                    <Route path='' element={<DashboardMain component="package_create" />} />
                    <Route path='informations' element={<DashboardMain component="package_informations" />} />
                    <Route path='attributes' element={<DashboardMain component="package_attributes" />} />
                    <Route path='photos' element={<DashboardMain component="package_photos" />} />
                    <Route path='review' element={<DashboardMain component="package_review" />} />
                </Route>

                <Route path='transportation'>
                    <Route path='' element={<DashboardMain component="transportation_create" />} />
                    <Route path='informations' element={<DashboardMain component="transportation_informations" />} />
                    <Route path='attributes' element={<DashboardMain component="transportation_attributes" />} />
                    <Route path='additional' element={<DashboardMain component="transportation_additional" />} />
                    <Route path='photos' element={<DashboardMain component="transportation_photos" />} />
                    <Route path='review' element={<DashboardMain component="transportation_review" />} />
                </Route>

                
            </Route>

            
            <Route path='/supplier' element={<Supplier />} />
            <Route path='/about' element={<About />} />
            <Route path='/policy' element={<Conditions />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/faq' element={<Faq />} />
            
            <Route path='/HelpDesk' element={<HelpDesk />} />
            <Route path='/User' element={<User />} />
            <Route path='/Password' element={<Password />} />
            <Route path='/Panier' element={<Panier/>} />
            <Route path='/Product' element={<Product />} />
            <Route path='/Product1' element={<Product1 />} />
            <Route path='/Product2' element={<Product2 />} />
            <Route path='/Product3' element={<Product3 />} />
            <Route path='/Users' element={<Users />} />
            <Route path='/AdminUser' element={<AdminUser />} />
            <Route path='/AddUser' element={<AddUser />} />
            <Route path='/Reservations' element={<Reservations />} />
            <Route path='/Reservation' element={<Reservation />} />
            <Route path='/Reservation2' element={<Reservation2 />} />
            <Route path='/Reservation3' element={<Reservation3 />} />
            <Route path='/Reservation4' element={<Reservation4 />} />
            <Route path='/Cancell' element={<Cancell />} />
            <Route path='/Removed' element={<Removed />} />
            <Route path='/Trash' element={<Trash />} />
            <Route path='/About' element={<About />} />
            <Route path='/About1' element={<About1 />} />
            <Route path='/About2' element={<About2 />} />
            <Route path='/About3' element={<About3 />} />
            <Route path='/About4' element={<About4 />} />
            <Route path='/About5' element={<About5 />} />
            <Route path='/Services' element={<Services />} />
            <Route path='/Service1' element={<Service1 />} />
            <Route path='/Service2' element={<Service2 />} />
            <Route path='/Service3' element={<Service3 />} />
            <Route path='/Join' element={<Join />} />
            <Route path='/Join1' element={<Join1 />} />
            <Route path='/Join2' element={<Join2 />} />
            <Route path='/Join3' element={<Join3 />} />
            <Route path='/Join4' element={<Join4 />} />
            <Route path='/Contact' element={<Contact/>} />
            <Route path='/Contact1' element={<Contact1/>} />
            <Route path='/Contact2' element={<Contact2/>} />
            <Route path='/Contact3' element={<Contact3/>} />
            <Route path='/Contactf' element={<Contactf/>} />
            <Route path='/SignUp' element={<SignUp/>} />
            <Route path='/HeaderSign' element={<HeaderSign/>} />
            <Route path='/Agency' element={<Agency/>} />
            <Route path='/HeaderConn' element={<HeaderConn/>} />
            <Route path='/Platform' element={<Platform/>} />
            <Route path='/Background' element={<Background/>} />
            <Route path='/MainForm' element={<MainForm/>} />
            <Route path='/Connexion' element={<Connexion/>} />
            <Route path='/FullDetails' element={<FullDetails/>} />
            


        </Routes>

    );
}
export default RouteComponents;
