import { useState, useEffect } from 'react';
import BlogItem from '../../data/blogdata.json'
import { Panel } from 'rsuite';
import { Row, Col } from 'react-bootstrap'

const Filters = () => {

    const [blogData, setRandomData] = useState([]);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        setArticles(BlogItem.articles);
    }, []);

    useEffect(() => {

        const shuffledData = [...BlogItem.articles].sort(() => Math.random() - 0.5);

        const slicedData = shuffledData.slice(0, 3);
        setRandomData(slicedData);

    }, []);

    const countTravel = articles.filter(article => article.type === 'City').length;
    const countStays = articles.filter(article => article.type === 'Food').length;
    const countBooking = articles.filter(article => article.type === 'Cultural').length;
    const countHotel = articles.filter(article => article.type === 'Cruises').length;

    const filtersLinks = [
        { label: "City", count: countTravel},
        { label: "Food", count: countStays},
        { label: "Cultural", count: countBooking},
        { label: "Cruises", count: countHotel}
    ]

    return (
        <>
            <div className="rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-bold fs-5">Search
                </span>} collapsible>
                    <div className='border-top pt-3'>
                        <form>
                            <input type="text" className='input-blue border p-3 rounded-5 w-75 mx-1' l placeholder="Ssearch..." />

                            <button className='btn-blue p-3 rounded-5 text-white' type="submit">Search</button>
                        </form>

                    </div>
                </Panel>
            </div>

            <div className="my-4 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-bold fs-5">Popular posts
                </span>} collapsible>
                    <div className='border-top pt-3'>
                        {blogData.map((article, index) => (
                            <a href={`/blog/${article.id}`} className="text-dark text-decoration-none">
                                <Row key={index} className='my-4 align-items-center'>
                                    <Col xs="4">
                                        <img alt={`Illusatrtion de l'article - ${article.name}`} width="80" height="80" src={article.img} />
                                    </Col>
                                    <Col xs="8">
                                        <div>
                                            <span className='fs-6 fw-medium title-blue'>{article.name}</span>
                                            <p>{article.date}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </a>
                        ))}
                    </div>
                </Panel>
            </div>

            <div className="my-4 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-bold fs-5">Categories
                </span>} collapsible>
                    <div className='border-top pt-3'>

                        <a href="/blog" className='bg-white d-flex justify-content-between align-items-center text-dark text-decoration-none'>
                            <span className={`border text-dark my-3 p-2 px-3 text-uppercase fw-bold rounded-5`}>
                                All
                            </span>
                            <span>{articles.length}</span>
                        </a>

                        {filtersLinks.map((link, index) => (
                            <a href={`/blog/type/${link.label}`} key={index} className='bg-white d-flex justify-content-between align-items-center text-dark text-decoration-none'>
                                <span className={`border rounded-5 my-3 p-2 px-3 text-uppercase fw-bold rounded-5`}>
                                    {link.label}
                                </span>
                                <span>{link.count}</span>
                            </a>
                        ))}

                    </div>
                </Panel>
            </div>

        </>
    )
}

export default Filters