
const Login = () => {

    return (

        <form>
            <fieldset className="p-3 d-flex flex-column">
                <legend className="fw-bold text-dark">Sign in to your account</legend>
                <div className="mt-3">
                    <div className="my-3">
                        <input className="p-3 input-blue w-100 rounded border" placeholder="Email or Username" />
                    </div>
                    <div className="my-3">
                        <input className="p-3 input-blue w-100 rounded border" placeholder="Password" />
                    </div>
                </div>
                <button type="submit" className="btn-blue p-3 rounded-5 fw-bold">Log In</button>
                <div className="d-flex align-items-center justify-content-between mt-4">
                    <div className="d-flex align-items-center">
                        <input id="SignInCheck" type="checkbox" />
                        <label htmlFor="SignInCheck" className="mx-3 fs-6 text-secondary">Remember me</label>
                    </div>
                    <a href="true">Fortgot password</a>
                </div>
            </fieldset>
        </form>
    )
}

export default Login