import RoomItem from '../../data/rooms.json'
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Baby, Users, Bed, ChevronFirst, ChevronLeft, ChevronRight, ChevronLast } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Rooms = () => {

    const { t } = useTranslation();
    const { id } = useParams();

    const itemsPerPage = 4;
    const buttonsToShow = 3;

    const [currentPage, setCurrentPage] = useState(0);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const getCurrentPageRooms = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        return Object.entries(RoomItem.rooms)
            .map(([roomType, rooms]) => rooms)
            .flat()
            .slice(startIndex, endIndex);
    };

    const pageCount = Math.ceil(
        Object.values(RoomItem.rooms).reduce((total, rooms) => total + rooms.length, 0) / itemsPerPage
    );

    let startPage = currentPage - Math.floor(buttonsToShow / 2);
    startPage = Math.max(startPage, 0);
    let endPage = startPage + buttonsToShow;
    endPage = Math.min(endPage, pageCount);

    const paginationButtons = [];
    for (let i = startPage; i < endPage; i++) {
        paginationButtons.push(
            <button
                style={i === currentPage ? { background: '#1a536d', color: 'white', width: '40px', height: '40px' } : { background: 'white', width: '40px', height: '40px' }}
                key={i}
                onClick={() => handlePageChange(i)}
                className={i === currentPage ? 'active btn rounded d-flex justify-content-center align-items-center' : 'btn btn-white border rounded d-flex justify-content-center align-items-center'}
            >
                {i + 1}
            </button>
        );
    }

    const goToPrevPage = () => {
        if (currentPage > 0) {
            handlePageChange(currentPage - 1);
        }
    };

    const goToFirstPage = () => {
        handlePageChange(0);
    };

    const goToLastPage = () => {
        handlePageChange(pageCount - 1);
    };

    const goToNextPage = () => {
        if (currentPage < pageCount - 1) {
            handlePageChange(currentPage + 1);
        }
    };

    return (
        <div className='p-3'>
            {getCurrentPageRooms().map((room, index) => (
                <Row key={index} className='border rounded-4 my-3' style={{ overflow: "hidden" }}>
                    <Col lg="4" className='px-0'>
                        <div className='h-100 bg-dark'>
                            <img alt={`Vue d'ensemble de la chambre ${room.Name}`} width="100%" height="100%" src='https://modmixmap.travelerwp.com/wp-content/uploads/2022/04/Staybridge-Suites-Dubai-Al-Maktoum-Airport-an-IHG-Hotel-800x600.png' />
                        </div>
                    </Col>
                    <Col lg="5">
                        <div className='d-flex flex-column align-items-center py-5' key={room.Code}>
                            <span className='fs-6 fw-bold text-dark'>{room.Name}</span>
                            <Row className='d-flex justify-content-between my-3'>
                                <Col xs="6" className='d-flex flex-column align-items-center'>
                                    <div title='No. Beds' className='border rounded p-3 shadow-sm'>
                                        <Bed color='#212529' strokeWidth={1} size={20} />
                                    </div>
                                    <div className='text-center mt-2'>{room.Board}</div>
                                </Col>

                                <Col xs="3" className='d-flex flex-column align-items-center'>
                                    <div title='No. Adults' className='border rounded p-3 shadow-sm'>
                                        <Users color='#212529' strokeWidth={1} size={20} />
                                    </div>
                                    <div className='text-center mt-2'>x{room.Adult}</div>
                                </Col>

                                <Col xs="3" className='d-flex flex-column align-items-center'>
                                    <div title='No. Children' className='border rounded p-3 shadow-sm'>
                                        <Baby color='#212529' strokeWidth={1} size={20} />
                                    </div>
                                    <div className='text-center mt-2'>x{room.Child}</div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg="3" className='d-flex flex-column align-items-center justify-content-center'>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <div><span className='fs-5 fw-bold text-dark'>{room.Price}£</span>/{t('night')}</div>
                            <a href={`/booking/hotel/${id}`}><button className='btn-blue fw-bold py-3 px-4 rounded-5 my-3'>{t('reserve')}</button></a>
                        </div>
                    </Col>
                </Row>
            ))}

            <Row className="d-flex justify-content-center my-5">
                <Col className="d-flex justify-content-between" xl="6">
                    <button className='btn border d-flex align-items-center justify-content-center rounded' onClick={goToFirstPage} disabled={currentPage === 0}>
                        <ChevronFirst size={15} />
                    </button>
                    <button disabled={currentPage === 0} className='btn border rounded d-flex align-items-center justify-content-center' onClick={goToPrevPage}><ChevronLeft size={15} /></button>
                    {paginationButtons}
                    <button className='btn border rounded d-flex align-items-center justify-content-center' onClick={goToNextPage} disabled={currentPage === pageCount - 1}><ChevronRight size={15} /></button>
                    <button className='btn border d-flex align-items-center justify-content-center rounded' onClick={goToLastPage} disabled={currentPage === pageCount - 1}>
                        <ChevronLast size={15} />
                    </button>
                </Col>
            </Row>
        </div>
    );
};

export default Rooms;
