import Home from './Home'
import Search from './Search'

function Main(props) {
    const composite = {
        home: <Home />,
        search: <Search />,
    };
    return (
        <>   {eval("composite." + props.component)}
        </>

    );

}
export default Main;