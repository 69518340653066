import P from "../slide/icone3.png";
import Button from 'react-bootstrap/Button';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Reservation2(){
    return(
        <div>
            <Container style={{marginTop:'2vw'}}>
                    <Row>
                            <Col className="formshop">
                                    <Col lg="2" md="12" className="imgshop">
                                        <img className="imgshp" src={P} style={{float:'left'}}/> 

                                    </Col>
                                    <Col lg="3" md="12" className="imgshop" style={{display:'flex',flexDirection:'column',marginRight:'1vw'}}>
                                        <h1 className="h1cart" style={{lineHeight:'2.5',marginBottom:'0.5rem'}}>Amadeus Dz</h1>
                                        <div><a style={{color:'#505050'}} className="pspspss">Airport name - AH1190 - H</a> </div>  
                                        <div><a style={{color:'#505050'}} className="pspspss"> Departure:</a> <span style={{float:'right'}}>25/12/2023</span> </div>
                                        <div><a style={{color:'#505050'}} className="pspspss"> Return:</a> <span style={{float:'right'}}>25/01/2024</span></div>
                                        <div>
                                    
                                                            

                                                            <Button id="btnid" style={{backgroundColor:'#861e12', padding: '5px 10px',margin:'auto',float:'left',marginRight:'8px', fontSize:'0.8rem',marginTop:'8px', fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                                               Non Refundable
                                                            </Button>
                                                        
                                                            
                                                            <Button id="btnid1" style={{ padding: '5px 10px',margin:'auto', float:'left',marginRight:'8px', fontSize:'0.8rem',marginTop:'8px', fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                                                <a href="/FullDetails" style={{color:'#fff'}}>+ Full details</a>
                                                            </Button>
                                                        
                                        </div>               
                                        
                                       

                                    </Col>
                                    <Col lg="3" md="12" className="imgshop" style={{marginRight:'10px'}}>
                                        <h1 className="h1cart" style={{lineHeight:'2.5',marginBottom:'0.5rem'}}> Supplier Name <br/> </h1>
                                        <a style={{color:'#505050'}} className="pspspss">Passenger:</a><span style={{float:'right'}}>rabia said</span> <br/>  
                                        <a style={{color:'#505050'}} className="pspspss">Agent:</a> <span style={{float:'right'}}>A. Smith</span><br/> 
                                        <a style={{color:'#505050'}} className="pspspss">Carthage Airport <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" color="#861e12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"/><path d="M2 12H22"/></svg></a><span style={{float:'right'}}>El Mouradi</span> <br/>  
                                        <a style={{color:'#505050'}} className="pspspss">Orly ORY Paris</a> <span style={{float:'right'}}> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" color="#861e12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-left"><path d="M6 8L2 12L6 16"/><path d="M2 12H22"/></svg> El Mouradi</span>  <br/> 
                                        



                                    </Col>
                                    <Col lg="2" md="12" className="imgshop" style={{marginRight:'10px'}}>
                                        <h1 className="h1cart" style={{color:'#cd0000',lineHeight:'2.5',marginBottom:'0.5rem'}}>Canceled 
                                        
                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id="tooltip-disabled" >PPC</Tooltip>}>
                                      
                                        <span style={{float:'right'}}><svg xmlns="http://www.w3.org/2000/svg" color="#1a536d" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-credit-card"><rect width="20" height="14" x="2" y="5" rx="2"/><line x1="2" x2="22" y1="10" y2="10"/></svg></span>
                                        </OverlayTrigger>
                                        <br/> </h1>
                                        
                                        <a style={{color:'#505050'}} className="pspspss">N°:</a><span style={{float:'right'}}>RB1702734341</span> <br/>  
                                         <a style={{color:'#505050'}} className="pspspss">City:</a><span style={{float:'right'}}>Paris/France</span> <br/>  
                                        <a style={{color:'#505050'}} className="pspspss">CD</a> <span style={{float:'right'}}>01/12/2023</span>  <br/> 
                                        <a style={{color:'#505050'}} className="pspspss">Price:</a><span style={{float:'right'}}>$ 242.00</span> <br/>  
                                       
                                    </Col>
                                    <Col lg="2" md="12" style={{textAlign:'center'}}>
                                        
                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id="tooltip-disabled" >Delete</Tooltip>}>
                                            
                                                <a href="/Cancell" style={{textDecoration:'none',color:'#1a536d' ,paddingRight:'1vw'}}> <svg xmlns="http://www.w3.org/2000/svg"  width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg></a>
                                            </OverlayTrigger> 
                                    
                                    
                                    </Col>
                            </Col>
                            
                    </Row>
                </Container>
                
        </div>
    );
}

export default Reservation2;