import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import Userf from "../slide/user.png"
import Userf1 from "../slide/us.png"

import SubHeaderPlatform from '../components/SubHeaderPlatform'
import HeaderDetails from '../components/HeaderDetails'




function Users(){
    return(
        <div  >
            
            <SubHeaderPlatform />
            <HeaderDetails />
            <div style={{backgroundColor: '#f7f6f5'}}>
            <div className="bci">
                        <h3 className="spancd" style={{fontFamily:'Cormorant Garamond'}}>Team Members</h3> 
                    </div>
                <Container style={{}}>
                    <Row style={{paddingBottom:'10vw', borderRadius: '6px'}}>
                                    <h1 className="h1cartt" style={{marginBottom:'1vw',paddingTop:'3vw', color:'#505050' , border:'none'}}></h1>
                                    
                                    <Col lg="5" md="12" className="users1">
                                    <a href="/AdminUser" style={{textDecoration:'none'}}>

                                        <img className="jimg"  src={Userf} alt="user" style={{borderRadius:'50%',width:'25%',}} />
                                        <h4 style={{textAlign:'center',paddingTop:'2vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                                @Admin 
                                            - DZ#1#16905 
                                                
                                                </p>
                                                <span style={{color:'#505050'}}></span>  
                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>  
                                            </a>

                                    </Col>
                                    <Col lg="3" md="12" className="users1">
                                        <img className="jimg"  src={Userf1} alt="user" style={{borderRadius:'50%',width:'35%',}} />
                                        <h4 style={{textAlign:'center',paddingTop:'3vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                                @Agent 1 - DZ#1#16905 </p>

                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>
                                    </Col>
                                    <Col lg="3" md="12" className="users1">
                                        <img className="jimg"  src={Userf} alt="user" style={{borderRadius:'50%',width:'35%',}} />
                                        <h4 style={{textAlign:'center',paddingTop:'3vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                            @Agent 2 - DZ#1#16905</p>

                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>
                                    </Col>
                                    <Col lg="3" md="12" className="users1">
                                        <img className="jimg"  src={Userf} alt="user" style={{borderRadius:'50%',width:'25%',}} />
                                        <h4 style={{textAlign:'center',paddingTop:'2vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                            @Agent 3 - DZ#1#16905 </p>

                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>
                                    </Col>
                                    <Col lg="3" md="12" className="users1">
                                        <img className="jimg"  src={Userf1} alt="user" style={{borderRadius:'50%',width:'25%',}} />
                                        <h4 style={{textAlign:'center',paddingTop:'2vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                            @Agent 4 - DZ#1#16905 </p>

                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>
                                    </Col>
                                    <Col lg="3" md="12" className="users1">
                                        <img className="jimg"  src={Userf} alt="user" style={{borderRadius:'50%',width:'25%',}} />
                                        <h4 style={{textAlign:'center',paddingTop:'2vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                                @Agent 5 - DZ#1#16905 </p>

                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>
                                    </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Users;