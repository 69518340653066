import Home from './Home'
import AddProduct from './AddProduct'
import ActivityCreate from './activity/Create'
import ActivityInformations from './activity/Informations';
import ActivityAttributes from './activity/Attributes';
import ActivityAdditional from './activity/Additional';
import ActivityPhotos from './activity/Photos'
import ActivityReview from './activity/Review'
import AccomodationCreate from './accomodation/Create'
import AccomodationInformations from './accomodation/Informations'
import AccommodationAttributes from './accomodation/Attributes'
import AccommodationAdditional from './accomodation/Additional'
import AccommodationPhotos from './accomodation/Photos'
import AccommodationReview from './accomodation/Review'
import CarCreate from './car/Create'
import CarInformations from './car/Informations'
import CarAttributes from './car/Attributes'
import CarPhotos from './car/Photos'
import CarReview from './car/Review'
import PackageCreate from './package/Create'
import PackageInformations from './package/Informations'
import PackageAttributes from './package/Attributes'
import PackagePhotos from './package/Photos'
import PackageReview from './package/Review'
import TrasportationCreate from './transportation/Create'
import TrasportationInformations from './transportation/Informations'
import TrasportationAttributes from './transportation/Attributes'
import TrasportationAdditional from './transportation/Additional'
import TrasportationPhotos from './transportation/Photos'
import TrasportationReview from './transportation/Review'

function Main(props) {
    
    const composite = {
        home: <Home />,
        products: <AddProduct />,
        activity_create:<ActivityCreate/>,
        activity_informations:<ActivityInformations/>,
        activity_attributs:<ActivityAttributes/>,
        activity_additional:<ActivityAdditional/>,
        activity_photos:<ActivityPhotos/>,
        activity_review:<ActivityReview/>,
        accomodation_create:<AccomodationCreate/>,
        accomodation_informations:<AccomodationInformations/>,
        accommodation_attributes:<AccommodationAttributes/>,
        accommodation_additional:<AccommodationAdditional/>,
        accommodation_photos:<AccommodationPhotos/>,
        accommodation_review:<AccommodationReview/>,
        car_create:<CarCreate/>,
        car_informations:<CarInformations/>,
        car_attributes:<CarAttributes/>,
        car_photos:<CarPhotos/>,
        car_review:<CarReview/>,
        package_create:<PackageCreate/>,
        package_informations:<PackageInformations/>,
        package_attributes:<PackageAttributes/>,
        package_photos:<PackagePhotos/>,
        package_review:<PackageReview/>,
        transportation_create:<TrasportationCreate/>,
        transportation_informations:<TrasportationInformations/>,
        transportation_attributes:<TrasportationAttributes/>,
        transportation_additional:<TrasportationAdditional/>,
        transportation_photos:<TrasportationPhotos/>,
        transportation_review:<TrasportationReview/>,
    };

    return (
        <>           
            {eval("composite." + props.component)}
        </>

    );

}

export default Main;