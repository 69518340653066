import { GaugeCircle, PackagePlus, Power, Settings2, UserCog2 } from "lucide-react"
import logo from '../../assets/logodark.svg'
import { useTranslation } from 'react-i18next';

const LeftSide = ({handleClick,boutonActif}) => {

    const { t } = useTranslation();

    const buttons = [
        {title:t('dashboard'),value:"dashboard",icon:<GaugeCircle strokeWidth={1.5} />},
        {title:t('add_product'),value:"create",icon:<PackagePlus strokeWidth={1.5} />},
        {title:t('manage_product'),value:"manage",icon:<Settings2 strokeWidth={1.5} />},
        {title:t('settings'),value:"settings",icon:<UserCog2 strokeWidth={1.5} />},
    ]

    return (
        <div className="p-3">
            <div className="p-4">
                <a href="/"><img src={logo} alt="logo du site" /></a>
            </div>
            <hr className="border-secondary" />
            <div className="p-3 fs-6 text-dark">

                {buttons.map((button,index)=>(
                    <div key={index} className={`d-flex align-items-center my-4 ${boutonActif === button.value ? 'text-blue' : ''}`} role="button" onClick={() => handleClick(button.value)} >{button.icon} <span className="mx-2">{button.title}</span></div>
                ))}

                <div role="button"><Power strokeWidth={1.5} /><span className="mx-2">{t('log_out')}</span></div>

            </div>
        </div>
    )
}

export default LeftSide