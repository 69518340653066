import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../slide/97.png";
import Button from 'react-bootstrap/Button';



function Join4(){
    return(
            <div className="qsd111">
            <Container style={{paddingTop:'6vw',paddingBottom:'10vw'}}>
                <Row>
                    <Col  xs="12"  xl="6"  className="j1" style={{ marginTop:'1vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976',marginBottom:'1rem'}}> +1 Million Hotels</p>

                            <h2 className="h2join">Highlight your Hotel  <br/>to Over 5000 Agencies</h2>
                        
                        </div> 
                        <div style={{marginBottom:'32px',float:'right',paddingLeft:'0vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw'}}>

                                The Hotelidh platform provides an innovative management system, streamlining operations and connecting with over 1M travel agencies globally.
                            <br/><br/>
                                Hotelidh offers an advanced management solution, guaranteeing ease of use while offering exceptional power and efficiency, thus optimizing your presence in the global market
                            
                            </p>

                            <p className="joinp1" style={{padding:'1vw'}}>
                            We ensure the visibility of your hotel through an extensive network of travel agencies, thus improving international visibility.
                            <br/><br/>

                            we ara dedicated to providing cost reductions, optimizing your profitability without compromising service quality.
                            </p>
                        </div>
                        <div style={{float:'left'}}>
                        <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                    color: white;
                                   
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                          
                                    background-color:#772828;
                                    padding: 8px 40px;
                                    border-radius: 0px;
                                    font-family: 'Cormorant Garamond';
                                    
                                    }

                                    `}
                                    </style>

                                    <Button id="btnid" style={{margin:'auto', fontSize:'0.9rem',fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                       <a href="SignUp">Sign Up</a>
                                    </Button>
                                </>
                                </div>
                        
                    </Col>
                    <Col  xs="12"  xl="6" className="mt-2">
                        <img src={SD} className="img-fluid"  alt="hotelidh" />
                    </Col>
                </Row>
            </Container>
            </div>
        
    );
}

export default Join4;