import { DateRangePicker } from 'rsuite';
import { ArrowRight, CalendarCheck2, CalendarX2 } from "lucide-react"
import { useTranslation } from 'react-i18next';

const DateInput = ({ rightLabel, leftLabel }) => {

    const { t } = useTranslation();


    const { beforeToday } =
        DateRangePicker;

    return (
        <div className="d-flex flex-column">

            <label htmlFor="date" className="fs-6 fw-medium title-blue"><CalendarCheck2 strokeWidth={1} size={20} className="form-icon mx-2" /> {rightLabel} <ArrowRight size={15} strokeWidth={1} className="mx-3" /> <CalendarX2 strokeWidth={1} size={20} className="form-icon me-2" /> {leftLabel}</label>

            <DateRangePicker id="date" shouldDisableDate={beforeToday()} showHeader={false} format="dd.MM.yyyy" label={t('select_date')} />
        </div>
    )
}

export default DateInput