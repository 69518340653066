import { Microwave, Tv, Wifi, AirVent, Car, Waves, Dumbbell, Refrigerator, LampDesk, Bath, Utensils, ConciergeBell, CakeSlice, Cigarette, User, ChevronsUpDown, Accessibility, Shirt, Inbox, Luggage, Webcam, PawPrint } from 'lucide-react'
import Recap from './Recap'
import { Row, Col } from 'react-bootstrap'
import { useReducer } from 'react';
import Checkbox from "../../../components/forms/Checkbox";
import { useState } from 'react';
import TopBanner from '../../../components/TopBanner';
import Select from '../../../components/forms/Select';
import supplierData from '../../../data/globaldata.json'

const attributes = {
    accommodation_facilities: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_FACILITY':
            const updatedFacilities = state.accommodation_facilities.includes(action.payload)
                ? state.accommodation_facilities.filter(facility => facility !== action.payload)
                : [...state.accommodation_facilities, action.payload];
            localStorage.setItem('accommodation_facilities', JSON.stringify(updatedFacilities));
            return { ...state, accommodation_facilities: updatedFacilities };

        default:
            return state;
    }
};

const Attributes = () => {

    const [state, dispatch] = useReducer(reducer, attributes);

    const [checkboxes, setCheckboxes] = useState([
        { id: 1, title: "Wifi", isChecked: false, value: 'wifi', icon: <Wifi strokeWidth={1.2} size={35} /> },
        { id: 2, title: "Television", isChecked: false, value: 'television', icon: <Tv strokeWidth={1.2} size={35} /> },
        { id: 3, title: "Kitchen", isChecked: false, value: 'kitchen', icon: <Microwave strokeWidth={1.2} size={35} /> },
        { id: 4, title: "Conditioner", isChecked: false, value: 'conditioner', icon: <AirVent strokeWidth={1.2} size={35} /> },
        { id: 5, title: "Parking", isChecked: false, value: 'parking', icon: <Car strokeWidth={1.2} size={35} /> },
        { id: 6, title: "Pool", isChecked: false, value: 'pool', icon: <Waves strokeWidth={1.2} size={35} /> },
        { id: 7, title: "Fridge", isChecked: false, value: 'fridge', icon: <Refrigerator strokeWidth={1.2} size={35} /> },
        { id: 8, title: "Gym", isChecked: false, value: 'gym', icon: <Dumbbell strokeWidth={1.2} size={35} /> },
        { id: 9, title: "Desk", isChecked: false, value: 'desk', icon: <LampDesk strokeWidth={1.2} size={35} /> },
        { id: 10, title: "Jacuzzi", isChecked: false, value: 'jacuzzi', icon: <Bath strokeWidth={1.2} size={35} /> },
        { id: 11, title: "Breakfast", isChecked: false, value: 'breakfast', icon: <CakeSlice strokeWidth={1.2} size={35} /> },
        { id: 12, title: "Restaurant", isChecked: false, value: 'restaurant', icon: <Utensils strokeWidth={1.2} size={35} /> },
        { id: 13, title: "24h/24 front desk", isChecked: false, value: '24h/24 frontdesk', icon: <ConciergeBell strokeWidth={1.2} size={35} /> },
        { id: 14, title: "Smoking room", isChecked: false, value: 'smoking', icon: <Cigarette strokeWidth={1.2} size={35} /> },
        { id: 15, title: "Room service", isChecked: false, value: 'service', icon: <User strokeWidth={1.2} size={35} /> },
        { id: 16, title: "Elevator", isChecked: false, value: 'elevator', icon: <ChevronsUpDown strokeWidth={1.2} size={35} /> },
        { id: 17, title: "Wheelchair accessible", isChecked: false, value: 'wheelchair accessible', icon: <Accessibility strokeWidth={1.2} size={35} /> },
        { id: 18, title: "Washer", isChecked: false, value: 'washer', icon: <Shirt strokeWidth={1.2} size={35} /> },
        { id: 19, title: "Safe-deposit box", isChecked: false, value: 'safe box', icon: <Inbox strokeWidth={1.2} size={35} /> },
        { id: 20, title: "Luggage storage", isChecked: false, value: 'luggage storage', icon: <Luggage strokeWidth={1.2} size={35} /> },
        { id: 21, title: "Security cameras", isChecked: false, value: 'security cameras', icon: <Webcam strokeWidth={1.2} size={35} /> },
        { id: 22, title: "Pets allowed", isChecked: false, value: 'pets allowed', icon: <PawPrint strokeWidth={1.2} size={35} /> },
    ]);

    const toggleCheckbox = (id, value) => {
        dispatch({ type: 'TOGGLE_FACILITY', payload: value });
        setCheckboxes(prevState =>
            prevState.map(checkbox =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };

    return (
        <section>
            <TopBanner data="Attributes" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/accommodation/additional'>
                            <fieldset>
                                <legend className='text-dark fs-2 fw-medium'>Tell travelers what equipment does your accommodation have?</legend>
                                <p className='fs-6 my-3'>You can select from this list everything that your accommodation contains in terms of services and equipment in order to better sell it to customers.</p>

                                <div className='my-3'>

                                    <Row>
                                        {checkboxes.map((checkbox, index) => (
                                            <Col key={index} lg="3" className='my-2'>
                                                <Checkbox
                                                    key={checkbox.id}
                                                    id={checkbox.id}
                                                    isChecked={checkbox.isChecked}
                                                    value={checkbox.value}
                                                    onChange={toggleCheckbox}
                                                    title={checkbox.title}
                                                    icon={checkbox.icon}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </div>

                                <Row>
                                    <Col sm="6" className='my-3'>
                                        <span className='fs-5 text-dark fw-bold'>The deadline for arrival of travelers?</span>
                                        <div className='my-3'>
                                            <Select data={supplierData.schedules} />
                                        </div>
                                    </Col>
                                    <Col sm="6" className='my-3'>
                                        <span className='fs-5 text-dark fw-bold'>The departure time for travelers?</span>
                                        <div className='my-3'>
                                            <Select data={supplierData.schedules} />
                                        </div>
                                    </Col>
                                </Row>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5 text-white'>Continue</button>
                            </fieldset>

                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>


            </div>
        </section>
    )
}

export default Attributes