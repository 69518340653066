class HotelInput {

    constructor(dataPost) {
        this.fieleds = [];
        this.dataPost = dataPost;
        this.fieleds =
        {
            "destination": {
                "element": "input",
                "type": "text",
                "placeholder":'hotel_destination_placeholder',
                "data-ctrl": "true",
                "value": this.dataPost.destination,
                "autocomplete": "off",
                "className": "w-100 h-100 bg-white border-0  text-left p-0 text-dark p-2"
            },
            "calendar": {
                "element": "daterange",
                "type": "text",
                "readonly": "true",
                "data-ctrl": "true",
                "className": "w-100 border-none border-0 p-2 text-left",
                "startDate": this.dataPost.checkin,
                "endDate": this.dataPost.checkout
            },

            "occupancy": {
                "element": "dropdown",
                "type": "dropdown",
                "placeholder": "",
                "value": 'rooms' + ' 1 , ' + 'adults' + ' 2 ' + 'childs' + ' 0 ',
                "className": "w-100 h-100 bg-white text-left text-dark p-0 rounded idownarrow-2 p-2"
            },

            "id": {
                "element": "input",
                "type": "hidden",
                "value": this.dataPost.id,
                "data-ctrl": "true",
                "data-focus": "#destination"
            },
            "city_id": {
                "element": "input",
                "type": "hidden",
                "value": this.dataPost.city_id,
                "data-ctrl": "true",
                "data-focus": "#destination"
            },
            "country_id": {
                "element": "input",
                "type": "hidden",
                "value": this.dataPost.country_id,
                "data-ctrl": "true",
                "data-focus": "#destination"
            },
            "type": {
                "element": "input",
                "type": "hidden",
                "value": this.dataPost.type,
                "data-ctrl": "true",
                "data-focus": "#destination"
            },
            "nationality": {
                "element": "input",
                "type": "hidden",
                "value": this.dataPost.nationality,
                "data-ctrl": "true"
            },
            "roomsCount": {
                "element": "input",
                "type": "hidden",
                "value": this.dataPost.roomsCount,
                "data-ctrl": "true"
            },
            "adultCount": {
                "element": "input",
                "type": "hidden",
                "value": this.dataPost.adultCount,
                "data-ctrl": "true"
            },
            "childCount": {
                "element": "input",
                "type": "hidden",
                "value": this.dataPost.childCount,
                "data-ctrl": "true"
            }
        }
    }
}

export default HotelInput;