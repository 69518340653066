import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Uploader, Loader } from 'rsuite';
import Input from '../../components/forms/Input'
import { useTranslation } from 'react-i18next';

function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result);
    };
    reader.readAsDataURL(file);
}

const Settings = () => {
    const [uploading, setUploading] = React.useState(false);
    const [fileInfo, setFileInfo] = React.useState(null);
    const { t } = useTranslation();

    return (
        <div className='p-3'>
            <div className='fs-3 fw-bold text-dark'>{t('settings')}</div>
            <p className='my-1 fs-6'>{t('settings_text')}</p>
            <div className='my-3 bg-white p-3 rounded border'>
                <Row className='align-items-center'>
                    <Col xs="5" md="3" lg="2">
                        <Uploader
                            fileListVisible={false}
                            listType="picture"
                            action="//jsonplaceholder.typicode.com/posts/"
                            onUpload={file => {
                                setUploading(true);
                                previewFile(file.blobFile, value => {
                                    setFileInfo(value);
                                });
                            }}
                            onSuccess={() => {
                                setUploading(false);
                            }}
                            onError={() => {
                                setFileInfo(null);
                                setUploading(false);
                            }}
                        >
                            <button style={{ width: 150, height: 150 }}>
                                {uploading && <Loader backdrop center />}
                                {fileInfo ? (
                                    <img src={fileInfo} width="100%" height="100%" alt='user profile' />
                                ) : (
                                    <img width="100%" height="100%" alt='avatar' src='https://modernize-angular-main.netlify.app/assets/images/profile/user-7.jpg' />
                                )}
                            </button>
                        </Uploader>
                    </Col>
                    <Col xs="4" md="3" lg="2">
                        <div className='fs-5 fw-medium text-dark'>{t('your_avatar')}</div>
                        <p className='my-3'>PNG or JPG no bigger than 800px wide and tall.</p>
                    </Col>
                </Row>
                <Row>
                    <Col xl="8">
                        <form>
                            <fieldset>
                                <Row>
                                    <Col lg="4" xl="12" className='my-2'>
                                        <Input placeholder={t('business_name')} />
                                    </Col>
                                    <Col sm="6" lg="4" xl="6" className='my-2'>
                                        <Input placeholder={t('first_name')} />
                                    </Col>
                                    <Col sm="6" lg="4" xl="6" className='my-2'>
                                        <Input placeholder={t('last_name')} />
                                    </Col>

                                    <Col sm="6" className='my-2'>
                                        <Input placeholder={t('email')} />
                                    </Col>
                                    <Col sm="6" className='my-2'>
                                        <Input placeholder={t('phone_number')} />
                                    </Col>

                                    <Col xs="12" className='my-2'>
                                        <Input placeholder={t('country')} />
                                    </Col>

                                    <Col md="4" className='my-2'>
                                        <Input placeholder={t('current_password')} />
                                    </Col>

                                    <Col md="4" className='my-2'>
                                        <Input placeholder={t('current_password_again')} />

                                    </Col>
                                    <Col md="4" className='my-2'>
                                        <Input placeholder={t('new_password')} />
                                    </Col>

                                </Row>
                                <button className='btn-blue p-3 rounded-5 my-3' type='submit'>{t('save_changes')}</button>
                            </fieldset>
                        </form>
                    </Col>
                </Row>

            </div>

        </div>
    )
}

export default Settings