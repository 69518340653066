import { Bus, Car, CarFront } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import Recap from './Recap'
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/Select';
import { useReducer } from 'react';
import { RadioTile, RadioTileGroup } from 'rsuite';
import TopBanner from '../../../components/TopBanner';
import supplierData from '../../../data/globaldata.json'

const create = {
    vehicle_name: '',
    vehicle_model: '',
    vehicle_type: '',
    vehicle_condtion: '',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_NAME':
            localStorage.setItem('vehicle_name', action.payload);
            return { ...state, vehicle_name: action.payload };

        case 'SET_MODEL':
            localStorage.setItem('vehicle_model', action.payload);
            return { ...state, vehicle_model: action.payload };

        case 'SET_TYPE':
            localStorage.setItem('vehicle_type', action.payload);
            return { ...state, vehicle_type: action.payload };

        case 'SET_CONDITION':
            localStorage.setItem('vehicle_condtion', action.payload);
            return { ...state, vehicle_condtion: action.payload };

        default:
            return state;
    }
};

const Create = () => {

    const [state, dispatch] = useReducer(reducer, create);

    const handleRadioChange = (type) => (e) => {
        dispatch({ type, payload: e });
    };

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    const vehicletype = [
        { title: "Car", icon: <CarFront strokeWidth={1.2} size={35} />, value: "Car", description: "Compact vehicle for daily passenger transport." },
        { title: "Suv", icon: <Car strokeWidth={1.2} size={35} />, value: "Suv", description: "Versatile, robust vehicle with raised ride, suitable for off-road adventures." },
        { title: "Van", icon: <Bus strokeWidth={1.2} size={35} />, value: "Van", description: "Spacious vehicle for transporting passengers or goods" },
    ]

    return (
        <section>
            <TopBanner data="Create" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>

                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/car/informations'>
                            <fieldset>
                                <legend className='fs-2 text-dark fw-medium'>What type of car will be available to travelers?</legend>
                                <p className='my-3 fs-6'>Give correct informations and specify what type of vehicle you own and its condition</p>

                                <Row>
                                    <Col md="6" xl="6">
                                        <div className='my-3'>
                                            <Input value={state.vehicle_name} type="text" handleChange={handleChange('SET_NAME')} placeholder="Car name or title" maxlength="200" />
                                        </div>
                                    </Col>
                                    <Col md="6" xl="6">
                                        <div className='my-3'>
                                            <Input value={state.vehicle_model} type="text" handleChange={handleChange('SET_MODEL')} placeholder="Car model" maxlength="200" />
                                        </div>
                                    </Col>
                                </Row>

                                <div className='my-3'>
                                    <RadioTileGroup aria-label="Create new project">
                                        <Row>
                                            {vehicletype.map((choice, index) => (
                                                <Col key={index} md="12" className='my-2'>
                                                    <RadioTile value={choice.value} onChange={handleRadioChange('SET_TYPE')} className={`p-3 ${state.vehicle_type === choice.value ? 'border-dark' : 'border'}`} >
                                                        <div className='text-dark'>
                                                            {choice.icon}
                                                            <div className='mt-2 fs-5 fw-medium'>{choice.title}</div>
                                                            <p>{choice.description}</p>

                                                        </div>
                                                    </RadioTile>
                                                </Col>
                                            ))}
                                        </Row>
                                    </RadioTileGroup>
                                </div>
                                <Row className='align-items-center'>
                                    <Col md="6" className='my-2'>
                                        <span className='fs-6 fw-medium text-dark'>What is the condition of your car?</span>
                                    </Col>
                                    <Col md="6" className='my-2'>
                                    <Select value={state.vehicle_condtion} handleChange={handleChange('SET_CONDITION')} data={supplierData.condition} />
                                    </Col>

                                </Row>
                                <button type='submit' className='btn-blue p-3 px-5 rounded-5'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Create