import './App.css'
import { Session } from "./redux/session"
import BackTop from './components/BackTop'
import RouteComponents from './routeComponents'
import { NotificationContainer } from "react-notifications"
import 'rsuite/dist/rsuite.min.css';


function App() {
  Session()

  return (
    <div className='main'>

       
        <BackTop />
        <RouteComponents />
        <NotificationContainer />

    </div>
  );
}
export default App;
