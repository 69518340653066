import { Nav } from 'react-bootstrap';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../slide/imagessss.png";
import Navbar from 'react-bootstrap/Navbar';


function HeaderDetails(){
    return(
       
       
                        <div className='subhead'style={{paddingBottom:'1vw',paddingTop:'1vw'}} >
                            <Container fluid >
                                <Row style={{}} className=''>
                                
                                    <Col  className='p-0' style={{margin:'auto'}}>
                                    
                                                <Navbar expand="lg" className="bg-body-tertiary p-0"  style={{marginLeft:'3vw'}}>
                                                    <Container fluid style={{backgroundColor:'white'}} className=''>
                                                        <Navbar.Brand href="./" style={{marginRight:'3vw'}}> <img src={Logo} alt="Logo" style={{}} /></Navbar.Brand>
                                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                                        <Navbar.Collapse id="basic-navbar-nav" >
                                                       
                                                                <Nav className="me-auto" style={{float:'right', fontSize:'16px',fontWeight:'550'}}>
                                                                    <Nav.Link href="/flights"  id='linkcp' style={{marginRight:'2vw'}}>
                                                                        <svg style={{marginRight:"8px"}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plane"><path d="M17.8 19.2 16 11l3.5-3.5C21 6 21.5 4 21 3c-1-.5-3 0-4.5 1.5L13 8 4.8 6.2c-.5-.1-.9.1-1.1.5l-.3.5c-.2.5-.1 1 .3 1.3L9 12l-2 3H4l-1 1 3 2 2 3 1-1v-3l3-2 3.5 5.3c.3.4.8.5 1.3.3l.5-.2c.4-.3.6-.7.5-1.2z"/></svg>
                                                                        
                                                                        Flights
                                                                    </Nav.Link>
                                                                  
                                                                    <Nav.Link href="/hotels"  id='linkcp' style={{marginRight:'2vw'}}>
                                                                        <svg style={{marginRight:"8px"}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bed"><path d="M2 4v16"/><path d="M2 8h18a2 2 0 0 1 2 2v10"/><path d="M2 17h20"/><path d="M6 8v9"/></svg>
                                                                    
                                                                        Stays
                                                                    </Nav.Link>

                                                                    <Nav.Link href="/activities"  id='linkcp' style={{marginRight:'2vw'}}>
                                                                        <svg style={{marginRight:"8px"}} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-roller-coaster"><path d="M6 19V5"/><path d="M10 19V6.8"/><path d="M14 19v-7.8"/><path d="M18 5v4"/><path d="M18 19v-6"/><path d="M22 19V9"/><path d="M2 19V9a4 4 0 0 1 4-4c2 0 4 1.33 6 4s4 4 6 4a4 4 0 1 0-3-6.65"/></svg>
                                                                       
                                                                        Visits
                                                                    </Nav.Link>

                                                                    <Nav.Link href="/transferts"  id='linkcp' style={{marginRight:'2vw'}}>
                                                                        <svg style={{marginRight:"8px"}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-left-right"><path d="M8 3 4 7l4 4"/><path d="M4 7h16"/><path d="m16 21 4-4-4-4"/><path d="M20 17H4"/></svg>
                                                                       
                                                                        Transfers
                                                                    </Nav.Link>

                                                                   
                                                                    <Nav.Link href="/cars"  id='linkcp' style={{marginRight:'2vw'}}>
                                                                        <svg style={{marginRight:"8px"}} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-car-front"><path d="m21 8-2 2-1.5-3.7A2 2 0 0 0 15.646 5H8.4a2 2 0 0 0-1.903 1.257L5 10 3 8"/><path d="M7 14h.01"/><path d="M17 14h.01"/><rect width="18" height="8" x="3" y="10" rx="2"/><path d="M5 18v2"/><path d="M19 18v2"/></svg>
                                                                        
                                                                        Car Rental
                                                                    </Nav.Link>

                                                                    <Nav.Link href="/packages"  id='linkcp' style={{marginRight:'2vw'}}>
                                                                        <svg style={{marginRight:"8px"}} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map"><polygon points="3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21"/><line x1="9" x2="9" y1="3" y2="18"/><line x1="15" x2="15" y1="6" y2="21"/></svg>
                                                                        
                                                                        Packages
                                                                    </Nav.Link>
                                                                  
                                                                    <Nav.Link href="/Reservations"  id='linkcp' style={{marginRight:'2vw'}}>
                                                                        <svg style={{marginRight:'8px'}} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-list"><line x1="8" x2="21" y1="6" y2="6"/><line x1="8" x2="21" y1="12" y2="12"/><line x1="8" x2="21" y1="18" y2="18"/><line x1="3" x2="3.01" y1="6" y2="6"/><line x1="3" x2="3.01" y1="12" y2="12"/><line x1="3" x2="3.01" y1="18" y2="18"/></svg>
                                                                        Bookings 
                                                                    </Nav.Link>

                                                                        
                                                                    <Nav.Link href="/Users"  id='linkcp1' style={{marginRight:'2vw'}}  >
                                                                        <svg style={{marginRight:"8px"}} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-users"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M22 21v-2a4 4 0 0 0-3-3.87"/><path d="M16 3.13a4 4 0 0 1 0 7.75"/></svg>
                                                                        Users
                                                                    </Nav.Link>
                                                                    

                                                                    

                                                                    <Nav.Link href="/HelpDesk"  id='linkcp3' style={{marginRight:'2vw'}}>
                                                                    <svg style={{marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-helping-hand"><path d="m3 15 5.12-5.12A3 3 0 0 1 10.24 9H13a2 2 0 1 1 0 4h-2.5m4-.68 4.17-4.89a1.88 1.88 0 0 1 2.92 2.36l-4.2 5.94A3 3 0 0 1 14.96 17H9.83a2 2 0 0 0-1.42.59L7 19"/><path d="m2 14 6 6"/></svg>
                                                                        Help Desk
                                                                    </Nav.Link>
                                                                   
                                                                   
                                                                    
                                                                    
                                                                       
                                                                </Nav>
                                                                
                                                        </Navbar.Collapse>
                                                    </Container>
                                                </Navbar>
                                                



                                    </Col>

                                </Row>
                            </Container>
                        </div>
       
       
        

       
    )
}

export default HeaderDetails; 