import { Panel } from 'rsuite';
import { Star } from 'lucide-react'
import FilterMap from '../../components/FilterMap'
import FilterPrice from '../../components/FilterPrice';
import FiltersData from '../../data/globaldata.json'

const Filters = () => {
    
    return (
        <>
            <div className='d-none d-xl-block position-relative'>
                <FilterMap/>
            </div>
            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <FilterPrice/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Category</span>} collapsible>
                    {FiltersData.category.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                                <input id={item.stars} type='checkbox' />
                                <div className='mx-3 fw-light'>
                                    {Array.from({ length: item.stars }).map((_, starIndex) => (
                                        <label htmlFor={item.stars} className="me-1 my-1" key={starIndex}>
                                            <Star color='#F8D448' fill='#F8D448' size={13} />
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Attractions</span>} collapsible>
                    {FiltersData.tickets.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'>
                                <input id={item.value} type='checkbox' />
                                <label htmlFor={item.value} className='my-1 mx-3 fw-light'>{item.title}</label>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Languages</span>} collapsible>
                    {FiltersData.lang.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'>
                                <input id={item.value} type='checkbox' />
                                <label htmlFor={item.value} className='my-1 mx-3 fw-light'>{item.title}</label>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Duration</span>} collapsible>
                    {FiltersData.duration.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                             <div className='d-flex align-items-center'>
                                <input id={item.value} type='checkbox' />
                                <label htmlFor={item.value} className='my-1 mx-3 fw-light'>{item.title}</label>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Time</span>} collapsible>
                    {FiltersData.time.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'>
                                <input id={item.value} type='checkbox' />
                                <label htmlFor={item.value} className='my-1 mx-3 fw-light'>{item.title}</label>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

        </>
    )
}

export default Filters