import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Reasons() {

   const { t } = useTranslation();

   const reasons = [
      { title:t('comptetetive_prices'), img: "1.svg", description:t('reasons1') },
      { title:t('smooth_booking'), img: "2.svg", description:t('reasons2') },
      { title:t('rich_catalog'), img: "3.svg", description:t('reasons3') },
      { title:t('assistance24h'), img: "4.svg", description:t('reasons4') },
   ]

   return (
      <section className='container'>

         <Row className='justify-content-between py-5'>
            <Col lg="6" className='px-5 py-3 d-flex flex-column align-items-start'>
               <h1 className='text-dark fw-medium'>{t('why_choose_us')}</h1>
               <h2 className='fs-5 fw-medium'>{t('reasons_sub')}</h2>
               <p className='pe-5 mt-5 fs-6' dangerouslySetInnerHTML={{__html: t('reasons_text')}}></p>
               <a href='/about' role='button' className='btn-blue mt-5 p-3 px-5 rounded-5 d-flex align-items-center text-decoration-none text-white'>{t('learn_more')}</a>
            </Col>
            <Col lg="6" className='py-3'>
               <Row>
                  {reasons.map((reason, index) => (
                     <Col md="6" key={index}>
                        <div className='d-flex flex-column p-3'>
                           <img width={70} height={70} src={require(`../../assets/icones/${reason.img}`)} alt={`Illustration pour -${reason.title}`} />
                           <span className='fw-bold fs-5 mt-3 text-dark'>{reason.title}</span>
                           <p className='mt-3'>{reason.description}</p>
                        </div>
                     </Col>
                  ))}
               </Row>
            </Col>
         </Row>
      </section>
   );
}

export default Reasons;