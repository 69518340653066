import { Uploader } from 'rsuite';
import { Row, Col } from 'react-bootstrap'
import Recap from './Recap';
import TopBanner from '../../../components/TopBanner';
import { Camera } from 'lucide-react';

const Photos = () => {

    return (
        <section>
            <TopBanner data="Photos" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/accommodation/review'>
                            <fieldset>
                                <legend className='fs-2 fw-medium text-dark'>Upload your photos</legend>
                                <p className='fs-6'>Relevant, engaging photos potentially increase your bookings and earnings.</p>

                                <div role='button' className='my-4'>
                                    <Uploader action="//jsonplaceholder.typicode.com/posts/" type="file" draggable>
                                        <div className='border' style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div className='text-center'>
                                                <div className='fs-5 fw-medium d-flex align-items-center'><Camera/> <span className='mx-2'>Click or Drag files to this area to upload</span></div>
                                                <p>06 unique photos are needed to get started</p>
                                            </div>
                                        </div>
                                    </Uploader>

                                </div>
                                <button type='submit' className='btn-blue p-3 px-5 rounded-5 text-white'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Photos