import About1 from "./About1";
import About2 from "./About2";
import About3 from "./About3";
import About5 from "./About5";
import About4 from "./About4";
import Header from "./home/Header";
import SubHeader from "./home/SubHeader";
import NewFoot from "./home/NewFoot";
function About(){
    return(
        <div>
            <SubHeader />
            <Header />
            <About1 />
            <About2 />
            <About3 />
            <About5 />
            <About4/>
            <NewFoot />
        </div>
    );
}

export default About;