import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../slide/6588.jpg";



function About2(){
    return(
            <div className="qsd1">
            <Container style={{paddingTop:'6vw',paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6" >
                        <img src={SD} className="img-fluid"  alt="hotelidh" />
                    </Col>
                    <Col xs="12"  xl="6" className="j1" style={{marginTop:'7vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976'}}>ABOUT HOTELIDH</p>

                            <h2 className="h2join">We have 20+ years  <br/>of Experience</h2>
                 
                        </div> 
                        <div style={{marginBottom:'32px'}}>
                            <p className="joinp1" style={{paddingLeft:'0vw'}}>
                                
                            Hotelidh, a leading B2B booking platform, has established itself as a cornerstone in the travel industry for more than 20 years, accumulating unmatched expertise and excellence. Our extensive experience is complemented by a diverse range of travel products, including accommodation options, flights, transfers, and car rentals. This deep expertise positions Hotelidh as an innovative and reliable player in the B2B travel sector, where our commitment to precision and professionalism remains unwavering.  
                              </p>
                        </div>
                        
                    </Col>
                </Row>
            </Container>
            </div>
        
    );
}

export default About2;