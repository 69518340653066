import { MapPin, Search, Car } from "lucide-react"
import { Row, Col } from 'react-bootstrap'
import InputCity from "../../../components/forms/InputCity";
import DateInput from "../../../components/forms/DateInput";

const Availability = () => {

    return (
        <section className="container px-xl-5">
            <form action="/cars/search">
                <div className='bg-white text-dark p-lg-3 py-lg-2 w-100 shadow-sm' style={{ overflow: 'hidden', borderRadius: '40px' }}>
                    <Row className="px-1 align-items-center">
                        <Col lg="3" className="border-end">
                            <InputCity id="location" label="Location" placeholder="Where are you going ?"  icon={<MapPin strokeWidth={1} size={20} className="form-icon" />} />
                        </Col>

                        <Col lg="4">
                            <DateInput rightLabel="Pick-Up" leftLabel="Drop-Off" />
                        </Col>

                        <Col lg="3" className="border-start">
                            <InputCity id="model" label="Model" placeholder="Wich model are you looking for ?"  icon={<Car strokeWidth={1} size={20} className="form-icon" />} />
                        </Col>

                        <Col lg="2" className="px-0">
                            <div className="d-flex align-items-center">
                                <button className="btn-blue d-none d-lg-block p-4 px-4 w-100 rounded-5"><Search size={20} /> Search</button>
                                <button className="btn-blue d-lg-none p-3 px-4 w-100"><Search size={20} /> Search</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </form>
        </section>
    )
}

export default Availability