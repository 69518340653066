
export const setToken = (value) => {
    return {
        type: "TOKEN",
        payload: value,
    };
};

export const setIsLogged = (value) => {
    return {
        type: "LOGGED",
        payload: value,
    };
};
export const setLang = () => {
    return {
        type: "LANG",
    };
}; 