import { MapPin, Users, Search, Trash2 } from "lucide-react"
import React, { useState, useEffect } from 'react';
import Guests from "./Guests";
import { Row, Col } from 'react-bootstrap';
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import HotelInput from './HotelInput';
import IForm from '../../../global/IForm'
import InputCity from '../../../components/forms/InputCity';
import DateInput from '../../../components/forms/DateInput';
import { useTranslation } from 'react-i18next';

const Availability = () => {

    const { t } = useTranslation();
    const [showGuests, setShowGuests] = useState(false);

    const handleCloseGuests = () => setShowGuests(false);
    const handleShowGuests = () => setShowGuests(true);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [Todaydate, setDate] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {

            setDate(new Date());
        }, 1000);


        return () => clearInterval(intervalId);
    }, []);

    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = Todaydate.toLocaleDateString('en-US', options);

    const hotel = useSelector((state) => state.hotel);
    const hotelInput = new HotelInput(hotel.dataPost);
    const ifrom = new IForm();

    const [rooms, setRooms] = useState(hotelInput.dataPost.rooms);
    const [showbtnadd, setShowBtnAdd] = useState(true);
    const [adultCount, setAdultCount] = useState(hotelInput.dataPost.adultCount);
    const [childCount, setChildCount] = useState(hotelInput.dataPost.childCount);
    const [roomsCount, setRoomsCount] = useState(hotelInput.dataPost.roomsCount);

    ifrom.init(hotelInput.fieleds);

    const removeRoom = (index) => {
        var roomId = parseInt(index);
        var room = rooms[roomId];
        var child = room.child;
        var adult = room.adult;
        _setAdultCount(adult, 0);
        _setChildCount(child, 0);
        const updatedRooms = rooms.filter((room, i) => i !== index);
        setRooms(updatedRooms);
        _setRoomsCount(-1);
        setShowBtnAdd(true)
    };

    const _setRoomsCount = (nb) => {
        var new_value = parseInt(roomsCount) + parseInt(nb);
        setRoomsCount(new_value);
    };

    const _setAdultCount = (lastValue, value) => {
        var new_value = parseInt(adultCount) - parseInt(lastValue) + parseInt(value);
        setAdultCount(new_value);
    };

    const _setChildCount = (lastValue, value) => {
        var new_value = parseInt(childCount) - parseInt(lastValue) + parseInt(value);
        setChildCount(new_value);
    };

    const addRoom = () => {
        const newRoom = { adult: 1, child: 0, childAge: [] };
        setRooms([...rooms, newRoom]);
        _setRoomsCount(1);
        _setAdultCount(0, 1);
        if (rooms.length + 1 == hotel.maxRooms) { setShowBtnAdd(false) }
    };

    const handleAdultesChange = (event) => {
        var roomId = parseInt(event.target.dataset.roomIndex);
        var room = rooms[roomId];
        var lastValue = room.adult;
        var newValue = event.target.value;
        _setAdultCount(lastValue, newValue);
        room.adult = newValue;
        rooms[roomId] = room;
        setRooms(rooms)
    };

    const handleChildChange = (event) => {
        var roomId = parseInt(event.target.dataset.roomIndex);
        var room = rooms[roomId];
        var lastValue = room.child;
        var newValue = event.target.value;
        _setChildCount(lastValue, newValue);
        room.child = newValue;
        var diff = parseInt(newValue) - parseInt(lastValue);
        if (diff <= 0) {
            var d = parseInt(newValue) - 1;
            if (newValue == 0) { d = 0; }
            room.childAge.splice(d, Math.abs(diff));
        } else {
            for (let i = lastValue; i < newValue; i++) {
                room.childAge.push(1)
            }
        }
        room_age_child(room, roomId)
        rooms[roomId] = room;
        setRooms(rooms)
    };

    const rooming = () => {
        return (
            <>
                {
                    rooms.map((room, index) => {
                        var nb = parseInt(index) + 1;
                        var id = 'room_' + index;
                        return (
                            <div id={id} key={id} className="mb-3 d-flex flex-column" >
                                {/* <span className='fw-bold fs-6' style={{ color: "var(--primary-blue)" }}>Room {nb}</span> */}
                                <div className="row" id={'occupancy_' + id}>
                                    <div className="col-md-6">
                                        {room_adult(room, index)}
                                    </div>
                                    <div className="col-md-6">
                                        {room_child(room, index)}
                                    </div>

                                    {room_age_child(room, index)}

                                </div>

                                <div className="align-self-end" style={{ top: "0", right: "0" }}>{btn_delete(index)}</div>

                            </div>)
                    })
                }

            </>
        );

    }

    const btn_delete = (index) => {
        if (index == 0) { return; }
        var id = 'room_' + index;
        return (<div className='border text-danger p-2 rounded-5' style={{ cursor: 'pointer' }} onClick={() => removeRoom(index)}><Trash2 size={20} /> <span className='text-dark'>Delete</span></div>)

    }

    const room_adult = (room, index) => {

        var html = '';
        for (let i = 1; i < 5; i++) {
            html += '<option value="' + i + '"  >' + i + ' ' + '</option > ';

        }
        return (
            <div className='d-flex flex-column my-3'>
                <label htmlFor={`adults${index}`} className='fw-bold fs-6'>{t('adults')} <span className='guests-notation'>{t('over_12_years_old')}</span></label>
                <select id={`adults${index}`} className='input-blue border mt-1 p-3 rounded' name={'rooms[' + index + '][adult]'} defaultValue={room.child} onChange={handleAdultesChange} data-room-index={index}>
                    {parse(html)}
                </select>
            </div>
        );
    }

    const room_child = (room, index) => {

        var html = '';
        for (let i = 0; i <= 4; i++) {
            html += '<option value="' + i + '"  >' + i + ' ' + '</option > ';
        }

        return (
            <div className='d-flex flex-column my-3'>
                <label htmlFor={`childs${index}`} className='fw-bold fs-6'>{t('childs')} <span className='guests-notation'>{t('from_0_to_11_years_old')}</span></label>
                <select id={`childs${index}`} className='input-blue border mt-1 p-3 rounded' name={'rooms[' + index + '][child]'} defaultValue={room.child} onChange={handleChildChange} data-room-index={index}>
                    {parse(html)}
                </select>
            </div>
        );
    }

    const room_age_child = (room, index) => {

        var html = '';

        for (let i = 1; i <= 11; i++) {
            html += (i > 1 ? '<option value="' + i + '">' + i + ' ans</option>' : '<option value="' + i + '">' + i + ' an</option>');
        }

        return (
            <>
                {
                    room.childAge.map((age, j) => {

                        return (
                            <div className="col-md-3 my-3" key={'div_age_' + index + '_' + j}>
                                <select className='w-100 p-3 input-blue border rounded' name={'rooms[' + index + '][childAge][]'} data-room-index={index} defaultValue={age} key={'age_' + index + '_' + j}>
                                    {parse(html)}
                                </select>
                            </div>
                        )
                    })
                }
            </>

        );

    }

    return (
        <section className="container px-xl-5">
            <form action="/hotels/search">
                <div className='bg-white text-dark p-lg-3 py-lg-2 w-100 shadow-sm' style={{ overflow: 'hidden', borderRadius: '40px' }}>
                    <Row className="px-1 align-items-center">
                        <Col lg="3" className="border-end">
                            <InputCity id="location" label={t('destination')} placeholder={t('where_are_you_going')} icon={<MapPin strokeWidth={1} size={20} className="form-icon" />} />
                        </Col>

                        <Col lg="4" className="border-end">
                            <DateInput rightLabel={t('check_in')} leftLabel={t('check_out')} />
                        </Col>

                        <Col lg="3">
                            <div className="w-100 d-flex align-items-center p-3 p-lg-0" onClick={handleShowGuests} style={{ cursor: "pointer" }}>
                                <Users strokeWidth={1} size={20} className="form-icon" />
                                <div className="d-flex flex-column mx-3">
                                    <span htmlFor="location" className="fs-6 fw-medium title-blue">{t('travelers')}</span>
                                    <div className="text-secondary">{roomsCount} {t('rooms')}, {adultCount + childCount} {t('travelers')}</div>
                                </div>
                            </div>
                        </Col>

                        <Col lg="2" className="px-0">
                            <div className="d-flex">
                                <button className="btn-blue d-none d-lg-block p-4 px-4 w-100 rounded-5"><Search size={20} /> {t('search')}</button>
                                <button className="btn-blue d-lg-none  p-3 px-4 w-100"><Search size={20} /> {t('search')}</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </form>
            <Guests showGuests={showGuests} handleCloseGuests={handleCloseGuests} handleShowGuests={handleShowGuests} rooming={rooming} showbtnadd={showbtnadd} addRoom={addRoom} roomsCount={roomsCount} />
        </section>
    )
}

export default Availability