import { Users, Search, PlaneTakeoff, PlaneLanding } from "lucide-react"
import { Row, Col } from 'react-bootstrap'
import React, { useState } from 'react';
import Guests from "./Guests";
import InputCity from '../../../components/forms/InputCity'
import DateInput from '../../../components/forms/DateInput'
import { useTranslation } from 'react-i18next';

const RoundTrip = () => {

    const { t } = useTranslation();
    const [AdultsCount, setAdultsCount] = useState(1);

    const handlesetAdultsCount = (event) => {
        setAdultsCount(event.target.value);
    };

    const [ChildsCount, setChildsCount] = useState(0);

    const handlesetChildsCount = (event) => {
        setChildsCount(event.target.value);
    };

    const [BabiesCount, setBabiesCount] = useState(0);

    const handlesetBabiesCount = (event) => {
        setBabiesCount(event.target.value);
    };

    const [ClasseValue, setClasseValue] = useState('Toutes');

    const handleClasseValue = (event) => {
        setClasseValue(event.target.value);
    };


    const GuestsCount = parseInt(AdultsCount) + parseInt(ChildsCount) + parseInt(BabiesCount)

    const [showGuests, setShowGuests] = useState(false);

    const handleCloseGuests = () => setShowGuests(false);
    const handleShowGuests = () => setShowGuests(true);

    return (
        <form action="/flights/search">
            <div className='bg-white border text-dark p-lg-3 py-lg-2 w-100' style={{ overflow: 'hidden', borderRadius: '40px' }}>
                <Row className="px-1 align-items-center">
                    <Col lg="2">
                        <InputCity id="location" label={t('flying_from')} placeholder={t('origin')} icon={<PlaneTakeoff strokeWidth={1} size={20} className="form-icon" />} />
                    </Col>

                    <Col lg="2" className="border-end">
                        <InputCity id="destination" label={t('flying_to')} placeholder={t('arrival')} icon={<PlaneLanding strokeWidth={1} size={20} className="form-icon" />} />
                    </Col>

                    <Col lg="4">
                        <DateInput rightLabel={t('start_date')} leftLabel={t('return_date')} />
                    </Col>

                    <Col lg="2" className="border-start">
                        <div className="w-100 d-flex align-items-center p-3 p-lg-0" onClick={handleShowGuests} style={{ cursor: "pointer" }}>
                            <Users strokeWidth={1} size={20} className="form-icon" />
                            <div className="d-flex flex-column mx-3">
                                <span htmlFor="location" className="fs-6 fw-medium title-blue">{t('travelers')}</span>
                                <div className="text-secondary">{GuestsCount} {t('travelers')}, {ClasseValue}</div>
                            </div>
                        </div>
                    </Col>

                    <Col lg="2" className="px-0">
                        <div className="d-flex align-items-center">
                            <button className="btn-blue d-none  d-lg-block p-4 w-100 rounded-5"><Search size={20} /> {t('search')}</button>
                            <button className="btn-blue d-lg-none p-3 px-4 w-100"><Search size={20} /> {t('search')}</button>
                        </div>
                    </Col>
                </Row>
            </div>
            <Guests showGuests={showGuests} handleCloseGuests={handleCloseGuests} handleShowGuests={handleShowGuests} AdultsCount={AdultsCount} handlesetAdultsCount={handlesetAdultsCount} ChildsCount={ChildsCount} handlesetChildsCount={handlesetChildsCount} ClasseValue={ClasseValue} handleClasseValue={handleClasseValue} GuestsCount={GuestsCount} handlesetBabiesCount={handlesetBabiesCount} />
        </form>
    )
}

export default RoundTrip