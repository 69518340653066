import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import supplierData from '../../../data/globaldata.json'

const ExtraCharge = () => {

    return (
        <Row className='align-items-center'>
            <Col md="6" className='my-2'>
                <Input placeholder="Amount of expenses" type="number" />
            </Col>
            <Col md="6" className='my-2'>
                <Select data={supplierData.fees} />
            </Col>

        </Row>
    )
}

export default ExtraCharge