import Recap from './Recap'
import { Row, Col } from 'react-bootstrap'
import Input from '../../../components/forms/Input';
import Textarea from '../../../components/forms/Textarea';
import { useReducer } from 'react';
import TopBanner from '../../../components/TopBanner';
import supplierData from '../../../data/globaldata.json'

const attributes = {
    package_meeting_point: '',
    package_inclusions: '',
    package_exclusions: '',
    package_guide: '',
};

const reducer = (state, action) => {
    switch (action.type) {

        case 'SET_MEETING_POINT':
            localStorage.setItem('package_meeting_point', action.payload);
            return { ...state, package_meeting_point: action.payload };

        case 'SET_INCLUSIONS':
            localStorage.setItem('package_inclusions', action.payload);
            return { ...state, package_inclusions: action.payload };

        case 'SET_EXCLUSIONS':
            localStorage.setItem('package_exclusions', action.payload);
            return { ...state, package_exclusions: action.payload };

        case 'SET_GUIDE':
            localStorage.setItem('package_guide', action.payload);
            return { ...state, package_guide: action.payload };

        default:
            return state;
    }
};

const Attributes = () => {

    const [state, dispatch] = useReducer(reducer, attributes);

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    return (
        <section>
            <TopBanner data="Attributes" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/package/photos'>
                            <fieldset>
                                <legend className='fs-2 fw-medium text-dark'>Tell travelers more about your package?</legend>
                                <p className='my-3 fs-6'>Inform customers even more about your package, the location where travelers are about to meet, things included and excluded from the price</p>
                                <div className='my-3'>
                                    <Input value={state.package_meeting_point} type="text" handleChange={handleChange('SET_MEETING_POINT')} placeholder="Meeting point" maxlength="200" />
                                </div>

                                <div className='my-3'>
                                    <div>
                                        <Textarea value={state.package_exclusions} handleChange={handleChange('SET_INCLUSIONS')} id="inclusions" rows={6} placeholder='List all the main features that are included in the price.' />
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <div>
                                        <Textarea value={state.package_exclusions} handleChange={handleChange('SET_EXCLUSIONS')} id="exclusions" rows={6} placeholder='List all the main features that are not included in the price' />
                                    </div>
                                </div>

                                <div className='border rounded my-3'>
                                    {supplierData.host.map((choice, index) => (
                                        <div key={index}>
                                            <div className='d-flex align-items-start p-3'>
                                                <Input name="interaction" id={choice.value} value={choice.value} type="radio" handleChange={handleChange('SET_GUIDE')} />
                                                <label htmlFor='tourguide' className='mx-2'>
                                                    <span className='fs-6 fw-bold text-dark'>{choice.title}</span>
                                                    <p>{choice.description}</p>
                                                </label>
                                            </div>
                                            <hr className='border-secondary' />
                                        </div>
                                    ))}
                                </div>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5 text-white'>Continue</button>
                            </fieldset>

                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>


            </div>
        </section>
    )
}

export default Attributes