import Recap from './Recap'
import { Row, Col } from 'react-bootstrap'
import Input from '../../../components/forms/Input';
import Textarea from '../../../components/forms/Textarea';
import Select from '../../../components/forms/Select';
import { useReducer } from 'react';
import TopBanner from '../../../components/TopBanner';
import supplierData from '../../../data/globaldata.json'

const attributes = {
    activity_lang: '',
    activity_guide: '',
    activity_keywords: '',
    activity_inclusions: '',
    activity_exclusions: ''
};

const reducer = (state, action) => {
    switch (action.type) {

        case 'SET_LANG':
            localStorage.setItem('activity_lang', action.payload);
            return { ...state, activity_lang: action.payload };

        case 'SET_GUIDE':
            localStorage.setItem('activity_guide', action.payload);
            return { ...state, activity_guide: action.payload };

        case 'SET_INCLUSIONS':
            localStorage.setItem('activity_inclusions', action.payload);
            return { ...state, activity_inclusions: action.payload };

        case 'SET_EXCLUSIONS':
            localStorage.setItem('activity_exclusions', action.payload);
            return { ...state, activity_exclusions: action.payload };

        default:
            return state;
    }
};

const Attributes = () => {

    const [state, dispatch] = useReducer(reducer, attributes);

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    return (
        <section>
            <TopBanner data="Attributes" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/activity/additional'>
                            <fieldset>
                                <legend className='fs-2 fw-medium text-dark'>Tell travelers more about your activity?</legend>
                                <p className='my-3 fs-6'>Inform customers even more about your activity, what language does the guide speak, things included and excluded from the price</p>

                                    <Row className='align-items-center'>
                                        <Col md="6" className='my-2'>
                                            <span className='fs-6 fw-medium text-dark'>What language does your guide speak?</span>
                                        </Col>
                                        <Col md="6" className='my-2'>
                                        <Select value={state.activity_lang} handleChange={handleChange('SET_LANG')} data={supplierData.lang} />
                                        </Col>
                                    </Row>

                                <div className='my-4'>
                                    <div>
                                        <Textarea value={state.activity_inclusions} handleChange={handleChange('SET_INCLUSIONS')} rows={6} placeholder='List all the main features that are included in the price.' />
                                    </div>
                                </div>

                                <div className='my-4'>
                                    <div>
                                        <Textarea value={state.activity_exclusions} handleChange={handleChange('SET_EXCLUSIONS')} rows={6} placeholder='List all the main features that are not included in the price.' />
                                    </div>
                                </div>

                                <div className='border rounded my-3'>
                                    {supplierData.host.map((choice,index)=>(
                                        <div key={index}>
                                            <div className='d-flex align-items-start p-3'>
                                                <Input name="interaction" id={choice.value} value={choice.value} type="radio" handleChange={handleChange('SET_GUIDE')} />
                                                <label htmlFor={choice.value} className='mx-2'>
                                                    <span className='fs-6 fw-bold text-dark'>{choice.title}</span>
                                                    <p>{choice.description}</p>
                                                </label>
                                            </div>
                                            <hr className='border-secondary' />
                                        </div>
                                    ))}                                    
                                </div>

                            
                                <button type='submit' className='btn-blue p-3 px-5 rounded-5 text-white'>Continue</button>
                            </fieldset>

                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>


            </div>
        </section>
    )
}

export default Attributes