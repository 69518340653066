import { Row, Col } from 'react-bootstrap'
import Input from '../../components/forms/Input'
import Textarea from '../../components/forms/Textarea'
import Select from '../../components/forms/Select';

const BookingForm = () => {

    const genderdata = [
        { title: "Gender", value: "" },
        { title: "Mr", value: "mr" },
        { title: "Ms", value: "ms" }
    ]

    const coutrydata = [
        { title: "Country", value: "" },
        { title: "Algeria", value: "algeria" },
        { title: "French", value: "french" },
        { title: "Spanish", value: "spanish" },
    ]

    const nationalitydata = [
        { title: "Nationality", value: "" },
        { title: "Algeria", value: "algeria" },
        { title: "French", value: "french" },
        { title: "Spanish", value: "spanish" },
    ]

    return (
        <>
            <span className='fs-5' htmlFor='genre'>Adult 1</span>
            <Row>
                <Col lg="12" className='my-2'>
                    <Select data={genderdata} />
                </Col>
                <Col lg="6" className='my-2'>
                    <Input label="First Name" name="fistname" id="fistname" type="text" placeholder="First Name" required={true} />
                </Col>
                <Col lg="6" className='my-2'>
                    <Input label="Last Name" name="lastname" id="lastname" type="text" placeholder="Last Name" required={true} />
                </Col>
            </Row>
            <Row>
                <Col lg="6" className='my-2'>
                    <Input label="Email" name="email" id="email" type="email" placeholder="Exemple@exemple.com" required={true} />
                </Col>
                <Col lg="6" className='my-2'>
                    <Input label="Phone" name="phone" id="phone" type="phone" placeholder="Your phone" required={true} />
                </Col>
            </Row>

            <Row>
                <Col lg="6" className='my-2'>
                    <Select data={coutrydata} id='country' />
                </Col>
                <Col lg="6" className='my-2'>
                    <Select data={nationalitydata} id='nationality' />
                </Col>
                <Col lg="12" className='my-2'>
                    <Textarea rows={4} placeholder='Special Requirements' maxlength="220" />
                </Col>
            </Row>
        </>
    )
}

export default BookingForm