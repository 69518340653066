import { Panel } from 'rsuite';
import { Star } from 'lucide-react'
import FilterMap from '../../components/FilterMap'
import FilterPrice from '../../components/FilterPrice';
import FiltersData from '../../data/globaldata.json'

const Filters = () => {

    const location = [
        { name: "Hôtel" },
        { name: "Appartement" },
        { name: "Villa" },
        { name: "Résidence" },
        { name: "Chalet" }
    ]

    const service = [
        { name: "Petit-déjeuner" },
        { name: "Wifi-gratuit" },
        { name: "Piscine" },
        { name: "Parking" },
        { name: "Salle de sport" },
        { name: "Parc aquatique" },
        { name: "Restaurant" },
        { name: "Réception 24H/24" },
        { name: "Chambres non-fumeurs" },
        { name: "Service d'étage" },
        { name: "Spa et bien-être" },
        { name: "Animaux admis" },
        { name: "Parc aquatique" },
    ]

    const equipment = [
        { name: "Climatisation" },
        { name: "Balcon" },
        { name: "Cuisine" },
        { name: "Réfrigérateur" },
        { name: "Vue sur la mer" },
        { name: "Terrasse" },
        { name: "Télévision à écran plat" },
        { name: "Sèche-cheveux" },
        { name: "Coffre-fort" },
        { name: "Télévision" },
        { name: "Baignoire" },
        { name: "Lave-linge" },
    ]

    const rating = [
        { name: "Fabuleux 4.5+" },
        { name: "Trés bien 4+" },
        { name: "Bien 3.5+" },
        { name: "Agréable 3+" },
    ]


    return (
        <>
            <div className='d-none d-xl-block position-relative'>
                <FilterMap/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <FilterPrice/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Location</span>} collapsible>
                    {location.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Category</span>} collapsible>
                    {FiltersData.category.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                                <input id={item.stars} type='checkbox' />
                                <div className='mx-3 fw-light'>
                                    {Array.from({ length: item.stars }).map((_, starIndex) => (
                                        <label htmlFor={item.stars} className="me-1 my-1" key={starIndex}>
                                            <Star color='#F8D448' fill='#F8D448' size={13} />
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Rating</span>} collapsible>
                    {rating.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Facilities</span>} collapsible>
                    {service.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center my-1'><input type='checkbox' /><span className='mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Equipment</span>} collapsible>
                    {equipment.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

        </>
    )
}

export default Filters