import Home from './Home'
import Search from './Search'
import Details from './Details'

function Main(props) {
    const composite = {
        home: <Home />,
        search: <Search />,
        details: <Details />,
    };
    
    return (
        <>   {eval("composite." + props.component)}
        </>

    );

}
export default Main;