import { Modal, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Guests({ showGuests, handleCloseGuests, AdultsCount, handlesetAdultsCount, ChildsCount, handlesetChildsCount, ClasseValue, handleClasseValue, BabiesCount, handlesetBabiesCount, GuestsCount }) {

    const { t } = useTranslation();
    
    return (
        <>
            <Modal show={showGuests} onHide={handleCloseGuests} backdrop="static" keyboard={false}>
                <div className='bg-white text-dark rounded-4'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div>
                                {t('travelers')} : {GuestsCount}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='px-3'>
                            <Row>
                                <Col xs="12" className='my-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor='class' className='fw-bold fs-6'>{t('class')}</label>
                                        <select id='class' className='p-3 border input-blue w-100 mt-1 rounded' value={ClasseValue} onChange={handleClasseValue}>
                                            <option value=''>{t('all')}</option>
                                            <option>{t('economic')}</option>
                                            <option>{t('business')}</option>
                                            <option>{t('first')}</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" className='my-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor='adults' className='fw-bold fs-6'>{t('adults')} <span className='guests-notation'>{t('adults_note')}</span></label>
                                        <select id="adults" className='input-blue border mt-1 p-3 rounded' value={AdultsCount} onChange={handlesetAdultsCount}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className='my-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor='childs' className='fw-bold fs-6'>{t('childs')} <span className='guests-notation'>{t('childs_note')}</span></label>
                                        <select id="childs" className='input-blue border mt-1 p-3 rounded' value={ChildsCount} onChange={handlesetChildsCount}>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" className='my-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor='baby' className='fw-bold fs-6'>{t('babies')} <span className='guests-notation'>{t('babies_note')}</span></label>
                                        <select id="baby" className='input-blue border mt-1 p-3 rounded' value={BabiesCount} onChange={handlesetBabiesCount}>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn-blue p-3 px-4 rounded-5' onClick={handleCloseGuests}>
                            {t('save')}
                        </button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
}

export default Guests;

