
const initialState = {
    isloged: false,
    lang: 'fr',
    sessionToken: ''
};
const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOGGED":
            return {
                ...state,
                isloged: action.payload,
            };
        case "LANG":
            return {
                ...state,
                lang: action.payload,
            };

        case "TOKEN":
            return {
                ...state,
                sessionToken: action.payload,
            };


        default:
            return state;
    }



};

export default profileReducer;
