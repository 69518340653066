import { Clock, Flag, Users } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react';

const Recap = () => {

    const [values, setValues] = useState({
        activity_name: '',
        activity_location: '',
        activity_type: '',
        activity_intro: '',
        activity_guide: '',
        activity_groupsize: null,
        activity_duration: '',
        activity_lang:''
    });


    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedData = {
                activity_name: localStorage.getItem('activity_name') || 'Not assigned',
                activity_location: localStorage.getItem('activity_location') || 'Not assigned',
                activity_type: localStorage.getItem('activity_type') || 'Not assigned',
                activity_intro: localStorage.getItem('activity_intro') || 'Not assigned',
                activity_guide: localStorage.getItem('activity_guide') || 'Not assigned',
                activity_groupsize: localStorage.getItem('activity_groupsize') || 1,
                activity_duration: localStorage.getItem('activity_duration') || 'Not assigned',
                activity_lang: localStorage.getItem('activity_lang') || 'Not assigned',
            };
            setValues(savedData);

        }, 10);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <section>
            <div className='border element rounded-4'>
                <div className='p-3'>
                    <div className='fs-5 fw-bold text-dark'>{values.activity_name}</div>
                    <div className='fs-6 fw-medium'>{values.activity_location}</div>
                </div>
                <div className='my-3 fs-6'>
                    <Row>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Users size={30} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.activity_groupsize}</div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Clock size={30} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.activity_duration}</div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Flag size={30} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.activity_type}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span className='text-dark'>Introduction</span>
                    <span className='ps-3'>{values.activity_intro}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Language</span>
                    <span>{values.activity_lang}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Guide</span>
                    <span>{values.activity_guide}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Price</span>
                    <span>$25,34,000</span>
                </div>
                
            </div>

        </section>
    )
}

export default Recap