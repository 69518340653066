
import SubHeaderPlatform from "./SubHeaderPlatform"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import Button from 'react-bootstrap/Button'
import Doc from "../slide/voucher.pdf"
import proforma from "../slide/proforma.pdf"
import HeaderDetails from "./HeaderDetails"



function FullDetails(){
    return(
        <div>
            <div style={{marginBottom:'4vw'}} >
                        <SubHeaderPlatform />
                        <HeaderDetails />

                        <Container>
                            <Row>
                            <Col sm={12} className="psps" style={{marginTop:'2vw'}}  >
                                    
                                    <a href="/platform" style={{borderRight:'solid 2px #861e12',paddingRight:'1vw', color:'#1e2031'}}><svg  style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><polyline points="9 22 9 12 15 12 15 22"/></svg></a>
                                    <a href="#" style={{paddingLeft:'1vw'}} className="pspsps" id="ctd1">
                                        Full details
                                    </a> 

                                </Col>
                            </Row>
                        </Container>
                     
                        <Container style={{paddingTop:'2vw'}}>
                            <Row>
                                <Col  style={{ display:'flex', flexDirection:'column',borderRadius: '6px',marginRight:''}}>
                                

                                    <Col style={{display:'flex',backgroundColor:'white',flexDirection:'column',boxShadow: '0 0 3px 2px rgba(0, 0, 0, .05)',borderRadius: '6px',padding:'3vw 3vw 1vw 4vw'}}>
                                        <h1 style={{color:'#861e12' ,paddingBottom:'1vw',fontFamily:'Inter,sans-serif', fontSize:'30px',fontWeight:'bold',textAlign:'left'}}>Cancellation policy <span style={{float:'right',fontSize:'16px'}}>N° RB1707752915 - 125 125 DZD</span> </h1>    
                                        
                                        <p style={{textAlign:'left',fontSize:'16px'}}>
                                                <ul>
                                                    <li>
                                                        This rate is non-refundable. If you cancel or change your booking, you will not get a refund or credit to use for a later stay
                                                    </li>
                                                    <li>
                                                        No refunds will be made for late arrival or early departure
                                                    </li>
                                                    <li> Any extension of stay requires a new reservation</li>
                                                </ul>   
                                                
                                        </p>
                                        
                                    </Col>

                                </Col>


                               
                            </Row>
                        </Container>
                        <Container style={{paddingTop:'2vw'}}>
                            
                            <Row>
                                <Col  style={{marginBottom:'2vw', display:'flex', flexDirection:'column',borderRadius: '6px',marginRight:''}}>
                                

                                    <Col style={{display:'flex',backgroundColor:'white',boxShadow: '0 0 3px 2px rgba(0, 0, 0, .05)',borderRadius: '6px',padding:'3vw 3vw 1vw 4vw'}}>
                                        <Col sm={4} style={{}}>
                                        <h1 style={{color:'#1a536d',marginBottom:'1vw', fontFamily:'Inter,sans-serif', fontSize:'30px',fontWeight:'bold',textAlign:'left'}}>Your Information  </h1>    
                                        
                                                
                                               
                                                <span style={{display:'flex',flexDirection:'row',paddingLeft:'1vw'}}>
                                                    <h1 style={{fontSize:'16px',marginTop:'2px'}}>
                                                        <b style={{fontSize:'18px'}}>First Name: </b>
                                                        John
                                                                                                        
                                                    </h1>
                                                </span>
                                                <span style={{display:'flex',flexDirection:'row',paddingLeft:'1vw'}}>
                                                    <h1 style={{fontSize:'16px',marginTop:'2px'}}>
                                                        <b style={{fontSize:'18px'}}>Last Name: </b>
                                                        Smith
                                                                                                        
                                                    </h1>
                                                </span>
                                                <span style={{display:'flex',flexDirection:'row',paddingLeft:'1vw'}}>
                                                    <h1 style={{fontSize:'16px',marginTop:'2px'}}>
                                                        <b style={{fontSize:'18px'}}>Email: </b>
                                                        email@email.com
                                                                                                        
                                                    </h1>
                                                </span>
                                                <span style={{display:'flex',flexDirection:'row',paddingLeft:'1vw'}}>
                                                    <h1 style={{fontSize:'16px',marginTop:'2px'}}>
                                                        <b style={{fontSize:'18px'}}>Phone Number: </b>
                                                        000 000 000
                                                                                                        
                                                    </h1>
                                                </span>
                                                <span style={{display:'flex',flexDirection:'row',paddingLeft:'1vw'}}>
                                                    <h1 style={{fontSize:'16px',marginTop:'2px'}}>
                                                        <b style={{fontSize:'18px'}}>Nationality: </b>
                                                        Algerian
                                                                                                        
                                                    </h1>
                                                </span>
                                        </Col>
                                        <Col sm={4}>
                                        <h1 style={{color:'#1a536d',marginBottom:'1vw', fontFamily:'Inter,sans-serif', fontSize:'30px',fontWeight:'bold',textAlign:'left'}}>Your Reservation  </h1>    
                                        
                                                <span style={{display:'flex',flexDirection:'row',paddingLeft:'1vw'}}>
                                                    <h1 style={{fontSize:'16px',marginTop:'2px'}}>
                                                        <b style={{fontSize:'18px'}}>Sure Hotel by Best Western Paris  </b>
                                                        
                                                                                                        
                                                    </h1>
                                                </span>
                                                <span style={{display:'flex',flexDirection:'row',paddingLeft:'1vw'}}>
                                                    <h1 style={{fontSize:'16px',marginTop:'2px'}}>
                                                        <b style={{fontSize:'18px'}}>Single Room: </b>
                                                            1 * Night 
                                                                                                
                                                    </h1>
                                                </span>

                                                <span style={{display:'flex',flexDirection:'row',paddingLeft:'1vw'}}>
                                                                                                    
                                                    <h1 style={{fontSize:'16px',marginTop:'2px'}}>
                                                        <b style={{fontSize:'18px'}}>Room only: </b>
                                                            1 extra-large double bed 
                                                                                                    
                                                    </h1>
                                                </span>
                                                <span style={{display:'flex',flexDirection:'row',paddingLeft:'1vw'}}>
                                                    <h1 style={{fontSize:'16px',marginTop:'2px'}}>
                                                        <b style={{fontSize:'18px'}}>Check-in: </b>
                                                            25/12/2024 
                                                                                                
                                                    </h1>
                                                </span>
                                                <span style={{display:'flex',flexDirection:'row',paddingLeft:'1vw'}}>
                                                    <h1 style={{fontSize:'16px',marginTop:'2px'}}>
                                                        <b style={{fontSize:'18px'}}>Check-out: </b>
                                                            28/12/2024 
                                                        <b style={{fontSize:'16px'}}> </b>
                                                    </h1>
                                                </span> 
                                        </Col>
                                        <Col sm={4} style={{display:'flex',flexDirection:'column'}}>
                                            <h1 style={{color:'#1a536d',marginBottom:'3vw', fontFamily:'Inter,sans-serif', fontSize:'30px',fontWeight:'bold',textAlign:'left'}}></h1>    
                                           

                                            <Button href={Doc}  id='finalb' style={{border:'none',backgroundColor:'#1a536d',marginBottom:'1vw'}} >
                                                 Download the Voucher
                                            </Button>   
                                            
                                            <Button  href={proforma} id='finalbb' style={{border:'none', marginBottom:'1vw',backgroundColor:'#505050'}}>
                                                The Proforma
                                            </Button>
                                            <Button  href="/Cancell" id='finalbb' style={{border:'none', backgroundColor:'#861e12'}}>
                                                Cancel the reservation
                                            </Button>
                                            
                                               
                                        </Col>
                                    </Col>

                                </Col>


                               
                            </Row>
                        </Container>
                        
                        
                        
            </div >        
        </div >
    );
}

export default FullDetails;