import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import { Panel, PanelGroup } from 'rsuite';
import TopBanner from '../../../components/TopBanner';
import PublishCelebration from '../PublishCelebration';

const Review = () => {

    const [values, setValues] = useState({
        vehicle_name: '',
        vehicle_model: '',
        vehicle_type: '',
        vehicle_registredcity: '',
        vehicle_description: '',
        vehicle_condtion: '',
        vehicle_passengers: 1,
        vehicle_doors: 0,
        vehicle_facilities: [],
        vehicle_color: '',
        vehicle_transmission: '',
        vehicle_year: '',
        vehicle_addfeatures: ''
    });


    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedData = {
                vehicle_name: localStorage.getItem('vehicle_name') || 'Not assigned',
                vehicle_model: localStorage.getItem('vehicle_model') || 'Not assigned',
                vehicle_type: localStorage.getItem('vehicle_type') || 'Not assigned',
                vehicle_registredcity: localStorage.getItem('vehicle_registredcity') || 'Not assigned',
                vehicle_description: localStorage.getItem('vehicle_description') || 'Not assigned',
                vehicle_condtion: localStorage.getItem('vehicle_condtion') || 'Not assigned',
                vehicle_passengers: localStorage.getItem('vehicle_passengers') || 1,
                vehicle_doors: localStorage.getItem('vehicle_doors') || 0,
                vehicle_facilities: JSON.parse(localStorage.getItem('vehicle_facilities')) || [],
                vehicle_transmission: localStorage.getItem('vehicle_transmission') || 'Not assigned',
                vehicle_color: localStorage.getItem('vehicle_color') || 'Not assigned',
                vehicle_year: localStorage.getItem('vehicle_year') || 'Not assigned',
                vehicle_addfeatures: localStorage.getItem('vehicle_addfeatures') || 'Not assigned',
            };

            setValues(savedData);

        }, 10);

        return () => clearInterval(intervalId);
    }, []);

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };


    return (
        <section>
            <TopBanner data="Review" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <div>
                    <span className='fs-2 fw-bold text-dark'>Is this informations correct?</span>
                    <p className='my-1 fs-5'>If everything looks good, save and publish to set it live on IdhTours's website.</p>
                    <hr className='my-3 border-secondary' />
                    <PanelGroup accordion defaultActiveKey={1}>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Basic</div>} eventKey={1} id="panel1" defaultExpanded>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Title</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.vehicle_name}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Model</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.vehicle_model}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Condition</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.vehicle_condtion}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Type</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.vehicle_type}</span>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Informations</div>} eventKey={2} id="panel2">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Registred city</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.vehicle_registredcity}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Model year</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.vehicle_year}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Description</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.vehicle_description}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Max passengers</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.vehicle_passengers}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Doors</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.vehicle_doors}</p>
                                </Col>
                            </Row>

                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Attributes</div>} eventKey={3} id="panel3">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Facilities</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <ul>
                                        {values.vehicle_facilities.map((tag, index) => (
                                            <li key={index}>{tag}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Color </span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.vehicle_color}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Transmission </span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.vehicle_transmission}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Additional </span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.vehicle_addfeatures}</span>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Photos</div>} eventKey={4} id="panel4">
                            Empty
                        </Panel> 
                    </PanelGroup>

                </div>
                <PublishCelebration showModal={showModal} closeModal={closeModal} />
                <button onClick={openModal} className='btn-blue p-3 px-5 rounded-5 text-white my-5'>Publish</button>
            </div>
        </section>
    )
}

export default Review