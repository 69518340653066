import { Row, Col } from 'react-bootstrap'
import Recap from './Recap';
import { useReducer } from 'react';
import Textarea from '../../../components/forms/Textarea';
import TopBanner from '../../../components/TopBanner';
import { TagInput } from 'rsuite';

const additional = {
    transportation_inclusions: '',
    transportation_exclusions: '',
    transportation_notallowed: '',
};

const reducer = (state, action) => {

    switch (action.type) {

        case 'SET_INCLUSIONS':
            localStorage.setItem('transportation_inclusions', action.payload);
            return { ...state, activitytransportation_inclusions_inclusions: action.payload };

        case 'SET_EXCLUSIONS':
            localStorage.setItem('transportation_exclusions', action.payload);
            return { ...state, transportation_exclusions: action.payload };

        case 'SET_NOTALLOWED':
            try {
                localStorage.setItem('transportation_notallowed', JSON.stringify(action.payload));
            } catch (error) {
                console.error('Error storing keywords in localStorage:', error);
            }
            return { ...state, transportation_notallowed: action.payload };

        default:
            return state;
    }
};

const Additional = () => {

    const [state, dispatch] = useReducer(reducer, additional);

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    const handleTagChange = (type) => (e) => {
        dispatch({ type, payload: e });
    };


    return (
        <section>
            <TopBanner data="Additional" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/transportation/photos'>
                            <fieldset>
                                <legend className='text-dark fs-2 fw-emdium'>Tell travelers more about your transportation</legend>
                                <p className='fs-6 my-3'>Inform customers even more about your transportation, what's not allowed, things included and excluded from the price</p>

                                <div className='my-3'>
                                    <div>
                                        <TagInput onChange={handleTagChange('SET_NOTALLOWED')} placeholder="What’s not allowed" className='p-3 rounded input-blue' block style={{ boxShadow: 'none' }} />
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <div>
                                        <Textarea value={state.transportation_inclusions} handleChange={handleChange('SET_INCLUSIONS')} id="inclusions" rows={6} placeholder='List all the main features that are included in the price.' />
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <div>
                                        <Textarea value={state.transportation_exclusions} handleChange={handleChange('SET_EXCLUSIONS')} id="exclusions" rows={6} placeholder='List all the main features that are not included in the price.' />
                                    </div>
                                </div>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5 text-white'>Continue</button>
                            </fieldset>

                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Additional