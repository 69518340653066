import { Row, Col } from 'react-bootstrap'
import BlogItem from '../../data/blogdata.json'
import { useState, useEffect } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react'
import { useTranslation } from 'react-i18next';

const Articles = () => {

    const [blogData, setRandomData] = useState([]);
    const [articles, setArticles] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setArticles(BlogItem.articles);
    }, []);

    useEffect(() => {

        const shuffledData = [...BlogItem.articles].sort(() => Math.random() - 0.5);

        setRandomData(shuffledData);

    }, []);

    const [startIndex, setStartIndex] = useState(0);

    const handlePrevious = () => {
        if (startIndex > 0) {
            setStartIndex(prev => prev - 1);
        }
    };

    const handleNext = () => {
        if (startIndex < blogData.length - 1) {
            setStartIndex(prev => prev + 1);
        }
    };

    const visibleElements = blogData.slice(startIndex, startIndex + 3);

    return (
        <section className='container py-3'>
            <div className='text-dark d-flex justify-content-between align-items-center'>
                <h1 className='fs-1 fw-medium'>{t('blog_title')}</h1>
                <div className=''>
                    <button className='bg-white border rounded-circle p-2 mx-2 shadow-sm' onClick={handlePrevious} disabled={startIndex === 0}>
                        <ChevronLeft strokeWidth={1} size={20} />
                    </button>
                    <button className='bg-white border rounded-circle p-2 mx-2 shadow-sm' onClick={handleNext} disabled={startIndex >= blogData.length - 3}>
                        <ChevronRight strokeWidth={1} size={20} />
                    </button>
                </div>
            </div>
            <Row className='mt-3'>
                {visibleElements.map((article, index) => (
                    <Col sm="6" lg="4" xl="4" className='my-3 position-relative' key={index}>
                        <a href={`/blog/${article.id}`} className="text-dark text-decoration-none">
                            <img className="rounded-4" width="100%" height="100%" alt="article illustration" src={article.img} style={{maxHeight:"310px"}} />
                            <div className="my-3">
                                <div className="fs-6">{article.date}</div>
                                <div className="fs-5 fw-medium title-blue">{article.name}</div>
                                <div className="my-3">{article.description}</div>
                            </div>
                            <span className="position-absolute top-0 start-0 m-4 bg-white p-2 px-3 rounded-5">{article.type}</span>
                        </a>
                    </Col>
                ))}
            </Row>

        </section>
    )
}

export default Articles