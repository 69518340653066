import { Col, Row } from 'react-bootstrap'
import { CheckCircle2, Clock, Lock, MapPin, MessageSquare, Pencil, Star, Trash2, Users2 } from "lucide-react";

const CartItem = ({ image, name, checkin, category, reviews, travelers, country, duration, price }) => {

    return (
        <>
            <Row className="justify-content-between">
                <Col lg="7" className='my-2'>
                    <div className="alert alert-danger text-center border-0">
                        <Clock color="grey" size={15} className="mx2" /> We’ll hold your spot for 23:29 minutes.
                    </div>

                    <span className="fs-5 text-secondary fw-bold">{checkin}</span>
                    <div className="border rounded-3 p-3 my-3">
                        <Row className="align-items-start">
                            <Col sm="3" xl="2">
                                <img className="rounded-3" width="100%" height="100" src={image} alt={`Illustration : ${name}`} />
                            </Col>
                            <Col sm="9" xl="10">
                                <div className="text-dark">
                                    <div className="fs-6 fw-bold mt-2">{name}</div>
                                    <div className="d-flex align-items-center">
                                        <span>{category}</span>
                                        <span className="mx-2 d-flex">
                                            {Array.from({ length: category }).map((_, starIndex) => (
                                                <div key={starIndex} className='me-1'><Star color='#F8D448' fill='#F8D448' size={13} /></div>
                                            ))}
                                        </span>
                                        <span>{reviews}</span>
                                    </div>

                                    <div className="mt-3 fw-medium">

                                        <div className="d-flex align-items-center my-2">
                                            <MapPin size={15} />
                                            <span className="mx-2">{country}</span>
                                        </div>
                                        <div className="d-flex align-items-center my-2">
                                            <Users2 size={15} />
                                            <span className="mx-2">{travelers}</span>
                                        </div>
                                        <div className="d-flex align-items-center my-2">
                                            <Clock size={15} />
                                            <span className="mx-2">{duration}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-3">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center bg-secondary-subtle rounded-5 py-2 px-3"><Pencil size={15} /> <span className="mx-1">Edit</span></div>
                                            <div className="text-danger mx-3"><Trash2 size={20} /></div>
                                        </div>
                                        <div className="fs-6">€ {price}</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-4 d-none d-lg-flex flex-column align-items-end text-dark">
                        <div className="fs-5 fw-bold">Total € {price}</div>
                        <p className="text-success">All taxes and fees included</p>
                    </div>
                </Col>
                <Col lg="4" xl="4" className='my-2'>
                    <div>
                        <div className="border p-3 rounded-3 d-flex flex-column">
                            <div className="d-flex justify-content-between text-dark">
                                <div className="fs-5">Total (1 item):</div>
                                <div>
                                    <div className="fs-5 text-end">€ 56</div>
                                    <p className="text-success">All taxes and fees included</p>
                                </div>
                            </div>
                            <a href={`/booking/payment`} className="btn-blue text-white p-2 rounded-5 my-4 fw-bold">Go to payment</a>

                            <div className="d-flex">
                                <CheckCircle2 color="green" />
                                <div className="mx-2">
                                    <span className="fw-bold">Free concelation</span>
                                    <p className="text-secondary">Cancel up to 24 hours in advance for a full refund</p>
                                </div>
                            </div>
                        </div>

                        <div className="border mt-3 p-3 rounded-3 d-flex flex-column text-dark">

                            <div className="fs-6 fw-medium">Why book with us?</div>

                            <div>
                                <div className="d-flex align-items-center my-3">
                                    <Lock color="#1a536d" size={20} />
                                    <span className="mx-2">Secure payment</span>
                                </div>
                                <div className="d-flex align-items-center my-3">
                                    <CheckCircle2 color="#1a536d" size={20} />
                                    <span className="mx-2">No hidden costs</span>
                                </div>
                                <div className="d-flex align-items-center my-3">
                                    <MessageSquare color="#1a536d" size={20} />
                                    <span className="mx-2">24/7 customer support worldwide</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default CartItem