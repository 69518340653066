import Product from "./Product"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import Product2 from "./Product2"
import Product3 from "./Product3"
import Product1 from "./Product1"
import Timer from "./Timer"
import SubHeaderPlatform from '../components/SubHeaderPlatform'
import HeaderDetails from '../components/HeaderDetails'



function Reservations(){
    return(
        <div>
            <div style={{marginBottom:'4vw'}} >
                     
                            <SubHeaderPlatform />
                            <HeaderDetails />
                            <Container>
                                <Row>

                                    <Col sm={12} className="psps" style={{marginTop:'2vw'}}  >
                                        
                                        <a style={{borderRight:'solid 2px #861e12',paddingRight:'1vw', color:'#1e2031'}}><svg  style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><polyline points="9 22 9 12 15 12 15 22"/></svg></a>
                                        <a style={{paddingLeft:'1vw',color:'#1e2031'}} className="pspsps">Shopping Cart</a> 
                                        
                                    </Col>
                                    <h1 className="h1cart" style={{color:'#505050'}}> 
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{paddingBottom:'4px',marginRight:'1vw'}} width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-timer"><line x1="10" x2="14" y1="2" y2="2"/><line x1="12" x2="15" y1="14" y2="11"/><circle cx="12" cy="14" r="8"/></svg>
                                        Activities stay in your cart for up to 20 minutes   <Timer />
                                    </h1>
                                </Row>
                            </Container>

                            <Product />
                            <Product1 />
                            <Product2 />
                            <Product3 />
                        
                      
            </div >
              
               
                
            
        </div >
    );
}

export default Reservations;