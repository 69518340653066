import { Row, Col } from 'react-bootstrap';
import data from '../../data/packagesdata.json'
import { Clock, Star, MapPin, Heart } from 'lucide-react'
import Pagination from '../../components/Pagination';

const GridDisplay = () => {

    const renderSpecificItems = (items) => {
        return (
            <>
                <Row className='mt-3'>
                    {items.map((packageItem, index) => (
                        <Col key={index} md="6" lg="4" className='my-3'>
                            <a href={`/packages/${packageItem.id}`} className="text-dark text-decoration-none">
                                <div className='bg-white rounded-4 border recommendations-item position-relative' style={{ overflow: "hidden" }}>
                                    <div style={{ overflow: "hidden" }}>
                                        <img alt={`Vue d'ensemble de l'activité ${packageItem.name}`} className='recommendations-item-img' width="100%" height="180" src={packageItem.img} />
                                    </div>
                                    <div className="position-absolute" title='Add to whishlist' style={{ top: "20px", right: "15px" }}>
                                        <Heart fill='#00000036' size={30} strokeWidth={1.3} color="white" />
                                    </div>
                                    <div className='p-3'>
                                        <div className='d-flex align-items-center'><MapPin strokeWidth={1.5} size={15} /><span className='mx-1'>{packageItem.country}</span></div>
                                        <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{packageItem.name}</div>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark'>{packageItem.note}</span><span>({packageItem.avis} Reviews</span></div>
                                        </div>
                                        <hr className='border-secondary' />
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div>From: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€{packageItem.price}</span></div>
                                            <div className='d-flex justify-content-between align-items-center'><Clock strokeWidth={1} size={20} /><span className='mx-2'>{packageItem.duration}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </Col>
                    ))}
                </Row>
            </>
        );
    };


    return (
        <>
            <Pagination data={data.packages} itemsPerPage={9} renderItems={renderSpecificItems} />
        </>
    )
}

export default GridDisplay