import {  Calendar, DoorOpen, Users } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react';

const Recap = () => {

    const [values, setValues] = useState({
        vehicle_name: '',
        vehicle_type: '',
        vehicle_registredcity: '',
        vehicle_condtion: '',
        vehicle_passengers: 1,
        vehicle_doors: 0,
        vehicle_color:'',
        vehicle_transmission:'',
        vehicle_year:''
    });


    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedData = {
                vehicle_name: localStorage.getItem('vehicle_name') || 'Not assigned',
                vehicle_type: localStorage.getItem('vehicle_type') || 'Not assigned',
                vehicle_registredcity: localStorage.getItem('vehicle_registredcity') || 'Not assigned',
                vehicle_condtion: localStorage.getItem('vehicle_condtion') || 'Not assigned',
                vehicle_passengers: localStorage.getItem('vehicle_passengers') || 1,
                vehicle_doors: localStorage.getItem('vehicle_doors') || 0,
                vehicle_transmission: localStorage.getItem('vehicle_transmission') || 'Not assigned',
                vehicle_color: localStorage.getItem('vehicle_color') || 'Not assigned',
                vehicle_year: localStorage.getItem('vehicle_year') || 'Not assigned',
            };

            setValues(savedData);

        }, 10);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <section>
            <div className='border element rounded-4'>
                <div className='p-3'>
                    <div className='fs-5 fw-bold text-dark'>{values.vehicle_name}</div>
                    <div className='fs-6 fw-medium'>{values.vehicle_type}</div>
                </div>
                <div className='my-3 fs-6'>
                    <Row>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Users size={30} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.vehicle_passengers}</div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <DoorOpen size={30} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.vehicle_doors}</div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Calendar size={30} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.vehicle_year}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Registred city :</span>
                    <span>{values.vehicle_registredcity}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Color :</span>
                    <span className='p-2 px-4 border' style={{background:values.vehicle_color}}></span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Transmission :</span>
                    <span>{values.vehicle_transmission}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Price :</span>
                    <span>$25,34,000</span>
                </div>
                
            </div>

        </section>
    )
}

export default Recap