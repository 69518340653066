import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from 'react-bootstrap/Dropdown';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function SubHeaderPlatform(){
    return(
        <div>
            <Container fluid   id="SubHeaderC">
                    <Row id="SubHeaderR" >
                            <Col sm  className="SubHeaderC1">
                                <div className="SubHeaderc1d1">
                                    
                                    <a href="#" className="SubHeadercap text-white">
                                        <span  style={{padding:'5px'}} className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check-circle-2"><circle cx="12" cy="12" r="10"/><path d="m9 12 2 2 4-4"/></svg></span>
                                        <b>Confirmed today</b>
                                    </a>
                                    <a href="#" className="SubHeadercap text-white">
                                        <span  style={{padding:'5px'}} className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-ellipsis"><circle cx="12" cy="12" r="10"/><path d="M17 12h.01"/><path d="M12 12h.01"/><path d="M7 12h.01"/></svg></span>
                                        <b>Pending payment</b>
                                    </a>
                                    <a href="#" className="SubHeadercap text-white">
                                        <span  style={{padding:'5px'}} className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-coins"><circle cx="8" cy="8" r="6"/><path d="M18.09 10.37A6 6 0 1 1 10.34 18"/><path d="M7 6h1v4"/><path d="m16.71 13.88.7.71-2.82 2.82"/></svg></span>
                                        <b>0.00 DZD</b>
                                    </a>
                                  
                                </div>
                            </Col>

                            <Col sm className="SubHeaderC2" style={{paddingRight:'2vw'}}>
                                <div className="SubHeaderc1d1" style={{float:'right'}}>

                                
                                    <OverlayTrigger placement='bottom'  overlay={<Tooltip id="tooltip-disabled" >Connected Agencies</Tooltip>}>
                                        <span className="d-inline-block" >
                                        
                                            <a href="https://www.instagram.com/idhtours/?hl=fr" className="SubHeadercap">
                                                <span style={{padding:'5px'}} className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-share-2"><circle cx="18" cy="5" r="3"/><circle cx="6" cy="12" r="3"/><circle cx="18" cy="19" r="3"/><line x1="8.59" x2="15.42" y1="13.51" y2="17.49"/><line x1="15.41" x2="8.59" y1="6.51" y2="10.49"/></svg></span>
                                                <b >163</b>
                                            </a>
                                        
                                        
                                        </span>
                                    </OverlayTrigger>

                                    <OverlayTrigger placement='bottom' overlay={<Tooltip id="tooltip" >Notes</Tooltip>}>
                                        <span className="d-inline-block" >

                                            <a href="https://www.instagram.com/idhtours/?hl=fr" className="SubHeadercap">
                                                <span style={{padding:'5px'}} className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clipboard-check"><rect width="8" height="4" x="8" y="2" rx="1" ry="1"/><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"/><path d="m9 14 2 2 4-4"/></svg></span>
                                                <b >0</b>
                                            </a>

                                      
                                        </span>
                                    </OverlayTrigger>

                                    <OverlayTrigger placement='bottom' overlay={<Tooltip id="tooltip-disabled" >Notifications</Tooltip>}>
                                        <span className="d-inline-block" >
                                   
                                            <a href="https://www.instagram.com/idhtours/?hl=fr" className="SubHeadercap">
                                                <span style={{padding:'5px'}} className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bell"><path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"/><path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"/></svg></span>
                                                <b >0</b>
                                            </a>

                                        </span>
                                    </OverlayTrigger>

                                    <a href="/Panier" className="SubHeadercap">
                                        <span style={{padding:'5px'}} className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart"><circle cx="8" cy="21" r="1"/><circle cx="19" cy="21" r="1"/><path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"/></svg></span>
                                        <b >25</b>
                                    </a>
                                       
                                    <Dropdown as={NavItem}>
                                            <Dropdown.Toggle as={NavLink}>

                                                <a id="db" href="/User" className="SubHeadercap text-white">
                                                    <span style={{padding:'5px'}} className="SubHeaderSpan1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-user"><circle cx="12" cy="12" r="10"/><circle cx="12" cy="10" r="3"/><path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662"/></svg>
                                                    </span>
                                                    <b> chiba katia </b>
                                                </a>

                                            </Dropdown.Toggle>
                                            
                                            <Dropdown.Menu>

                                                <Dropdown.Item href="/User"><svg style={{marginRight:'5px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-round"><circle cx="12" cy="8" r="5"/><path d="M20 21a8 8 0 0 0-16 0"/></svg><a href="/User" className="drita"> Profile </a></Dropdown.Item>
                                                <Dropdown.Item href="/Password"><svg className="mr-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lock"><rect width="18" height="11" x="3" y="11" rx="2" ry="2"/><path d="M7 11V7a5 5 0 0 1 10 0v4"/></svg><a className="drita">  Password</a></Dropdown.Item>
                                                <Dropdown.Item href="/"> <svg className="mr-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/><polyline points="16 17 21 12 16 7"/><line x1="21" x2="9" y1="12" y2="12"/></svg><a href="/" className="drita">  Logout </a></Dropdown.Item>
                                                
                                            </Dropdown.Menu>
                                    </Dropdown>
                                          
                                </div>
                            </Col>
                           
                    </Row>  
            </Container>
        </div>
    );
}


export default SubHeaderPlatform;