import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import Userf from "../slide/user.png"
import Userf1 from "../slide/us.png"
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import SubHeaderPlatform from '../components/SubHeaderPlatform'
import HeaderDetails from '../components/HeaderDetails'

function Password(){
    return(
        <div  >
                <SubHeaderPlatform />
                <HeaderDetails />
            
            <div style={{backgroundColor: '#f7f6f5'}}>
                <Container style={{paddingTop:'8vw'}}>
                    <Row>
                        <Col lg="8" sm="12" style={{marginBottom:'40vw', display:'flex', flexDirection:'column',borderRadius: '6px',marginRight:''}}>
                            

                            <Col style={{display:'flex',backgroundColor:'white',flexDirection:'column',boxShadow: '0 0 3px 2px rgba(0, 0, 0, .05)',borderRadius: '6px',padding:'4vw'}}>
                                <h1 style={{color:'#861e12',paddingBottom:'1vw',fontFamily:'Inter,sans-serif', fontSize:'30px',fontWeight:'bold',textAlign:'left'}}>Change My Password</h1>    
                                <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label style={{float:'left'}}>My password</Form.Label>
                                            <Form.Control type="email" placeholder="" />
                                            <Form.Text className="text-muted">
                                            
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label  style={{float:'left'}}>New Password</Form.Label>
                                            <Form.Control type="password" placeholder="Password" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label  style={{float:'left'}}>Confirm password</Form.Label>
                                            <Form.Control type="password" placeholder="Password" />
                                        </Form.Group>
                                        

                                    
                                        <a href='#'>
                                                    <Button variant="primary" type="submit" id='bdd' style={{float:'left',marginTop:'1vw', paddingTop:'10px',paddingBottom:'10px', paddingLeft:'5vw', paddingRight:'5vw',border:'none'}}>
                                                        Save Changes
                                                    </Button>
                                        </a> 
                                </Form>
                            
                            </Col>

                        </Col>


                        <Col className="dispn">
                            <Col style={{backgroundColor:'white',marginBottom:'2vw', boxShadow: '0 0 3px 2px rgba(0, 0, 0, .05)',borderRadius: '6px',padding:'2vw'}}>
                                    <h1 style={{fontSize:'30px',fontWeight:'bold',paddingBottom:'1vw',color:'#1e2031',fontFamily:'Inter,sans-serif'}}>Active Members</h1>
                                <Col style={{padding:'2vw 1vw 0vw 1vw', borderTop: 'solid 1px #c3bbaa'}}>
                                    <img className="jimg" src={Userf} alt="user" style={{borderRadius:'50%',width:'25%',paddingRight:'5px'}} />
                                    <img className="jimg" src={Userf1} alt="user" style={{borderRadius:'50%',width:'25%',paddingRight:'5px'}} />
                                    <img className="jimg" src={Userf} alt="user" style={{borderRadius:'50%',width:'25%',paddingRight:'5px'}} />
                                    <img className="jimg" src={Userf1} alt="user" style={{borderRadius:'50%',width:'25%',paddingRight:'5px'}} />
                                    <p className="joinp" style={{color:'#861e12',paddingTop:'1.5vw', fontSize:'14px',fontWeight:'bold'}}>View All</p>   

                                </Col>
                            </Col>
                            <Col style={{backgroundColor:'white', height:'30vw',boxShadow: '0 0 3px 2px rgba(0, 0, 0, .05)',borderRadius: '6px',padding:'2vw'}}>
                                <h1 style={{fontSize:'30px',fontWeight:'bold',paddingBottom:'1vw',color:'#1e2031',fontFamily:'Inter,sans-serif'}}>Also On My Team</h1>    
                                <Col style={{padding:'2vw 1vw 1vw 1vw', borderTop: 'solid 1px #c3bbaa'}}>
                                    <img className="jimg"  src={Userf} alt="user" style={{borderRadius:'50%',width:'25%',float:'left'}} />
                                    <h4 style={{textAlign:'center'}}>Jack Black</h4>
                                    <p className="pa">@AdminCommercial agent1 </p>

                                </Col>
                                <Col style={{padding:'1vw'}}>
                                    <img className="jimg"  src={Userf1} alt="user" style={{borderRadius:'50%',width:'25%',float:'left'}} />
                                    <h4 style={{textAlign:'center'}}>Anna Smith</h4>
                                    <p className="pa">@Commercial agent1 </p>

                                </Col>
                                <Col style={{padding:'1vw'}}>
                                    <img className="jimg"  src={Userf} alt="user" style={{borderRadius:'50%',width:'25%',float:'left'}} />
                                    <h4 style={{textAlign:'center'}}>Jack Black</h4>
                                    <p className="pa">@Commercial agent1 </p>

                                </Col>
                            </Col>
                        
                        
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Password;