import { format } from "date-fns";
export function dateFr(dt, type) {

    if (dt === null) { return '-'; }
    const date = new Date(dt);
    switch (type) {
        case 'd_t':
            return format(date, 'dd/MM/yyyy hh:mm:ss');
            break;
        case 't':
            return format(date, 'dd/MM/yyyy hh:mm:ss');
            break;
        default:
            return format(date, 'dd/MM/yyyy');
            break;
    }
}