import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

const PublishCelebration = ({ showModal, closeModal }) => {

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showModal]);


    return (
        showModal && (
        <div className="modal bg-dark">
            <div className="w-100 h-100 d-flex justify-content-center flex-column text-white">
                    <Row className=' h-100'>
                        <Col lg="6" className='p-0'>
                            <div className='h-100'>
                                <img src='https://demo2.themelexus.com/bexper/wp-content/uploads/2023/08/H1-img-5.jpg' width="100%" height="100%" alt='Publish celebration banner' />
                            </div>
                        </Col>
                        <Col lg="6" className='d-flex flex-column justify-content-center h-100 p-0'>
                            <div className='p-5 justify-self-center'>
                                <div className='fs-1 fw-bold'>Congratulations <br/> User !</div>
                                <p className='fs-6 my-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <span className='my-5'>IDHTOURS</span>
                            </div>
                            <div className='border-top p-5'>
                                <a href='/dashboard' className='btn-blue p-3 px-5 rounded-5 text-white'>Finish</a>
                            </div>
                        </Col>
                    </Row>
            </div>
        </div>)
    )
}

export default PublishCelebration