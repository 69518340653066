import { Info, MinusCircle, PlusCircle } from 'lucide-react'
import { useReducer } from 'react';
import { Tooltip, Whisper } from 'rsuite';


const informations = {
    accommodation_travelers: 1,
    accommodation_rooms: 0,
    accommodation_beds: 1,
    accommodation_baths: 0,
    accommodation_age: 15,
};

const reducer = (state, action) => {
    switch (action.type) {

        case 'INCREMENT_TRAVELERS':
            const incrementedTravelers = state.accommodation_travelers + action.payload;
            localStorage.setItem('accommodation_travelers', incrementedTravelers);
            return { ...state, accommodation_travelers: incrementedTravelers };

        case 'DECREMENT_TRAVELERS':
            const decrementTravelers = state.accommodation_travelers - action.payload;
            localStorage.setItem('accommodation_travelers', decrementTravelers);
            return { ...state, accommodation_travelers: decrementTravelers };

        case 'INCREMENT_ROOMS':
            const incrementedRooms = state.accommodation_rooms + action.payload;
            localStorage.setItem('accommodation_rooms', incrementedRooms);
            return { ...state, accommodation_rooms: incrementedRooms };

        case 'DECREMENT_ROOMS':
            const decrementRooms = state.accommodation_rooms - action.payload;
            localStorage.setItem('accommodation_rooms', decrementRooms);
            return { ...state, accommodation_rooms: decrementRooms };

        case 'INCREMENT_BEDS':
            const incrementBeds = state.accommodation_beds + action.payload;
            localStorage.setItem('accommodation_beds', incrementBeds);
            return { ...state, accommodation_beds: incrementBeds };

        case 'DECREMENT_BEDS':
            const decrementBeds = state.accommodation_beds - action.payload;
            localStorage.setItem('accommodation_beds', decrementBeds);
            return { ...state, accommodation_beds: decrementBeds };

        case 'INCREMENT_BATHS':
            const incrementBaths = state.accommodation_baths + action.payload;
            localStorage.setItem('accommodation_baths', incrementBaths);
            return { ...state, accommodation_baths: incrementBaths };

        case 'DECREMENT_BATHS':
            const decrementBaths = state.accommodation_baths - action.payload;
            localStorage.setItem('accommodation_baths', decrementBaths);
            return { ...state, accommodation_baths: decrementBaths };

        case 'INCREMENT_AGE':
            const incrementAge = state.accommodation_age + action.payload;
            localStorage.setItem('accommodation_age', incrementAge);
            return { ...state, accommodation_age: incrementAge };

        case 'DECREMENT_AGE':
            const decrementAge = state.accommodation_age - action.payload;
            localStorage.setItem('accommodation_age', decrementAge);
            return { ...state, accommodation_age: decrementAge };

        default:
            return state;
    }
};

const DetailsRoom = () => {

    const [state, dispatch] = useReducer(reducer, informations);

    const handleIncrement = (type) => () => {
        dispatch({ type, payload: 1 });
    };

    const handleDecrement = (type) => () => {
        dispatch({ type, payload: 1 });
    };


    const informationsdata = [
        { label: 'Rooms', value: state.accommodation_rooms, min: 1, max: 49, increment: 'INCREMENT_ROOMS', decrement: 'DECREMENT_ROOMS', info: "Please note that one hotel corresponds to only one unit" },
        { label: 'Travelers', value: state.accommodation_travelers, min: 2, max: 15, increment: 'INCREMENT_TRAVELERS', decrement: 'DECREMENT_TRAVELERS', info: "The maximum number of travelers your accommodation can accommodate" },
        { label: 'Beds', value: state.accommodation_beds, min: 2, max: 49, increment: 'INCREMENT_BEDS', decrement: 'DECREMENT_BEDS', info: "The number of beds available to customers, in the case of a hotel the maximum beds that a room can have" },
        { label: 'Baths', value: state.accommodation_baths, min: 1, max: 15, increment: 'INCREMENT_BATHS', decrement: 'DECREMENT_BATHS', info: "The number of bathrooms available to guests, in the case of a hotel the maximum bathroom that a room can have" },
        { label: 'Age', value: state.accommodation_age, min: 16, max: 24, increment: 'INCREMENT_AGE', decrement: 'DECREMENT_AGE', info: "The minimum age required for a traveler to be able to book" },
    ]


    return (
        <>
            {informationsdata.map((item, index) => (
                <div className="d-flex justify-content-between py-4 border-bottom fs-6" key={index}>

                    <div className="text-dark fs-5">{item.label}
                        <span className='mx-2 text-blue'>
                            <Whisper controlId="control-id-hover" trigger="hover" speaker={<Tooltip>{item.info}</Tooltip>}>
                                <Info size={20} strokeWidth={1.5} />
                            </Whisper>
                        </span>
                    </div>

                    <div className="d-flex align-items-center">
                        <button type='button' className="mx-3 bg-white" onClick={handleDecrement(item.decrement)} disabled={item.value < item.min}><MinusCircle color="#1a536d" strokeWidth={1} size={40} /></button>

                        <input className='border-0 tetx-center fs-6' value={item.value} type="number" readOnly style={{ width: "30px", height: "30px" }} />

                        <button type='button' className="mx-3 bg-white" onClick={handleIncrement(item.increment)} disabled={item.value > item.max}><PlusCircle size={40} color="#1a536d" strokeWidth={1} /></button>
                    </div>
                </div>
            ))}
        </>
    )
}

export default DetailsRoom