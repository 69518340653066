import Contact1 from "./Contact1";
import Contact2 from "./Contact2";
import Contact3 from "./Contact3";
import Contactf from "./Contactf";
import Header from "./home/Header";
import NewFoot from "./home/NewFoot";
import SubHeader from "./home/SubHeader";

function Contact(){
    return(
        <div>
            <SubHeader />
            <Header />
            <Contact1 />
            <Contact2 />
            <Contact3 />
            <Contactf />
            <NewFoot />
        </div>
    );
}

export default Contact;