import { Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import TopBanner from '../components/TopBanner';
import Input from '../components/forms/Input'
import Select from '../components/forms/Select'
import Textarea from '../components/forms/Textarea'
import { useEffect } from 'react';
import { Toggle } from 'rsuite';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

function Supplier() {

    const [annees, setAnnees] = useState([]);
    const [mois, setMois] = useState([]);
    const [jours, setJours] = useState([]);

    useEffect(() => {
        const remplirAnnees = () => {
            const anneeActuelle = new Date().getFullYear();
            const anneesArray = [];

            for (let annee = 1930; annee <= anneeActuelle; annee++) {
                anneesArray.push(annee);
            }

            setAnnees(anneesArray);
        };

        const remplirMois = () => {
            const moisArray = Array.from({ length: 12 }, (_, index) => {
                const date = new Date(2000, index, 1); // Choisissez une année et un jour arbitraires pour obtenir le nom du mois
                const nomMois = date.toLocaleString('fr-FR', { month: 'long' });
                return { numero: index + 1, nom: nomMois };
            });

            setMois(moisArray);
        };

        const remplirJours = () => {
            const joursArray = Array.from({ length: 31 }, (_, index) => index + 1);
            setJours(joursArray);
        };

        remplirAnnees();
        remplirMois();
        remplirJours();
    }, []);

    const genderData = [
        { title: "Gender", value: "" },
        { title: "Male", value: "male" },
        { title: "Female", value: "female" },
    ]

    return (

        <>
            <TopBanner data="Sign up" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='my-5 container'>
                <span className='fs-2 fw-bold text-dark'>Register</span>
                <p>Enter your details  below to create your account and get started</p>

                <Row>
                    <Col md="6" className='my-3'>
                        <Input type="text" placeholder="Full name or company name" />
                    </Col>
                    <Col md="6" className='my-3'>
                        <Select data={genderData} />
                    </Col>
                </Row>

                <Row>
                    <Col md="6" className='my-3'>
                        <Input type="text" placeholder="Email" />
                    </Col>
                    <Col md="6" className='my-3'>
                        <Input type="text" placeholder="Phone" />
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className='my-3'>
                        <Input type="text" placeholder="Address" />
                    </Col>

                    <Col lg="6">
                        <Row>
                            <Col sm="4" className='my-3'>
                                <select id="jour" name="jour" className='input-blue w-100 p-4 border rounded'>
                                    {jours.map((jour) => (
                                        <option key={jour} value={jour}>
                                            {jour}
                                        </option>
                                    ))}
                                </select>
                            </Col>

                            <Col sm="4" className='my-3'>
                                <select id="mois" name="mois" className='input-blue w-100 p-4 border rounded'>
                                    {mois.map((mois) => (
                                        <option key={mois.numero} value={mois.numero}>
                                            {mois.nom}
                                        </option>
                                    ))}
                                </select>
                            </Col>

                            <Col sm="4" className='my-3'>
                                <select id="annee" name="annee" className='input-blue w-100 p-4 border rounded'>
                                    {annees.map((annee) => (
                                        <option key={annee} value={annee}>
                                            {annee}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col xl="6" className='my-3'>
                        <Textarea rows={12} placeholder="Brief description of you and your business" />
                    </Col>
                    <Col xl="6">
                        <Row>
                            <Col sm="6" xl="12" className='my-3'>
                                <Input type="text" placeholder="Password" />
                            </Col>
                            <Col sm="6" xl="12" className='my-3'>
                                <Input type="text" placeholder="Confirm password" />
                            </Col>
                        </Row>
                        <div className='d-flex align-items-center'>
                            <Toggle checkedChildren={<CheckIcon />} unCheckedChildren={<CloseIcon />} />
                            <span className='mx-3'>I accept terms and conditions</span>
                        </div>
                        <button className='btn-blue p-3 px-5 rounded-5 mt-3'>Confirm</button>
                    </Col>
                </Row>

            </div>

        </>

    );
}

export default Supplier;