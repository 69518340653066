import { Panel } from 'rsuite';
import FilterMap from '../../components/FilterMap'
import FilterPrice from '../../components/FilterPrice';
import FiltersData from '../../data/globaldata.json'

const Filters = () => {

    return (
        <>
            <div className='d-none d-xl-block position-relative'>
                <FilterMap/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <FilterPrice/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Brand</span>} collapsible>
                    {FiltersData.brands.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'>
                                <input id={item.value} type='checkbox' />
                                <label htmlFor={item.value} className='my-1 mx-3 fw-light'>{item.title}</label>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Features</span>} collapsible>
                    {FiltersData.car_features.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'>
                                <input id={item.value} type='checkbox' />
                                <label htmlFor={item.value} className='my-1 mx-3 fw-light'>{item.title}</label>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Color</span>} collapsible>
                    {FiltersData.colors.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'>
                                <input id={item.value} type='checkbox' />
                                <label htmlFor={item.value} className='my-1 mx-3 fw-light'>{item.title}</label>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

        </>
    )
}

export default Filters