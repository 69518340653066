import { Modal, Row, Col } from 'react-bootstrap';

function Guests({ showGuests, handleCloseGuests, AdultsCount, handlesetAdultsCount, ChildsCount, handlesetChildsCount, CountryValue, handleCountryValue, NationalityValue, handleNationalityValue, GuestsCount }) {

    return (
        <>
            <Modal show={showGuests} onHide={handleCloseGuests} backdrop="static" keyboard={false}>
                <div className='bg-white text-dark rounded-4'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div>
                                Total guests : {GuestsCount}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='px-3'>
                            <Row>
                                <Col xs="12" className='my-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor='class' className='fw-bold fs-6'>Nationality</label>
                                        <select id='class' className='p-3 border input-blue w-100  mt-1 rounded' value={NationalityValue} onChange={handleNationalityValue}>
                                            <option>ALGERIA</option>
                                            <option>FRANCE</option>
                                            <option>ALBANIA</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" className='my-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor='country' className='fw-bold fs-6'>Country</label>
                                        <select id="country" className='input-blue border mt-1 p-3 rounded' value={CountryValue} onChange={handleCountryValue}>
                                            <option>ALGERIA</option>
                                            <option>FRANCE</option>
                                            <option>ALBANIA</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className='my-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor='adults' className='fw-bold fs-6'>Adults <span className='guests-notation'>Plus de 12 ans</span></label>
                                        <select id="adults" className='input-blue border mt-1 p-3 rounded' value={AdultsCount} onChange={handlesetAdultsCount}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" className='my-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor='childs' className='fw-bold fs-6'>Childs <span className='guests-notation'>De 0 à 11 ans</span></label>
                                        <select id="childs" className='input-blue border mt-1 p-3 rounded' value={ChildsCount} onChange={handlesetChildsCount}>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn-blue p-3 px-4 rounded-5' onClick={handleCloseGuests}>
                            Save Changes
                        </button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
}

export default Guests;

