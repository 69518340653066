import { Car, Hotel, Map, Package, CarTaxiFront, TrainFront } from 'lucide-react'
import { RadioTile, RadioTileGroup } from 'rsuite';
import { Row, Col } from 'react-bootstrap'
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AddProduct = () => {

    const { t } = useTranslation();

    const choice = [

        { title: t('accommodations'), icon: <Hotel strokeWidth={1.5} size={35} />, value: "accommodation", intro: t('accommodations_intro') },
        { title: t('activities'), icon: <Map strokeWidth={1.5} size={35} />, value: "activity", intro: t('activities_intro') },
        { title: t('cars'), icon: <Car size={35} strokeWidth={1.5} />, value: "car", intro: t('cars_intro') },
        { title: t('packages'), icon: <Package size={35} strokeWidth={1.5} />, value: "package", intro: t('packages_intro') },
        { title: t('transportations'), icon: <TrainFront size={35} strokeWidth={1.5} />, value: "transportation", intro: t('transportations_intro') },
        { title: t('transfers'), icon: <CarTaxiFront size={35} strokeWidth={1.5} />, value: "transfer", intro: t('transfers_intro') }
    ]

    const [selectedValue, setSelectedValue] = useState("accommodation");

    const handleRadioButtonChange = (value) => {
        setSelectedValue(value);
    };

    return (
        <section className='bg-body-tertiary'>
            <div className='container py-3'>
                <fieldset>
                    <Row>
                        <Col xl="12" className='d-flex flex-column justify-content-center'>
                            <legend className='text-blue fs-1 fw-bold'>{t('addproduct_title')}</legend>
                            <p className='fs-6'>{t('addproduct_text')}</p>
                            <RadioTileGroup aria-label="Create new project" className='mt-3'>
                                <Row>
                                    {choice.map((choice, index) => (
                                        <Col key={index} md="6" lg="4" xl="3" className='my-2'>
                                            <RadioTile value={choice.value} onChange={() => handleRadioButtonChange(choice.value)} className={`bg-white p-4 border h-100 ${selectedValue === choice.value ? 'border-blue bg-blue' : ''}`}>
                                                <div className='text-dark d-flex flex-column align-items-center'>
                                                    <div className='text-blue'>
                                                        {choice.icon}
                                                    </div>
                                                    <div className='mt-3 fw-bold fs-6'>
                                                        {choice.title}
                                                    </div>
                                                    <p className='fs-6 text-center mt-2'>{choice.intro}</p>
                                                </div>
                                            </RadioTile>
                                        </Col>
                                    ))}
                                </Row>
                            </RadioTileGroup>
                            {selectedValue && (
                                <div className='my-5'>
                                    <a className='btn-blue p-3 px-5 text-white rounded-5' href={`/dashboard/${selectedValue}`} target="_blank" rel="noopener noreferrer">
                                        {t('start')}
                                    </a>
                                </div>
                            )}
                        </Col>
                    </Row>
                </fieldset>
            </div>
        </section>
    )
}

export default AddProduct