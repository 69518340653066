import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import IForm from '../../global/IForm'
import { contactInput } from './contactInput';
import { ArrowRight } from 'lucide-react'
import { _post } from '../../global/axios'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next';

function FormConcat(props) {
    const ifrom = new IForm();
    ifrom.init(contactInput);
    const [loading, setLoading] = useState(false);
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            full_name: "",
            phone: "",
            email: "",
            message: ""
        }
    });

    const onSubmit = async (data) => {
        setLoading(true);
        const res = await _post('/contact', data);
        if (res?.data?.status == true) {
            NotificationManager.success(res?.data?.result.message);
        }
        setLoading(false);
    }

    const { t } = useTranslation();

    return (
        <>

            <form onSubmit={handleSubmit(onSubmit)} >
                <div className='rounded-4 p-5 d-flex flex-column shadow '>
                    <span className='h3 text-dark fw-bold'>{t('send_a_message')}</span>
                    <Row className='justify-content-between'>
                        <Col md="6" className='mt-4'>

                            <FloatingLabel
                                controlId="full_name"
                                label={"Full name"}
                                className="mb-3"
                            >
                                {ifrom.controll('full_name', control)}
                                <small className="erreur-input">
                                    {errors?.full_name && errors.full_name.message}
                                </small>
                            </FloatingLabel>
                        </Col>
                        <Col md="6" className='mt-4'>
                            <FloatingLabel
                                controlId="phone"
                                label={"Name"}
                                className="mb-3"
                            >
                                {ifrom.controll('phone', control)}
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col lg="12">
                            <FloatingLabel
                                controlId="email"
                                label={"Email"}
                                className="mb-3"
                            >
                                {ifrom.controll('email', control)}
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col lg="12">
                            <FloatingLabel
                                controlId="message"
                                label={"Message"}
                                className="mb-3"
                            >
                                {ifrom.controll('message', control)}
                            </FloatingLabel>
                        </Col>
                    </Row>
                    {gteSubmitContent({ loading })}

                </div>
            </form>
        </>
    );
}

export default FormConcat;

const gteSubmitContent = ({ loading }) => {

    return (

        <button type="submit" className="btn-blue mt-5 p-3 px-4 rounded-5 align-self-start mt-3 p-3 ps-4 pe-4" >
            {loading && (
                <span className="spinner-border spinner-border-sm"></span>
            )}
            Submit<ArrowRight className='ms-2' size={20} /> </button>

    );
}; 