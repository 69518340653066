import { Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import { Panel, PanelGroup } from 'rsuite';
import FaqData from '../data/faqdata.json'
import TopBanner from '../components/TopBanner';
import { CalendarCheck, CreditCard, FolderLock, User } from 'lucide-react';

function Faq() {

    const [boutonActif, setBoutonActif] = useState('Payment');

    const handleClick = (bouton) => {
        setBoutonActif(bouton);
    };

    const buttons = [
        { title: "Payment", icon: <CreditCard /> },
        { title: "Booking", icon: <CalendarCheck /> },
        { title: "Privacy", icon: <FolderLock /> },
        { title: "Account", icon: <User /> },
    ]

    const Payment = () => {
        return (
            <div className='rounded-4 border p-3'>
                <PanelGroup accordion defaultActiveKey={0}>
                    {FaqData.payment.map((item,index)=>(
                        
                            <Panel eventKey={index} className='my-3' key={index} header={<span className='fs-5 fw-medium'>{item.question}</span>} collapsible bordered>
                                <div>{item.response}</div>
                            </Panel>
                        
                    ))}
                </PanelGroup>
            </div>
        )
    }

    const Booking = () => {
        return (
            <div className='rounded-4 border p-3'>
                <PanelGroup accordion defaultActiveKey={0}>
                    {FaqData.booking.map((item,index)=>(
                        
                            <Panel eventKey={index} className='my-3' key={index} header={<span className='fs-5 fw-medium'>{item.question}</span>} collapsible bordered>
                                <div>{item.response}</div>
                            </Panel>
                        
                    ))}
                </PanelGroup>
            </div>
        )
    }

    const Privacy = () => {
        return (
            <div className='rounded-4 border p-3'>
                <PanelGroup accordion defaultActiveKey={0}>
                    {FaqData.privacy.map((item,index)=>(
                        
                            <Panel eventKey={index} className='my-3' key={index} header={<span className='fs-5 fw-medium'>{item.question}</span>} collapsible bordered>
                                <div>{item.response}</div>
                            </Panel>
                        
                    ))}
                </PanelGroup>
            </div>
        )
    }

    return (

        <>
            <TopBanner data="Frequently asked questions" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />
            <div className='my-5 container'>
                <Row>
                    <Col xl="3">
                        <div className='border p-3 rounded-4'>
                            {buttons.map((button, index) => (
                                <button key={index} className={`w-100 my-2 border rounded-5 p-2 px-3 fw-medium text-center ${boutonActif === button.title ? 'bg-blue text-white' : 'bg-white'}`} onClick={() => handleClick(button.title)}>
                                    {button.icon} {button.title}
                                </button>
                            ))}
                        </div>
                    </Col>

                    <Col xl="9">
                        <>
                            {boutonActif === 'Payment' && <Payment />}
                            {boutonActif === 'Booking' && <Booking/>}
                            {boutonActif === 'Privacy' && <Privacy/>}
                            {boutonActif === 'Account' && <Booking/>}
                        </>
                    </Col>
                </Row>
            </div>

        </>

    );
}

export default Faq;