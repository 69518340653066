import { Modal } from 'react-bootstrap';
import { MinusCircle, PlusCircle } from 'lucide-react';
import { useState } from 'react';

function Guests({ showGuests, handleCloseGuests }) {

    const [adultCount, setadultCount] = useState(1);
    const [childCount, setchildCount] = useState(0);
    const [infantCount, setinfantCount] = useState(0);


    const TotalCount = parseInt(adultCount) + parseInt(childCount) + parseInt(infantCount)


    const incrementAdultCount = () => {
        setadultCount(adultCount + 1);
    };

    const decrementAdultCount = () => {
        setadultCount(adultCount - 1);
    };

    const incrementChildCount = () => {
        setchildCount(childCount + 1);
    };

    const decrementChildCount = () => {
        setchildCount(childCount - 1);
    };

    const incrementInfantCount = () => {
        setinfantCount(infantCount + 1);
    };

    const decrementInfantCount = () => {
        setinfantCount(infantCount - 1);
    };

    return (
        <>
            <Modal show={showGuests} onHide={handleCloseGuests} backdrop="static" keyboard={false}>
                <div className='bg-white text-dark rounded-4'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div>
                                Total guests : {TotalCount}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="p-3 fw-bold">
                    <div className="d-flex justify-content-between py-3 border-bottom">
                        <div>
                            <div className="text-dark fs-6">Adult</div>
                            <div className='fw-light'>(Age 16-99)</div>
                        </div>

                        <div className="d-flex align-items-center">
                            <button className="mx-2 bg-white" onClick={decrementAdultCount} disabled={adultCount < 1}><MinusCircle color="#1a536d" strokeWidth={1.5} /></button>
                            <span className="border mx-2 text-dark d-flex align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>{adultCount}</span>
                            <button className="mx-2 bg-white" onClick={incrementAdultCount} disabled={adultCount > 4}><PlusCircle color="#1a536d" strokeWidth={1.5} /></button>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-3 my-3 border-bottom">
                        <div>
                            <div className="text-dark fs-6">Children</div>
                            <div className='fw-light'>(Age 2-15)</div>
                        </div>

                        <div className="d-flex align-items-center">
                            <button className="mx-2 bg-white" onClick={decrementChildCount} disabled={childCount < 1}><MinusCircle color="#1a536d" strokeWidth={1.5} /></button>
                            <span className="border mx-2 text-dark d-flex align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>{childCount}</span>
                            <button className="mx-2 bg-white" onClick={incrementChildCount} disabled={childCount > 4}><PlusCircle color="#1a536d" strokeWidth={1.5} /></button>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-3 border-bottom fs-6">
                        <div>
                            <div className="text-dark fs-6">Babies</div>
                            <div className='fw-light'>(Age 0-1)</div>
                        </div>

                        <div className="d-flex align-items-center">
                            <button className="mx-2 bg-white" onClick={decrementInfantCount} disabled={infantCount < 1}><MinusCircle color="#1a536d" strokeWidth={1.5} /></button>
                            <span className="border mx-2 text-dark d-flex align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>{infantCount}</span>
                            <button className="mx-2 bg-white" onClick={incrementInfantCount} disabled={infantCount > 4}><PlusCircle color="#1a536d" strokeWidth={1.5} /></button>
                        </div>
                    </div>
                </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn-blue p-3 px-4 rounded-5' onClick={handleCloseGuests}>
                            Save Changes
                        </button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
}

export default Guests;

