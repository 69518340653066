import { PlusCircle, MinusCircle, Users } from 'lucide-react'
import React, { useState } from 'react';
import { Dropdown } from 'rsuite';

const GuestsCount = () => {

    const [adultCount, setadultCount] = useState(1);
    const [childCount, setchildCount] = useState(0);
    const [infantCount, setinfantCount] = useState(0);


    const incrementAdultCount = () => {
        setadultCount(adultCount + 1);
    };

    const decrementAdultCount = () => {
        setadultCount(adultCount - 1);
    };

    const incrementChildCount = () => {
        setchildCount(childCount + 1);
    };

    const decrementChildCount = () => {
        setchildCount(childCount - 1);
    };

    const incrementInfantCount = () => {
        setinfantCount(infantCount + 1);
    };

    const decrementInfantCount = () => {
        setinfantCount(infantCount - 1);
    };


    return (
        <div>
            <Dropdown title={<div className='d-flex align-items-center justify-content-center fw-bold'><Users size={20}/><span className='mx-1'>Adults x {adultCount}</span><span className='mx-1'>Childs x {childCount}</span><span className='mx-1'>Infant x {infantCount}</span></div>} className='w-100'>


                <div className="p-3 fw-bold">
                    <div className="d-flex justify-content-between py-3 border-bottom fs-6">
                        <div>
                            <div className="text-dark">Adult</div>
                            <div>(Age 16-99)</div>
                        </div>

                        <div className="d-flex align-items-center">
                            <button className="mx-2 bg-white" onClick={decrementAdultCount} disabled={adultCount < 1}><MinusCircle color="#1a536d" strokeWidth={1.5} /></button>
                            <span className="border mx-2 text-dark d-flex align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>{adultCount}</span>
                            <button className="mx-2 bg-white" onClick={incrementAdultCount} disabled={adultCount > 4}><PlusCircle color="#1a536d" strokeWidth={1.5} /></button>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-3 my-3 border-bottom fs-6">
                        <div>
                            <div className="text-dark">Children</div>
                            <div>(Age 2-15)</div>
                        </div>

                        <div className="d-flex align-items-center">
                            <button className="mx-2 bg-white" onClick={decrementChildCount} disabled={childCount < 1}><MinusCircle color="#1a536d" strokeWidth={1.5} /></button>
                            <span className="border mx-2 text-dark d-flex align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>{childCount}</span>
                            <button className="mx-2 bg-white" onClick={incrementChildCount} disabled={childCount > 4}><PlusCircle color="#1a536d" strokeWidth={1.5} /></button>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-3 border-bottom fs-6">
                        <div>
                            <div className="text-dark">Infant</div>
                            <div>(Age 0-1)</div>
                        </div>

                        <div className="d-flex align-items-center">
                            <button className="mx-2 bg-white" onClick={decrementInfantCount} disabled={infantCount < 1}><MinusCircle color="#1a536d" strokeWidth={1.5} /></button>
                            <span className="border mx-2 text-dark d-flex align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>{infantCount}</span>
                            <button className="mx-2 bg-white" onClick={incrementInfantCount} disabled={infantCount > 4}><PlusCircle color="#1a536d" strokeWidth={1.5} /></button>
                        </div>
                    </div>
                </div>
            </Dropdown>
        </div>
    )
}

export default GuestsCount