import { useParams } from "react-router-dom";
import details from '../../data/cardata.json'
import { Dot, Star, LayoutGrid, Tag, Users2, Luggage, DoorOpen, Settings } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import Reviews from '../../components/Reviews'
import ImagesCarousel from '../../components/ImagesCarousel'
import { useState } from 'react';
import WriteReview from '../../components/WriteReview'
import TopBanner from '../../components/TopBanner'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'


function Details() {

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const { id } = useParams();
    let car = details.cars.filter(objet => objet.id === parseInt(id))[0];

    return (

        <div>
            <SubHeaderPlatform />
            <HeaderDetails />
            <section>
                <TopBanner data={car.name} cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

                <div className='container'>

                    <ImagesCarousel showModal={showModal} closeModal={closeModal} item={car} />

                    <div className='my-4 d-flex justify-content-between align-items-center'>
                        <div>
                            <div className='d-flex align-items-center'>
                                {Array.from({ length: 3 }).map((_, starIndex) => (
                                    <div key={starIndex} className='me-1'><Star color='#F8D448' fill='#F8D448' size={13} /></div>
                                ))}
                            </div>
                            <div className='my-2'>
                                <span className='py-1 px-2 border-primary-subtle rounded fw-bold' style={{ color: "#1a536d", border: "1px solid #1a536d" }}>5 / 5</span>
                                <span className='fw-bold text-dark mx-3'>Excellent</span>
                                <span>(3 Reviews)</span>
                                <span><Dot /></span>
                                <span>{car.registredcity.toUpperCase()}</span>
                            </div>
                        </div>
                        <a href={`/booking/car/${id}`} className='btn-blue p-3 rounded-5 fw-bold text-white'>Reserve a car</a>
                    </div>

                    <div role="button" className='position-relative' onClick={openModal}>
                        <Row className='rounded-4 justify-content-between'>
                            <Col lg="7" className='px-1'>
                                <img alt="Illustration d'un hôtel 1" width='100%' height="100%" style={{ maxHeight: "450px", objectFit: "cover" }} src={car.images[0]} />
                            </Col>
                            <Col lg="5" className='d-none d-lg-block'>
                                <Row style={{ rowGap: '10px' }}>
                                    <Col md="6" className="px-1">
                                        <img width="100%" height="100%" alt={`Ilustration 2 de l'activité`} src={car.images[1]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                    </Col>
                                    <Col md="6" className="px-1">
                                        <img width="100%" height="100%" alt={`Ilustration 2 de l'activité`} src={car.images[2]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                    </Col>
                                    <Col md="6" className="px-1">
                                        <img width="100%" height="100%" alt={`Ilustration 3 de l'activité`} src={car.images[3]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                    </Col>
                                    <Col md="6" className="px-1">
                                        <img width="100%" height="100%" alt={`Ilustration 4 de l'activité`} src={car.images[4]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div role='button' className='d-flex align-items-center position-absolute text-white rounded-5 py-3 px-3 border border-white' style={{ bottom: "30px", right: "30px", background: "#00000036" }}><LayoutGrid strokeWidth={1} size={20} /> <span className='mx-1'>All {car.images.length} photos</span></div>
                    </div>
                </div>

                <div className='container'>
                    <Row>
                        <Col lg="7" xl="8">
                            <Row className="my-4 justify-content-between">
                                <Col xl="3" className="d-flex align-items-center">
                                    <Users2 strokeWidth={1} size={40} />
                                    <span className="fs-5 mx-1">{car.passengers}</span>
                                </Col>
                                <Col xl="3" className="d-flex align-items-center">
                                    <Luggage strokeWidth={1} size={40} />
                                    <span className="fs-5 mx-1">{car.bagage}</span>
                                </Col>
                                <Col xl="3" className="d-flex align-items-center">
                                    <DoorOpen strokeWidth={1} size={40} />
                                    <span className="fs-5 mx-1">{car.doors}</span>
                                </Col>
                                <Col xl="3" className="d-flex align-items-center">
                                    <Settings strokeWidth={1} size={40} />
                                    <span className="fs-5 mx-1">{car.transmission}</span>
                                </Col>
                            </Row>

                            <div className='my-2'>
                                <h2 className='text-dark fs-3'>About this car</h2>
                                <div className='text-dark my-3' style={{ fontSize: "16px" }}>
                                    <p dangerouslySetInnerHTML={{ __html: car.description }}></p>
                                </div>
                            </div>

                            <hr className='my-5 border-secondary' />

                            <div className='my-2'>
                                <h2 className='text-dark fs-3'>Car features</h2>
                                <Row>
                                    {car.amenties.map((item, index) => (
                                        <Col key={index} xs="6" sm="4" md="3" lg="4" xl="3" className="my-3 fs-6 text-dark">
                                            <Tag /> <span className="mx-3">{item}</span>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Col>

                        <Col className='d-none d-lg-block' lg="5" xl="4">
                            <div className="my-3">
                                <Reviews />
                            </div>
                        </Col>

                    </Row>


                    <WriteReview />
                </div>

            </section>
        </div>
      

    );

}

export default Details;
