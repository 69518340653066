import { Users, Home, DoorOpen } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import Recap from './Recap'
import Input from '../../../components/forms/Input';
import { useReducer } from 'react';
import { RadioTile, RadioTileGroup } from 'rsuite';
import TopBanner from '../../../components/TopBanner';
import supplierData from '../../../data/globaldata.json'

const create = {
    accommodation_name: '',
    accommodation_type: 'Home',
    accommodation_choice: 'Entire accommodation',
    accommodation_location: '',
};

const reducer = (state, action) => {
    switch (action.type) {

        case 'SET_LOCATION':
            localStorage.setItem('accommodation_location', action.payload);
            return { ...state, accommodation_location: action.payload };

        case 'SET_NAME':
            localStorage.setItem('accommodation_name', action.payload);
            return { ...state, accommodation_name: action.payload };

        case 'SET_TYPE':
            localStorage.setItem('accommodation_type', action.payload);
            return { ...state, accommodation_type: action.payload };

        case 'SET_CHOICE':
            localStorage.setItem('accommodation_choice', action.payload);
            return { ...state, accommodation_choice: action.payload };

        default:
            return state;
    }
};


const Create = () => {

    const [state, dispatch] = useReducer(reducer, create);

    const handleRadioChange = (type) => (e) => {
        dispatch({ type, payload: e });
    };

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    const accomodationchoice = [
        { title: "Entire", icon: <Home strokeWidth={1.2} size={35} />, value: "Entire accommodation", description: "The entire accommodation" },
        { title: "A room", icon: <DoorOpen strokeWidth={1.2} size={35} />, value: "One bedroom", description: "A personal room with shared spaces" },
        { title: "Shared room", icon: <Users strokeWidth={1.2} size={35} />, value: "Shared room", description: "A room that is shared with other people" },
    ]

    return (
        <section>
            <TopBanner data="Create" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/accommodation/informations'>
                            <fieldset>
                                <legend className='text-dark fs-2 fw-medium'>What type of accommodation will be available to travelers?</legend>
                                <p className='fs-6 my-3'>Give correct informations and specify what type of accommodation you have, and what you wish to rent out.</p>

                                <Row>
                                    <Col md="6" xl="6">
                                        <div className='my-3'>
                                            <Input value={state.accommodation_name} type="text" handleChange={handleChange('SET_NAME')} placeholder="Name of your accommodation" maxlength="100" />
                                        </div>
                                    </Col>
                                    <Col md="6" xl="6">
                                        <div className='my-3'>
                                            <Input value={state.accommodation_location} type="text" handleChange={handleChange('SET_LOCATION')} placeholder="Where is your accommodation located?" maxlength="30" />
                                        </div>
                                    </Col>
                                </Row>

                                <div className='my-3'>
                                    <RadioTileGroup defaultValue="Home">
                                        <Row>
                                            {supplierData.accomodation.map((choice, index) => (
                                                <Col key={index} xs="6" sm="4" md="3" lg="3" className='my-2'>
                                                    <RadioTile value={choice.value} onChange={handleRadioChange('SET_TYPE')} className={`p-3 ${state.accommodation_type === choice.value ? 'border-dark' : 'border'}`} >
                                                        <div className='text-dark fs-5 p-3'>

                                                            {choice.title}

                                                        </div>
                                                    </RadioTile>
                                                </Col>
                                            ))}
                                        </Row>
                                    </RadioTileGroup>
                                </div>

                                <div className='my-3'>
                                    <RadioTileGroup defaultValue="Entire accommodation">
                                        <Row>
                                            {accomodationchoice.map((choice, index) => (
                                                <Col key={index} xs="12" className='my-2'>
                                                    <RadioTile value={choice.value} onChange={handleRadioChange('SET_CHOICE')} className={`p-3 ${state.accommodation_choice === choice.value ? 'border-dark' : 'border'}`} >
                                                        <div className='text-dark'>
                                                            {choice.icon}
                                                            <div className='mt-2 fs-5 fw-medium'>{choice.title}</div>
                                                            <p>{choice.description}</p>

                                                        </div>
                                                    </RadioTile>
                                                </Col>
                                            ))}
                                        </Row>
                                    </RadioTileGroup>
                                </div>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Create