import { Maximize, Menu, Power, Search } from "lucide-react"
import { Col, Row } from "react-bootstrap"
import { useState } from "react";
import LeftSide from "./LeftSide";
import Dashboard from "./Dashboard";
import Offcanvas from 'react-bootstrap/Offcanvas';
import AddProduct from "./AddProduct";
import ManageProduct from "./ManageProduct";
import Settings from "./Settings";
import { changeLanguage } from '../../lang/i18n';
import { useLocation } from 'react-router-dom';
import i18n from '../../lang/i18n'
import { Dropdown } from 'rsuite';

const Home = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const currentLanguage = i18n.language;
    const location = useLocation();

    const handleFullscreenToggle = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    };

    const [boutonActif, setBoutonActif] = useState('dashboard');

    const handleClick = (bouton) => {
        setBoutonActif(bouton);
    };

    const handleLanguageChange = (lng) => {
        changeLanguage(lng);
    };

    const lang = [
        { title: "English", country: "Usa", value: "en" },
        { title: "Français", country: "France", value: "fr" },
        { title: "Español", country: "España", value: "es" },
    ]

    const renderIconButton = (props, ref) => {
        return (
            <div {...props} ref={ref} role='button' className='text-dark d-flex justify-content-center align-items-center bg-dark-subtle rounded-5 p-2'>
                <img src={require(`../../assets/flags/${currentLanguage}.png`)} width="25%" height="25%" alt={`${currentLanguage} Flag `} />
                <span className='mx-2'>|</span>
                <div>
                    {currentLanguage}
                </div>
            </div>
        );
    };

    return (

        <>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Dashboard</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <LeftSide handleClick={handleClick} boutonActif={boutonActif} />
                </Offcanvas.Body>
            </Offcanvas>
            <Row>
                <Col xl="2" className="border-end d-none d-xl-block">
                    <LeftSide handleClick={handleClick} boutonActif={boutonActif} />
                </Col>

                <Col xl="10" className="p-0">
                    <Row className="p-3 justify-content-between align-items-center">
                        <Col xs="1" sm="1" className="d-xl-none">
                            <div role="button" onClick={handleShow}>
                                <Menu />
                            </div>
                        </Col>
                        <Col xs="8" sm="6" md="4">
                            <div className="d-flex align-items-center border p-2 rounded-5">
                                <Search strokeWidth={1.5} /> <input className="border-0 p-1 mx-2" placeholder="Search" />
                            </div>
                        </Col>

                        <Col sm="4" md="4" className="d-none d-sm-block">
                            <div className="d-flex justify-content-end align-items-center text-dark">

                                <Dropdown renderToggle={renderIconButton}>
                                    <div className='px-3'>
                                        {lang.map((lang, index) => (
                                            <a key={index} className='d-flex align-items-center p-3 text-dark lang-item rounded' href={location.pathname} onClick={() => handleLanguageChange(lang.value)}>
                                                <img src={require(`../../assets/flags/${lang.value}.png`)} width="20%" height="20%" alt={`${lang.country} Flag `} />
                                                <span className='mx-2 fs-6 fw-medium'>{lang.title}</span>
                                            </a>
                                        ))}
                                    </div>
                                </Dropdown>

                                <span className="mx-3" role="button" onClick={handleFullscreenToggle}><Maximize size={20} /></span>
                                <span className="mx-3 text-danger d-flex align-items-center" >
                                    <Power size={20} />
                                    <span className="mx-1 fs-6 text-dark">Log out</span>
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <hr className="my-0 border-secondary" />
                    <div className="p-3 py-1" style={{ background: "#F8FAFD" }}>
                        <div className="my-2">
                            {boutonActif === 'dashboard' && <Dashboard />}
                            {boutonActif === 'create' && <AddProduct />}
                            {boutonActif === 'manage' && <ManageProduct />}
                            {boutonActif === 'settings' && <Settings />}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Home