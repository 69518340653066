import React, { useState } from 'react';
import { Row, Col, Offcanvas } from 'react-bootstrap';
import Filters from './Filters'
import FlexDisplay from './FlexDisplay'
import { SlidersHorizontal, Check, ChevronRight, ChevronLeft } from 'lucide-react'
import Availability from './forms/Availability'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

function Search() {


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const elements = [
        { price: "26 561", date: "jeudi. 28" },
        { price: "39 289", date: "ven. 29" },
        { price: "63 395", date: "sam. 30" },
        { price: "49 545", date: "dim. 31" },
        { price: "26 561", date: "jeudi. 28" },
        { price: "39 289", date: "ven. 29" },
        { price: "63 395", date: "sam. 30" },
        { price: "49 545", date: "dim. 31" },
        { price: "26 561", date: "jeudi. 28" },
        { price: "39 289", date: "ven. 29" },
        { price: "63 395", date: "sam. 30" },
        { price: "49 545", date: "dim. 31" },

    ];


    const [startIndex, setStartIndex] = useState(0);
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);

    const handlePrevious = () => {
        if (startIndex > 0) {
            setStartIndex(prev => prev - 1);
        }
    };

    const handleNext = () => {
        if (startIndex < elements.length - 1) {
            setStartIndex(prev => prev + 1);
        }
    };

    const handleItemClick = (index) => {
        setSelectedItemIndex(index);
    };

    const visibleElements = elements.slice(startIndex, startIndex + 6);


    return (
        <>
            <div>
                <SubHeaderPlatform />
                <HeaderDetails />
                <section className='bg-body-tertiary'>
                
                    <div className="pt-5 backgrounded-container">
                        <div className="mt-5 pt-5 pb-3">
                            <div className='container'>
                                <div className='py-3 rounded bg-white rounded-5 my-3'>
                                    <Availability />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-xl">
                        <Offcanvas className="w-100" show={show} onHide={handleClose}>
                            <Offcanvas.Header closeButton>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Filters />
                            </Offcanvas.Body>
                        </Offcanvas>

                        <div className='bg-white border rounded-4 p-5 my-5' style={{ overflowX: 'hidden' }}>
                            <div className='fs-6'>vendredi 29 décembre 2023</div>
                            <div className='d-flex align-items-center justify-content-center mt-3'>
                                <button className='bg-white' onClick={handlePrevious} disabled={startIndex === 0}>
                                    <ChevronLeft strokeWidth={1} size={40} />
                                </button>
                                <div className='d-flex align-items-center justify-content-between w-100 px-md-5'>
                                    {visibleElements.map((element, index) => (
                                        <div style={{ minWidth: '80px' }} className='mx-1' role='button' onClick={() => handleItemClick(index)} key={index}>
                                            <div className='d-flex flex-column align-items-center'>
                                                <div className={`text-center p-3 d-flex flex-column align-items-center fs-6 ${index === selectedItemIndex ? 'btn-blue' : 'border-blue'}`}>
                                                    <div>{element.price}</div>
                                                    <div>DZD</div>
                                                </div>
                                                {index === selectedItemIndex ?
                                                    <div className='d-flex justify-content-center align-items-center text-white my-2 btn-blue rounded-circle' style={{ width: "30px", height: "30px" }}>
                                                        <Check size={15} />
                                                    </div> : <div className='my-2 rounded-circle p-1'>{element.date}</div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <button className='bg-white' onClick={handleNext} disabled={startIndex >= elements.length - 6}>
                                    <ChevronRight strokeWidth={1} size={40} />
                                </button>
                            </div>
                        </div>
                        <Row>
                            <Col className='d-none d-xl-block' xl="3">
                                <Filters />
                            </Col>
                            <Col xl="9">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span onClick={handleShow} className='d-xl-none btn btn-light border rounded-5 d-flex align-items-center'><SlidersHorizontal className='me-1' strokeWidth={1} size={15} />
                                        Filters
                                    </span>

                                </div>
                                <FlexDisplay />

                            </Col>
                        </Row>
                    </div>

                </section >
            </div>
            
        </>

    );

}

export default Search;