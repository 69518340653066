import Home from './Home'
import Details from './Details'
import Search from './Search'


function Main(props) {

    const composite = {

        home: <Home />,
        search: <Search />,
        details: <Details />,
    };

    return (
        <>            {eval("composite." + props.component)}
        </>

    );

}
export default Main;