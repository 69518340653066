
function Service1(){
    return(
       
            <div className="Service1">
                <div className="ContactH1">
                    <div className="ContactH11">
                        <div className="ContactH111">
                            <span className="spanc">What We</span>
                            <h2 className="h3c" style={{fontWeight:'400'}}>Offer For You</h2>
                        </div>

                    </div>

                </div>
                
          
            </div>
    );
}

export default Service1;