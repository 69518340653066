import { useParams } from "react-router-dom";
import details from '../../data/hotel-information.json'
import { Dot, Star, Check, LayoutGrid, ChevronDown } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import Meteo from '../../components/meteo'
import Rooms from './Rooms'
import Reviews from '../../components/Reviews'
import ImagesCarousel from '../../components/ImagesCarousel'
import { useState } from 'react';
import WriteReview from '../../components/WriteReview'
import TopBanner from '../../components/TopBanner'
import FilterMap from '../../components/FilterMap'
import { useTranslation } from 'react-i18next'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

function Details() {
    
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const { id } = useParams();
    let hotel = details.hotels.filter(objet => objet.id === parseInt(id))[0];

    const [showAllAmenties, setShowAllAmenties] = useState(false);
    let amenitiesArray = Object.entries(hotel.amenities || {});

    const amenitiesToShow = showAllAmenties ? amenitiesArray : amenitiesArray.slice(0, 5);

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
                            <SubHeaderPlatform />
                            <HeaderDetails />
                            <section>
                        <TopBanner data={hotel.name} cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

                        <div className='container'>

                            <ImagesCarousel showModal={showModal} closeModal={closeModal} item={hotel} />

                            <div className='my-4 d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='d-flex align-items-center'>
                                        {Array.from({ length: hotel.category }).map((_, starIndex) => (
                                            <div index={starIndex} className='me-1'><Star color='#F8D448' fill='#F8D448' size={13} /></div>
                                        ))}
                                    </div>
                                    <div className='my-2'>
                                        <span className='py-1 px-2 border-primary-subtle rounded fw-bold' style={{ color: "#1a536d", border: "1px solid #1a536d" }}>{hotel.trip_advisor_rating} / 5</span>
                                        <span className='fw-bold text-dark mx-3'>Excellent</span>
                                        <span>(3 {t('reviews')})</span>
                                        <span><Dot /></span>
                                        <span>{hotel.city.toUpperCase()}</span>
                                    </div>
                                </div>
                                <button className='btn-blue p-3 rounded-5 fw-bold' onClick={() => scrollToSection('rooms')}>{t('reserve')}</button>
                            </div>

                            <div role="button" className='position-relative' onClick={openModal}>
                                <Row className='rounded-4 justify-content-between'>
                                    <Col lg="7" className='px-1'>
                                        <img alt="Illustration d'un hôtel 1" width='100%' height="100%" style={{ maxHeight: "450px", objectFit: "cover" }} src={hotel.images[0]} />
                                    </Col>
                                    <Col lg="5" className='d-none d-lg-block'>
                                        <Row style={{ rowGap: '10px' }}>
                                            <Col md="6" className="px-1">
                                                <img width="100%" height="100%" alt={`Ilustration 2 de l'activité`} src={hotel.images[1]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                            </Col>
                                            <Col md="6" className="px-1">
                                                <img width="100%" height="100%" alt={`Ilustration 2 de l'activité`} src={hotel.images[2]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                            </Col>
                                            <Col md="6" className="px-1">
                                                <img width="100%" height="100%" alt={`Ilustration 3 de l'activité`} src={hotel.images[3]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                            </Col>
                                            <Col md="6" className="px-1">
                                                <img width="100%" height="100%" alt={`Ilustration 4 de l'activité`} src={hotel.images[4]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div role='button' className='d-flex align-items-center position-absolute text-white rounded-5 py-3 px-3 border border-white' style={{ bottom: "30px", right: "30px", background: "#00000036" }}><LayoutGrid strokeWidth={1} size={20} /> <span className='mx-1'>All {hotel.images.length} photos</span></div>
                            </div>
                        </div>

                        <div className='container'>
                            <Row>
                                <Col lg="7" xl="8">

                                    <div className='my-2'>
                                        <h2 className='text-dark fs-3'>{t('about_this_hotel')}</h2>
                                        <div className='text-dark my-3' style={{ fontSize: "16px" }}>
                                            <p>{hotel.descriptionss.dining}</p>
                                            <p>{hotel.descriptionss.national_ratings}</p>
                                            <p>{hotel.descriptionss.business_amenities}</p>
                                            <p>{hotel.descriptionss.rooms}</p>
                                            <p>{hotel.descriptionss.location}</p>
                                        </div>
                                    </div>

                                    <hr className='my-5 border-secondary' />

                                    <div className='my-2'>
                                        <h2 className='text-dark fs-3'>{t('facilities')}</h2>
                                        <div className='text-dark my-3' style={{ fontSize: "16px" }}>
                                            {amenitiesToShow.map(([index, amenity]) => (
                                                <div key={index} xl="2" className='my-2 d-flex align-items-center'>
                                                    <div className='bg-success-subtle text-success rounded-circle p-1'>
                                                        <Check size={20} strokeWidth={1} />
                                                    </div>
                                                    <span className='mx-3'>{amenity}</span>
                                                </div>
                                            ))}
                                            {!showAllAmenties && (
                                                <div role="button" className='fs-6 fw-medium m-4 text-blue' onClick={() => setShowAllAmenties(true)}>
                                                    <span>{t('view_more')}</span> <ChevronDown />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <hr className='my-5 border-secondary' />

                                    <div className='my-2'>
                                        <h2 className='text-dark fs-3'>{t('rules')}</h2>
                                        <div className='text-dark my-3' style={{ fontSize: "16px" }}>
                                            <p dangerouslySetInnerHTML={{ __html: hotel.checkin_instructions }}></p>
                                        </div>
                                    </div>

                                    <hr className='my-5 border-secondary' />

                                </Col>

                                <Col className='d-none d-lg-block' lg="5" xl="4">
                                    <div className='p-4 my-4 rounded-4 border' style={{ overflow: "hidden" }}>
                                        <img alt={`Illustration de l'hôtel - ${hotel.name}`} src={hotel.images[8]} width="100%" height="100%" />
                                    </div>

                                    <div className='position-relative'>
                                        <FilterMap/>
                                    </div>

                                    <div className='my-4 rounded-5 meteo-app shadow-sm border'>
                                        <Meteo lon={hotel.longitude} lat={hotel.latitude} />
                                    </div>
                                </Col>
                            </Row>

                            <Row className='my-2'>
                                <Col xl="8" id='rooms'>
                                    <h2 className='text-dark fs-3'>{t('availability')}</h2>
                                    <Rooms />
                                </Col>
                                <Col xl="4">
                                    <h2 className='text-dark fs-3'>{t('reviews')}</h2>
                                    <Reviews />
                                </Col>
                            </Row>

                            <div className='my-2'>
                                <WriteReview/>
                            </div>
                        </div>

                            </section>
        </div>
        

    );

}

export default Details;
