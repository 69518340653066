import { Col, Row } from 'react-bootstrap'
import { RadioTile, RadioTileGroup } from 'rsuite';

const Choice = ({ text, onChange, selectedChoice }) => {

    return (

        <Row className='align-items-center'>
            <Col lg="6">
                <span className='fs-6 fw-medium text-dark'>{text}</span>
            </Col>
            <Col lg="6">
                <RadioTileGroup defaultValue="no">
                    <Row>
                        <Col xs="6" sm="4" md="3" lg="6" className='my-3'>
                            <RadioTile value="yes" onChange={onChange} className={`rounded-4 ${selectedChoice === "yes" ? 'border-dark' : 'border'}`} >
                                <div className='mt-2 fs-6'>Yes</div>
                            </RadioTile>
                        </Col>

                        <Col xs="6" sm="4" md="3" lg="6" className='my-3'>
                            <RadioTile value="no" onChange={onChange} className={`rounded-4 ${selectedChoice === "no" ? 'border-dark' : 'border'}`} >
                                <div className='mt-2 fs-6'>No</div>
                            </RadioTile>
                        </Col>

                    </Row>
                </RadioTileGroup>
            </Col>
        </Row>
    )
}

export default Choice