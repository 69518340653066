import { Panel } from 'rsuite'
import { Star } from 'lucide-react'
import FiltersData from '../../data/globaldata.json'

const Filters = () => {

    const type = [
        { name: "Flights" },
        { name: "Stays" },
        { name: "Visits" },
        { name: "Transfers" },
        { name: "Car Rental" },
        {name:  "Packages"}
    ]

    const status = [
        { name: "Pending confirmation" },
        { name: "Confirmed" },
        { name: "Canceled" },
        { name: "Not available" },
        { name: "Refund" },
        { name: "Unpaid" }
    ]

   

    const when = [
        { name: "check-in" },
        { name: "check-out" },
        { name: "creation date" },
        { name: "cancellation date" },
    ]


    return (
        <>
            


            <div className="filterd my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Reservation Type</span>} collapsible>
                    {type.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="filterd my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Category</span>} collapsible>
                    {FiltersData.category.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                                <input id={item.stars} type='checkbox' />
                                <div className='mx-3 fw-light'>
                                    {Array.from({ length: item.stars }).map((_, starIndex) => (
                                        <label htmlFor={item.stars} className="me-1 my-1" key={starIndex}>
                                            <Star color='#F8D448' fill='#F8D448' size={13} />
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>


            <div className="filterd my-3 rounded-4 p-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Status</span>} collapsible>
                    {status.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center my-1'><input type='checkbox' /><span className='mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="filterd my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">When</span>} collapsible>
                    {when.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

        </>
    )
}

export default Filters