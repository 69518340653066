import { expName, expEmail, expNumeric } from '../../global/constants'

export const contactInput = {
    "full_name": {
        "element": "input",
        "type": "text",
        "placeholder": "",
        "data-ctrl": "true",
        "value": "",
        "autocomplete": "off",
        "className": "rounded-3 border ps-3 w-100 input-large ",
        rules: {
            required:"Required",
            pattern: {
                value: expName,
                message: "Format invalid",
            },
        }
    },
    "phone": {
        "element": "input",
        "type": "text",
        "data-ctrl": "true",
        "className": "rounded-3 border ps-3 w-100 input-large",
        "value": "",
        rules: {
            required: "Required",
            pattern: {
                value: expNumeric,
                message:"Format invalid",
            },
        }
    },
    "email": {
        "element": "input",
        "type": "text",
        "data-ctrl": "true",
        "className": "rounded-3 border ps-3 w-100 input-large",
        "value": "",
        rules: {
            required:"Required",
            pattern: {
                value: expEmail,
                message: "Format invalid",
            },
        }
    },
    "message": {
        "element": "textarea",
        "type": "textarea",
        "placeholder": "",
        "rows": 5,
        "cols": 5,
        "className": "rounded-3 border ps-3 w-100   input-large-3",
        rules: {
            required:"Required",
        }
    },
    "btn_send": {
        "element": "button",
        "type": "button",
        "className": "submit-btn bi bi-search",
        "value":"Send message",
        "data-form": "#HotelForm",
        "data-hotel": "true",
        "data-action": "search"
    }
}  