import { Row, Col } from 'react-bootstrap';
import data from '../../data/transfersdata.json'
import { Luggage, Users} from 'lucide-react'
import Pagination from '../../components/Pagination';

const GridDisplay = () => {

    const renderSpecificItems = (items) => {
        return (
            <>
                <Row className='mt-3'>
                    {items.map((car, index) => (
                        <Col key={index} md="6" lg="4" className='my-3'>
                            <div className='p-3 bg-white rounded-4 border recommendations-item' style={{ overflow: "hidden" }}>
                                <div className="rounded-4" style={{ overflow: "hidden" }}>
                                    <img alt={`Ilustration du véhicule - ${car.name}`} className='recommendations-item-img' width="100%" height="100%" src={require(`../../${car.img}`)} />
                                </div>
                                <div className='mt-2'>
                                    <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{car.name}</div>

                                    <div className='d-flex align-items-center my-3'>
                                        <div className='d-flex flex-column align-items-center'>
                                            <div className='border p-3 rounded my-1'><Users strokeWidth={1} size={20} /></div>
                                            <div>{car.passengers}</div>
                                        </div>

                                        <div className='d-flex flex-column align-items-center mx-3'>
                                            <div className='border p-3 rounded my-1'><Luggage strokeWidth={1} size={20} /></div>
                                            <div>{car.bagage}</div>
                                        </div>

                                    </div>

                                    <hr className='border-secondary' />
                                    <div className='my-2'>From: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€150.00</span></div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </>
        );
    };

    return (
        <>
            <Pagination data={data.transfers} itemsPerPage={9} renderItems={renderSpecificItems} />
        </>
    )
}

export default GridDisplay