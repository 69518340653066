import { ChevronRight, Clock, Lock } from 'lucide-react'

const PersonnalDetails = () => {

    return (
        <div>
            <div className='fs-6 fw-bold'>
                <span className='text-secondary'>1. Deals</span>
                <ChevronRight size={15} className='mx-2' />
                <span className='text-decoration-underline'>2. Personal</span>
            </div>

            <div className="alert alert-danger text-center border-0 my-3">
                <Clock color="grey" size={15} className="mx2" /> We’ll hold your spot for 23:29 minutes.
            </div>

            <span className='fs-4'>Check your personal details</span>

            <div className="d-flex align-items-center my-2 text-success">
                <Lock size={15} />
                <span className="mx-2">Checkout is fast and secure</span>
            </div>

            <div className='border p-4 rounded-3 my-3 fs-6 d-flex justify-content-between align-items-center'>
                <div>
                    <div>Idh tours</div>
                    <div className='text-secondary'>exemple@gmail.com</div>
                    <div className='text-secondary'>(+213) 0549 55 98 91</div>
                </div>
                <span className='text-blue rounded-5 py-2 px-4 fw-bold'>Edit</span>
            </div>

            <div className="my-5">
                <div className="d-flex align-items-center">
                    <input id='discounts' type='checkbox' />
                    <label htmlFor='discounts' className="mx-2">Send me discounts and other offers by email</label>
                </div>
                <p className='mt-2' style={{ fontSize: "12px" }}>We'll send you occasional discounts and promotions related to your bookings. You can opt out of all non-essential emails at any time. See our <span className='text-blue'>Privacy Policy.</span></p>
            </div>


            <div>

            </div>

        </div>
    )
}

export default PersonnalDetails