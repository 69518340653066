import TopBanner from '../../../components/TopBanner';
import { Row, Col } from 'react-bootstrap'
import Recap from './Recap'
import Input from '../../../components/forms/Input';
import { useReducer } from 'react';
import supplierData from '../../../data/globaldata.json'

const create = {
    activity_name: '',
    activity_location: '',
    activity_type: ''
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_NAME':
            localStorage.setItem('activity_name', action.payload);
            return { ...state, activity_name: action.payload };
        case 'SET_LOCATION':
            localStorage.setItem('activity_location', action.payload);
            return { ...state, activity_location: action.payload };
        case 'SET_TYPE':
            localStorage.setItem('activity_type', action.payload);
            return { ...state, activity_type: action.payload };
        default:
            return state;
    }
};


const Create = () => {

    const [state, dispatch] = useReducer(reducer, create);

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    return (
        <section>
            <TopBanner data="Create" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>

                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/activity/informations'>
                            <fieldset>
                                <legend className='fs-2 text-dark fw-medium'>What type of activity will be available to travelers?</legend>
                                <p className='my-3 fs-6'>Give correct informations and specify what type of activity you have.</p>

                                <Row>
                                    <Col md="6" xl="6">
                                        <div className='my-3'>
                                            <Input value={state.activity_name} type="text" handleChange={handleChange('SET_NAME')} placeholder="Name of your activity" maxlength="200" />
                                        </div>
                                    </Col>
                                    <Col md="6" xl="6">
                                        <div className='my-3'>
                                            <Input value={state.activity_location} type="text" handleChange={handleChange('SET_LOCATION')} placeholder="Where will travelers do this activity?" maxlength="30" />
                                        </div>
                                    </Col>
                                </Row>

                                <div className='border rounded my-3'>
                                    {supplierData.tickets.map((choice, index) => (
                                        <div key={index}>
                                            <div className='d-flex align-items-start p-3'>
                                                <Input name="typeactivity" id={choice.value} value={choice.value} type="radio" handleChange={handleChange('SET_TYPE')} />
                                                <label htmlFor={choice.value} className='mx-2'>
                                                    <span className='fs-6 fw-bold text-dark'>{choice.title}</span>
                                                    <p>{choice.description}</p>
                                                </label>
                                            </div>
                                            <hr className='border-secondary' />
                                        </div>
                                    ))}
                                </div>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Create