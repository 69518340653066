import { useParams } from "react-router-dom"
import { Lock, CheckCircle2, MessageSquare } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import TopBanner from '../../components/TopBanner'
import Input from '../../components/forms/Input'
import React, { useState } from 'react'
import Cards from 'react-credit-cards-2'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

function Payment() {

    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;

        setState((prev) => ({ ...prev, [name]: value }));
    }

    const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
    }

    const { id } = useParams();


    const cards = [
        { label: "Visa", img: "https://t-ec.bstatic.com/static/img/payments/payment_icons_redesign/visa.svg" },
        { label: "MasterCard", img: "https://t-ec.bstatic.com/static/img/payments/payment_icons_redesign/mc.svg" },
        { label: "PayPal", img: "https://t-ec.bstatic.com/static/img/payments/payment_icons_redesign/paypal-desktop_variant.svg" }
    ]

    return (

        <div>
                <SubHeaderPlatform />
                <HeaderDetails />
                <section >
            <TopBanner data="Payment" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container py-5'>

                <Row className="justify-content-between">
                    <Col lg="7" className='h-100'>

                            <fieldset>
                                <legend className='text-dark'>Payment details</legend>
                                <span>Accepted methods :</span>
                                <span>
                                    {cards.map((card, index) => (
                                        <img key={index} className="mx-3" src={card.img} alt={`Card payment ${card.label}`} />
                                    ))}
                                </span>
                                <Row>
                                    <Col xs="12" className='my-3'>
                                        <label className="fs-5 fw-medium">Card holder name *</label>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={state.name}
                                            handleChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            placeholder="Full name"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <Row>
                                            <Col md="12" className='my-3'>
                                                <label className="fs-5 fw-medium">Credit Card Number *</label>
                                                <Input
                                                    type="number"
                                                    name="number"
                                                    value={state.number}
                                                    handleChange={handleInputChange}
                                                    onFocus={handleInputFocus}
                                                    placeholder="0000 0000 0000 0000"
                                                />
                                            </Col>

                                            <Col md="6" className='my-3'>
                                                <label className="fs-5 fw-medium">CVC *</label>
                                                <Input
                                                    type="number"
                                                    name="cvc"
                                                    value={state.cvc}
                                                    handleChange={handleInputChange}
                                                    onFocus={handleInputFocus}
                                                    placeholder="CVC/CVV" />
                                            </Col>
                                            <Col md="6" className='my-3'>
                                                <label className="fs-5 fw-medium">Expiry date *</label>
                                                <Input
                                                    type="number"
                                                    name="expiry"
                                                    value={state.expiry}
                                                    handleChange={handleInputChange}
                                                    onFocus={handleInputFocus}
                                                    placeholder="MM/YY" />
                                            </Col>

                                        </Row>

                                    </Col>
                                    <Col md="6" className="my-3">
                                        <Cards
                                            number={state.number}
                                            expiry={state.expiry}
                                            cvc={state.cvc}
                                            name={state.name}
                                            focused={state.focus}
                                        />
                                    </Col>
                                </Row>

                            </fieldset>
                    </Col>
                    <Col lg="4" xl="4" className='my-2 d-lg-block d-none'>
                        <>
                            <div className="border p-3 rounded-3 d-flex flex-column">
                                <div className="d-flex justify-content-between text-dark">
                                    <div className="fs-5">Total (1 item):</div>
                                    <div>
                                        <div className="fs-5 text-end">€ 56</div>
                                        <p className="text-success">All taxes and fees included</p>
                                    </div>
                                </div>
                                <a href={`/booking/voucher/${id}`} className="btn-blue text-white p-2 rounded-5 my-4 fw-bold">Confirm booking</a>

                                <div className="d-flex">
                                    <CheckCircle2 color="green" />
                                    <div className="mx-2">
                                        <span className="fw-bold">Free concelation</span>
                                        <p className="text-secondary">Cancel up to 24 hours in advance for a full refund</p>
                                    </div>
                                </div>
                            </div>

                            <div className="border mt-3 p-3 rounded-3 d-flex flex-column text-dark">

                                <div className="fs-6 fw-medium">Why book with us?</div>

                                <div>
                                    <div className="d-flex align-items-center my-3">
                                        <Lock color="#1a536d" size={20} />
                                        <span className="mx-2">Secure payment</span>
                                    </div>
                                    <div className="d-flex align-items-center my-3">
                                        <CheckCircle2 color="#1a536d" size={20} />
                                        <span className="mx-2">No hidden costs</span>
                                    </div>
                                    <div className="d-flex align-items-center my-3">
                                        <MessageSquare color="#1a536d" size={20} />
                                        <span className="mx-2">24/7 customer support worldwide</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Col>
                </Row>
            </div>
                </section>
        </div>
        
    )
}

export default Payment