import React, { useState } from 'react';
import { Row, Col, Offcanvas } from 'react-bootstrap';
import Filters from './Filters'
import GridDisplay from './GridDisplay'
import FlexDisplay from './FlexDisplay'
import { SlidersHorizontal } from 'lucide-react'
import { LayoutGrid, StretchHorizontal } from 'lucide-react'
import Availability from './forms/Availability'
import ActivityItem from '../../data/activitiesdata.json'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

function Search() {

    const [ActiveDisplay, setActiveDisplay] = useState('flex');

    const handleClick = (bouton) => {
        setActiveDisplay(bouton);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
        <div>
                            <SubHeaderPlatform />
                            <HeaderDetails />
                            <section className='bg-body-tertiary'>
                                <div className="pt-5 backgrounded-container">
                                    <div className="mt-5 pt-5 pb-3">
                                        <div className='my-5'>
                                            <Availability />
                                        </div>
                                    </div>
                                </div>

                                <div className="container-xl py-5">
                                    <Offcanvas className="w-100" show={show} onHide={handleClose}>
                                        <Offcanvas.Header closeButton>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body>
                                            <Filters />
                                        </Offcanvas.Body>
                                    </Offcanvas>
                                    <Row>
                                        <Col className='d-none d-xl-block' xl="3">
                                            <Filters />
                                        </Col>
                                        <Col xl="9">
                                            <div className='d-flex justify-content-between align-items-center bg-white border p-3 rounded-4'>
                                                <span onClick={handleShow} className='d-xl-none btn btn-light border rounded-5 d-flex align-items-center'><SlidersHorizontal className='me-1' strokeWidth={1} size={15} />
                                                    Filters
                                                </span>

                                                <span style={{ fontSize: "16px" }} className='fw-light d-none d-lg-block fs-6 text-blue'>{ActivityItem.activities.length} activities found</span>

                                                <div className='d-lg-inline d-none'>
                                                    <span title="List view"><StretchHorizontal onClick={() => handleClick('flex')} style={{ cursor: "pointer", color: ActiveDisplay === 'flex' ? '#1a536d' : '' }} size={20} strokeWidth={1} className="mx-1" /> </span>
                                                    <span title="Grid view"><LayoutGrid onClick={() => handleClick('grid')} style={{ cursor: "pointer", color: ActiveDisplay === 'grid' ? '#1a536d' : '' }} size={20} strokeWidth={1} className="mx-1" /></span>
                                                </div>
                                            </div>
                                            <div>
                                                {ActiveDisplay === 'flex' && <FlexDisplay />}
                                                {ActiveDisplay === 'grid' && <GridDisplay />}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </section>
        </div>
            
        </>

    );

}

export default Search;