import { MinusCircle, PlusCircle } from 'lucide-react'
import Recap from './Recap'
import { Row, Col } from 'react-bootstrap'
import { useReducer } from 'react';
import TopBanner from '../../../components/TopBanner';
import Textarea from '../../../components/forms/Textarea';
import Highlights from '../../../components/forms/Highlights';

const informations = {
    transportation_travelers: 1,
    transportation_seats: 0,
    transportation_duration: "01h hour or less",
    transportation_intro: '',
    transportation_description: '',
    transportation_highlights: ['', '', '', ''],
};

const reducer = (state, action) => {

    const durations = ['Less than 01 hour', '01h - 02 hours', '02 - 03 hours', '03 - 04 hours', '04 - 05 hours'];
    const currentIndex = durations.indexOf(state.transportation_duration);

    switch (action.type) {

        case 'INCREMENT_TRAVELERS':
            const incrementedTravelers = state.transportation_travelers + action.payload;
            localStorage.setItem('transportation_travelers', incrementedTravelers);
            return { ...state, transportation_travelers: incrementedTravelers };

        case 'DECREMENT_TRAVELERS':
            const decrementTravelers = state.transportation_travelers - action.payload;
            localStorage.setItem('transportation_travelers', decrementTravelers);
            return { ...state, transportation_travelers: decrementTravelers };

        case 'INCREMENT_SEATS':
            const incrementedSeats = state.transportation_seats + action.payload;
            localStorage.setItem('transportation_seats', incrementedSeats);
            return { ...state, transportation_seats: incrementedSeats };

        case 'DECREMENT_SEATS':
            const decrementSeats = state.transportation_seats - action.payload;
            localStorage.setItem('transportation_seats', decrementSeats);
            return { ...state, transportation_seats: decrementSeats };

        case 'INCREMENT_DURATION':
            const nextIndex = (currentIndex + 1) % durations.length;
            const nextDuration = durations[nextIndex];
            localStorage.setItem('transportation_duration', nextDuration);
            return { ...state, transportation_duration: nextDuration };

        case 'DECREMENT_DURATION':
            const prevIndex = (currentIndex - 1 + durations.length) % durations.length;
            const prevDuration = durations[prevIndex];

            localStorage.setItem('transportation_duration', prevDuration);
            return { ...state, transportation_duration: prevDuration };

        case 'SET_INTRO':
            localStorage.setItem('transportation_intro', action.payload);
            return { ...state, transportation_intro: action.payload };

        case 'SET_DESCRIPTION':
            localStorage.setItem('transportation_description', action.payload);
            return { ...state, transportation_description: action.payload };

        case 'SET_HIGHLIGHT':
            const updatedTransHighlights = state.transportation_highlights.map((highlight, index) => {
                return index === action.payload.index ? action.payload.value : highlight;
            });
            localStorage.setItem('transportation_highlights', JSON.stringify(updatedTransHighlights));
            return { ...state, transportation_highlights: updatedTransHighlights };

        default:
            return state;
    }
};

const Informations = () => {

    const [state, dispatch] = useReducer(reducer, informations);

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    const handleHighlightsChange = (type, index) => (e) => {
        dispatch({ type, payload: { index, value: e.target.value } });
    };

    const handleIncrement = (type) => () => {
        dispatch({ type, payload: 1 });
    };

    const handleDecrement = (type) => () => {
        dispatch({ type, payload: 1 });
    };

    const handleIncrementDuration = () => {
        dispatch({ type: 'INCREMENT_DURATION' });
    };

    const handleDecrementDuration = () => {
        dispatch({ type: 'DECREMENT_DURATION' });
    };


    const informationsdata = [
        { label: 'Travelers', value: state.transportation_travelers, min: 2, max: 49, increment: 'INCREMENT_TRAVELERS', decrement: 'DECREMENT_TRAVELERS' },
        { label: 'Seats', value: state.transportation_seats, min: 1, max: 49, increment: 'INCREMENT_SEATS', decrement: 'DECREMENT_SEATS' },
    ]

    return (
        <section>
            <TopBanner data="Informations" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/transportation/attributes'>
                            <fieldset>
                                <legend className='text-dark fs-2 fw-medium'>Main informations about your transportation</legend>
                                <p className='fs-6 my-3'>Specify the number of passengers your transport can accommodate, the number of seats it has, the duration and describe it so that your ad stands out from the others</p>

                                <div className='my-3'>
                                    {informationsdata.map((item, index) => (
                                        <div className="d-flex justify-content-between py-4 border-bottom fs-6" key={index}>

                                            <div className="text-dark fs-5">{item.label}</div>

                                            <div className="d-flex align-items-center">
                                                <button type='button' className="mx-3 bg-white" onClick={handleDecrement(item.decrement)} disabled={item.value < item.min}><MinusCircle color="#1a536d" strokeWidth={1} size={40} /></button>

                                                <input className='border-0 text-center fs-6' value={item.value} type="number" readOnly style={{ width: "100px" }} />

                                                <button type='button' className="mx-3 bg-white" onClick={handleIncrement(item.increment)} disabled={item.value > item.max}><PlusCircle size={40} color="#1a536d" strokeWidth={1} /></button>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className='my-3'>
                                    <div className="d-flex justify-content-between py-4 border-bottom fs-6">

                                        <div className="text-dark fs-5">Duration</div>

                                        <div className="d-flex align-items-center">
                                            <button type='button' className="mx-3 bg-white" onClick={handleDecrementDuration}><MinusCircle color="#1a536d" strokeWidth={1} size={40} /></button>

                                            <span className='border-0 text-center fs-6' style={{ width: "100px" }}>{state.transportation_duration}</span>


                                            <button type='button' className="mx-3 bg-white" onClick={handleIncrementDuration}><PlusCircle size={40} color="#1a536d" strokeWidth={1} /></button>
                                        </div>
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <Textarea rows={4} placeholder='Create your introduction, this will be the first thing customers read after the title, and will inspire them to continue.' value={state.transportation_intro} handleChange={handleChange('SET_INTRO')} maxlength="220" />
                                    <div>{state.transportation_intro.length === 220 ? <span className='text-danger fs-6'>Your introduction must not exceed 220 characters</span> : ''}</div>
                                    <div>{state.transportation_intro.length} / 220</div>
                                </div>

                                <div className='my-3'>
                                    <Textarea rows={10} placeholder='Create your description and provide all the details about your transportation' value={state.transportation_description} handleChange={handleChange('SET_DESCRIPTION')} />
                                </div>

                                <div className='my-3'>
                                    <Highlights state={state.transportation_highlights} handleChange={handleHighlightsChange} />
                                </div>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Informations