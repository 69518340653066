import { AirVent, Baby, Cast, LocateFixed, Luggage, Wifi } from 'lucide-react'
import Recap from './Recap'
import { Row, Col } from 'react-bootstrap'
import Textarea from '../../../components/forms/Textarea';
import Select from '../../../components/forms/Select';
import { useReducer } from 'react';
import { useState } from 'react';
import Checkbox from "../../../components/forms/Checkbox";
import { RadioTile, RadioTileGroup } from 'rsuite';
import TopBanner from '../../../components/TopBanner';
import supplierData from '../../../data/globaldata.json'

const attributes = {
    vehicle_facilities: [],
    vehicle_color: '',
    vehicle_transmission: '',
    vehicle_addfeatures: ''
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_VEHICULE_FACILITY':
            const updatedFacilities = state.vehicle_facilities.includes(action.payload)
                ? state.vehicle_facilities.filter(facility => facility !== action.payload)
                : [...state.vehicle_facilities, action.payload];
            localStorage.setItem('vehicle_facilities', JSON.stringify(updatedFacilities));
            return { ...state, vehicle_facilities: updatedFacilities };

        case 'SET_ACCOMMODATION_COLOR':
            localStorage.setItem('vehicle_color', action.payload);
            return { ...state, vehicle_color: action.payload };

        case 'SET_VEHICLE_TRANSMISSION':
            localStorage.setItem('vehicle_transmission', action.payload);
            return { ...state, vehicle_transmission: action.payload };

        case 'SET_VEHICLE_ADDFEATURES':
            localStorage.setItem('vehicle_addfeatures', action.payload);
            return { ...state, vehicle_addfeatures: action.payload };

        default:
            return state;
    }
};

const Attributes = () => {

    const [state, dispatch] = useReducer(reducer, attributes);

    const [Facilitiescheckboxes, setFacilitiescheckboxes] = useState([
        { id: 1, title: "Screen", isChecked: false, value: 'Screen', icon: <Cast strokeWidth={1.2} size={35} /> },
        { id: 2, title: "Conditioner", isChecked: false, value: 'Conditioner', icon: <AirVent strokeWidth={1.2} size={35} /> },
        { id: 3, title: "Gps", isChecked: false, value: 'Gps', icon: <LocateFixed strokeWidth={1.2} size={35} /> },
        { id: 4, title: "Baby seat", isChecked: false, value: 'baby seat', icon: <Baby strokeWidth={1.2} size={35} /> },
        { id: 5, title: "Wifi", isChecked: false, value: 'Wifi', icon: <Wifi strokeWidth={1.2} size={35} /> },
        { id: 6, title: "Storage", isChecked: false, value: 'Storage', icon: <Luggage strokeWidth={1.2} size={35} /> },
    ]);

    const toggleFacilitiesCheckbox = (id, value) => {
        dispatch({ type: 'TOGGLE_VEHICULE_FACILITY', payload: value });
        setFacilitiescheckboxes(prevState =>
            prevState.map(Facilitiescheckboxes =>
                Facilitiescheckboxes.id === id ? { ...Facilitiescheckboxes, isChecked: !Facilitiescheckboxes.isChecked } : Facilitiescheckboxes
            )
        );
    };

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    const handleRadioChange = (type) => (e) => {
        dispatch({ type, payload: e });
    };

    return (
        <section>
            <TopBanner data="Attributes" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/car/photos'>
                            <fieldset>
                                <legend className='fs-2 fw-medium text-dark'>Tell travelers what equipment does your car have?</legend>
                                <p className='fs-6 my-3'>You can select everything that your car contains in terms of services and equipment in order to better sell it to customers. </p>

                                <div className='my-3'>
                                    <Row>
                                        {Facilitiescheckboxes.map((checkbox, index) => (
                                            <Col key={index} lg="3" className='my-3'>
                                                <Checkbox
                                                    key={checkbox.id}
                                                    id={checkbox.id}
                                                    isChecked={checkbox.isChecked}
                                                    value={checkbox.value}
                                                    onChange={toggleFacilitiesCheckbox}
                                                    title={checkbox.title}
                                                    icon={checkbox.icon}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </div>

                                <div className='my-3'>
                                    <RadioTileGroup>
                                        <Row>
                                            {supplierData.colors.map((choice, index) => (
                                                <Col key={index} xs="1" className='my-2'>
                                                    <RadioTile value={choice.value} onChange={handleRadioChange('SET_ACCOMMODATION_COLOR')} className={`p-4 ${state.accommodation_choice === choice.value ? 'border-dark' : 'border'}`} style={{ background: choice.value }} >
                                                    </RadioTile>
                                                </Col>
                                            ))}
                                        </Row>
                                    </RadioTileGroup>
                                </div>

                                <div className='my-3'>
                                    <Textarea value={state.vehicle_addfeatures} handleChange={handleChange('SET_VEHICLE_ADDFEATURES')} rows={10} placeholder='Additional features' />
                                </div>

                                <Row className='align-items-center'>
                                    <Col md="6" className='my-2'>
                                        <span className='fs-6 fw-medium text-dark'>What is your car's transmission?</span>
                                    </Col>
                                    <Col md="6" className='my-2'>
                                        <Select value={state.vehicle_transmission} handleChange={handleChange('SET_VEHICLE_TRANSMISSION')} data={supplierData.transmission} />
                                    </Col>

                                </Row>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5 text-white'>Continue</button>
                            </fieldset>

                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>


            </div>
        </section>
    )
}

export default Attributes