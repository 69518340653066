import Wishlist from './Wishlist'
import BookHotel from './BookHotel'
import BookRental from './BookRental'
import BookFlight from './BookFlight'
import BookActivity from './BookActivity'
import BookTransportation from './BookTransportation'
import BookCar from './BookCar'
import BookPackage from './BookPackage'
import Cart from './Cart';
import Payment from './Payment'
import Voucher from './Voucher';

function Main(props) {

    const composite = {
        
        wishlist: <Wishlist />,
        book_hotel:<BookHotel/>,
        book_rental:<BookRental/>,
        book_flight:<BookFlight/>,
        book_activity:<BookActivity/>,
        book_package:<BookPackage/>,
        book_car:<BookCar/>,
        book_transportation:<BookTransportation/>,
        cart:<Cart/>,
        payment:<Payment/>,
        voucher:<Voucher/>
    };

    return (
        <>
            {eval("composite." + props.component)}
        </>
    );
}

export default Main;