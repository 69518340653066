import Availability from './forms/Availability'
import Hotels from '../../components/recommendations/Hotels'
import Articles from '../../components/Articles'
import { useTranslation } from 'react-i18next'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

function Home() {

    const { t } = useTranslation();

    return (
        <>
            <div>
                <SubHeaderPlatform />
                <HeaderDetails />
                <section className='bg-body-tertiary'>
                    <div className="pt-5 backgrounded-container">
                        <div className="mt-5 pt-5 pb-3">
                            <div className='my-5'>
                                <Availability />
                            </div>
                        </div>
                    </div>

                    <div className="container-xl py-5">
                        <>
                            <div className='text-dark fs-1 fw-medium text-center'>{t('our_best_sellers')}</div>
                            <p className="my-1 fs-6 text-center">{t('find_your_hotel')}</p>
                            <Hotels/>
                        </>
                        <Articles/>
                    </div>
                </section>
            </div>
            
        </>

    );

}

export default Home;