import { ArrowDown, ArrowUp } from 'lucide-react';
import { Col, Row } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { Progress } from 'rsuite';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {

    const { t } = useTranslation();

    var options = {
        series: [{
            name: 'Earnings',
            data: [31, 40, 28, 51, 42, 109, 100]
          }, {
            name: 'Sales',
            data: [11, 32, 45, 32, 34, 52, 41]
          }, {
            name: 'Products',
            data: [8, 45, 3, 2, 19, 52, 56]
          }],
        chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], 
          opacity: 0.5
        },
      },
      xaxis: {
        categories: ['Sat', 'Sun', 'Mon', 'Thu','Wed','Thu','Fri'],
      }
      };

    return (
    <>
        <Row className='my-3'>
            <Col md="6" lg="4" className='my-2'>
                <div className='bg-blue text-white border rounded p-3'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <div className='fs-6 fw-bold'>{t('welcome_back')} Asmane!</div>
                            <p className='my-2'>{t('dashboard_text')}</p>
                        </div>
                        <div>
                            <img width="120" height="120" src="https://modernize-angular-main.netlify.app/assets/images/backgrounds/welcome-bg2.png" alt='' />
                        </div>
                    </div>
                </div>
            </Col>
            <Col md="6" lg="2" className='my-2'>
            <div className='bg-white border rounded p-3 h-100'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <div className='fs-6'>{t('products')}</div>
                            <div className='fs-5 fw-bold text-dark my-2'>6</div>
                            <div>{t('available_now')}</div>
                        </div>
                        <div>
                            <img width={60} height={60} src="https://gotrip-reactjs.ibthemespro.com/img/dashboard/icons/4.svg" alt='' />
                        </div>
                    </div>
                </div>
            </Col>
            <Col md="6" lg="3" className='my-2'>
                <div className='bg-white border rounded p-3 h-100'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <div className='fs-6'>{t('earnings')}</div>
                            <div className='fs-5 fw-bold text-dark my-2'>$1200.00</div>
                            <div><span className='text-green'><ArrowUp/> 25%</span> {t('last_month')}</div>
                        </div>
                        <div>
                            <img width={60} height={60} src="https://gotrip-reactjs.ibthemespro.com/img/dashboard/icons/2.svg" alt='' />
                        </div>
                    </div>
                </div>
            </Col>
            <Col md="6" lg="3" className='my-2'>
                <div className='bg-white border rounded p-3 h-100'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <div className='fs-6'>{t('sales')}</div>
                            <div className='fs-5 fw-bold text-dark my-2'>17</div>
                            <div><span className='text-danger'><ArrowDown/> 5%</span> {t('last_month')}</div>
                        </div>
                        <div>
                            <img width={60} height={60} src="https://gotrip-reactjs.ibthemespro.com/img/dashboard/icons/3.svg" alt='' />
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col xl="8" className='my-2'>
                <div className='bg-white border rounded h-100 d-flex flex-column justify-content-between' style={{overflow:'hidden'}}>
                    <div className='p-3'>
                        <span className='fs-5 fw-medium my-1 text-dark'>{t('overview')}</span>
                        <Chart options={options} series={options.series} type="line" height={330} />
                    </div>
                    <Row className='justify-content-between text-center bg-dark text-white'>
                            <Col xl="4" className='border-end p-3'>
                                <div>1200.00</div>
                                <div>{t('earnings')}</div>
                            </Col>
                            <Col xl="4" className='border-end p-3'>
                            <div>6</div>
                                <div>{t('products')}</div>
                            </Col>
                            <Col xl="4" className='p-3'>
                                <div>17</div>
                                <div>{t('sales')}</div>
                            </Col>
                    </Row>
                </div>
            </Col>
            <Col xl="4" className='my-2'>
                <div className='bg-orange rounded p-3'>
                    <div className='text-dark fs-6'><strong>{t('best_selling_product')}</strong><br/>{t('overview')} 2023</div>
                    <img width="100%" height="100%" src='https://modernize-angular-main.netlify.app/assets/images/backgrounds/piggy.png' alt='figure' />
                    <div className='bg-white p-3 mt-3 rounded'>
                        <>
                            <div className='fs-6 fw-bold text-dark'>Dubai: Zip Line across the Marina</div> 
                            <div className='fs-6 my-1'>$23,568</div>
                            <Progress.Line percent={55} strokeColor="#1a536d" />
                        </>
                    </div>
                </div>
            </Col>
        </Row>
    </>
  )
}

export default Dashboard