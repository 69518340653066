import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

function TopDestinations() {

    const { t } = useTranslation();

    const destinations = [
        { title: "Instanbul", image: "https://cf.bstatic.com/xdata/images/city/540x270/977227.jpg?k=7cf547df1d40f65019ad3bb9cc72a9bfd9763dc86b933cd51b50dfcb41dac8f2&o=", services: [{ hotels: 14, flights: 22, activities: 18, cars: 4 }],xl:6 },
        { title: "Shangai", image: "https://www.qatarairways.com/content/dam/images/renditions/square/destinations/china/shanghai/s-shanghai-city.jpg/jcr:content/renditions/rendition.360.360.jpg", services: [{ hotels: 14, flights: 22, activities: 18, cars: 4 }],xl:3 },
        { title: "Bali", image: "https://www.qatarairways.com/content/dam/images/renditions/square/destinations/indonesia-/bali/s-bali.jpg/jcr:content/renditions/rendition.360.360.jpg", services: [{ hotels: 14, flights: 22, activities: 18, cars: 4 }],xl:3 },
        { title: "Dubaï", image: "https://www.qatarairways.com/content/dam/images/renditions/square/destinations/united-arab-emirates/dubai/s-dubai-burj-khalifa.jpg/jcr:content/renditions/rendition.360.360.jpg", services: [{ hotels: 14, flights: 22, activities: 18, cars: 4 }],xl:3 },
        { title: "Barcelona", image: "https://cf.bstatic.com/xdata/images/city/354x266/977185.jpg?k=36b2aeec77f9df95f928540b3d0cafc0ce91b958cb3652e653b0be29d65867be&o=", services: [{ hotels: 14, flights: 22, activities: 18, cars: 4 }],xl:3  },
        { title: "Paris", image: "https://cf.bstatic.com/xdata/images/city/540x270/977242.jpg?k=72bfe23a6d7a496e0305b94bbb28ce197f3df2d6dd28986c3760a5f1c931664c&o=", services: [{ hotels: 14, flights: 22, activities: 18, cars: 4 }],xl:6  },
    ]

    return (

        <section className='container-md top-destination py-3'>
            <h1 className='text-dark text-center fw-medium fs-1'>{t('top_destination')}</h1>
            <Row className='justify-content-between mt-3'>
                {destinations.map((destination, index) => (
                    <Col key={index} sm="6" lg="4" xl={destination.xl} className='my-3'>
                        <div className='top-destination-item rounded-4 h-100' style={{ overflow: 'hidden', position: 'relative' }}>
                            <img className='top-destination-img' alt={`Ville de ${destination.title}`} style={{ filter: 'brightness(80%)' }} width="100%" height="100%" src={destination.image} />
                            <div className='text-white w-100 h-100 d-flex flex-column justify-content-center align-items-center' style={{ position: 'absolute', top: "0", left: "0" }}>
                                <div className='fs-2' style={{ fontWeight: "400" }}>{destination.title}</div>
                                <div className='px-5 text-center'>
                                    {destination.services.map((service, index) => (
                                        <div style={{ fontSize: "16px" }} key={index}>
                                            <span>{service.hotels} {t('hotels')}</span>
                                            <span>{service.flights} {t('flights')}</span>
                                            <span>{service.activities} {t('activities')}</span>
                                            <span>{service.cars} {t('cars')}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </section>
    );
}

export default TopDestinations;