import { Row, Col } from 'react-bootstrap';
import data from '../../data/cardata.json'
import { Star, Heart, MapPin } from 'lucide-react'
import Pagination from '../../components/Pagination';

const GridDisplay = () => {

    const renderSpecificItems = (items) => {
        return (
            <>
                <Row className='mt-3'>
                    {items.map((car, index) => (
                        <Col key={index} sm="6" lg="4" className="my-3">
                            <a href={`/cars/${car.id}`} className="text-dark text-decoration-none">
                                <div className='bg-white rounded-4 border recommendations-item position-relative' style={{ overflow: "hidden", minHeight: "398px", maxHeight: "398px" }}>
                                    <div style={{ overflow: "hidden" }}>
                                        <img alt={`Vue d'ensemble de l'hôtel - ${car.name}`} className='recommendations-item-img' width="100%" height="190" src={car.img} />
                                    </div>
                                    <div className="position-absolute" title='Add to whishlist' style={{ top: "20px", right: "15px" }}>
                                        <Heart fill='#00000036' size={30} strokeWidth={1.3} color="white" />
                                    </div>
                                    <div className='p-3'>
                                        <div className='d-flex align-items-center'><MapPin strokeWidth={1.5} size={15} /><span className='mx-1'>{car.registredcity}</span></div>
                                        <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{car.name}</div>

                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark'>5</span><span>(3 Reviews)</span></div>
                                        </div>
                                        <hr className='border-secondary' />
                                        <div className='mt-4'>
                                            From: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€150.00</span> /night
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </Col>
                    ))}
                </Row>
            </>
        );
    };

    return (
        <>
            <Pagination data={data.cars} itemsPerPage={9} renderItems={renderSpecificItems} />
        </>
    )
}

export default GridDisplay