import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Join5(){
    return(
        
        


        <div style={{}}>
        <div>
                    <h1 style={{fontWeight:'400',color:'#1e2031', textAlign:'center',fontSize:'62px',fontFamily:'"Cormorant Garamond"',paddingTop:'5vw',paddingBottom:'2vw'}}> How To Join Us! </h1>  
                    <p style={{textAlign:'center'}}>Initiate your membership process by completing our registration form. Upon submission, 
                        our dedicated team will promptly review your details granting you privileged access to
                        <br/>  our 
                        platform and its sophisticated suite of services tailored to elevate your business. 
                    </p>
        </div>  
        <Container  style={{paddingTop:'5vw', paddingBottom:'5vw'}}>
            <Row>
                <Col className="ContSp" sm={4} style={{display:'flex', flexDirection:'column',paddingLeft:'4vw'}}>
                    <h3 className="colh3"> 01  </h3>
                    <h3 className="colh3" style={{color:'#1a536d'}}>Account Creation</h3>
                    <p style={{marginBottom:'1rem',textAlign:'left',fontSize:'16px'}}>
                    Simply fill out the provided form, and our team will guide you through the next steps to initiate a seamless collaboration. 

                    </p>
                    
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:'4px'}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <p style={{marginBottom:'1rem',paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>Fill out the registration form</p>
                    </span>
                    <span >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:"4px"}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <br/> 
                        <p style={{marginBottom:'1rem', textAlign:'left', paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>Wait for an email with the required documents</p> 
                   
                    </span>
                    

                </Col>

                <Col className="ContSp" sm={4} style={{display:'flex', flexDirection:'column',paddingLeft:'4vw'}}>
                    <h3 className="colh3"> 02 </h3>
                    <h3 className="colh3" style={{color:'#1a536d'}}> Request Processing</h3>
                    <p style={{textAlign:'left',fontSize:'16px'}}>

                    We are dedicated to swiftly handling your request to promptly furnish you with the comprehensive<br/>
                     list of necessary documents.
                    </p>
                    
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:'4px'}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                            Submit the requested documents to us
                        </p>
                    </span>
                    <span >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:"4px"}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <br/> 
                        <p style={{textAlign:'left', paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                            Await the activation of your account
                        
                        </p> 
                   
                    </span>
                </Col>

                <Col  className="ContSp" sm={4} style={{display:'flex', flexDirection:'column',paddingLeft:'4vw'}}>
                    <h3 className="colh3"> 03 </h3>
                    <h3 className="colh3" style={{color:'#1a536d'}}>Activation & Support</h3>
                    <p style={{textAlign:'left',fontSize:'16px'}}>

                        Our IT team will ensure to assist you with the activation of your account, 
                        providing comprehensive support as needed.
                    </p>
                    
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:'4px'}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                        Our IT team will activate your agency account
                            
                        </p>
                    </span>
                    <span >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:"4px"}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <br/> 
                        <p style={{textAlign:'left', paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}> 
                        Our helpdesk will always be available for assistance

                        
                        </p> 
                   
                    </span>
                </Col>
                
            </Row>
        </Container>
        </div>
    );
}

export default Join5;