import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../slide/fgfgg.jpg";



function About5(){
    return(

            <div className="divsmp">
            <Container style={{paddingTop:'6vw',paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6" className="j1" style={{marginTop:'7vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976'}}>with HOTELIDH</p>

                            <h2 className="h2join">Start your Amazing   <br/>Adventure! </h2>
                        </div> 
                        <div style={{marginBottom:'32px'}}>
                            <p className="joinp1" style={{paddingLeft:'0vw'}}>
                            With our B2B booking platform, Hotelidh Designed for simplicity and excellence,
                            it connects you to a diverse array of travel products, empowering your business to curate 
                            unforgettable experiences. 
                            Your amazing adventure starts here, where innovation meets efficiency
                                
                                </p>
                        </div>
                        
                    </Col>
                    <Col xs="12"  xl="6">
                        <img src={SD} className="img-fluid" alt="hotelidh" />
                    </Col>
                </Row>
            </Container>
            </div>
        
    );
}

export default About5;