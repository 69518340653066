import { useParams } from "react-router-dom"
import details from '../../data/activitiesdata.json'
import { Clock, Ticket, Star, Users2, CheckCircle2, Heart, ThumbsUp, Gift } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import React, { useState } from 'react'
import ActivityAdditions from './ActivityAdditions'
import PersonnalDetails from "./PersonnalDetails"
import TopBanner from "../../components/TopBanner"
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

const Booking = () => {

    const { id } = useParams();
    let activity = details.activities.filter(objet => objet.id === parseInt(id))[0];

    const [step, setStep] = useState(1);

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const [PromoCodeShow, setPromoCodeShow] = useState(false);

    const showPromoCodeContent = () => {
        setPromoCodeShow(true);
    };

    return (
        <div>
                            <SubHeaderPlatform />
                            <HeaderDetails />
                            <section>
            <TopBanner data="Booking" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='my-5 container-xl px-lg-5 fw-bold text-dark'>
                <Row className="justify-content-between">
                    <Col md="7" xl="6" className='my-2'>
                        <div>

                            {step === 1 && (
                                <div>
                                    <ActivityAdditions handleNextStep={handleNextStep} />
                                    <div className="my-3">*IdhTours discount</div>
                                    <button className='btn-blue rounded-5 py-3 px-5 fw-bold my-3' onClick={handleNextStep}>Skip this step</button>
                                </div>
                            )}

                            {step === 2 && (
                                <div>
                                    <PersonnalDetails handleNextStep={handleNextStep} />
                                    <a href={`/booking/cart/${activity.id}/activity`} className='btn-blue rounded-5 py-3 px-5 fw-bold my-3 text-white' onClick={handleNextStep}>Next: Add to cart</a>
                                </div>
                            )}

                        </div>
                    </Col>
                    <Col md="5" xl="4" className='my-2'>
                        <div>
                            <span className="fs-5">Order summary</span>
                            <div className="my-3 border border-2 rounded-3">
                                <Row className="p-3">
                                    <Col lg="3">
                                        <div>
                                            <img className="rounded-3" width="100%" height="100%" alt={`Illustration activité - ${activity.name}`} src={activity.img} />
                                        </div>
                                    </Col>
                                    <Col lg="9">
                                        <div className="d-flex flex-column align-items-start">
                                            <div>{activity.name}</div>
                                            <div className="text-secondary" style={{ fontSize: "12px" }}>Provided by {activity.provider}</div>
                                            <div className="d-flex align-items-center">
                                                <span>{activity.note}</span>
                                                <span className="mx-2 d-flex">
                                                    {Array.from({ length: activity.note }).map((_, starIndex) => (
                                                        <div index={starIndex} className='me-1'><Star color='#F8D448' fill='#F8D448' size={13} /></div>
                                                    ))}
                                                </span>
                                                <span>({activity.reviews})</span>
                                            </div>
                                            <div className="bg-secondary-subtle p-1 rounded mt-2"><Heart color="#C72A3D" fill="#C72A3D" size={15} /> Top rated</div>
                                        </div>
                                    </Col>
                                </Row>

                                <hr className="border-secondary m-0" />

                                <div className="p-3">
                                    <div className="d-flex align-items-center my-2">
                                        <Ticket size={15} />
                                        <span className="mx-2">1-Way Ticket</span>
                                    </div>
                                    <div className="d-flex align-items-center my-2">
                                        <Clock size={15} />
                                        <span className="mx-2">Tuesday, January 23, 2024</span>
                                    </div>
                                    <div className="d-flex align-items-center my-2">
                                        <Users2 size={15} />
                                        <span className="mx-2">1 Adult (Age 16 - 99)</span>
                                    </div>
                                </div>

                                <hr className="border-secondary m-0" />

                                <div className="p-3">
                                    <div className="d-flex my-2">
                                        <CheckCircle2 size={15} />
                                        <div className="mx-2">
                                            <span>{activity.cancel}</span>
                                            <p className="text-secondary fw-medium">{activity.cancel_text}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex my-2">
                                        <ThumbsUp size={15} color="green" />
                                        <div className="mx-2">
                                            <span>Great value</span>
                                            <p className="text-secondary fw-medium">Customers rated this {activity.note}/5 for value for money</p>
                                        </div>
                                    </div>
                                </div>

                                <hr className="border-secondary m-0" />

                                {step > 1 ?

                                    <div>
                                        {!PromoCodeShow ? (
                                            <div role="button" onClick={showPromoCodeContent} className="px-3 p-2 d-flex align-items-center text-blue">
                                                <Gift size={15} /><span className="mx-2">Enter gift or promo code</span>
                                            </div>
                                        ) : (
                                            <div className="px-3 p-2">
                                                <label style={{ fontSize: "12px" }}>Enter gift code or promo code</label>
                                                <div className="d-flex align-items-center">
                                                    <input className="border input-blue p-2 rounded" />
                                                    <button className="btn-outline-blue p-2 px-3 rounded-5 mx-5">Redeem</button>
                                                </div>
                                            </div>
                                        )}
                                    </div> : ""

                                }

                                <hr className="border-secondary m-0" />
                                <div className="bg-secondary-subtle p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="fs-6">Subtotal</div>
                                        <div>
                                            <div className="fs-6 text-end">€ {activity.price}</div>
                                            <p className="text-success fw-medium">All taxes and fees included</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
                            </section>
        </div>
        
    );
}

export default Booking

