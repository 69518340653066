import { Table, Pagination } from 'rsuite';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Eye, Pencil, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
const { Column, HeaderCell, Cell } = Table;

const actions = () => {
  return (
    <div className='d-flex align-items-center'>
      <span role='button' className='mx-2 text-green'><Eye strokeWidth={1.5} size={20} /></span>
      <span role='button' className='mx-2 text-orange'><Pencil strokeWidth={1.5} size={20} /></span>
      <span role='button' className='mx-2 text-danger'><Trash2 strokeWidth={1.5} size={20} /></span>
    </div>
  )
}


const defaultData = [
  { type: "Accomodation", title: "Ibis Paris Maine Montparnasse 14éme", location: "Tizi-Ouzou", status: 'Confirmed', sales: 11, cost: '$150', date: "04/04/2022", action: actions() },
  { type: "Activity", title: "Dubai: Desert Safari, Quad Bike, Camel Ride & Al Khayma Camp", location: "Dubai", status: 'Pending', sales: 4, cost: '$66.81', date: "01/02/2024", action: actions() },
  { type: "Package", title: "Breathtaking Manali Tour From Mumbai", location: "Mumbai", status: 'Rejected', sales: 7, cost: '$11.81', date: "09/09/2023", action: actions() },
  { type: "Accomodation", title: "Ibis Paris Maine Montparnasse 14éme", location: "Tizi-Ouzou", status: 'Confirmed', sales: 11, cost: '$150', date: "04/04/2022", action: actions() },
  { type: "Activity", title: "Dubai: Desert Safari, Quad Bike, Camel Ride & Al Khayma Camp", location: "Dubai", status: 'Pending', sales: 4, cost: '$66.81', date: "01/02/2024", action: actions() },
  { type: "Package", title: "Breathtaking Manali Tour From Mumbai", location: "Mumbai", status: 'Rejected', sales: 7, cost: '$11.81', date: "09/09/2023", action: actions() },
  { type: "Accomodation", title: "Ibis Paris Maine Montparnasse 14éme", location: "Tizi-Ouzou", status: 'Confirmed', sales: 11, cost: '$150', date: "04/04/2022", action: actions() },
  { type: "Activity", title: "Dubai: Desert Safari, Quad Bike, Camel Ride & Al Khayma Camp", location: "Dubai", status: 'Pending', sales: 4, cost: '$66.81', date: "01/02/2024", action: actions() },
  { type: "Package", title: "Breathtaking Manali Tour From Mumbai", location: "Mumbai", status: 'Rejected', sales: 7, cost: '$11.81', date: "09/09/2023", action: actions() },
  { type: "Accomodation", title: "Ibis Paris Maine Montparnasse 14éme", location: "Tizi-Ouzou", status: 'Confirmed', sales: 11, cost: '$150', date: "04/04/2022", action: actions() },
  { type: "Activity", title: "Dubai: Desert Safari, Quad Bike, Camel Ride & Al Khayma Camp", location: "Dubai", status: 'Pending', sales: 4, cost: '$66.81', date: "01/02/2024", action: actions() },
  { type: "Package", title: "Breathtaking Manali Tour From Mumbai", location: "Mumbai", status: 'Rejected', sales: 7, cost: '$11.81', date: "09/09/2023", action: actions() },
  { type: "Accomodation", title: "Ibis Paris Maine Montparnasse 14éme", location: "Tizi-Ouzou", status: 'Confirmed', sales: 11, cost: '$150', date: "04/04/2022", action: actions() },
  { type: "Activity", title: "Dubai: Desert Safari, Quad Bike, Camel Ride & Al Khayma Camp", location: "Dubai", status: 'Pending', sales: 4, cost: '$66.81', date: "01/02/2024", action: actions() },
  { type: "Package", title: "Breathtaking Manali Tour From Mumbai", location: "Mumbai", status: 'Rejected', sales: 7, cost: '$11.81', date: "09/09/2023", action: actions() },
]

const ManageProduct = () => {

  const { t } = useTranslation();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  const data = defaultData.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  return (
    <>
      <div className='fs-3 fw-bold text-dark'>{t('your_product')}</div>
      <p className='my-1 fs-6'>{t('manage_text')}</p>
      <Row className='my-3'>
        <Col xl="12">
          <div className='bg-white rounded border p-3'>
            <Table height={420} data={data}>
              <Column width={150} align="center" fixed>
                <HeaderCell>{t('type')}</HeaderCell>
                <Cell dataKey="type" />
              </Column>

              <Column width={300}>
                <HeaderCell>{t('title')}</HeaderCell>
                <Cell dataKey="title" />
              </Column>

              <Column width={100}>
                <HeaderCell>{t('location')}</HeaderCell>
                <Cell dataKey="location" />
              </Column>
              <Column width={100}>
                <HeaderCell>{t('cost')}</HeaderCell>
                <Cell dataKey="cost" />
              </Column>
              <Column width={150}>
                <HeaderCell>{t('date')}</HeaderCell>
                <Cell dataKey="date" />
              </Column>

              <Column width={100}>
                <HeaderCell>{t('sales')}</HeaderCell>
                <Cell dataKey="sales" />
              </Column>

              <Column width={100}>
                <HeaderCell>{t('status')}</HeaderCell>
                <Cell dataKey="status" />
              </Column>
              <Column width={200} flexGrow={1}>
                <HeaderCell>{t('actions')}</HeaderCell>
                <Cell dataKey="action" />
              </Column>
            </Table>
            <div style={{ padding: 20 }}>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                size="lg"
                layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                limitOptions={[10, 30, 50]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ManageProduct