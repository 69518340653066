import { getTime } from 'date-fns';
import { dateFr } from '../../global/global'

const initialState = {
    date: dateFr(new Date()),
    maxRooms: 3,
    textSearch: '',
    dataPost: {
        "destination": "",
        "checkin": dateFr(new Date()),
        "checkout": dateFr(new Date()),
        "rooms": [
            {
                "adult": 1,
                "child": 0,
                "childAge": []
            }
        ],
        "id": "",
        "city_id": "",
        "country_id": "",
        "type": "",
        "nationality": "DZ",
        "roomsCount": 1,
        "adultCount": 1,
        "childCount": 0
    },
    availability: []

};
const hotelReducer = (state = initialState, action) => {

    switch (action.type) {
        case "data_post":
            return {
                ...state,
                dataPost: {
                    ...state?.dataPost,
                    ...action.payload,
                }

            };
        case "availability":
            return {
                ...state,
                availability: {
                    ...state?.availability,
                    ...action.payload,
                }

            };

        default:
            return state;
    }



};

export default hotelReducer;
