import mapimg from '../assets/map-view-2.png'
import { MapPin } from 'lucide-react'
import { useTranslation } from 'react-i18next';

const FilterMap = () => {

    const { t } = useTranslation();
    
    return (
        <>
            <img alt="Illusatrtion d'une carte" width="100%" height="100%" className="rounded-4" src={mapimg} />
            <div className='w-100 d-flex flex-column align-items-center' style={{ position: "absolute", bottom: '20px'}} >
                <MapPin color='#DC3545' size={30} strokeWidth={1.5} />
                <button className='border bg-white fw-medium fs-6 p-3 px-5 rounded-5 mt-4 text-blue'>{t('view_in_map')}</button>
            </div>
        </>
    )
}

export default FilterMap