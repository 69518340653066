import { TrainFront, Bus, CarTaxiFront } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import Recap from './Recap'
import Input from '../../../components/forms/Input';
import { useReducer } from 'react';
import { RadioTile, RadioTileGroup } from 'rsuite';
import TopBanner from '../../../components/TopBanner';

const create = {
    transportation_name: '',
    transportation_location: '',
    transportation_type: '',
};

const reducer = (state, action) => {
    switch (action.type) {

        case 'SET_LOCATION':
            localStorage.setItem('transportation_location', action.payload);
            return { ...state, transportation_location: action.payload };

        case 'SET_NAME':
            localStorage.setItem('transportation_name', action.payload);
            return { ...state, transportation_name: action.payload };

        case 'SET_TYPE':
            localStorage.setItem('transportation_type', action.payload);
            return { ...state, transportation_type: action.payload };

        default:
            return state;
    }
};


const Create = () => {

    const [state, dispatch] = useReducer(reducer, create);

    const handleRadioChange = (type) => (e) => {
        dispatch({ type, payload: e });
    };

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };


    const transportationtype = [
        { title: "Train", icon: <TrainFront strokeWidth={1.2} size={35} />, value: "Train" },
        { title: "Bus", icon: <Bus strokeWidth={1.2} size={35} />, value: "Bus" },
        { title: "Taxi", icon: <CarTaxiFront strokeWidth={1.2} size={35} />, value: "Taxi" },
    ]
    return (
        <section>
            <TopBanner data="Create" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/transportation/informations'>
                            <fieldset>
                                <legend className='text-dark fs-2 fw-medium'>What type of transportation will be available to travelers?</legend>
                                <p className='fs-6 my-3'>Give correct informations and specify what type of transportation you have.</p>
                                <div className='my-3'>
                                    <Input value={state.transportation_name} type="text" handleChange={handleChange('SET_NAME')} placeholder="Title of your transportation" maxlength="100" />
                                </div>

                                <div className='my-3'>
                                    <Input value={state.transportation_location} type="text" handleChange={handleChange('SET_LOCATION')} placeholder="Where is your transportation located?" maxlength="30" />
                                </div>

                                <div className='my-3'>
                                    <RadioTileGroup>
                                        <Row>
                                            {transportationtype.map((choice, index) => (
                                                <Col key={index} xs="12" className='my-2'>
                                                    <RadioTile value={choice.value} onChange={handleRadioChange('SET_TYPE')} className={`p-3 ${state.transportation_type === choice.value ? 'border-dark' : 'border'}`} >
                                                        <div className='text-dark'>
                                                            {choice.icon}
                                                            <div className='mt-2'>{choice.title}</div>

                                                        </div>
                                                    </RadioTile>
                                                </Col>
                                            ))}
                                        </Row>
                                    </RadioTileGroup>
                                </div>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Create