import { Row, Col } from 'react-bootstrap';
import data from '../../data/rentalsdata.json'
import { Star, MapPin, Heart } from 'lucide-react'
import Pagination from '../../components/Pagination';
import { useTranslation } from 'react-i18next';

const FlexDisplay = () => {

  const { t } = useTranslation();

  const renderSpecificItems = (items) => {
    return (
      <>
        <Row className='my-3 justify-content-center'>
          {items.map((rental, index) => (
            <Col key={index} xs="12" className="my-2">
              <a href={`/rentals/${rental.id}`} className="text-dark text-decoration-none">
                <div className='bg-white rounded-4 border recommendations-item' style={{ overflow: "hidden" }}>
                  <Row>
                    <Col md="4">
                      <div className='position-relative h-100' style={{ overflow: "hidden" }}>
                        <img alt={`Vue d'ensemble de l'hôtel - ${rental.name}`} className='recommendations-item-img' width="100%" height="100%" style={{ maxWidth: "100%" }} src={rental.img} />
                        <div className="position-absolute" title='Add to whishlist' style={{ top: "30px", right: "25px" }}>
                          <Heart fill='#00000036' size={30} strokeWidth={1.3} color="white" />
                        </div>
                      </div>
                    </Col>

                    <Col sm="7" md="5" className='py-2 d-flex align-items-center'>
                      <div className='p-3 pb-5 h-100 border-end'>
                        <div className='d-flex align-items-center'><MapPin strokeWidth={1} size={20} />
                          <span className='mx-1'>{rental.location}</span>
                        </div>

                        <div className='title-blue fw-bold my-2 fs-5'>{rental.name}</div>

                        <div className='d-flex align-items-center my-3'>
                          <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark fs-6'>{rental.note}</span><span>(3 {t('reviews')})</span></div>
                        </div>

                        <p className='my-3 fs-6'>{rental.introduction}</p>
                      </div>
                    </Col>

                    <Col sm="5" md="3" className='py-4'>
                      <div className='d-flex flex-column align-items-center justify-content-between h-100'>
                        <div className='py-1 px-2 border-primary-subtle rounded fw-bold' style={{ color: "#1a536d", border: "1px solid #1a536d" }}>{rental.note} / 5</div>
                        <>
                          <div className='mt-5'>
                          {t('from')}: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€{rental.price}</span> /{t('night')}

                          </div>
                          <button className='btn-blue my-3 py-3 px-4 rounded-5 fw-bold'>{t('view_details')}</button>
                        </>

                      </div>
                    </Col>
                  </Row>
                </div>
              </a>

            </Col>
          ))}
        </Row>
      </>
    );
  };


  return (
    <>
      <Pagination data={data.rentals} itemsPerPage={8} renderItems={renderSpecificItems} />
    </>
  )
}

export default FlexDisplay