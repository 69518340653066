import { Check, X } from 'lucide-react'
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import { Panel, PanelGroup } from 'rsuite';
import PublishCelebration from '../PublishCelebration';
import TopBanner from '../../../components/TopBanner';

const Review = () => {

    const [values, setValues] = useState({

        transportation_name: '',
        transportation_location: '',
        transportation_type: '',
        transportation_travelers: 1,
        transportation_seats: 1,
        transportation_duration: "",
        transportation_intro: '',
        transportation_description: '',
        transportation_departure: "",
        transportation_arriving: "",
        transportation_facilities: [],
        transportation_highlights: [],
        transportation_inclusions: '',
        transportation_exclusions: '',
        transportation_notallowed: [],
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedData = {
                transportation_name: localStorage.getItem('transportation_name') || 'Not assigned',
                transportation_location: localStorage.getItem('transportation_location') || 'Not assigned',
                transportation_type: localStorage.getItem('transportation_type') || '...',
                transportation_travelers: localStorage.getItem('transportation_travelers') || 1,
                transportation_seats: localStorage.getItem('transportation_seats') || 1,
                transportation_duration: localStorage.getItem('transportation_duration') || 'Not assigned',
                transportation_intro: localStorage.getItem('transportation_intro') || 'Not assigned',
                transportation_description: localStorage.getItem('transportation_description') || 'Not assigned',
                transportation_departure: localStorage.getItem('transportation_departure') || 'Not assigned',
                transportation_arriving: localStorage.getItem('transportation_arriving') || 'Not assigned',
                transportation_highlights: JSON.parse(localStorage.getItem('transportation_highlights')) || ['Not assigned'],
                transportation_facilities: JSON.parse(localStorage.getItem('transportation_facilities')) || [],
                transportation_inclusions: localStorage.getItem('transportation_inclusions') || 'Not assigned',
                transportation_exclusions: localStorage.getItem('transportation_exclusions') || 'Not assigned',
                transportation_notallowed: JSON.parse(localStorage.getItem('transportation_notallowed')) || ['Not assigned'],
            };

            setValues(savedData);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <section>
            <TopBanner data="Review" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <div>
                    <span className='fs-2 fw-bold text-dark'>Is this information correct?</span>
                    <p className='fs-5'>If everything looks good, save and publish your activity to set it live on IdhTours's website.</p>
                    <hr className='my-3 border-secondary' />
                    <PanelGroup accordion defaultActiveKey={1}>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Basic</div>} eventKey={1} id="panel1" defaultExpanded>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Title</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.transportation_name}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Location</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.transportation_location}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Type</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.transportation_type}</span>
                                </Col>
                            </Row>

                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Informations</div>} eventKey={2} id="panel2">

                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Travelers</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.transportation_travelers}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Seats</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.transportation_seats}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Duration</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.transportation_duration}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Introduction</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.transportation_intro}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Description</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.transportation_description}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Highlights</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <ul>
                                        {values.transportation_highlights.map((high, index) => (
                                            <li key={index}>{high}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Panel>

                        <Panel header={<div className='fs-5 fw-bold text-dark'>Attributes</div>} eventKey={3} id="panel3">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Departure</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.transportation_departure}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Arriving</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.transportation_arriving}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Facilities</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    {values.transportation_facilities.map((tag, index) => (
                                        <div key={index} className='my-1 text-success'><Check size={15} /> <span className='text-dark'>{tag}</span></div>
                                    ))}
                                </Col>
                            </Row>

                        </Panel>

                        <Panel header={<div className='fs-5 fw-bold text-dark'>Additional</div>} eventKey={4} id="panel4">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Inclusions</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    {values.transportation_inclusions.split('\n').map((item, index) => (
                                        <div key={index} className='my-1 text-success'><Check size={15} /> <span className='text-dark'>{item}</span></div>
                                    ))}
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Exclusions</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    {values.transportation_exclusions.split('\n').map((item, index) => (
                                        <div key={index} className='my-1 text-danger'><X size={15} /> <span className='text-dark'>{item}</span></div>
                                    ))}
                                </Col>
                            </Row>

                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Not allowed</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <ul>
                                        {values.transportation_notallowed.map((tag, index) => (
                                            <li key={index}>{tag}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Panel>

                        <Panel header={<div className='fs-5 fw-bold text-dark'>Photos</div>} eventKey={4} id="panel4">
                            Empty
                        </Panel>
                    </PanelGroup>

                </div>
                <PublishCelebration showModal={showModal} closeModal={closeModal} />
                <button onClick={openModal} className='btn-blue p-3 px-5 rounded-5 text-white my-5'>Publish</button>
            </div>
        </section>
    )
}

export default Review