import { Row, Col } from 'react-bootstrap';
import logo from '../assets/404.svg'
import { Link } from 'react-router-dom'
import TopBanner from '../components/TopBanner';

function ErrorPage() {

    return (
        <section>
            <TopBanner data="Error" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />
            <Row className='py-5'>
                <Col md="12">
                    <div className='d-flex justify-content-center align-items-center'>
                        <img style={{ maxWidth: '30%' }} src={logo} alt="logo du site" loading="lazy" />
                    </div>
                </Col>
                <Col md="12">
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <Link to='/'><button className='btn-blue p-4 rounded-5 text-decoration-none'>back_to_home</button></Link>
                    </div>
                </Col>
            </Row>
        </section>

    );
}

export default ErrorPage;