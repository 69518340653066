import { Row, Col } from 'react-bootstrap'
import CarItem from '../../data/cardata.json'
import { MapPin, Heart, Users, Luggage, Star, DoorClosed, Settings } from 'lucide-react'
import { useTranslation } from 'react-i18next';

const Cars = () => {

    const CarResults = CarItem.cars.slice(0, 6);
    const { t } = useTranslation();

    return (
        <>
            <Row className='my-3'>
                {CarResults.map((car, index) => (
                    <Col key={index} md="6" xl="4" className='my-3'>
                        <a href={`/cars/${car.id}`} className="text-dark">
                            <div className='bg-white rounded-4 border recommendations-item position-relative' style={{ overflow: "hidden" }}>
                                <div style={{ overflow: "hidden" }}>
                                    <img alt={`Représentation du véhicule - ${car.name}`} className='recommendations-item-img' width="100%" height="100%" src={car.img} />
                                </div>
                                <div className="position-absolute" title='Add to whishlist' style={{ top: "20px", right: "15px" }}>
                                    <Heart fill='#00000036' size={30} strokeWidth={1.3} color="white" />
                                </div>
                                <div className='p-3'>
                                    <div className='d-flex align-items-center'><MapPin strokeWidth={1.5} size={15} /><span className='mx-1'>{car.registredcity}</span></div>
                                    <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{car.name}</div>

                                    <div className='d-flex align-items-center my-3'>
                                        <div className='d-flex align-items-center me-3'>
                                            <div className='me-2'><Users strokeWidth={1} size={20} /></div>
                                            <div>{car.passengers}</div>
                                        </div>

                                        <div className='d-flex align-items-center mx-3'>
                                            <div className='me-2'><Luggage strokeWidth={1} size={20} /></div>
                                            <div>{car.bagage}</div>
                                        </div>

                                        <div className='d-flex align-items-center mx-3'>
                                            <div className='me-2'><DoorClosed strokeWidth={1} size={20} /></div>
                                            <div>{car.doors}</div>
                                        </div>

                                        <div className='d-flex align-items-center mx-3'>
                                            <div className='me-2'><Settings strokeWidth={1} size={20} /></div>
                                            <div>{car.transmission}</div>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark'>5</span><span>(3 {t('reviews')})</span></div>
                                    </div>

                                    <hr className='border-secondary' />
                                    <div className='my-2'>{t('from')}: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€150.00</span></div>
                                </div>
                            </div>
                        </a>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default Cars