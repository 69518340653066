import localStorage from "redux-persist/es/storage";
import { baseUrl } from '../global/constants'
export const loadState = () => {
    try {
        let serializedState = localStorage.getItem(baseUrl + ":state");

        if (serializedState === null) {
            throw new Error("session doesn't exist");
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return initializeState();
    }
};

export const saveState = (state) => {
    try {
        let serializedState = JSON.stringify(state);
        localStorage.setItem(baseUrl + ":state", serializedState);
    } catch (err) { }
};

export const initializeState = () => {
    return {
        //state object
    };
};
