import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import RoomOption from './RoomOption'
import ExtraCharge from './ExtraCharge';
import supplierData from '../../../data/globaldata.json'

const Detailskitchen = () => {

    const [kitchen, setKitchen] = useState(false);
    const [kitchenFees, setKitchenFees] = useState(false);
    const [fridgeExtra, setFridgeExtra] = useState(false);

    const handleKitchen = () => {
        setKitchen(!kitchen);
    };

    const handleFridgeExtra = () => {
        setFridgeExtra(!fridgeExtra);
    };

    const handlekitchenFees = () => {
        setKitchenFees(!kitchenFees);
    };

    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    return (
        <div className='fs-6'>
            <div className='d-flex align-items-center my-3'>
                <input id="kitchen" type="checkbox" value={kitchen} onChange={handleKitchen} />
                <label htmlFor='kitchen' className='mx-2'>Kitchen</label>
            </div>
            {kitchen ?
                <>
                    <RoomOption />
                    <Row className='align-items-center'>
                        <Col md="6" className='my-2'>
                            <span className='fs-6 fw-medium text-dark'>What kind of kitchen is it?</span>
                        </Col>
                        <Col md="6" className='my-2'>
                            <Select data={supplierData.kitchen} />
                        </Col>

                    </Row>
                </>
                : ''
            }
            <div className='d-flex align-items-center my-3'>
                <input id="kitchenfees" type="checkbox" value={kitchenFees} onChange={handlekitchenFees} />
                <label htmlFor='kitchenfees' className='mx-2'>Kitchen usage fee charged</label>
            </div>

            {kitchenFees ?
                <ExtraCharge />
                : ''
            }

            <div className='my-3'>

                <span className='fs-6 fw-medium text-blue'>Kitchen features</span>
                <div className='px-3'>

                    {supplierData.kitchen_facilities.map((check, index) => (
                        <div key={index} className='my-3'>
                            <>
                                <div className='d-flex align-items-center'>
                                    <input
                                        id={check.value}
                                        type="checkbox"
                                        value={check.value}
                                        checked={checkedItems[check.value] || false}
                                        onChange={() => handleCheckboxChange(check.value)}
                                    />
                                    <label htmlFor={check.value} className='mx-2'>
                                        {check.title}
                                    </label>
                                </div>
                                {checkedItems[check.value] && (
                                    <RoomOption />
                                )}
                            </>

                            {checkedItems[check.value] && (
                                <>
                                    {check.value === 'fridge' && (
                                        <>
                                            <Row className='align-items-center'>
                                                <Col md="6" className='my-2'>
                                                    <span className='fs-6 fw-medium text-dark'>What kind of fridge is it?</span>
                                                </Col>
                                                <Col md="6" className='my-2'>
                                                    <Select data={supplierData.fridge} />
                                                </Col>

                                                <Col md="12" className='my-2 ps-5'>
                                                    <div className='d-flex align-items-center'>
                                                        <input id='extracharges' type="checkbox" value={fridgeExtra} onChange={handleFridgeExtra} />
                                                        <label htmlFor='extracharges' className='mx-2'>
                                                            Extra charge for the refrigerator
                                                        </label>
                                                    </div>
                                                    {fridgeExtra ? <ExtraCharge /> : ''}
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </>
                            )}


                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Detailskitchen