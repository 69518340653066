import Carousel from 'react-bootstrap/Carousel';
import Video6 from './Video6';


function Home1(){
    return(
        <Carousel style={{marginBottom:'4vw'}}>
            <div style={{height:'40vw'}}>
        
                <Video6 />
                <Carousel.Caption style={{marginTop:'-35vw'}}>
                        <div className="">
                            
                            <h2 className="h3cc"> WELCOME BACK TO HOTELIDH </h2>
                            <span className="spancf" >The most comprehensive Booking Platform.</span>

                        </div>
                
                </Carousel.Caption>
            </div>
            
    
        </Carousel>
    );
}

export default Home1;