import { Row, Col } from 'react-bootstrap';
import Filters from './Filters'
import data from '../../data/blogdata.json'
import TopBanner from '../../components/TopBanner'
import Pagination from '../../components/Pagination';

const SearchType = () => {

    const renderSpecificItems = (items) => {

        return (
            <>
                <section>
                    <TopBanner data="Blog" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />
                    <div className='container my-5'>
                        <Row>
                            <Col lg="5" xl="4" className='d-none d-lg-block'>
                                <Filters />
                            </Col>
                            <Col lg="7" xl="8">
                                <Row>
                                    {items.map((article, index) => (
                                        <Col key={index} lg="6" className="position-relative my-3">
                                            <a href={`/blog/${article.id}`} className="text-dark text-decoration-none">
                                                <img className="rounded-4" width="100%" height="100%" alt="article illustration" src={article.img} style={{ maxHeight: "310px" }} />
                                                <div className="my-3">
                                                    <div className="fs-6">{article.date}</div>
                                                    <div className="fs-5 fw-medium title-blue">{article.name}</div>
                                                </div>
                                                <span className="position-absolute top-0 start-0 m-4 bg-white p-2 px-3 rounded-5">{article.type}</span>
                                            </a>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>
            </>
        );
    };

    return (
        <>
            <Pagination data={data.articles} itemsPerPage={9} renderItems={renderSpecificItems} />
        </>
    )
}

export default SearchType