import {  CarFront } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'

const Options = () => {
    return (
        <>
            <Row>
                <Col lg="12" className='my-3'>
                    <div className='border rounded p-3'>
                        <span className='fs-5'>Options to add</span>
                        <Row className='justify-content-between my-2'>
                            <Col lg="9" className='pe-5 py-3 d-flex'>
                                <input type='checkbox' />
                                <div className='mx-2'>
                                    <span>I want to rent a car</span>
                                    <p>Make the most of your stay and view car rental options on your reservation confirmation.</p>
                                </div>
                            </Col>
                            <Col lg="2">
                                <CarFront strokeWidth={1.5} size={50} />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col lg="12" className='my-3'>
                    <Row>
                        <Col lg="6" className='d-flex flex-column'>
                            <span className='fw-medium text-dark fs-6'>Who are you booking for?</span>
                            <div className='d-flex align-items-center my-1'>
                                <input id='mainclient' name="client" type='radio' />
                                <label htmlFor='mainclient' className='mx-2 fs-6'>I am the main customer</label>
                            </div>
                            <div className='d-flex align-items-center my-1'>
                                <input id='otherclient' name="client" type='radio' />
                                <label htmlFor='otherclient' className='mx-2 fs-6'>I am booking for another client</label>
                            </div>
                        </Col>

                        <Col lg="6" className='d-flex flex-column'>
                            <span className='fw-medium text-dark fs-6'>Are you traveling for work?</span>
                            <div className='d-flex align-items-center my-1'>
                                <input id='yes' name="job" type='radio' />
                                <label htmlFor='yes' className='mx-2 fs-6'>Yes</label>
                            </div>
                            <div className='d-flex align-items-center my-1'>
                                <input id='no' name="job" type='radio' />
                                <label htmlFor='no' className='mx-2 fs-6'>No</label>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Options