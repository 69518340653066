import SubHeaderPlatform from "./SubHeaderPlatform"
import HeaderDetails from "./HeaderDetails"
import TopDestinations from "./TopDestinations"
import Recommendations from "./Recommendations"
import Background from "./Background";


function Platform(){
    return(
        
        <div>
            <SubHeaderPlatform />
            <HeaderDetails />
            <Background />
            <TopDestinations />
            <Recommendations />
        </div>

    );
}

export default Platform;