import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid';

const AmountOption = () => {

  const [groupId] = useState(uuidv4());
  const [selectedAmount, setSelectedAmount] = useState(null);

  const handleSelectedAmount = (event) => {
    setSelectedAmount(event.target.id);
  };

  useEffect(() => {
    setSelectedAmount(null);
  }, []);


  return (

    <Row className='fs-5 align-items-center'>
      <Col md="6" className='d-flex align-items-center my-3'>
        <span className='fs-6 fw-medium text-dark'>What type of rate adjustment are you considering?</span>
      </Col>
      <Col md="6" className='d-flex align-items-center my-3'>
        <div className='d-flex align-items-center mx-3'>
          <input
            id={`discount_${groupId}`}
            type="radio"
            name={`amount_${groupId}`}
            checked={selectedAmount === `discount_${groupId}`}
            onChange={handleSelectedAmount}
          />
          <label htmlFor={`discount_${groupId}`} className='mx-2'>Discount</label>
        </div>

        <div className='d-flex align-items-center mx-3'>
          <input
            id={`increase_${groupId}`}
            type="radio"
            name={`amount_${groupId}`}
            checked={selectedAmount === `increase_${groupId}`}
            onChange={handleSelectedAmount}
          />
          <label htmlFor={`increase_${groupId}`} className='mx-2'>Increase</label>
        </div>
      </Col>
    </Row>
  )
}

export default AmountOption