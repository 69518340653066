import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';



function Service3(){
    return(
       
				<div id="srv1" className="join3divvn" >           
						<Container fluid  >
							<Row >
										< Col sm={12} style={{paddingTop:'10vw'}}>
											<div className="join3divpp" style={{display:'flex', flexDirection:'column'}}>
												
                                                <span className="join3span" style={{ color:'white',fontFamily:'Cormorant Garamond',textAlign:'center'}}>Join Us Now</span>
											
												<p style={{color:'white',marginLeft:'2vw',fontSize:'18px',marginBottom:'1.5vw',textAlign:'center'}}>
                                                Embark on the enrollment journey by meticulously filling out our professional registration form. Subsequent to your submission, our specialized team will expeditiously review your particulars, providing you exclusive access to our platform. This gateway unlocks a spectrum of sophisticated services meticulously curated to elevate and refine your business operations.
												</p>

                                                <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                    color: white;
                                   
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                                    font-size: 1.5rem;
                                    background-color:#772828;
                                    padding: 8px 40px;
                                    border-radius: 0px;
                                    margin: auto ;
                                    }

                                    `}
                                    </style>

                                    <Button style={{padding:'10px',margin:'auto', fontSize:'1rem', width:'25%',fontWeight:'600',letterSpacing:'1px'}} variant="flat" size="xxl"  >
                                        <a href="./Join" style={{fontFamily:'Join Hotelidh ',fontSize:'1.1rem',fontWeight:'200'}} className="text-white"> Join Hotelidh  </a>
                                    </Button>
                                    </>

											</div>
										</Col>
										
										
							</Row>
						</Container>

				</div>


        
    );
}

export default Service3;