import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Droplet, Wind, Umbrella } from 'lucide-react'
import { Loader } from 'rsuite';
import { useTranslation } from 'react-i18next';

function Meteo({ lon, lat }) {

  const { t } = useTranslation();
  const [weatherData, setWeatherData] = useState(null);
  const apiKey = 'd4bd73407ac5786594ef35d361d4cc83';

  const [Todaydate, setDate] = useState(new Date());

  const [key, setKey] = useState(0);

  useEffect(() => {

    const interval = setInterval(() => {
      setKey(prevKey => prevKey + 1);
    }, 10);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const intervalId = setInterval(() => {

      setDate(new Date());
    }, 5000);


    return () => clearInterval(intervalId);
  }, []);

  const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
  const formattedDate = Todaydate.toLocaleDateString('en-US', options);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&lang=fr`
        );
        setWeatherData(response.data)
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    };

    fetchData();
  }, [lat, lon, apiKey]);

  const convertirKelvinEnCelsius = kelvin => (kelvin - 273.15).toFixed(0);

  return (
    <div>
      {weatherData ? (
        <div key={key} className='d-flex flex-column p-4 rounded-5 text-dark'>
          <Row>
            <Col xs="6">
              <img width="100%" height="100%" src={require(`../assets/weather/${weatherData.weather[0].icon}.svg`)} alt={`Illustration animé du temps - ${weatherData.weather[0].description}`} />
            </Col>
            <Col xs="6">
              <div className='d-flex flex-column align-items-center'>
                <div className='fs-6 fw-medium'>{formattedDate}</div>
                <div className='fw-bold my-3 lh-1 text-blue' style={{ fontSize: "400%" }}>{convertirKelvinEnCelsius(weatherData.main.temp)} °C</div>
                <div className='fs-5 fw-bold'>{weatherData.weather[0].description}</div>
              </div>

            </Col>
          </Row>

          <p className='fw-bold fs-3 text-center title-blue'>{weatherData.name}</p>

          <Row className='my-3'>
            <Col xs="4">
              <div className='d-flex flex-column align-items-center'>
                <Umbrella color='#053DD0' strokeWidth="1" size={30} />
                <span className='fs-6 fw-medium my-1'>{weatherData.rain ? <span>{weatherData.rain[0]}</span> : <span>0</span>}mm</span>
                <p>{t('precipitation')}</p>
              </div>
            </Col>
            <Col xs="4">
              <div className='d-flex flex-column align-items-center'>
                <Droplet color='#053DD0' strokeWidth="1" size={30} />
                <span className='fs-6 fw-medium my-1'>{weatherData.main.humidity}%</span>
                <p>{t('humidity')}</p>
              </div>
            </Col>
            <Col xs="4">
              <div className='d-flex flex-column align-items-center'>
                <Wind color='#053DD0' strokeWidth="1" size={30} />
                <span className='fs-6 fw-medium my-1'>{Math.floor(weatherData.wind.speed * 3.6)} km/h</span>
                <p>{t('wind_speed')}</p>
              </div>
            </Col>
          </Row>

        </div>
      ) : (
        <div className='p-5 my-5 d-flex flex-column align-items-center justify-content-center'>
          <Loader speed="slow" size="md" content="Loading" />
          <p className='my-3'>Chargement des données météo</p>
        </div>
      )}
    </div>
  );
};

export default Meteo;