import { Row, Col } from 'react-bootstrap';
import { Facebook, Instagram, Twitter, Linkedin, SendHorizonal } from 'lucide-react';
import headphones from '../assets/headphone.svg'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import company from '../data/company.json'

function Footer() {

    const location = useLocation();
    const currentYear = new Date().getFullYear();
    const { t } = useTranslation();

    if (location.pathname === '/dashboard') {
        return null;
    }

    const soccial_links = [
        { icon: <Facebook size={20} />, link: company.facebook },
        { icon: <Instagram size={20} />, link: company.instagram },
        { icon: <Twitter size={20} />, link: company.twitter },
        { icon: <Linkedin size={20} />, link: company.linkedIn },
    ]

    return (
        <footer className='text-white p-sm-5 bg-dark-blue'>
            <div className='container py-3'>
                <Row className='align-items-center'>
                    <Col lg="5">
                        <div className='d-flex align-items-center fs-5'>
                            <img src={headphones} width="10%" height="10%" alt='headphones illustration' />
                            <div className='mx-2'>{t('speak_to_expert')} <span className='text-orange'>{company.phone}</span></div>
                        </div>
                    </Col>
                    <Col lg="7">
                        <div className='d-flex justify-content-end align-items-center fs-5'>
                            <span>{t('follow_us')}</span>
                            <div className='mx-3 d-flex'>
                                {soccial_links.map((link, index) => (
                                    <a key={index} className='me-3 text-white' aria-label="Lien vers nos réseaux sociaux" href={link.link}>{link.icon}</a>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='container mt-3'>
                <Row className='pt-lg-5'>
                    <Col lg='3' className='p-3'>
                        <div>
                            <span className='h5'>{t('contact')}</span>
                            <p className='my-3'>
                                Discover a new era in travel with Idhtours.com, Transform your travel dreams into reality with a single click!  </p>
                            <div className='my-3'>{company.adress}</div>
                            <div className='my-3'>{company.contact}</div>
                        </div>
                    </Col>
                    <Col lg='3' className='d-flex p-3'>
                        <div>
                            <span className='h5'>{t('company')}</span>
                            <nav className='d-flex flex-column mt-3'>
                                <a href="/about" className='mb-2 text-white'>{t('about')}</a>
                                <a href='/contact' className='mb-2 text-white'>{t('contact')}</a>
                                <a href="/faq" className='mb-2 text-white'>{t('faq')}</a>
                                <a href="/mentions" className='mb-2 text-white'>{t('data_policy')}</a>
                                <a href="/politique" className='mb-2 text-white'>{t('terms_and_conditions')}</a>
                            </nav>
                        </div>
                    </Col>
                    <Col lg='3' className='d-flex p-3'>
                        <div>
                            <span className='h5'>{t('support')}</span>
                            <nav className='d-flex flex-column mt-3'>
                                <a href="/flights" className='mb-2 text-white'>{t('flights')}</a>
                                <a href='/hotels' className='mb-2 text-white'>{t('hotels')}</a>
                                <a href="/activities" className='mb-2 text-white'>{t('activities')}</a>
                                <a href="/transferts" className='mb-2 text-white'>{t('cars')}</a>
                                <a href="/blog" className='mb-2 text-white'>{t('articles')}</a>
                            </nav>
                        </div>
                    </Col>
                    <Col lg='3' className='p-3'>
                        <>
                            <span className='h5'>{t('subscribe')}</span>
                            <p className='mt-2 fs-6 fw-light'>{t('subscribe_text')}</p>
                            <div className='border rounded-5 mt-3 d-flex p-2'>
                                <input className='w-75 border-0 text-dark ps-3 bg-transparent p-2' placeholder='Email' />
                                <button className='btn btn-orange text-white rounded-5 w-25 rounded-0'><SendHorizonal size={20} /></button>
                            </div>
                        </>
                    </Col>
                </Row>
            </div>

            <hr className='m-5' />

            <div className='d-flex align-items-center container'>
                <span className='m-3'>© {currentYear}. {t('all_rights_reserved')}</span>
            </div>
        </footer>
    )
};

export default Footer