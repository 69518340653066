import { useParams } from "react-router-dom";
import details from '../../data/availability.json'
import {  MoveRight } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import TopBanner from '../../components/TopBanner'
import Recap from "./Recap";
import BookingForm from "./BookingForm";
import Options from "./Options"
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

function Booking() {

    const { id } = useParams();
    let hotel = details.hotels.filter(objet => objet.id === parseInt(id))[0];

    return (
        <div>
                            <SubHeaderPlatform />
                            <HeaderDetails />
                            <section>
                            <TopBanner data="Booking" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

                            <div className='my-5 container'>
                                <Row>
                                    <Col lg="7" xl="8">
                                        <>

                                            <fieldset>
                                                <legend className='text-dark fs-3 fw-bold'>Booking Submission</legend>
                                                <BookingForm />
                                                <Options/>
                                            </fieldset>
                                        </>
                                    </Col>
                                    <Col lg="5" xl="4">
                                        <Recap name={hotel.name} image={hotel.image} country={hotel.address} type="Queen room" checkin="24, Jan. 2023" checkout="26, Jan. 2023" depart_time="10:50 am" return_time="12:00 am" travelers={2} duration="01 night" price={24} />
                                    </Col>
                                </Row>

                                <hr className='border-secondary' />

                                <div className='my-3 fs-6 d-flex align-items-center'>
                                    <input id='terms' type='checkbox' />
                                    <label className='mx-2' htmlFor='terms'>I have read and accept the terms and conditions and Privacy Policy</label>
                                </div>

                                <div className="my-5">
                                    <a className='btn-blue p-3 px-4 rounded-5 mt-3 fs-6 text-white' href={`/booking/cart/${id}/hotel`}>
                                        Submit <MoveRight />
                                    </a>
                                </div>
                            </div>
                            </section>
            
        </div>
        
    );
}

export default Booking;