
import axios from "axios";
import { apiUrl } from "./constants";
import { services } from "./services";


export function send(request) {

    var method = services.method[request.service][request.action]
    var url = services.url[request.service][request.action]
    var res;
    if (request.urlAppend) {
        url += '/' + request.urlAppend;
    }

    switch (method) {
        case 'get':
            res = _get(url, request.data);
            break;
        case 'post':
            res = _post(url, request.data);
            break;
        default:

            break;
    }

    return res;

}
export function http() {
    return axios.create({
        baseURL: apiUrl,
        headers: {
            "Content-type": "application/json",
            "sessionToken": `${window.sessionToken}`
        },
    });
}
export const _post = async (url, data) => {
    let res = [];
    try {
        res = await http().post(url, data);
    } catch (e) {
        console.log(e);

    }
    return res;
}
export const _put = async (url, data) => {
    let res = [];
    try {
        res = await http().put(url, data);
    } catch (e) {
        console.log(e);
    }
    return res;
}
export const _get = async (url, param = "") => {
    let res = [];
    try {
        res = await http().get(url, { params: param });
    } catch (e) {
        console.log(e);

    }
    return res;
}

export const getOptions = async (url) => {
    const items = [];
    try {
        const res = await http().get(url);
        res?.data?.data?.map((item) => {
            items.push({ value: item.id, label: item.name });
        });

    } catch (e) {
        console.log(e);
    }

    return items;

};

export const autocomplete = async (text, url) => {
    try {
        const res = await http().get(url + "?search=" + text);
        const data = res?.data?.data?.map((item) => {
            return { ...item, value: item.id, label: item.name };
        });
        return data;
    } catch (e) {
        return [];
    }
};