import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Select from '../../../components/forms/Select'
import Input from '../../../components/forms/Input'
import { useState } from 'react'
import supplierData from '../../../data/globaldata.json'

const AmountValue = () => {

    const [extraType, setExtraType] = useState('amount');

    const handleExtraType = (e) => {
        setExtraType(e.target.value);
    };


    return (
        <Row className='fs-5 align-items-center'>
            <Col sm="6" className='my-2'>
                <Input type="number" placeholder={extraType === "amount" ? "$" : "%" } />
            </Col>
            <Col sm="6" className='my-2'>
                <Select data={supplierData.extra_type} value={extraType} handleChange={handleExtraType} />
            </Col>
        </Row>
    )
}

export default AmountValue