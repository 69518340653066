import { Armchair, Clock, Users2 } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react';

const Recap = () => {

    const [values, setValues] = useState({
        transportation_name: '',
        transportation_location: '',
        transportation_type: '',
        transportation_travelers: 1,
        transportation_seats: 1,
        transportation_duration:"",
        transportation_intro: '',
        transportation_departure:'',
        transportation_arriving:''
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedData = {
                transportation_name: localStorage.getItem('transportation_name') || 'Not assigned',
                transportation_location: localStorage.getItem('transportation_location') || 'Not assigned',
                transportation_type: localStorage.getItem('transportation_type') || '...',
                transportation_travelers: localStorage.getItem('transportation_travelers') || 1,
                transportation_seats: localStorage.getItem('transportation_seats') || 1,
                transportation_duration: localStorage.getItem('transportation_duration') || 'Not assigned',
                transportation_intro: localStorage.getItem('transportation_intro') || 'Not assigned',
                transportation_description: localStorage.getItem('transportation_description') || 'Not assigned',
                transportation_departure: localStorage.getItem('transportation_departure') || 'Not assigned',
                transportation_arriving: localStorage.getItem('transportation_arriving') || 'Not assigned',

            };

            setValues(savedData);
        }, 100);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <section>
            <div className='border element rounded-4'>
                <div className='p-3'>
                    <div className='fs-5 fw-bold text-dark'>{values.transportation_name}</div>
                    <div className='fs-6 fw-medium'>{values.transportation_location}</div>
                </div>
                <div className='my-3 fs-6'>
                    <Row>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Users2 size={25} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.transportation_travelers}</div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Armchair size={25} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1 text-center'>{values.transportation_seats}</div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Clock size={25} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.transportation_duration}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span className='text-dark'>Introduction</span>
                    <span className='ps-3'>{values.transportation_intro}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Type</span>
                    <span>{values.transportation_type}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Departure</span>
                    <span>{values.transportation_departure}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span className='text-dark'>Destination</span>
                    <span className='ps-3'>{values.transportation_arriving}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Price</span>
                    <span>$25,34,000</span>
                </div>
            </div>
        </section>
    )
}

export default Recap