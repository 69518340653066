import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Star } from 'lucide-react'


const comments = [
    { img: "https://modmixmap.travelerwp.com/wp-content/uploads/2022/04/32.jpg", name: "John doe", comment: "If you want to get away from city life, rent a car and book Joyuam. It is a no-frills accommodation where you can enjoy fresh air and serene surrounding. A pity we only stayed 2 nights. I’ll book this place again next time." },
    { img: "https://modmixmap.travelerwp.com/wp-content/uploads/2022/04/32.jpg", name: "John doe", comment: "If you want to get away from city life, rent a car and book Joyuam. It is a no-frills accommodation where you can enjoy fresh air and serene surrounding. A pity we only stayed 2 nights. I’ll book this place again next time." },
    { img: "https://modmixmap.travelerwp.com/wp-content/uploads/2022/04/32.jpg", name: "John doe", comment: "If you want to get away from city life, rent a car and book Joyuam. It is a no-frills accommodation where you can enjoy fresh air and serene surrounding. A pity we only stayed 2 nights. I’ll book this place again next time." },
    { img: "https://modmixmap.travelerwp.com/wp-content/uploads/2022/04/32.jpg", name: "John doe", comment: "If you want to get away from city life, rent a car and book Joyuam. It is a no-frills accommodation where you can enjoy fresh air and serene surrounding. A pity we only stayed 2 nights. I’ll book this place again next time." },
]

const Reviews = () => {
    return (
        <div>
            <div>
                {
                    comments.map((comment, index) => (
                        <div key={index} className='border rounded-4 p-3 my-3'>
                            <Row className='align-items-center'>
                                <Col xs="2" xl="3">
                                    <img alt={`Illustration d'un utilisateur`} className='rounded-circle' width="100%" height="100%" src={comment.img} />
                                </Col>
                                <Col xs="10" xl="9">
                                    <span className='fs-6 fw-medium text-dark'>{comment.name}</span>
                                    <p>06/10/2022</p>
                                    <div className='d-flex align-items-center mt-1'><Star size={15} color='#F8D448' fill='#F8D448' /> <Star size={15} color='#F8D448' fill='#F8D448' /> <Star size={15} color='#F8D448' fill='#F8D448' /></div>
                                </Col>
                                <Col xs="12">
                                    <p className='my-3'>{comment.comment}</p>
                                </Col>
                            </Row>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Reviews