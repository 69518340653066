import { MinusCircle, PlusCircle } from 'lucide-react'
import Recap from './Recap'
import { Row, Col } from 'react-bootstrap'
import { useReducer } from 'react';
import Textarea from '../../../components/forms/Textarea';
import Select from '../../../components/forms/Select';
import Input from '../../../components/forms/Input';
import TopBanner from '../../../components/TopBanner';

const informations = {
    vehicle_passengers: 1,
    vehicle_doors: 0,
    vehicle_description: '',
    vehicle_registredcity: '',
    vehicle_year: ''
};

const reducer = (state, action) => {
    switch (action.type) {

        case 'INCREMENT_PASSENGERS':
            const incrementedPassengers = state.vehicle_passengers + action.payload;
            localStorage.setItem('vehicle_passengers', incrementedPassengers);
            return { ...state, vehicle_passengers: incrementedPassengers };

        case 'DECREMENT_PASSENGERS':
            const decrementPassengers = state.vehicle_passengers - action.payload;
            localStorage.setItem('vehicle_passengers', decrementPassengers);
            return { ...state, vehicle_passengers: decrementPassengers };

        case 'INCREMENT_DOORS':
            const incrementedDoors = state.vehicle_doors + action.payload;
            localStorage.setItem('vehicle_doors', incrementedDoors);
            return { ...state, vehicle_doors: incrementedDoors };

        case 'DECREMENT_DOORS':
            const decrementDoors = state.vehicle_doors - action.payload;
            localStorage.setItem('vehicle_doors', decrementDoors);
            return { ...state, vehicle_doors: decrementDoors };

        case 'SET_DESCRIPTION':
            localStorage.setItem('vehicle_description', action.payload);
            return { ...state, vehicle_description: action.payload };


        case 'SET_REGISTREDCITY':
            localStorage.setItem('vehicle_registredcity', action.payload);
            return { ...state, vehicle_registredcity: action.payload };

        case 'SET_YEAR':
            localStorage.setItem('vehicle_year', action.payload);
            return { ...state, vehicle_year: action.payload };

        default:
            return state;
    }
};

const Informations = () => {

    const [state, dispatch] = useReducer(reducer, informations);

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    const handleIncrement = (type) => () => {
        dispatch({ type, payload: 1 });
    };

    const handleDecrement = (type) => () => {
        dispatch({ type, payload: 1 });
    };

    const informationsdata = [
        { label: 'Passengers', value: state.vehicle_passengers, min: 2, max: 15, increment: 'INCREMENT_PASSENGERS', decrement: 'DECREMENT_PASSENGERS' },
        { label: 'Doors', value: state.vehicle_doors, min: 1, max: 3, increment: 'INCREMENT_DOORS', decrement: 'DECREMENT_DOORS' },
    ]

    const CurrentDate = new Date();
    const CurrentYear = CurrentDate.getFullYear();
    const prevYears = [{ title: "Model year", value: "" }];

    for (let i = 1; i <= 15; i++) {
        const year = CurrentYear - i;
        prevYears[`${year}`] = {
            title: `${year}`,
            valeur: year,
        };
    }

    return (
        <section>
            <TopBanner data="Informations" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/car/attributes'>

                            <fieldset>
                                <legend className='fs-2 text-dark fw-medium'>Main informations about your car</legend>
                                <p className='my-3 fs-6'>Give more informations that customers had to know about your car and specify the number of passengers that your car can accommodate, the number of doors.</p>



                                <div className='my-3'>
                                    <Input value={state.vehicle_registredcity} type="text" handleChange={handleChange('SET_REGISTREDCITY')} placeholder="Registred city" maxlength="30" />
                                </div>

                                <div className='my-3'>
                                    <Select value={state.vehicle_year} handleChange={handleChange('SET_YEAR')} data={prevYears} />
                                </div>

                                <div className='my-3'>
                                    <Textarea rows={10} placeholder='Create your description and provide all the details about what the customer will see and had to know about the car.' value={state.vehicle_description} handleChange={handleChange('SET_DESCRIPTION')} maxlength="150" />
                                </div>

                                <div className='my-3'>
                                    {informationsdata.map((item, index) => (
                                        <div className="d-flex justify-content-between py-4 border-bottom fs-6" key={index}>

                                            <div className="text-dark fs-5">{item.label}</div>

                                            <div className="d-flex align-items-center">
                                                <button type='button' className="mx-3 bg-white" onClick={handleDecrement(item.decrement)} disabled={item.value < item.min}><MinusCircle color="#1a536d" strokeWidth={1} size={40} /></button>

                                                <input className='border-0 tetx-center fs-6' value={item.value} type="number" readOnly style={{ width: "30px", height: "30px" }} />

                                                <button type='button' className="mx-3 bg-white" onClick={handleIncrement(item.increment)} disabled={item.value > item.max}><PlusCircle size={40} color="#1a536d" strokeWidth={1} /></button>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Informations