import P from "../slide/icone5.png";
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function Product3(){
    return(
        <div>
            <Container style={{marginTop:'2vw'}}>
                    <Row>
                                <Col className="formshop">
                                    <Col lg="2" md="12" className="imgshop">
                                        <img className="imgshp" src={P} style={{float:'left'}}/> 

                                    </Col>
                                    <Col lg="3" md="12" className="imgshop" style={{display:'flex',flexDirection:'column',marginRight:'1vw'}}>
                                        <h1 className="h1cart"> Company Name </h1>
                                        <div><a style={{color:'#505050'}} className="pspspss">Standard Car</a> </div>  
                                        <div><a style={{color:'#505050'}} className="pspspss">2 Weeks </a>  </div>
                                        <div><a style={{color:'#505050'}} className="pspspss"></a> </div>
                                        <div>
                                        <>
                                                            <style type="text/css">
                                                                {`
                                                            .btn-flat {
                                                         
                                                            }

                                                            .btn-xxl {
                                                            padding: 1rem 1.5rem;
                                                
                                                            background-color:#772828;
                                                            
                                                            border-radius: 0px;
                                                            font-family: 'Cormorant Garamond';
                                                            
                                                            }

                                                            `}
                                                            </style>

                                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id="tooltip-disabled" >Until 14/03/2024</Tooltip>}>
                                      
                                                                <Button id="btnidd" style={{backgroundColor:'#1a536d', padding: '5px 10px',margin:'auto',float:'left',marginRight:'8px', fontSize:'0.8rem',marginTop:'8px', fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                                                    Free Cancellation
                                                                </Button>

                                                            </OverlayTrigger>
                                                        </>
                                                        
                                                        <>
                                                            <style type="text/css">
                                                                {`
                                                            .btn-flat {
                                                            
                                                        
                                                            }

                                                            .btn-xxl {
                                                            padding: 1rem 1.5rem;
                                                
                                                            background-color:#772828;
                                                          
                                                            border-radius: 0px;
                                                            font-family: 'Cormorant Garamond';
                                                            
                                                            }

                                                            `}
                                                            </style>

                                                            <Button id="btnid1" style={{ padding: '5px 10px',margin:'auto', float:'left',marginRight:'8px', fontSize:'0.8rem',marginTop:'8px', fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                                                <a href="/FullDetails" style={{color:'#fff'}}>+ Full details</a>
                                                            </Button>
                                                        </>

                                                        <>
                                                            <style type="text/css">
                                                                {`
                                                            .btn-flat {
                                                            
                                                        
                                                            }

                                                            .btn-xxl {
                                                            padding: 1rem 1.5rem;
                                                
                                                            background-color:#772828;
                                                          
                                                            border-radius: 0px;
                                                            font-family: 'Cormorant Garamond';
                                                            
                                                            }

                                                            `}
                                                            </style>

                                                            
                                                        </>
                                        </div>               
                                        
                                       

                                    </Col>
                                    <Col lg="3" md="12" className="imgshop" style={{marginRight:'1vw'}}>
                                        <h1 className="h1cart">Supplier Name <br/> </h1>
                                        <a style={{color:'#505050'}} className="pspspss">Passenger:</a><span style={{float:'right'}}>rabia said</span> <br/>  
                                        <a style={{color:'#505050'}} className="pspspss">Agent:</a> <span style={{float:'right'}}>A. Smith</span><br/> 
                                       


                                    </Col>
                                    <Col lg="2" md="12" className="imgshop" style={{marginRight:'3vw'}}>
                                        <h1 className="h1cart"> <br/> </h1>
                                        <a style={{color:'#505050'}} className="pspspss">N°:</a><span style={{float:'right'}}>RB1702734341</span> <br/>  
                                        <a style={{color:'#505050'}} className="pspspss">City:</a><span style={{float:'right'}}>Paris/France</span> <br/>  
                                        <a style={{color:'#505050'}} className="pspspss">CD</a> <span style={{float:'right'}}>25/12/2023</span>  <br/> 
                                        <a style={{color:'#505050'}} className="pspspss">Price:</a><span style={{float:'right'}}>$ 242.00</span> <br/>  
                                       
                                    </Col>
                                    <Col lg="2" md="12" style={{float:'right'}}>
                                    <OverlayTrigger placement='bottom' overlay={<Tooltip id="tooltip-disabled" >Proceed to payment</Tooltip>}>
                                            <a href="booking/payment" style={{textDecoration:'none',color:'#861e12' ,paddingRight:'1vw'}}> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wallet-cards"><rect width="18" height="18" x="3" y="3" rx="2"/><path d="M3 9a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2"/><path d="M3 11h3c.8 0 1.6.3 2.1.9l1.1.9c1.6 1.6 4.1 1.6 5.7 0l1.1-.9c.5-.5 1.3-.9 2.1-.9H21"/></svg></a>
                                        </OverlayTrigger>

                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id="tooltip-disabled" >Delete</Tooltip>}>
                                        
                                            <a href="/Trash" style={{textDecoration:'none',color:'#861e12' ,paddingRight:'1vw'}}> <svg xmlns="http://www.w3.org/2000/svg"  width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg></a>
                                        </OverlayTrigger>
                                    
                                    
                                    </Col>
                                </Col>
                    </Row>
                </Container>
                
        </div>
    );
}

export default Product3;