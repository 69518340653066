import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import Input from '../../../components/forms/Input';
import AmountOption from './AmountOption';
import AmountValue from './AmountValue';
import AddedValue from './AddedValue';
import supplierData from '../../../data/globaldata.json'

const ExtraPrices = () => {

    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    return (
        <div className='my-3 fs-6'>
            <>

                {supplierData.extra_prices.map((check, index) => (
                    <div key={index} className='my-3'>
                        <>
                            <div className='d-flex py-2'>
                                <input
                                    id={check.value}
                                    type="checkbox"
                                    value={check.value}
                                    checked={checkedItems[check.value] || false}
                                    onChange={() => handleCheckboxChange(check.value)}
                                />
                                <label htmlFor={check.value} className='mx-2'>
                                    <div className='fw-medium'>{check.title}</div>
                                    <div>{check.content}</div>
                                </label>
                            </div>

                        </>

                        {checkedItems[check.value] && (
                            <>
                                {check.value === 'breakfast' && (
                                    <>
                                        <AmountOption />
                                        <Row className='align-items-center'>
                                            <Col xl="6" className='my-2'>
                                                <Select data={supplierData.breakfast} />
                                            </Col>
                                            <Col xl="6" className='my-2'>
                                                <AmountValue />
                                            </Col>
                                        </Row>

                                    </>
                                )}

                                {check.value === 'value' && (
                                    <>
                                        <AmountOption />
                                        <AmountValue />
                                        <AddedValue />
                                    </>
                                )}

                                {check.value === 'booking' && (
                                    <>
                                        <AmountOption />
                                        <Row className='align-items-center'>
                                            <Col xl="6" className='my-2'>
                                                <Input type="number" placeholder="Discount %" />
                                            </Col>
                                            <Col xl="6" className='my-2'>
                                                <Input type="number" placeholder="Days in advance" />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {['fullboard', 'halfboard'].includes(check.value) && (
                                    <>
                                        <AmountOption />
                                        <AmountValue />
                                    </>
                                )}

                                {check.value === 'length' && (
                                    <>
                                        <Row className='align-items-center'>
                                            <Col xl="6" className='my-2'>
                                                <Input type="number" placeholder="Discount %" />
                                            </Col>
                                            <Col xl="6" className='my-2'>
                                                <Input type="number" placeholder="The stay must be at least x nights" />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                            </>
                        )}
                        <hr className='border-secondary' />

                    </div>
                ))}
            </>
        </div>
    )
}

export default ExtraPrices