import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import SD from "../slide/tibi22.jpg";



function Contact2(){
    return(
        <Container style={{backgroundSize:'cover'}}>
            <Row style={{position:'relative',margin:'0 auto'}}>
                <div className="cont22">
                    <Col xs="12"  xl="6" className="j1" style={{padding:'3vw'}}>   
                            <div style={{marginBottom:'26px'}}>
                                <p className="joinp" style={{color:'#1a536d'}}>Always With You</p>
                                <h2 className="h2join">Contact Us Now </h2>
                            </div> 
                            <div style={{marginBottom:'32px'}}>
                                <p className="joinp1" style={{paddingLeft:'0vw'}}>

                                Embark on a seamless partnership with Hotelidh, where effortless integration meets reliable collaboration. For tailored insights into our array of products and services, or to delve deeper into specific features, our team is just a message or call away. Feel free to utilize the provided communication channels to connect with us, and we'll eagerly guide you through every detail, empowering you to make well-informed decisions that resonate with your business objectives.

                               <br/> <br/>
                               Hotelidh places a premium on transparency, ensuring that you not only receive support but also gain comprehensive knowledge. We are dedicated to furnishing you with the necessary information, enabling you to extract maximum value from our offerings    
                                </p>
                            </div>
                            <div >
                            <>
                                <style type="text/css">
                                    {`
                                .btn-flat {
                                background-color: #c3bbaa;
                                color: white;
                                }

                                .btn-xxl {
                                padding: 1rem 1.5rem;
                                font-size: 1.5rem;
                                float: left;
                                


                                }
                                `}
                                </style>

                                <Button variant="flat" size="xxl" style={{}}>
                                    <a href="./Join" className="text-white "> Join Us </a>
                                </Button>
                            </> 

                           
                            </div>
                    </Col>
                    <Col xs="12"  xl="6" className="">

                        <img className="img-fluid" src={SD}  alt="hotelidh" />
                    </Col>

                </div>

            </Row>

        </Container>
    );
}

export default Contact2;