import { Check } from 'lucide-react'
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import { Panel, PanelGroup } from 'rsuite';
import PublishCelebration from '../PublishCelebration';
import TopBanner from '../../../components/TopBanner';

const Review = () => {

    const [values, setValues] = useState({
        accommodation_name: '',
        accommodation_type: '',
        accommodation_choice: '',
        accommodation_location: '',
        accommodation_intro: '',
        accommodation_description: '',
        accommodation_host: '',
        accommodation_currency: '',
        accommodation_travelers:1,
        accommodation_rooms: 0,
        accommodation_beds: 1,
        accommodation_baths: 0,
        accommodation_age: 15,
        accommodation_facilities:[]
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedData = {
                accommodation_name: localStorage.getItem('accommodation_name') || 'Not assigned',
                accommodation_type: localStorage.getItem('accommodation_type') || 'Not assigned',
                accommodation_choice: localStorage.getItem('accommodation_choice') || 'Not assigned',
                accommodation_location: localStorage.getItem('accommodation_location') || 'Not assigned',
                accommodation_intro: localStorage.getItem('accommodation_intro') || 'Not assigned',
                accommodation_description: localStorage.getItem('accommodation_description') || 'Not assigned',
                accommodation_host: localStorage.getItem('accommodation_host') || 'Not assigned',
                accommodation_currency: localStorage.getItem('accommodation_currency') || 'Not assigned',
                accommodation_travelers: localStorage.getItem('accommodation_travelers') || 1,
                accommodation_rooms: localStorage.getItem('accommodation_rooms') || 0,
                accommodation_beds: localStorage.getItem('accommodation_beds') || 1,
                accommodation_baths: localStorage.getItem('accommodation_baths') || 1,
                accommodation_age: localStorage.getItem('accommodation_age') || 15,
                accommodation_facilities: JSON.parse(localStorage.getItem('accommodation_facilities')) || [],
            };

            setValues(savedData);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <section>
            <TopBanner data="Review" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <div>
                    <span className='fs-2 fw-bold text-dark'>Is this informations correct?</span>
                    <p className='fs-5'>If everything looks good, save and publish to set it live on IdhTours's website.</p>
                    <hr className='my-3 border-secondary' />
                    <PanelGroup accordion defaultActiveKey={1}>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Basic</div>} eventKey={1} id="panel1" defaultExpanded>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Title</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.accommodation_name}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Location</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.accommodation_location}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Type</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.accommodation_type}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Availability </span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.accommodation_choice}</span>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Informations</div>} eventKey={2} id="panel2">
                            
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Travelers</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.accommodation_travelers}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Rooms</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.accommodation_rooms}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Beds</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.accommodation_beds}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Baths</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.accommodation_baths}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Minimum age to book</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.accommodation_age}</p>
                                </Col>
                            </Row>
                        </Panel>

                        <Panel header={<div className='fs-5 fw-bold text-dark'>Attributes</div>} eventKey={3} id="panel3">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Facilities</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                        {values.accommodation_facilities.map((tag, index) => (
                                            <div key={index} className='my-1 text-success'><Check size={15} /> <span className='text-dark'>{tag}</span></div>
                                        ))}
                                </Col>
                            </Row>
                        </Panel>

                        <Panel header={<div className='fs-5 fw-bold text-dark'>Additional</div>} eventKey={4} id="panel4">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Introduction</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.accommodation_intro}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Description</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.accommodation_description}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Host</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.accommodation_host}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Currency</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.accommodation_currency}</span>
                                </Col>
                            </Row>
                        </Panel> 

                        <Panel header={<div className='fs-5 fw-bold text-dark'>Photos</div>} eventKey={4} id="panel4">
                            Empty
                        </Panel> 


                    </PanelGroup>

                </div>
                <PublishCelebration showModal={showModal} closeModal={closeModal} />
                <button onClick={openModal} className='btn-blue p-3 px-5 rounded-5 text-white my-5'>Publish</button>
            </div>
        </section>
    )
}

export default Review