import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Data from '../data/globaldata.json'

const CapitalDestination = () => {

  const { t } = useTranslation();
  const [capitals, setCapitals] = useState([]);
  const [continent, setContinent] = useState("Europe");

  const getRandomItems = (array, count) => {
    const shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled.slice(0, count);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countries = response.data;

        const continentCountries = countries.filter(country => country.region.includes(continent));

        const randomContinentCountries = getRandomItems(continentCountries, 24);

        const capitalData = randomContinentCountries.map(country => ({
          countryName: country.name.common,
          capital: country.capital[0]
        }));

        setCapitals(capitalData);
      } catch (error) {
        console.error('Une erreur s\'est produite :', error);
      }
    };

    fetchData();
  }, [continent]);

  const handleClick = (bouton) => {
    setContinent(bouton);
  };

  return (
    <section className='my-3'>
      <div className='text-center'>
        <h2 className='text-dark fs-1 fw-medium'>{t('these_cities_we_love')}</h2>
        <p className="my-1 fs-6">{t('find_popular_destinations')}</p>
      </div>
      <Row className='align-items-center mt-4'>
        {Data.continents.map((button, index) => (
          <Col key={index} xs="3" md="2" xl='1' className='justify-content-center my-1'>
            <button className={`w-100 border rounded-5 p-2 px-3 ${continent === button.value ? 'bg-blue text-white' : 'bg-white'}`} onClick={() => handleClick(button.value)}>
              {button.title}
            </button>
          </Col>
        ))}
      </Row>

      <Row className='p-2'>
        {capitals.map((capital, index) => (

          <Col key={index} xl="2" lg="3" md="3" sm="4" xs="6">
            <div className='capital-item p-3' style={{ textDecoration: 'none' }}>
              <div className='fs-6 text-dark fw-medium'>{capital.capital}</div>
              <div className='text-secondary'>{capital.countryName}</div>
            </div>
          </Col>

        ))}
      </Row>
    </section>

  );
};

export default CapitalDestination;
