import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import Button from 'react-bootstrap/Button'
import SubHeaderPlatform from '../components/SubHeaderPlatform'
import HeaderDetails from '../components/HeaderDetails'



function Cancell(){
    return(
        <div>
            <div style={{marginBottom:'6vw'}} >
                       
                        <SubHeaderPlatform />
                        <HeaderDetails />
                        <Container style={{paddingTop:'2vw',marginBottom:'4vw'}}>
                            <Row>
                                <Col  style={{ display:'flex', flexDirection:'column',borderRadius: '6px',marginRight:''}}>
                                

                                    <Col style={{display:'flex',backgroundColor:'white',flexDirection:'column',boxShadow: '0 0 3px 2px rgba(0, 0, 0, .05)',borderRadius: '6px',padding:'3vw 3vw 1vw 4vw'}}>
                                        <h1 style={{color:'#861e12' ,paddingBottom:'1vw',fontFamily:'Inter,sans-serif', fontSize:'30px',fontWeight:'bold',textAlign:'left'}}>Cancellation policy <span style={{float:'right',fontSize:'16px'}}>N° RB1707752915 - 125 125 DZD</span> </h1>    
                                        
                                        <p style={{textAlign:'left',fontSize:'16px'}}>
                                                <ul>
                                                    <li>
                                                        This rate is non-refundable. If you cancel or change your booking, you will not get a refund or credit to use for a later stay
                                                    </li>
                                                    <li>
                                                        No refunds will be made for late arrival or early departure
                                                    </li>
                                                    <li> Any extension of stay requires a new reservation</li>
                                                </ul>   
                                                
                                        </p>
                                            <h1 style={{color:'#1a536d' ,paddingBottom:'5px',fontFamily:'Inter,sans-serif', fontSize:'25px',fontWeight:'bold',textAlign:'left'}}>
                                                Do you realy want to cancel this reservation ! 
                                            </h1> 

                                            <span style={{marginLeft:'1vw'}}>
                                                <Button id="Bfff" href="/Removed" style={{width:'20%',marginRight:'2vw'}}>
                                                    Yes
                                                </Button>
                                                
                                                
                                               
                                            </span>
                                        
                                    </Col>
                                  

                                </Col>


                               
                            </Row>
                        </Container>
                        
                       
                        
                        
                        
                        
            </div >        
        </div >
    );
}

export default Cancell;