import { Modal } from 'react-bootstrap'
import Login from './Login'
import Register from './Register'
import React, { useState } from 'react';

const Account = ({ showAccountModal, handleCloseAccountModal, handleShowAccountModal }) => {

    const [boutonActif, setBoutonActif] = useState('sign');

    const handleClick = (bouton) => {
        setBoutonActif(bouton);
    };

    return (
        <div>
            <Modal className='text-dark m-0 p-0' show={showAccountModal} onHide={handleCloseAccountModal}>
                <div className='bg-white rounded-4'>
                    <Modal.Header className='p-4' closeButton>
                        <div className='w-100 d-flex justify-content-center'>
                            <span className='fs-5 fw-medium mx-2' onClick={() => handleClick('sign')} style={{ cursor: "pointer", color: boutonActif === 'sign' ? '#1a536d' : '', textDecoration: boutonActif === 'sign' ? 'underline' : '' }}>
                                Sign in
                            </span>
                            <span className='fs-5 fw-medium  mx-2' onClick={() => handleClick('register')} style={{ cursor: "pointer", color: boutonActif === 'register' ? '#1a536d' : '', textDecoration: boutonActif === 'register' ? 'underline' : '' }}>
                                Register
                            </span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {boutonActif === 'sign' && <Login />}
                            {boutonActif === 'register' && <Register />}
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}

export default Account