import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import RoomOption from './RoomOption'
import Input from '../../../components/forms/Input';
import supplierData from '../../../data/globaldata.json'

const DetailsLayout = () => {

    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    return (
        <div className='my-3 fs-6'>
            <>

                {supplierData.layout.map((check, index) => (
                    <div key={index} className='my-3'>
                        <>
                            <div className='d-flex align-items-center'>
                                <input
                                    id={check.value}
                                    type="checkbox"
                                    value={check.value}
                                    checked={checkedItems[check.value] || false}
                                    onChange={() => handleCheckboxChange(check.value)}
                                />
                                <label htmlFor={check.value} className='mx-2'>
                                    {check.title}
                                </label>
                            </div>
                        </>

                        {checkedItems[check.value] && (
                            <>
                                {check.value === 'bedroom size' && (
                                    <>
                                        <Row className='align-items-center'>
                                            <Col md="6" className='my-2'>
                                                <Input placeholder="Square feet" type="number" />
                                            </Col>
                                            <Col md="6" className='my-2'>
                                                <Input placeholder="Square meters" type="number" />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {check.value === 'floor types' && (
                                    <>
                                        <RoomOption />
                                        {supplierData.floors.map((check, index) => (
                                            <div key={index} className='d-flex align-items-center my-3 px-3'>
                                                <input
                                                    id={check.value}
                                                    type="checkbox"
                                                />
                                                <label htmlFor={check.value} className='mx-2'>
                                                    {check.title}
                                                </label>
                                            </div>
                                        ))}
                                    </>
                                )}

                                {check.value === 'room location' && (
                                    <Row className='align-items-center'>
                                        <Col md="6" className='my-2'>
                                            <span className='fs-6 fw-medium text-dark'>Where is your room located?</span>
                                        </Col>
                                        <Col md="6" className='my-2'>
                                            <Select data={supplierData.location} />
                                        </Col>
                                    </Row>
                                )}

                                {['dining area', 'cupboard', 'desk', 'chimney', 'external hallways', 'swimming pool', 'heating'].includes(check.value) && (
                                    <RoomOption />
                                )}

                                {check.value === 'spaces' && (
                                    <>
                                        <RoomOption />
                                        <Row className='align-items-center'>
                                            <Col md="6" className='my-2'>
                                                <span className='fs-6 fw-medium text-dark'>Where is your room located?</span>
                                            </Col>
                                            <Col md="6" className='my-2'>
                                                <Select data={supplierData.spaces} />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {check.value === 'fan' && (
                                    <>
                                        <RoomOption />
                                        {supplierData.fan.map((check, index) => (
                                            <div key={index} className='d-flex align-items-center my-3 px-3'>
                                                <input
                                                    id={check.value}
                                                    type="checkbox"
                                                />
                                                <label htmlFor={check.value} className='mx-2'>
                                                    {check.title}
                                                </label>
                                            </div>
                                        ))}
                                    </>
                                )}

                            </>
                        )}


                    </div>
                ))}
            </>
            <Row className='align-items-center'>
                <Col md="6" className='my-2'>
                    <span className='fs-6 fw-medium text-dark'>Does this room have a special view?</span>
                </Col>
                <Col md="6" className='my-2'>
                    <Select data={supplierData.views} />
                </Col>
            </Row>
        </div>
    )
}

export default DetailsLayout