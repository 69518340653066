import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Contact3(){
    return(
        <div style={{backgroundColor:'#f7f6f5'}}>
        <Container  style={{paddingTop:'5vw', paddingBottom:'5vw'}}>
            <Row> 
                <Col  className="ContSp" sm={12} style={{display:'flex', flexDirection:'column'}}>
                    <h3 className="colh3"> Get in Touch for Personalized Assistance </h3>
                    <p style={{textAlign:'left',fontSize:'16px'}}>
                    Do not hesitate to contact us for personalized support in obtaining the best products and services customized
                    to meet your business requirements. Our team is available to assist you throughout the entire process. 
                    If you require helpdesk assistance for our platform, feel free to reach out, our support team is accessible 
                    24/7 to promptly and effectively address your queries. Whether you have inquiries or new product suggestions, 
                    we value your feedback at any time. 
                    Reach out to us, and our team will address your concerns and take into consideration your valuable input.
                        
                    <br/><br/></p>
                    
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
                        <br/> 
                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                            contact@hotelidh.com <br/>
                        </p>
                        
                    </span>

                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
                       
                     <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                            infos@elcomparador-b2b.com
                        </p>
                    </span>
                   
                    <span>
                    <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map-pin"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"/><circle cx="12" cy="10" r="3"/></svg><br/> 
                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                            Calle velazquez N53 Planta 2
                            Puerta IZO 28001 Madrid 
                            
                        </p>
                    </span>
                </Col>
                
            </Row>
        </Container>
        </div>
    );
}

export default Contact3;