import { Check, X } from 'lucide-react'
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import { Panel, PanelGroup } from 'rsuite';
import TopBanner from '../../../components/TopBanner';
import PublishCelebration from '../PublishCelebration';

const Review = () => {

    const [values, setValues] = useState({
        package_name: '',
        package_location: '',
        package_type: '',
        package_groupsize: null,
        package_duration: '',
        package_intro: '',
        package_description: '',
        package_highlights: [],
        package_meeting_point: '',
        package_inclusions: '',
        package_exclusions: '',
        package_guide: ''
    });


    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedData = {
                package_name: localStorage.getItem('package_name') || 'Not assigned',
                package_location: localStorage.getItem('package_location') || 'Not assigned',
                package_type: localStorage.getItem('package_type') || 'Not assigned',
                package_groupsize: localStorage.getItem('package_groupsize') || 0,
                package_duration: localStorage.getItem('package_duration') || 'Not assigned',
                package_intro: localStorage.getItem('package_intro') || 'Not assigned',
                package_description: localStorage.getItem('package_description') || 'Not assigned',
                package_highlights: JSON.parse(localStorage.getItem('package_highlights')) || ['Not assigned'],
                package_meeting_point: localStorage.getItem('package_meeting_point') || ['Not assigned'],
                package_inclusions: localStorage.getItem('package_inclusions') || 'Not assigned',
                package_exclusions: localStorage.getItem('package_exclusions') || 'Not assigned',
                package_guide: localStorage.getItem('package_guide') || 'Not assigned',
            };

            setValues(savedData);

        }, 10);

        return () => clearInterval(intervalId);
    }, []);

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <section>
            <TopBanner data="Activity" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <div>
                    <span className='fs-2 fw-bold text-dark'>Is this informations correct?</span>
                    <p className='my-1 fs-5'>If everything looks good, save and publish to set it live on IdhTours's website.</p>
                    <hr className='my-3 border-secondary' />
                    <PanelGroup accordion defaultActiveKey={1}>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Basic</div>} eventKey={1} id="panel1" defaultExpanded>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Title</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.package_name}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Location</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.package_location}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Type</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.package_type}</span>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Informations</div>} eventKey={2} id="panel2">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Group size</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.package_groupsize}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Duration</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.package_duration}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Introduction</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.package_intro}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Description</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.package_description}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Highlights</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <ul>
                                        {values.package_highlights.map((high, index) => (
                                            <li key={index}>{high}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Attributes</div>} eventKey={3} id="panel3">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Meeting point</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.package_meeting_point}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Inclusions</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    {values.package_inclusions.split('\n').map((item, index) => (
                                        <div key={index} className='my-1 text-success'><Check size={15} /> <span className='text-dark'>{item}</span></div>
                                    ))}
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Exclusions</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    {values.package_exclusions.split('\n').map((item, index) => (
                                        <div key={index} className='my-1 text-danger'><X size={15} /> <span className='text-dark'>{item}</span></div>
                                    ))}
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Guide</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.package_guide}</span>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Photos</div>} eventKey={4} id="panel4">
                            Empty
                        </Panel>
                    </PanelGroup>

                </div>
                <PublishCelebration showModal={showModal} closeModal={closeModal} />
                <button onClick={openModal} className='btn-blue p-3 px-5 rounded-5 text-white my-5'>Publish</button>
            </div>
        </section>
    )
}

export default Review