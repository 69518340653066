import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import room from "../slide/ted4.png";
import billet from "../slide/bil.png";
import Sds from "../slide/ddrhhn.png";
import car from "../slide/car.png";
import SD from "../slide/taaa.png";
import Sds1 from "../slide/3a6.png";



function Service2(){
    return(
        <div id="srv" style={{backgroundColor:'#fafafa',position:'relative',zIndex:'100'}}>
          
            <Container className="scm" style={{position: 'relative',top: '-4vw'}}>
                <Row>
                    <Col className="prcol" xl="4" lg="12" style={{display:'flex', flexDirection:'column',paddingBottom:'50px'}}>
                        <div className="jfi">       
                            <img src={room}  alt="hotelidh" className="jimg"  /> 
                        </div>
                        <div className="divdimg">
                            <h2 className="h2s2">Accommodation</h2>
                            <p className="pservice2">
                                Hotelidh allows you to seamlessly book accommodations in over 
                                1 000 000 hotels worldwide, catering to the unique requirements of our diverse clientele. 
                                <br/><br/>
                                At Hotelidh, we prioritize your satisfaction, 
                                ensuring a smooth and enjoyable travel experience from reservation to check-out
                             </p>
                        </div>
                    
                    </Col>

                    <Col className="prcol" xl="4" lg="12" style={{display:'flex', height:'fit-content', flexDirection:'column',paddingBottom:'50px'}}>
                    <div className="jfi">   
                        <img src={billet}  alt="hotelidh" className="jimg"  /> 
                    </div>   
                        <div className="divdimg">
                            <h2 className="h2s2">Ticketing</h2>
                            <p className="pservice2">
                            Book your flights effortlessly on Hotelidh, where over 950 scheduled regular flights and 180 <br/> low-cost 
                            carriers await. Enjoy real-time booking convenience with our user-friendly platform, providing you with a 
                            diverse selection of airlines to meet your travel needs. Hotelidh simplifies the booking process, ensuring 
                            a seamless experience whether you're planning a business trip or a leisure getaway
                             
                             </p>
                            
                        </div>
                    
                    </Col>

                    <Col className="prcol" xl="4" lg="12" style={{display:'flex', flexDirection:'column',paddingBottom:'100px',paddingRight:'50px'}}>
                            <div className="jfi">   
                                <img src={Sds}  alt="hotelidh" className="jimg"  /> 
                            </div> 
                            <div className="divdimg">
                                <h2 className="h2s2">Our Packages</h2>
                                 
                                <p className="pservice2">

                                Experience the ultimate in travel convenience with our comprehensive packages
                                 that include accommodation, flights, transfers, and guided visits.
                                 Our all-in-one service is crafted to make your journey effortless and unforgettable.
                                 From comfortable stays to seamless transfers and engaging guided visits, our 
                                 packages are designed to ensure every aspect of your travel is taken care of.
                                Let us handle the details, so you can focus on creating lasting memories
                                </p>
                                
                            </div>
                        
                    </Col>
                    
                    <Col className="prcol" id="dtop" xl="4" lg="12" style={{display:'flex', flexDirection:'column',paddingBottom:'50px'}}>
                        <div className="jfi">
                            <img src={car}  alt="hotelidh" className="jimg" /> 
                        </div>
                        <div className="divdimg">
                            <h2 className="h2s2">Car Rental</h2>
                            <p className="pservice2">
                            Discover newfound freedom in your travels with Hotelidh's car rental service. 
                            Choose from a diverse array of vehicles, all at enticing rates. 
                            Whether you're navigating city streets or embarking on a family road trip, 
                            our extensive fleet accommodates every preference. Benefit from effortless booking and 
                            cost-effective options, providing both convenience and affordability. 
                            With Hotelidh's car rental service, your journey becomes a personalized adventure, 
                            offering flexibility and comfort throughout
                            </p>
                        </div>
                    
                    </Col>

                    <Col className="prcol" id="ddtop" xl="4" lg="12" style={{display:'flex', flexDirection:'column',paddingBottom:'10px'}}>
                        <div className="jfi">   
                            <img src={SD}  alt="hotelidh" className="jimg" /> 
                        </div>
                        <div className="divdimg">
                            <h2 className="h2s2">Transfers </h2>
                            <p className="pservice2">

                            Experience convenience at its best with our hotel-to-airport and airport-to-hotel services. 
                            Whether you're arriving or departing, our seamless transportation solutions ensure a stress-free journey. 
                            Enjoy comfortable transfers from the hotel to the airport or vice versa, allowing you to focus on what matters 
                            most—your travel experience. 
                            Book with us and elevate your stay with reliable and efficient transfers hotel-to-airport and airport-to-hotel  
                             
                             </p>
                        </div>
                    
                    </Col>

                    <Col className="prcol" id="dddtop" xl="4" lg="12" style={{display:'flex', flexDirection:'column',paddingBottom:'50px',paddingRight:'50px'}}>
                        <div className="jfi">     
                            <img src={Sds1}  alt="hotelidh" className="jimg"  /> 
                        </div>
                        <div className="divdimg">
                            <h2 className="h2s2">Visits</h2>
                            <p className="pservice2">
                               
                            Discover the world's top tourist destinations all in one place with Hotelidh. 
                            Choose from a diverse array of offerings, including a wide variety of visits, guided tours, 
                            excursions, access to stadiums, museums, festivals, folklore dinners, amusement parks, and more. 
                            Tailor your travel experience to your interests and preferences by exploring the extensive 
                             available on Hotelidh. We invite you to make the most of your journey with our comprehensive 
                             selection of activities and experiences, ensuring every moment of your trip is filled with adventure 
                             and delight
                               
                            </p>
                        </div>
                    
                    </Col>
                </Row>
            </Container>
            </div>

    );
}


export default Service2;