import { MinusCircle, PlusCircle } from 'lucide-react'
import Recap from './Recap'
import { Row, Col } from 'react-bootstrap'
import Textarea from '../../../components/forms/Textarea';
import { useReducer } from 'react';
import TopBanner from '../../../components/TopBanner';
import Highlights from '../../../components/forms/Highlights';


const informations = {
    activity_groupsize: 1,
    activity_duration: '1 heure',
    activity_intro: '',
    activity_description: '',
    activity_highlights: ['', '', '', ''],
};

const reducer = (state, action) => {

    const durations = ['0 - 01 hour', '01 - 04 hours', '04h - 01 day', '01 day+'];
    const currentIndex = durations.indexOf(state.activity_duration);

    switch (action.type) {

        case 'INCREMENT_GROUP':
            const incrementedGroup = state.activity_groupsize + action.payload;
            localStorage.setItem('activity_groupsize', incrementedGroup);
            return { ...state, activity_groupsize: incrementedGroup };
        case 'DECREMENT_GROUP':
            const decrementGroup = state.activity_groupsize - action.payload;
            localStorage.setItem('activity_groupsize', decrementGroup);
            return { ...state, activity_groupsize: decrementGroup };

        case 'INCREMENT_DURATION':
            const nextIndex = (currentIndex + 1) % durations.length;
            const nextDuration = durations[nextIndex];
            localStorage.setItem('activity_duration', nextDuration);
            return { ...state, activity_duration: nextDuration };

        case 'DECREMENT_DURATION':
            const prevIndex = (currentIndex - 1 + durations.length) % durations.length;
            const prevDuration = durations[prevIndex];

            localStorage.setItem('activity_duration', prevDuration);
            return { ...state, activity_duration: prevDuration };

        case 'SET_INTRO':
            localStorage.setItem('activity_intro', action.payload);
            return { ...state, activity_intro: action.payload };
        case 'SET_DESCRIPTION':
            localStorage.setItem('activity_description', action.payload);
            return { ...state, activity_description: action.payload };

        case 'SET_HIGHLIGHT':
            const updatedAccHighlights = state.activity_highlights.map((highlight, index) => {
                return index === action.payload.index ? action.payload.value : highlight;
            });
            localStorage.setItem('activity_highlights', JSON.stringify(updatedAccHighlights));
            return { ...state, activity_highlights: updatedAccHighlights };

        default:
            return state;
    }
};

const Informations = () => {

    const [state, dispatch] = useReducer(reducer, informations);

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    const handleHighlightsChange = (type, index) => (e) => {
        dispatch({ type, payload: { index, value: e.target.value } });
    };

    const handleIncrement = (type) => () => {
        dispatch({ type, payload: 1 });
    };

    const handleDecrement = (type) => () => {
        dispatch({ type, payload: 1 });
    };


    const handleIncrementDuration = () => {
        dispatch({ type: 'INCREMENT_DURATION' });
    };

    const handleDecrementDuration = () => {
        dispatch({ type: 'DECREMENT_DURATION' });
    };

    return (
        <section>
            <TopBanner data="Informations" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/activity/attributes'>

                            <fieldset>
                                <legend className='fs-2 text-dark fw-medium'>Main information about your activity</legend>
                                <p className='my-3 fs-6'>Specify the maximum number of people your activity can accommodate, the duration, and describe her to Make your ad stand out from the crowd</p>

                                <div className='my-3'>
                                    <div className="d-flex justify-content-between py-4 border-bottom fs-6">

                                        <div className="text-dark fs-5">Group size</div>

                                        <div className="d-flex align-items-center">
                                            <button type='button' className="mx-3 bg-white" onClick={handleDecrement('DECREMENT_GROUP')} disabled={state.activity_groupsize < 2}><MinusCircle color="#1a536d" strokeWidth={1} size={40} /></button>

                                            <input className='border-0 text-center fs-5' value={state.activity_groupsize} type="number" readOnly style={{ width: "100px" }} />

                                            <button type='button' className="mx-3 bg-white" onClick={handleIncrement('INCREMENT_GROUP')} disabled={state.activity_groupsize > 49}><PlusCircle size={40} color="#1a536d" strokeWidth={1} /></button>
                                        </div>
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <div className="d-flex justify-content-between py-4 border-bottom fs-6">

                                        <div className="text-dark fs-5">Duration</div>

                                        <div className="d-flex align-items-center">
                                            <button type='button' className="mx-3 bg-white" onClick={handleDecrementDuration}><MinusCircle color="#1a536d" strokeWidth={1} size={40} disabled={state.activ < 2} /></button>

                                            <span className='border-0 text-center fs-6' style={{ width: "100px" }}>{state.activity_duration}</span>


                                            <button type='button' className="mx-3 bg-white" onClick={handleIncrementDuration}><PlusCircle size={40} color="#1a536d" strokeWidth={1} /></button>
                                        </div>
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <Textarea rows={4} placeholder='Create your introduction, this will be the first thing customers read after the title, and will inspire them to continue.' value={state.activity_intro} handleChange={handleChange('SET_INTRO')} maxlength="220" />
                                    <div>{state.activity_intro.length === 220 ? <span className='text-danger fs-6'>Your introduction must not exceed 220 characters</span> : ''}</div>
                                    <div>{state.activity_intro.length} / 220</div>
                                </div>

                                <div className='my-4'>
                                    <Textarea rows={10} placeholder='Create your description and provide all the details about your activity' value={state.activity_description} handleChange={handleChange('SET_DESCRIPTION')} />
                                </div>

                                <div className='my-3'>
                                    <Highlights state={state.activity_highlights} handleChange={handleHighlightsChange} />
                                </div>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Informations