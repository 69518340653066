import { Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import { Plus, Search, Trash2 } from 'lucide-react'
import OneWay from './OneWay'
import RoundTrip from './RoundTrip'
import { useTranslation } from 'react-i18next';

function FlightAvailability() {

    const { t } = useTranslation();
    const [boutonActif, setBoutonActif] = useState('oneway');

    const buttons = [
        { title: t('one_way'), value: "oneway" },
        { title: t('round_trip'), value: "roundtrip" },
        { title: t('multiple'), value: "multiple" }
    ]

    const handleClick = (bouton) => {
        setBoutonActif(bouton);
    };

    const [multiDestinationComponents, setMultiDestinationComponents] = useState([]);

    const handleAddMultiDestination = () => {
        if (multiDestinationComponents.length < 2) {
            setMultiDestinationComponents([...multiDestinationComponents, <OneWay key={multiDestinationComponents.length} />]);
        }
    };

    const handleRemoveMultiDestination = (index) => {
        const updatedComponents = [...multiDestinationComponents];
        updatedComponents.splice(index, 1);
        setMultiDestinationComponents(updatedComponents);
    };


    return (

        <>
            <div className='container px-xl-5'>
                <Row className='align-items-start text-dark py-0 my-0'>
                    {buttons.map((button, index) => (
                        <Col key={index} className='radio-button my-1' xs="3" md="3" lg="2">
                            <div role='button' className={`border rounded-5 text-center p-2 ${boutonActif === button.value ? 'bg-blue text-white' : ''}`} onClick={() => handleClick(button.value)}>{button.title}</div>
                        </Col>
                    ))}

                    <Col className='my-1' xs="3" md="2" lg="2">
                        {boutonActif === "multiple" && (
                            <div className='border rounded-5 text-center p-2' style={{ cursor: "pointer" }} onClick={handleAddMultiDestination}>
                                <Plus size={20} /> {t('add_flight')}
                            </div>
                        )}
                    </Col>
                </Row>

                <>
                    {boutonActif === 'oneway' && <div className='py-2'>
                        <form action='/flights/search'>

                            <OneWay />

                            <div className="container d-flex justify-content-end">
                                <div>
                                    <button className="btn-blue p-4 px-5 rounded-5 my-2"><Search size={20} /> {t('search')}</button>
                                </div>
                            </div>

                        </form>
                    </div>}
                    {boutonActif === 'roundtrip' && <div className='py-2'>
                        <RoundTrip /></div>}
                    {boutonActif === 'multiple' && <form className='d-flex flex-column' action='/flights/search'>

                        <div className='my-2'>
                            <OneWay />
                        </div>

                        <div className='my-2'>
                            <OneWay />
                        </div>

                        {multiDestinationComponents.map((component, index) => (

                            <div className='d-flex flex-column rounded' key={index}>
                                {component}
                                <div className='my-2 d-flex justify-content-end'>
                                    <div className='border text-danger p-2 rounded-5' style={{ cursor: 'pointer' }} onClick={() => handleRemoveMultiDestination(index)}><Trash2 size={20} /> <span className='text-dark'>{t('delete')}</span></div>
                                </div>
                            </div>
                        ))}

                        <div className="container d-flex justify-content-end">
                            <button className="btn-blue p-4 px-5 rounded-5 my-2"><Search size={20} /> {t('search')}</button>
                        </div>
                    </form>}
                </>
            </div>
        </>
    );
}

export default FlightAvailability;