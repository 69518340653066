import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import HeaderConn from "./HeaderConn";

function Connexion(){
    return(
        
        <div style={{}}>
           
            <Container fluid className="sdxc">
                <Row>
                    <Col className="imgconn">

                    </Col>

                    <Col style={{display:'flex',flexDirection:'column'}}>
                    <HeaderConn />
                            <Col style={{margin:'auto'}}>
                                <Col style={{paddingTop:'10vw', paddingLeft:'3vw'}}>
                                    <a href="./Connexion" className="SubHeadercc">
                                        <span style={{}} className="SubHeaderSpan11">HOTELS</span>
  
                                    </a>
                                    <a href="./Agency" className="SubHeadercc">
                                        <span style={{}} className="SubHeaderSpan12">TRAVEL AGENCIES</span>
  
                                   </a>
                                 
                                </Col> 
                            </Col>
                                <Col style={{marginTop:'-12vw',}}>
                                        <Form > 
                                            <Form.Group style={{width:'70%',margin:'auto', display:'flex',flexDirection:'row'}}  className="mb-3" controlId="formBasicEmail">
                                               
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop:'7px', marginRight:'15px'}}  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg> <Form.Control type="email"  placeholder= "Username" />
                                                <Form.Text className="text-muted">
                                               
                                                </Form.Text>
                                            </Form.Group>
                                            
                                            <Form.Group style={{width:'70%',margin:'auto', display:'flex',flexDirection:'row'}}  className="mb-3" controlId="formBasicEmail">
                                            <svg xmlns="http://www.w3.org/2000/svg"  style={{marginTop:'7px', marginRight:'15px'}} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mails"><rect width="16" height="13" x="6" y="4" rx="2"/><path d="m22 7-7.1 3.78c-.57.3-1.23.3-1.8 0L6 7"/><path d="M2 8v11c0 1.1.9 2 2 2h14"/></svg>
                                                <Form.Control type="email" placeholder="Enter email" />
                                                <Form.Text className="text-muted">
                                               
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group style={{width:'70%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3" controlId="formBasicPassword">
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop:'7px', marginRight:'15px'}} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lock-keyhole"><circle cx="12" cy="16" r="1"/><rect x="3" y="10" width="18" height="12" rx="2"/><path d="M7 10V7a5 5 0 0 1 10 0v3"/></svg>
                                                <Form.Control type="password" placeholder="Password" />
                                                </Form.Group>
                                            <br/>

                                                <span style={{margin:'auto',textAlign:'center',display:'flex',flexDirection:'column'}}>
                                                    <Button href="/Platform"  type="submit" id='bdd' style={{margin:'auto', paddingTop:'10px',paddingBottom:'10px', paddingLeft:'6vw', paddingRight:'6vw',border:'none'}}>
                                                        Enter
                                                    </Button>
                                            
                                                    <br/>
                                                    <a href="#"  className="ba" style={{textAlign:'center'}}>Forgot your password?</a>
                                                </span>
                                                
                                        </Form> 
                                </Col> 
                                
                                    
                               
                    </Col>
                </Row>
            </Container>
           
           
        </div>
    );
}

export default  Connexion;