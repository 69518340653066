import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import TopBanner from '../../components/TopBanner'

const Wishlist = () => {

    const [boutonActif, setBoutonActif] = useState('hotel');

    const handleClick = (bouton) => {
        setBoutonActif(bouton);
    };

    return (
        <section>
            <TopBanner data="Wishlist" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />
            <div className='container py-5'>
                <h2 className='my-3 text-dark text-center'>Wishlist</h2>
                <Row className='align-items-center justify-content-center mt-4'>
                    <Col xs="3" md="2" xl='1' className='justify-content-center my-1'>
                        <button className='w-100 border py-2 rounded-3' onClick={() => handleClick('hotel')} style={{ fontSize: "16px", fontWeight: "500", background: boutonActif === 'hotel' ? '#1a536d' : 'white', color: boutonActif === 'hotel' ? 'white' : '#212529' }}>
                            Hotel
                        </button>
                    </Col>
                    <Col xs="3" md="2" xl='1' className='justify-content-center my-1'>
                        <button className='w-100 border py-2 rounded-3' onClick={() => handleClick('flight')} style={{ fontSize: "16px", fontWeight: "500", background: boutonActif === 'flight' ? '#1a536d' : 'white', color: boutonActif === 'flight' ? 'white' : '#212529' }}>
                            Flight
                        </button>
                    </Col>
                    <Col xs="3" md="2" xl='1' className='justify-content-center my-1'>
                        <button className='w-100 border py-2 rounded-3' onClick={() => handleClick('activity')} style={{ fontSize: "16px", fontWeight: "500", background: boutonActif === 'activity' ? '#1a536d' : 'white', color: boutonActif === 'activity' ? 'white' : '#212529' }}>
                            Activity
                        </button>
                    </Col>
                    <Col xs="3" md="2" xl='1' className='justify-content-center my-1'>
                        <button className='w-100 border py-2 rounded-3' onClick={() => handleClick('rental')} style={{ fontSize: "16px", fontWeight: "500", background: boutonActif === 'rental' ? '#1a536d' : 'white', color: boutonActif === 'rental' ? 'white' : '#212529' }}>
                            Rental
                        </button>
                    </Col>
                    <Col xs="3" md="2" xl='1' className='justify-content-center my-1'>
                        <button className='w-100 border py-2 rounded-3' onClick={() => handleClick('car')} style={{ fontSize: "16px", fontWeight: "500", background: boutonActif === 'car' ? '#1a536d' : 'white', color: boutonActif === 'car' ? 'white' : '#212529' }}>
                            Car
                        </button>
                    </Col>



                </Row>

                <div>
                    {boutonActif === 'hotel' && <div className='my-5 p-4 alert alert-warning'>Nothing in your hotel cart</div>}
                    {boutonActif === 'flight' && <div className='my-5 p-4 alert alert-warning'>Nothing in your flight cart</div>}
                    {boutonActif === 'activity' && <div className='my-5 p-4 alert alert-warning'>Nothing in your activity cart</div>}
                    {boutonActif === 'rental' && <div className='my-5 p-4 alert alert-warning'>Nothing in your rental cart</div>}
                    {boutonActif === 'car' && <div className='my-5 p-4 alert alert-warning'>Nothing in your car cart</div>}
                </div>
            </div>
        </section>
    )
}

export default Wishlist