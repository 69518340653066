import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import data from '../../data/rentalsdata.json'
import { Star, Heart, MapPin } from 'lucide-react'
import Pagination from '../../components/Pagination';
import { useTranslation } from 'react-i18next';

const GridDisplay = () => {

    const { t } = useTranslation();

    const renderSpecificItems = (items) => {
        return (
            <>
                <Row className='mt-3'>
                    {items.map((rental, index) => (
                        <Col key={index} sm="6" lg="4" className="my-3">
                            <a href={`/rentals/${rental.id}`} className="text-dark text-decoration-none">
                                <div className='bg-white rounded-4 border recommendations-item position-relative' style={{ overflow: "hidden", minHeight: "398px", maxHeight: "398px" }}>
                                    <div style={{ overflow: "hidden" }}>
                                        <img alt={`Vue d'ensemble de l'hôtel - ${rental.name}`} className='recommendations-item-img' width="100%" height="190" src={rental.img} />
                                    </div>
                                    <div className="position-absolute" title='Add to whishlist' style={{ top: "20px", right: "15px" }}>
                                        <Heart fill='#00000036' size={30} strokeWidth={1.3} color="white" />
                                    </div>
                                    <div className='p-3'>
                                        <div className='d-flex align-items-center'><MapPin strokeWidth={1.5} size={15} /><span className='mx-1'>{rental.location}</span></div>
                                        <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{rental.name}</div>

                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark'>{rental.note}</span><span>(3 {t('reviews')})</span></div>
                                        </div>
                                        <hr className='border-secondary' />
                                        <div className='mt-4'>
                                            {t('from')}: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€{rental.price}</span> /{t('night')}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </Col>
                    ))}
                </Row>
            </>
        );
    };

    return (
        <>
            <Pagination data={data.rentals} itemsPerPage={15} renderItems={renderSpecificItems} />
        </>
    )
}

export default GridDisplay