import { Row, Col } from 'react-bootstrap'
import ActivityItem from '../data/activitiesdata.json'
import { MapPin, Star, Clock, Heart } from 'lucide-react'
import { useTranslation } from 'react-i18next';

const ActivitiesRecommendations = () => {

    const ActivitiesResults = ActivityItem.activities.slice(0, 6);
    const { t } = useTranslation();

    return (
        <>
            <Row className='my-3'>
                {ActivitiesResults.map((activity, index) => (
                    <Col key={index} md="6" xl="4" className='my-3'>
                        <a href={`/activities/${activity.id}`} className="text-dark">
                            <div className='bg-white rounded-4 border recommendations-item position-relative' style={{ overflow: "hidden" }}>
                                <div style={{ overflow: "hidden" }}>
                                    <img alt={`Vue d'ensemble de l'activité ${activity.name}`} className='recommendations-item-img' width="100%" height="276" src={activity.img} />
                                </div>
                                <div className="position-absolute" title='Add to whishlist' style={{ top: "20px", right: "15px" }}>
                                    <Heart fill='#00000036' size={30} strokeWidth={1.3} color="white" />
                                </div>
                                <div className='p-3'>
                                    <div className='d-flex align-items-center'><MapPin strokeWidth={1.5} size={15} /><span className='mx-1'>{activity.country}</span></div>
                                    <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{activity.name}</div>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark'>{activity.note}</span><span>({activity.avis} {t('reviews')})</span></div>
                                    </div>
                                    <hr className='border-secondary' />
                                    <div className='mt-5 d-flex justify-content-between align-items-center'>
                                        <div>{t('from')}: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€{activity.price}</span></div>
                                        <div className='d-flex justify-content-between align-items-center'><Clock strokeWidth={1} size={20} /><span className='mx-2'>{activity.duration}</span></div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default ActivitiesRecommendations