import { Uploader } from 'rsuite';
import { Row, Col } from 'react-bootstrap'
import Recap from './Recap';
import TopBanner from '../../../components/TopBanner';
import { Slider } from 'rsuite';
import { useState } from 'react';

const Photos = () => {

    const defaultPrice = 17
    const [person, setNight] = useState(1);

   
    const handleChange = (value) => {
        setNight(value);
    };

    const renderTooltip = () => {
        return (
          <div className='d-flex'>
                {person} {person > 1 ? <span className='mx-1'> persons</span> : <span className='mx-1'> person</span>} 
          </div>
        );
      };

    return (
        <section>
            <TopBanner data="Prices & photos" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/accommodation/review'>
                            <fieldset>
                                <legend className='fs-2 fw-medium text-dark'>Set your price and upload your photos</legend>
                                <p className='fs-6'>Correct prices and relevant photos potentially increase your bookings and earnings.</p>

                                <div className='mt-5 d-flex flex-column align-items-center'>
                                    <div className='fs-3 fw-bold text-blue'>You could earn</div>
                                    <div className='fs-1 fw-medium text-dark'>${person*defaultPrice}</div>
                                    <p className='my-3 fs-6'>For {person} {person > 1 ? <span>persons</span> : <span>person</span>} with an estimated average price of $17 per person</p>
                                    <Slider renderTooltip={renderTooltip} value={person} onChange={handleChange} step={1} min={1} max={35} style={{ width: 200 }} />
                                </div>

                                <div className='my-4'>
                                    <Uploader type="file" draggable>
                                        <div className='border' style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span>Click or Drag files to this area to upload</span>
                                        </div>
                                    </Uploader>

                                </div>
                                <button type='submit' className='btn-blue p-3 px-5 rounded-5 text-white'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Photos