import { Row, Col } from 'react-bootstrap'
import HotelItem from '../../data/availability.json'
import { Star, Heart, MapPin } from 'lucide-react'
import { useTranslation } from 'react-i18next';

const Hotels = () => {

    const HotelsResults = HotelItem.hotels.filter(hotel => hotel.trip_advisor_rating > 4);
    const limitedResults = HotelsResults.slice(0, 6);
    const { t } = useTranslation();

    return (
        <>
            <Row className='my-3'>
                {limitedResults.map((hotel, index) => (
                    <Col key={index} md="6" xl="4" className='my-3'>
                        <div className='bg-white rounded-4 recommendations-item border position-relative' style={{ overflow: "hidden" }}>
                            <div style={{ overflow: "hidden" }}>
                                <img alt={`Illustration de l'hôtel - ${hotel.name}`} className='recommendations-item-img' width="100%" height="276" src={hotel.image} />
                            </div>
                            <div className="position-absolute" title='Add to whishlist' style={{ top: "20px", right: "15px" }}>
                                <Heart fill='#00000036' size={30} strokeWidth={1.3} color="white" />
                            </div>
                            <div className='p-3'>
                                <div className='d-flex align-items-center'><MapPin strokeWidth={1.5} size={15} /><span className='mx-1'>{hotel.city_name}</span></div>
                                <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{hotel.name}</div>
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark'>{hotel.category}</span><span>(3 {t('reviews')})</span></div>
                                </div>
                                <hr className='border-secondary' />
                                <div className='mt-4'>
                                    {t('from')}: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€150.00</span> /{t('night')}
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default Hotels