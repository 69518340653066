import SubHeaderPlatform from '../components/SubHeaderPlatform'
import HeaderDetails from '../components/HeaderDetails'



function Header() {

  return (
    <div>
            <SubHeaderPlatform />
            <HeaderDetails />
    </div>
  );
  
 
}

export default Header;