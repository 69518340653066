import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import Input from '../../../components/forms/Input';
import { Lightbulb } from 'lucide-react';
import Select from '../../../components/forms/Select';
import ExtraPrices from './ExtraPrices'
import Choice from './Choice';
import supplierData from '../../../data/globaldata.json'

const DetailsPricing = () => {

    const [ratesType, setRatesType] = useState('everyday');
    const [extraPeople, setExtraPeople] = useState('no');
    const [extraType, setExtraType] = useState('amount');


    const handleRatesType = (e) => {
        setRatesType(e.target.value);
    };

    const handleExtraPeople = (value) => {
        setExtraPeople(value);
    };

    const handleExtraType = (e) => {
        setExtraType(e.target.value);
    };

    return (
        <div className='fs-6'>
            <Row className='fs-5 align-items-center'>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <span className='fs-6 fw-medium text-dark'>How do you want to provide your rates?</span>
                </Col>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <div className='d-flex align-items-center mx-3'>
                        <input value="everyday" checked={ratesType === 'everyday'} onChange={handleRatesType} id='everyday' type="radio" name="ratestype" />
                        <label htmlFor='everyday' className='mx-2'>For everyday</label>
                    </div>

                    <div className='d-flex align-items-center mx-3'>
                        <input value="week" checked={ratesType === 'week'} onChange={handleRatesType} id='week' type="radio" name="ratestype" />
                        <label htmlFor='week' className='mx-2'>By day week</label>
                    </div>
                </Col>
            </Row>

            {ratesType === 'everyday' ?
                <>
                    <Row>
                        <Col lg="8" className='my-2'>
                            <Input placeholder="Base price" type="number" />
                        </Col>
                        <Col lg="4" className='my-2'>
                            <select className='input-blue w-100 p-4 border rounded'>
                                <option>01 person</option>
                            </select>
                        </Col>
                    </Row>

                </>
                : ratesType === 'week' ?

                    <>

                        <div className='d-flex my-3'><Lightbulb size={40} /><p className='px-3'>We have set up rates based on the day of the week for your accommodation. You can modify them on the Rates and Availability page before or after publishing your accommodation listing online.</p></div>
                        <Row>
                            {supplierData.week_days.map((day, index) => (
                                <Col key={index} xl="3" className='my-2'>
                                    <Input placeholder={day} type="number" />
                                </Col>
                            ))}
                        </Row>
                    </>

                    : ''
            }

            <Choice text="Do you require a deposit ?" onChange={handleExtraPeople} selectedChoice={extraPeople} />

            {extraPeople === 'yes' ?
                <>
                    <Row>
                        <Col sm="4" className='my-2'>
                            <Select data={supplierData.extra_type} value={extraType} handleChange={handleExtraType} />
                        </Col>
                        <Col sm="4" className='my-2'>
                            <Input type="number" placeholder={`Adults ${extraType === "amount" ? "$" : '%'}`} />
                        </Col>
                        <Col sm="4" className='my-2'>
                            <Input type="number" placeholder={`Childs ${extraType === "amount" ? "$" : '%'}`} />
                        </Col>
                    </Row>
                </>

                : ''
            }

            <hr className='border-secondary' />

            <ExtraPrices />

        </div>
    )
}

export default DetailsPricing