import { Check, X } from 'lucide-react'
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import { Panel, PanelGroup } from 'rsuite';
import TopBanner from '../../../components/TopBanner';
import PublishCelebration from '../PublishCelebration';

const Review = () => {

    const [values, setValues] = useState({
        activity_name: '',
        activity_location: '',
        activity_type: '',
        activity_intro: '',
        activity_description: '',
        activity_highlights: [],
        activity_inclusions: '',
        activity_exclusions: '',
        activity_guide: '',
        activity_notsuitable: [],
        activity_notallowed: [],
        activity_whatbring: [],
        activity_lang: '',
        activity_groupsize: null,
        activity_duration: '',
        activity_custominfo: []
    });


    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedData = {
                activity_name: localStorage.getItem('activity_name') || 'Not assigned',
                activity_location: localStorage.getItem('activity_location') || 'Not assigned',
                activity_type: localStorage.getItem('activity_type') || 'Not assigned',
                activity_intro: localStorage.getItem('activity_intro') || 'Not assigned',
                activity_description: localStorage.getItem('activity_description') || 'Not assigned',
                activity_highlights: JSON.parse(localStorage.getItem('activity_highlights')) || ['Not assigned'],
                activity_inclusions: localStorage.getItem('activity_inclusions') || 'Not assigned',
                activity_exclusions: localStorage.getItem('activity_exclusions') || 'Not assigned',
                activity_guide: localStorage.getItem('activity_guide') || 'Not assigned',
                activity_notsuitable: JSON.parse(localStorage.getItem('activity_notsuitable')) || ['Not assigned'],
                activity_notallowed: JSON.parse(localStorage.getItem('activity_notallowed')) || ['Not assigned'],
                activity_whatbring: JSON.parse(localStorage.getItem('activity_whatbring')) || ['Not assigned'],
                activity_lang: localStorage.getItem('activity_lang') || ['Not assigned'],
                activity_groupsize: localStorage.getItem('activity_groupsize') || 0,
                activity_duration: localStorage.getItem('activity_duration') || 'Not assigned',
                activity_custominfo: localStorage.getItem('activity_custominfo') || 'Not assigned',


            };

            setValues(savedData);

        }, 10);

        return () => clearInterval(intervalId);
    }, []);

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <section>
            <TopBanner data="Review" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <div>
                    <span className='fs-2 fw-bold text-dark'>Is this informations correct?</span>
                    <p className='my-1 fs-5'>If everything looks good, save and publish to set it live on IdhTours's website.</p>
                    <hr className='my-3 border-secondary' />
                    <PanelGroup accordion defaultActiveKey={1}>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Basic</div>} eventKey={1} id="panel1" defaultExpanded>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Title</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.activity_name}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Location</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.activity_location}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Type</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.activity_type}</span>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Informations</div>} eventKey={2} id="panel2">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Group size</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.activity_groupsize}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Duration</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.activity_duration}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Introduction</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.activity_intro}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Description</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <p>{values.activity_description}</p>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Highlights</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <ul>
                                        {values.activity_highlights.map((high, index) => (
                                            <li key={index}>{high}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Attributes</div>} eventKey={3} id="panel3">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Languages</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.activity_lang}</span>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Inclusions</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    {values.activity_inclusions.split('\n').map((item, index) => (
                                        <div key={index} className='my-1 text-success'><Check size={15} /> <span className='text-dark'>{item}</span></div>
                                    ))}
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Exclusions</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    {values.activity_exclusions.split('\n').map((item, index) => (
                                        <div key={index} className='my-1 text-danger'><X size={15} /> <span className='text-dark'>{item}</span></div>
                                    ))}
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Guide </span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.activity_guide}</span>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<div className='fs-5 fw-bold text-dark'>Additional</div>} eventKey={4} id="panel4">
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Not suitable for</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <ul>
                                        {values.activity_notsuitable.map((tag, index) => (
                                            <li key={index}>{tag}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>What to bring</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <ul>
                                        {values.activity_whatbring.map((tag, index) => (
                                            <li key={index}>{tag}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Not allowed</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <ul>
                                        {values.activity_notallowed.map((tag, index) => (
                                            <li key={index}>{tag}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>

                            <Row className='border fs-6'>
                                <Col md="4" className='border-end p-3'>
                                    <span className='fw-bold text-dark'>Custom informations</span>
                                </Col>
                                <Col md="8" className='p-3'>
                                    <span>{values.activity_custominfo}</span>
                                </Col>
                            </Row>
                        </Panel>

                        <Panel header={<div className='fs-5 fw-bold text-dark'>Photos</div>} eventKey={4} id="panel4">
                            Empty
                        </Panel>

                    </PanelGroup>

                </div>
                <PublishCelebration showModal={showModal} closeModal={closeModal} />
                <button onClick={openModal} className='btn-blue p-3 px-5 rounded-5 text-white my-5'>Publish</button>
            </div>
        </section>
    )
}

export default Review