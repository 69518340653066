import { X } from 'lucide-react';
import React from 'react'
import { useState } from 'react';
import supplierData from '../../../data/globaldata.json'

const AddedValue = () => {

    const [selectedValues, setSelectedValues] = useState([]);
    const [availableOptions, setAvailableOptions] = useState(supplierData.options);
    const [selectedOption, setSelectedOption] = useState('');

    const handleSelectChange = (event) => {
        const value = event.target.value;

        if (!selectedValues.includes(value)) {
            setSelectedValues([...selectedValues, value]);
            setAvailableOptions(availableOptions.filter(option => option !== value));
        }

        setSelectedOption('');
    };

    const handleDelete = (value) => {
        const updatedList = selectedValues.filter((item) => item !== value);
        setSelectedValues(updatedList);

        setAvailableOptions([...availableOptions, value]);
    };

    return (
        <>
            <span className='fs-6 text-blue'>Items included in the price :</span>
            <select value={selectedOption} onChange={handleSelectChange} className='input-blue w-100 p-4 border rounded my-2'>
                <option value="">-- Choose an option --</option>
                {availableOptions.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <div className='my-3'>
                <>
                    {selectedValues.map((value) => (
                        <div key={value} className='d-flex align-items-center my-2'>
                            <span className='p-3 border'>{value}</span>
                            <span role='button' onClick={() => handleDelete(value)} className='text-danger mx-2'><X/></span>
                        </div>
                    ))}
                </>
            </div>
        </>
    );
};

export default AddedValue