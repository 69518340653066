import { Row, Col } from 'react-bootstrap'
import RentalItem from '../../data/rentalsdata.json'
import { MapPin, Users, Bed, Bath, Star, Heart } from 'lucide-react'
import { useTranslation } from 'react-i18next';

const RentalRecommendations = () => {

    const RentalResults = RentalItem.rentals.slice(0, 6);
    const { t } = useTranslation();

    return (
        <>
            <Row className='my-3'>
                {RentalResults.map((rental, index) => (
                    <Col key={index} md="6" xl="4" className='my-3'>
                        <div className='text-dark bg-white rounded-4 border recommendations-item position-relative' style={{ overflow: "hidden" }}>
                            <div style={{ overflow: "hidden", background: "#F3F4F6" }}>
                                <img alt={`Vue d'ensemble de l'hébergement - ${rental.name}`} className='recommendations-item-img' width="100%" height="100%" src={rental.img} />
                            </div>
                            <div className="position-absolute" title='Add to whishlist' style={{ top: "20px", right: "15px" }}>
                                <Heart fill='#00000036' size={30} strokeWidth={1.3} color="white" />
                            </div>
                            <div className='p-3'>
                                <div className='d-flex align-items-center'><MapPin strokeWidth={1.5} size={15} /><span className='mx-1'>{rental.location}</span></div>
                                <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{rental.name}</div>

                                <div className='d-flex align-items-center my-3'>
                                    <div className='d-flex align-items-center me-3'>
                                        <div className='me-2'><Users strokeWidth={1} size={20} /></div>
                                        <div>{rental.peoples}</div>
                                    </div>

                                    <div className='d-flex align-items-center mx-3'>
                                        <div className='me-2'><Bed strokeWidth={1} size={20} /></div>
                                        <div>{rental.beds}</div>
                                    </div>

                                    <div className='d-flex align-items-center mx-3'>
                                        <div className='me-2'><Bath strokeWidth={1} size={20} /></div>
                                        <div>{rental.bathroom}</div>
                                    </div>

                                </div>

                                <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark'>{rental.note}</span><span>({rental.reviews} {t('reviews')})</span></div>
                                </div>

                                <hr className='border-secondary' />
                                <div className='my-2'>{t('from')}: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€{rental.price}.00</span></div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default RentalRecommendations