import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { setIsLogged, setToken } from './action'
export function Session() {
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();

    if (!profile.isloged) {
        dispatch(setIsLogged(true));
        dispatch(setToken(uuidv4()));
    }






    window.lang = profile.lang
    window.sessionToken = profile.sessionToken
}

