import React, { useState } from 'react';
import Activity from '../../pages/activities/forms/Availability'
import Transfer from '../../pages/transferts/forms/Availability'
import Hotel from '../../pages/hotels/forms/Availability'
import Car from '../../pages/cars/forms/Availability'
import Flight from '../../pages/flights/forms/RoundTrip'
import Package from '../../pages/packages/forms/Availability'
import { useTranslation } from 'react-i18next';

const MainForm = () => {
    const { t } = useTranslation();
    const [boutonActif, setBoutonActif] = useState('flights');
    const handleClick = (bouton) => {
        setBoutonActif(bouton);
    };

    const buttons = [
        {title:t('flights'),value:"flights"},
        {title:t('Stays'),value:"hotels"},
        {title:t('Visits'),value:"activities"},
        {title:t('transfers'),value:"transfers"},
        {title:t('Car rental'),value:"carrental"},
        {title:t('packages'),value:"packages"},
        
    ]

    return (
        <section className='w-100'>
            <div className='d-flex align-items-center justify-content-center'>
                {buttons.map((button,index)=>(
                    <div key={index} role='button' onClick={() => handleClick(button.value)} className={`fs-6 py-1 mx-3 text-white ${boutonActif === button.value ? 'border-bottom' : ''}`}>
                        {button.title}
                    </div>
                ))}
            </div>
  
            <div className='mt-4'>
                {boutonActif === 'hotels' && <Hotel />}
                {boutonActif === 'flights' && <div className='container px-xl-5'><Flight /></div>}
                {boutonActif === 'activities' && <Activity />}
                {boutonActif === 'transfers' && <Transfer />}
                {boutonActif === 'carrental' && <Car />}
                {boutonActif === 'packages' && <Package/>}
               
            </div>
        </section>
    )
}

export default MainForm