import Service1 from "./Service1";
import Service2 from "./Service2";
import Service3 from "./Service3"
import Header from "./home/Header";
import SubHeader from "./home/SubHeader";
import NewFoot from "./home/NewFoot";


function Services(){
    return(
        
        <div>
            <SubHeader />
            <Header />
            <Service1 />
            <Service2 />
            <Service3 />
            <NewFoot />
        </div>

    );
}

export default Services;