import { Check } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import jsPDF from 'jspdf';
import TopBanner from '../../components/TopBanner'
import Proforma from '../../slide/proforma.pdf'
import Doc from '../../slide/voucher.pdf'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

function Voucher() {

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.text('Numéro de la réservation : 894847', 10, 10);
        doc.text('Type de la réservation : Hébergement', 10, 20);
        doc.text("Titre de la réservation : ...........", 10, 30);
        doc.text("Location : Algérie", 10, 40);
        doc.text('Nom : John doe', 10, 50);

        doc.save('reservation.pdf');
    };

    return (

        <div>
            <SubHeaderPlatform />
            <HeaderDetails />
            <section >
            <TopBanner data="Voucher" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container py-5'>
                <div className='shadow-sm p-md-5 rounded-5 border'>
                    <Row className='m-5 my-3'>
                        <Col xl="9" className='my-3'>
                            <Row className='d-flex align-items-center'>
                                <Col lg="3" className='d-flex justify-content-center'>
                                    <div className='p-3 rounded-circle m-4' style={{ background: "#4CBB7F", boxShadow: " 0px 0px 0px 10px #CEECD5" }}>
                                        <Check color='white' size={20} />
                                    </div>
                                </Col>
                                <Col lg="9">
                                    <div>
                                        <span className='fs-4 fw-bold text-dark'>Your order was submitted successfully!</span>
                                        <p className='fs-6 mt-2'>you must download this  : <span className='text-blue fw-bold fs-5 fw-bold mx-3' style={{ cursor: "pointer" }} ><a href={Doc} style={{color:'#861e12'}}>Voucher</a></span></p>
                                        <p className='fs-6 mt-2'>Download : <span className='text-blue fw-bold fs-5 fw-bold mx-3' ><a href={Proforma} style={{color:'#1a536d'}}>Proforma</a> </span></p>
                                    
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="3" className='my-3'>
                            <div className='fs-6'>
                                <div className='d-flex justify-content-between my-2'>
                                    <span>Booking Number:</span>
                                    <span className='text-dark fw-medium'>16421</span>
                                </div>
                                <div className='d-flex justify-content-between my-2'>
                                    <span>Booking Date:</span>
                                    <span className='text-dark fw-medium'>12/09/2023</span>
                                </div>
                                <div className='d-flex justify-content-between my-2'>
                                    <span>Payment Method:</span>
                                    <span className='text-dark fw-medium'>Bank Transfer</span>
                                </div>
                                <div className='d-flex justify-content-between my-2'>
                                    <span>Status:</span>
                                    <span className='text-dark fw-medium'>Confirmed</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row className='mt-5'>
                    <Col xs="12" className='h-100 my-1'>
                        <div>
                            <h3 className='text-dark'>Your Information</h3>
                            <div className='rounded-4 border mt-3 p-4 fs-6 h-100'>
                                <Row>
                                    <Col xs="4">
                                        <span>Gender</span>
                                    </Col>
                                    <Col xs="4">
                                        <span className='text-dark fw-medium fs-6'>Mr</span>
                                    </Col>
                                </Row>

                                <hr className='border-secondary my-4' />
                                <Row>
                                    <Col xs="4">
                                        <span>First Name</span>
                                    </Col>
                                    <Col xs="4">
                                        <span className='text-dark fw-medium fs-6'>john</span>
                                    </Col>
                                </Row>

                                <hr className='border-secondary my-4' />

                                <Row>
                                    <Col xs="4">
                                        <span>Last Name</span>
                                    </Col>
                                    <Col xs="4">
                                        <span className='text-dark fw-medium fs-6'>smith</span>
                                    </Col>
                                </Row>

                                <hr className='border-secondary my-4' />

                                <Row>
                                    <Col xs="4">
                                        <span>Email</span>
                                    </Col>
                                    <Col xs="4">
                                        <span className='text-dark fw-medium fs-6'>happyone1200@gmail.com</span>
                                    </Col>
                                </Row>

                                <hr className='border-secondary my-4' />

                                <Row>
                                    <Col xs="4">
                                        <span>Phone</span>
                                    </Col>
                                    <Col xs="4">
                                        <span className='text-dark fw-medium fs-6'>0552154876</span>
                                    </Col>
                                </Row>

                                <hr className='border-secondary my-4' />

                                <Row>
                                    <Col xs="4">
                                        <span>Country</span>
                                    </Col>
                                    <Col xs="4">
                                        <span className='text-dark fw-medium fs-6'>Algérie</span>
                                    </Col>
                                </Row>

                                <hr className='border-secondary my-4' />

                                <Row>
                                    <Col xs="4">
                                        <span>City</span>
                                    </Col>
                                    <Col xs="4">
                                        <span className='text-dark fw-medium fs-6'>Tizi ouzou</span>
                                    </Col>
                                </Row>

                                <hr className='border-secondary my-4' />

                                <Row>
                                    <Col xs="4">
                                        <span>Special Requirements</span>
                                    </Col>
                                    <Col xs="4">
                                        <span className='text-dark fw-medium fs-6'>Content</span>
                                    </Col>
                                </Row>

                            </div>

                        </div>
                    </Col>

                </Row>
            </div>
        </section>
        </div>
        
    );
}

export default Voucher;