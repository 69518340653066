import Availability from './forms/Availability'
import Articles from '../../components/Articles'
import Packages from '../../components/recommendations/Packages'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

const Home = () => {
    return (
        <div>
             <SubHeaderPlatform />
             <HeaderDetails />
             <section className='bg-body-tertiary'>
            <div className="pt-5 backgrounded-container">
                <div className="mt-5 pt-5 pb-3">
                    <div className='my-5'>
                        <Availability />
                    </div>
                </div>
            </div>
            <div className="container-xl py-5">
                <>
                    <div className='text-dark fs-1 fw-medium text-center'>Our best sellers</div>
                    <p className="my-1 fs-6 text-center">Find your package</p>
                    <Packages />
                </>
                <Articles />
            </div>
             </section>
        </div>
        
    )
}

export default Home