import { Row, Col } from 'react-bootstrap';
import data from '../../data/transfersdata.json'
import { Luggage, MapPin, Users } from 'lucide-react'
import Pagination from '../../components/Pagination';

const FlexDisplay = () => {

  const renderSpecificItems = (items) => {
    return (
      <>
        <Row className='mt-3'>
          {items.map((car, index) => (
            <Col key={index} xs="12" className="my-2">
              <div className='p-3 bg-white rounded-4 border recommendations-item' style={{ overflow: "hidden" }}>
                <Row>
                  <Col md="4">
                    <div className="rounded-4" style={{ overflow: "hidden" }}>
                      <img alt={`Ilustration du véhicule - ${car.name}`} className='recommendations-item-img' width="100%" height="100%" src={require(`../../${car.img}`)} />
                    </div>
                  </Col>

                  <Col sm="7" md="5" className='d-flex flex-column justify-content-center'>
                    <div className='p-3 border-end'>
                      <div className='d-flex align-items-center'><MapPin strokeWidth={1} size={20} />
                        <span className='mx-1'>{car.depart}</span>
                      </div>

                      <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{car.name}</div>

                      <div className='d-flex align-items-center my-3'>
                        <div className='d-flex flex-column align-items-center'>
                          <div className='border p-3 rounded my-1'><Users strokeWidth={1} size={20} /></div>
                          <div>{car.passengers}</div>
                        </div>

                        <div className='d-flex flex-column align-items-center mx-3'>
                          <div className='border p-3 rounded my-1'><Luggage strokeWidth={1} size={20} /></div>
                          <div>{car.bagage}</div>
                        </div>
                      </div>

                      <p className='my-3 fs-6'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                  </Col>

                  <Col sm="5" md="3" className='p-3 py-4'>
                    <div className='d-flex flex-column align-items-center justify-content-center h-100'>
                      <div>
                        <div className='mt-4'>
                          From: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€150.00</span> /day
                        </div>
                        <button className='btn-blue my-3 py-3 px-4 rounded-5 fw-bold'>View details</button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

            </Col>
          ))}
        </Row>
      </>
    );
  };

  return (
    <>
      <Pagination data={data.transfers} itemsPerPage={8} renderItems={renderSpecificItems} />
    </>
  )
}

export default FlexDisplay