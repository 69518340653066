import { MinusCircle, PlusCircle } from 'lucide-react'
import Recap from './Recap'
import { Row, Col } from 'react-bootstrap'
import Textarea from '../../../components/forms/Textarea';
import { useReducer } from 'react';
import TopBanner from '../../../components/TopBanner';
import Highlights from '../../../components/forms/Highlights';

const informations = {
    package_groupsize: 1,
    package_duration: '02 - 04 days',
    package_intro: '',
    package_description: '',
    package_highlights: ['', '', '', ''],
};


const reducer = (state, action) => {

    const durations = ['02 - 04 days', '04 - 07 days', '07 - 14 days', '14 days+'];
    const currentIndex = durations.indexOf(state.package_duration);

    switch (action.type) {

        case 'INCREMENT_GROUP':
            const incrementedGroup = state.package_groupsize + action.payload;
            localStorage.setItem('package_groupsize', incrementedGroup);
            return { ...state, package_groupsize: incrementedGroup };

        case 'DECREMENT_GROUP':
            const decrementGroup = state.package_groupsize - action.payload;
            localStorage.setItem('package_groupsize', decrementGroup);
            return { ...state, package_groupsize: decrementGroup };

        case 'INCREMENT_DURATION':
            const nextIndex = (currentIndex + 1) % durations.length;
            const nextDuration = durations[nextIndex];
            localStorage.setItem('package_duration', nextDuration);
            return { ...state, package_duration: nextDuration };

        case 'DECREMENT_DURATION':
            const prevIndex = (currentIndex - 1 + durations.length) % durations.length;
            const prevDuration = durations[prevIndex];

            localStorage.setItem('package_duration', prevDuration);
            return { ...state, package_duration: prevDuration };

        case 'SET_INTRO':
            localStorage.setItem('package_intro', action.payload);
            return { ...state, package_intro: action.payload };

        case 'SET_DESCRIPTION':
            localStorage.setItem('package_description', action.payload);
            return { ...state, package_description: action.payload };

        case 'SET_HIGHLIGHT':
            const updatedPackHighlights = state.package_highlights.map((highlight, index) => {
                return index === action.payload.index ? action.payload.value : highlight;
            });
            localStorage.setItem('package_highlights', JSON.stringify(updatedPackHighlights));
            return { ...state, package_highlights: updatedPackHighlights };

        default:
            return state;
    }
};

const Informations = () => {

    const [state, dispatch] = useReducer(reducer, informations);

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    const handleHighlightsChange = (type, index) => (e) => {
        dispatch({ type, payload: { index, value: e.target.value } });
    };

    const handleIncrement = (type) => () => {
        dispatch({ type, payload: 1 });
    };

    const handleDecrement = (type) => () => {
        dispatch({ type, payload: 1 });
    };


    const handleIncrementDuration = () => {
        dispatch({ type: 'INCREMENT_DURATION' });
    };

    const handleDecrementDuration = () => {
        dispatch({ type: 'DECREMENT_DURATION' });
    };

    return (
        <section>
            <TopBanner data="Informations" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/package/attributes'>

                            <fieldset>
                                <legend className='fs-2 text-dark fw-medium'>Main informations about your package</legend>
                                <p className='my-3 fs-6'>Specify the maximum number of people your package can accommodate, the duration, and describe her to Make your ad stand out from the crowd</p>

                                <div className='my-3'>
                                    <div className="d-flex justify-content-between py-4 border-bottom fs-6">

                                        <div className="text-dark fs-5">Group size</div>

                                        <div className="d-flex align-items-center">
                                            <button type='button' className="mx-3 bg-white" onClick={handleDecrement('DECREMENT_GROUP')} disabled={state.package_groupsize < 2}><MinusCircle color="#1a536d" strokeWidth={1} size={40} /></button>

                                            <input className='border-0 text-center fs-5' value={state.package_groupsize} type="number" readOnly style={{ width: "100px" }} />

                                            <button type='button' className="mx-3 bg-white" onClick={handleIncrement('INCREMENT_GROUP')} disabled={state.package_groupsize > 49}><PlusCircle size={40} color="#1a536d" strokeWidth={1} /></button>
                                        </div>
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <div className="d-flex justify-content-between py-4 border-bottom fs-6">

                                        <div className="text-dark fs-5">Duration</div>

                                        <div className="d-flex align-items-center">
                                            <button type='button' className="mx-3 bg-white" onClick={handleDecrementDuration}><MinusCircle color="#1a536d" strokeWidth={1} size={40} /></button>

                                            <span className='border-0 text-center fs-6' style={{ width: "100px" }}>{state.package_duration}</span>


                                            <button type='button' className="mx-3 bg-white" onClick={handleIncrementDuration}><PlusCircle size={40} color="#1a536d" strokeWidth={1} /></button>
                                        </div>
                                    </div>
                                </div>

                                <div className='my-3'>
                                    <Textarea rows={4} placeholder='Create your introduction, this will be the first thing customers read after the title, and will inspire them to continue.' value={state.package_intro} handleChange={handleChange('SET_INTRO')} maxlength="220" />
                                    <div>{state.package_intro.length === 220 ? <span className='text-danger fs-6'>Your introduction must not exceed 220 characters</span> : ''}</div>
                                    <div>{state.package_intro.length} / 220</div>
                                </div>

                                <div className='my-4'>
                                    <Textarea rows={10} placeholder='Create your description and provide all the details about what the customer will see and do during the package.' value={state.package_description} handleChange={handleChange('SET_DESCRIPTION')} />
                                </div>

                                <div className='my-3'>
                                    <Highlights state={state.package_highlights} handleChange={handleHighlightsChange} />
                                </div>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Informations