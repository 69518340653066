import { Plus } from 'lucide-react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Guests({ showGuests, handleCloseGuests, showbtnadd, addRoom, rooming, roomsCount }) {

    const { t } = useTranslation();

    return (
        <>
            <Modal show={showGuests} onHide={handleCloseGuests} backdrop="static" keyboard={false}>
                <div className='bg-white text-dark rounded-4'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className='d-flex align-items-center'>
                                <span>{t('total_rooms')} : {roomsCount}</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='p-3'>
                            <Row>
                                <Col xs="12">
                                    <label htmlFor='nation' className='fw-bold fs-6'>{t('nationality')}</label>
                                    <select id='nation' className='p-3 border input-blue w-100 mt-1 rounded'>
                                        <option>ALGERIA</option>
                                        <option>ALBANIA</option>
                                        <option>AUSTRIA</option>
                                    </select>
                                </Col>
                            </Row>
                            <div className='d-flex flex-column my-3'>
                                {rooming()}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='mx-3'>
                            {showbtnadd && (
                                <div className='border rounded-5 text-center p-3 px-4 ' style={{ cursor: "pointer" }} onClick={addRoom}>
                                    <Plus size={20} /> {t('add_room')}
                                </div>
                            )}
                        </div>
                        <button className='btn-blue p-3 px-4 rounded-5' onClick={handleCloseGuests}>
                            {t('save_changes')}
                        </button>

                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
}

export default Guests;

