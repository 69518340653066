import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid';

const RoomOption = () => {

  const [groupId] = useState(uuidv4());
  const [selectedRoom, setSelectedRoom] = useState(null);

  const handleRoomChange = (event) => {
    setSelectedRoom(event.target.id);
  };

  useEffect(() => {

    setSelectedRoom(null);
  }, []);

  return (

    <Row className='fs-5 align-items-center'>
      <Col md="6" className='d-flex align-items-center my-3'>
        <span className='fs-6 fw-medium text-dark'>Is it like that in ?</span>
      </Col>
      <Col md="6" className='d-flex align-items-center my-3'>
        <div className='d-flex align-items-center mx-3'>
          <input
            id={`everyroom_${groupId}`}
            type="radio"
            name={`roomType_${groupId}`}
            checked={selectedRoom === `everyroom_${groupId}`}
            onChange={handleRoomChange}
          />
          <label htmlFor={`everyroom_${groupId}`} className='mx-2'>Every room</label>
        </div>

        <div className='d-flex align-items-center mx-3'>
          <input
            id={`thisroom_${groupId}`}
            type="radio"
            name={`roomType_${groupId}`}
            checked={selectedRoom === `thisroom_${groupId}`}
            onChange={handleRoomChange}
          />
          <label htmlFor={`thisroom_${groupId}`} className='mx-2'>This bedroom</label>
        </div>
      </Col>
    </Row>
  )
}

export default RoomOption