import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import Button from 'react-bootstrap/Button'
import SubHeaderPlatform from '../components/SubHeaderPlatform'
import HeaderDetails from '../components/HeaderDetails'


function Trash(){
    return(
        <div>
            <div style={{marginBottom:'4vw'}} >
                        <SubHeaderPlatform />
                        <HeaderDetails />

                        <Container style={{paddingTop:'10vw',marginBottom:'2vw'}}>
                            <Row>
                                <Col sm={10} id="mlp" style={{margin:'auto', display:'flex', flexDirection:'column',borderRadius: '6px'}}>
                                

                                    <Col style={{display:'flex',backgroundColor:'white',flexDirection:'column',boxShadow: '0 0 3px 2px rgba(0, 0, 0, .05)',borderRadius: '6px',padding:'3vw 3vw 2vw 4vw'}}>
                                        <h1 style={{color:'#1a536d' ,paddingBottom:'5px',fontFamily:'Inter,sans-serif', fontSize:'30px',fontWeight:'bold',textAlign:'left'}}>
                                        Do you realy want to remove this product ! 
                                        </h1>    
                                        
                                       
                                        <span>
                                            <Button id="Bfff" href="/Removed" style={{width:'20%',marginRight:'2vw'}}>
                                                Yes
                                            </Button>
                                        
                                        </span>
                                        
                                    </Col>

                                </Col>
                               


                               
                            </Row>
                        </Container>
                        
                        
                        
            </div >        
        </div >
    );
}

export default Trash;