import Recap from './Recap'
import { Row, Col } from 'react-bootstrap'
import TopBanner from '../../../components/TopBanner';
import { Panel, PanelGroup } from 'rsuite';
import DetailsRoom from './DetailsRoom';
import DetailsBathroom from './DetailsBathroom';
import Detailskitchen from './Detailskitchen';
import DetailsLayout from './DetailsLayout';
import DetailsBeds from './DetailsBeds';
import { Bath, BedIcon, Coins, DoorOpen, Refrigerator, Settings } from 'lucide-react';
import DetailsPricing from './DetailsPricing';
import DetailsPolicy from './DetailsPolicy'

const Informations = () => {

    return (
        <section>
            <TopBanner data="Informations" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/accommodation/attributes'>
                            <fieldset>

                                <PanelGroup accordion defaultActiveKey={1} bordered className='rounded-4'>
                                    <Panel header={<div className='fw-light fs-5 d-flex align-items-center'><DoorOpen className='mx-3' /> Informations about the room</div>} eventKey={1}>
                                        <DetailsRoom />
                                        <DetailsLayout />
                                    </Panel>

                                    <Panel header={<div className='fw-light fs-5 d-flex align-items-center'><Bath className='mx-3' /> Bathroom details</div>} eventKey={2}>
                                        <DetailsBathroom />
                                    </Panel>
                                    <Panel header={<div className='fw-light fs-5 d-flex align-items-center'><Refrigerator className='mx-3' /> Kitchen</div>} eventKey={3}>
                                        <Detailskitchen />
                                    </Panel>

                                    <Panel header={<div className='fw-light fs-5 d-flex align-items-center'><BedIcon className='mx-3' /> Beds and occupancy</div>} eventKey={3}>
                                        <DetailsBeds />
                                    </Panel>

                                    <Panel header={<div className='fw-light fs-5 d-flex align-items-center'><Settings className='mx-3' /> Conditions and settings</div>} eventKey={3}>
                                        <DetailsPolicy />
                                    </Panel>

                                    <Panel header={<div className='fw-light fs-5 d-flex align-items-center'><Coins className='mx-3' /> Pricing and availability</div>} eventKey={3}>
                                        <DetailsPricing />
                                    </Panel>

                                </PanelGroup>

                                <button type='submit' className='btn-blue p-3 px-5 rounded-5 mt-3'>Continue</button>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Informations