import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../../slide/977.png";
import Button from 'react-bootstrap/Button';



function Home2(){
    return(
            <div className="qsd1111">
            <Container style={{paddingTop:'6vw',paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6">
                        <img className="img-fluid" src={SD}  alt="hotelidh" />
                    </Col>
                    <Col  xs="12"  xl="6" className="j1" style={{ marginTop:'1vw',display:'flex', flexDirection:'column'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976',textAlign:'center',fontSize:'14px'}}>B2B Travel Solutions</p>
                            <h2 className="h2join2" style={{textAlign:'center',lineHeight:'90px'}}> All-in-One <br/>Booking Platform</h2>
                          
                        </div> 
                        <div style={{marginBottom:'32px',paddingLeft:'0vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw',textAlign:'center'}}>
                            
                    
    Experience our All-in-One Booking Platform, where efficiency<br/>  meets innovation. 
    Tailored for B2B clients,our platform provides seamless access to a wide array of travel products, 
    including hotel bookings, flights, and car <br/> rentals. With advanced technology and an intuitive interface,
    we empower travel agencies and hoteliers to streamline their <br/> reservation processes 
    and enhance  their<br/> performance in a competitive market.



                            </p>
                        </div>
                            <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                    color: white;
                                   
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                          
                                    background-color:#772828;
                                    padding: 8px 40px;
                                    border-radius: 0px;
                                    font-family: 'Cormorant Garamond';
                                    
                                    }

                                    `}
                                    </style>

                                    <Button id="btnid" style={{padding:'10px',margin:'auto', fontSize:'0.9rem', width:'25%',fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                       <a href="about" className="text-white">Who We Are    </a>
                                    </Button>
                            </>
                       
                        
                    </Col>
                </Row>
            </Container>
            </div>
        
    );
}

export default Home2;