import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import Userf from "../slide/user.png"
import Userf1 from "../slide/us.png"
import Form from 'react-bootstrap/Form'
import SubHeaderPlatform from '../components/SubHeaderPlatform'
import HeaderDetails from '../components/HeaderDetails'




function AdminUser(){
    return(
        <div  >
           
                <SubHeaderPlatform />
                <HeaderDetails />
                <Container>
                            <Row>

                                <Col sm={12} className="psps" style={{marginTop:'2vw'}}  >
                                    
                                    <a href="/platform" style={{borderRight:'solid 2px #861e12',paddingRight:'1vw', color:'#1e2031'}}>
                                        <svg  style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><polyline points="9 22 9 12 15 12 15 22"/></svg>
                                    </a>

                                    <a href="#" style={{paddingLeft:'2vw'}} className="pspsps" id="ctd1">
                                        <svg style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-copy-check"><path d="m12 15 2 2 4-4"/><rect width="14" height="14" x="8" y="8" rx="2" ry="2"/><path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"/></svg>
                                        Select ALL
                                    </a> 
                                    <a href="#" style={{paddingLeft:'2vw'}} className="pspsps" id="ctd1">
                                        <svg style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-round-check"><path d="M2 21a8 8 0 0 1 13.292-6"/><circle cx="10" cy="8" r="5"/><path d="m16 19 2 2 4-4"/></svg>
                                        Activate
                                    </a> 
                                    <a href="#" style={{paddingLeft:'2vw'}} className="pspsps" id="ctd1">
                                        <svg style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-round-x"><path d="M2 21a8 8 0 0 1 11.873-7"/><circle cx="10" cy="8" r="5"/><path d="m17 17 5 5"/><path d="m22 17-5 5"/></svg>    
                                        Desactivate
                                    </a> 
                                    <a href="#" style={{paddingLeft:'2vw'}} className="pspsps" id="ctd1">
                                        <svg style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-round-minus"><path d="M2 21a8 8 0 0 1 13.292-6"/><circle cx="10" cy="8" r="5"/><path d="M22 19h-6"/></svg>
                                        Delete
                                    </a> 
                                    <a href="#" style={{paddingLeft:'2vw'}} className="pspsps" id="ctd1">
                                        <svg style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shield-check"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10"/><path d="m9 12 2 2 4-4"/></svg>
                                        Activate the booking confirmation
                                    </a> 
                                    <a href="#" style={{paddingLeft:'2vw'}} className="pspsps" id="ctd1">
                                        <svg style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shield-off"><path d="M19.7 14a6.9 6.9 0 0 0 .3-2V5l-8-3-3.2 1.2"/><path d="m2 2 20 20"/><path d="M4.7 4.7 4 5v7c0 6 8 10 8 10a20.3 20.3 0 0 0 5.62-4.38"/></svg>
                                        Desactive the booking confirmation
                                    </a> 
                                    <a href="/AddUser" style={{paddingLeft:'2vw'}} className="pspsps" id="ctd1">
                                        <svg  style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-round-plus"><path d="M2 21a8 8 0 0 1 13.292-6"/><circle cx="10" cy="8" r="5"/><path d="M19 16v6"/><path d="M22 19h-6"/></svg>
                                        Add User
                                    </a> 
                                        
                                    
                                </Col>

                            </Row>
                </Container>
                <div style={{backgroundColor: ''}}>
                <Container style={{}}>
                    <Row style={{paddingBottom:'1vw', borderRadius: '6px'}}>
                    <h1 className="h1cartt" style={{marginBottom:'1vw', color:'#505050',border:'none'}}></h1>
                    

                                    <Col sm={3} className="users1">
                                    <span style={{float:'right'}}>
                                            <Form.Check aria-label="option 1" />
                                    </span>
                                        
        
                                        <img className="jimg"  src={Userf1} alt="user" style={{borderRadius:'50%',width:'25%',}} />
                                        

                                    
                                            <h4 style={{textAlign:'center',paddingTop:'5vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                                @Agent 1 - DZ#1#16905 </p>

                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>
                                    </Col>

                                    <Col sm={3} className="users1">
                                            <span style={{float:'right'}}>
                                                    <Form.Check aria-label="option 1" />
                                            </span>

                                        <img className="jimg"  src={Userf} alt="user" style={{borderRadius:'50%',width:'25%',}} />
                                        <h4 style={{textAlign:'center',paddingTop:'5vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                            @Agent 2 - DZ#1#16905</p>

                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>
                                    </Col>



                                    <Col sm={3} className="users1">
                                        <span style={{float:'right'}}>
                                                <Form.Check aria-label="option 1" />
                                        </span>

                                        <img className="jimg"  src={Userf} alt="user" style={{borderRadius:'50%',width:'25%',}} />
                                        <h4 style={{textAlign:'center',paddingTop:'5vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                            @Agent 3 - DZ#1#16905</p>

                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>
                                    </Col>


                                    <Col sm={3} className="users1">
                                        <span style={{float:'right'}}>
                                                <Form.Check aria-label="option 1" />
                                        </span>

                                        <img className="jimg"  src={Userf1} alt="user" style={{borderRadius:'50%',width:'25%',}} />
                                        <h4 style={{textAlign:'center',paddingTop:'2vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                            @Agent 4 - DZ#1#16905 </p>

                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>
                                    </Col>
                                    <Col sm={3} className="users1">
                                        <span style={{float:'right'}}>
                                                <Form.Check aria-label="option 1" />
                                        </span>

                                        <img className="jimg"  src={Userf} alt="user" style={{borderRadius:'50%',width:'25%',}} />
                                        <h4 style={{textAlign:'center',paddingTop:'2vw',fontWeight:'bold',color:'#1e2031'}}>Jack Black</h4>
                                            <p className="uspus" style={{color:'#861e12',fontWeight:'bold',fontSize:'17px',borderBottom:'solid 1px',paddingBottom:'30px', marginBottom:'10px'}}>
                                                @Agent 5 - DZ#1#16905 </p>

                                            <span style={{color:'#505050'}}>
                                                <a className="mailpro" href="mailto:contact.admin@hotelidh.com">
                                                contact.admin@hotelidh.com</a>
                                            </span>
                                    </Col>
                                    


                                
                            
                        
                    </Row>
                </Container>
                </div>
        </div>
    );
}

export default AdminUser;