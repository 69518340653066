import Availability from './forms/Availability'
import CapitalDestination from '../../components/CapitalDestination'
import TopDestination from '../../components/home/TopDestinations'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

const Home = () => {
    return (
        <div>
                            <SubHeaderPlatform />
                            <HeaderDetails />
                            <section className='bg-body-tertiary'>
            <div className="pt-5 backgrounded-container">
                <div className="mt-5 pt-5 pb-3">
                    <div className='my-5'>
                        <Availability />
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <TopDestination/>
                <CapitalDestination/>
            </div>
                            </section>
        </div>
        
    )
}

export default Home