import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import HeaderSign from "./HeaderSign";


function SignUp(){
    return(
        
        <div style={{}}>
           
            <Container fluid className="sdxc">
                <Row>
                    <Col className="imgconn3">

                    </Col>

                    <Col style={{display:'flex',flexDirection:'column'}}>
                    <HeaderSign />
                            <div style={{}}>
                                
                                    <Col className="SubHeaderSpan111" style={{}}>
                                        <h3 style={{fontWeight:'200'}}>Registration</h3> 
  
                                        <p> (*) Required Field  </p>
                                    </Col>
                            </div>
                                <div >
                                        <Form style={{display:'flex',flexDirection:'column'}}>
                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}}  className="mb-3" controlId="formBasicEmail">
                                               
                                             <Form.Control type="email"  placeholder= "Agency or Company name *" />
                                                <Form.Text className="text-muted">
                                               
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3">
                                                
                                                <Form.Select >
                                                    <option>Agency</option>
                                                    <option>Hotel</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}}  className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="email" placeholder="Last name *" />
                                                <Form.Text className="text-muted">
                                               
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3" controlId="formBasicPassword">
                                                <Form.Control type="password" placeholder="First name * " />
                                            </Form.Group>

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3" controlId="formBasicPassword">
                                                <Form.Control type="password" placeholder="Tel * " />
                                            </Form.Group>

                                           

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3" controlId="formBasicPassword">
                                                <Form.Control type="password" placeholder="E-mail *" />
                                            </Form.Group>

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3">
                                                
                                                <Form.Select >
                                                    <option>Country</option>
                                                    <option>USA</option>
                                                    <option>Spain</option>
                                                    <option>Algeria</option>
                                                    <option>Tunisia</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3">
                                                
                                                <Form.Select >
                                                    <option>City</option>
                                                    <option>City 1</option>
                                                    <option>City 2</option>
                                                    <option>City 3</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3" controlId="formBasicPassword">
                                                <Form.Control type="password" placeholder="Address" />
                                            </Form.Group>

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3">
                                                
                                                <Form.Select >
                                                    <option>EUR-Euro</option>
                                                    <option>DZD-Dinars</option>
                                                </Form.Select>

                                            </Form.Group>
                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3">
                                                
                                                <Form.Select >
                                                    <option>English</option>
                                                    <option>French</option>
                                                    <option>Spanish</option>
                                                    
                                                </Form.Select>
                                                
                                            </Form.Group>

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3" controlId="formBasicPassword">
                                                <Form.Control type="password" placeholder="Exp: www.mywebsite.com" />
                                            </Form.Group>

                                            <Form.Group style={{width:'85%',margin:'auto', display:'flex',flexDirection:'row'}} className="mb-3">
                                                
                                                <Form.Select >
                                                    <option>How did you hear from Elcomparador-b2b ?</option> 
                                                    <option>Social Media</option>
                                                    <option>Emailing/Newsletter</option>
                                                    
                                                </Form.Select>
                                                
                                            </Form.Group>
                                            <br/>

                                            <Form.Group style={{margin:'auto',width:'85%',textAlign:'center',marginBottom:'1rem'}} className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="I agree with the Privacy Policy and how Hotelidh uses my data. I accept the Legal Notice Conditions of use." />
                                            </Form.Group>

                                           

                                            
                                            <span style={{display:'flex',flexDirection:'column', margin:'auto'}}>
                                                <Button variant="primary" type="submit" id='bdd' style={{paddingTop:'10px',paddingBottom:'10px', paddingLeft:'6vw', paddingRight:'6vw',border:'none',}}>
                                                <a href='#' className="text-white" style={{fontFamily:'initial'}}> Send    </a> 
                                                </Button> 
                                            </span>
                                            
                                            
                                        </Form> 
                                </div> 
                                
                                    
                               
                    </Col>
                </Row>
            </Container>
           
           
        </div>
    );
}

export default  SignUp;