import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import SubHeaderPlatform from '../components/SubHeaderPlatform'
import HeaderDetails from '../components/HeaderDetails'


function Removed(){
    return(
        <div>
            <div style={{marginBottom:'4vw'}} >

                        <SubHeaderPlatform />
                        <HeaderDetails />
                        <Container style={{paddingTop:'10vw',marginBottom:'2vw'}}>
                            <Row>
                                <Col sm={8} id="mlp" style={{margin:'auto', display:'flex', flexDirection:'column',borderRadius: '6px'}}>
                                

                                    <Col style={{display:'flex',backgroundColor:'white',flexDirection:'column',boxShadow: '0 0 3px 2px rgba(0, 0, 0, .05)',borderRadius: '6px',padding:'3vw 3vw 2vw 4vw'}}>
                                        <h1 style={{color:'#1a536d' ,paddingBottom:'5px',fontFamily:'Inter,sans-serif', fontSize:'30px',fontWeight:'bold',textAlign:'left'}}>
                                        <svg style={{marginRight:'2vw'}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check-check"><path d="M18 6 7 17l-5-5"/><path d="m22 10-7.5 7.5L13 16"/></svg>
                                             Your product has successfully been removed 
                                        </h1>    
                                        
                                       
                                        
                                        
                                    </Col>

                                </Col>
                               


                               
                            </Row>
                        </Container>
                        
                        
                        
            </div >        
        </div >
    );
}

export default Removed;