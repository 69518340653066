import MainForm from './MainForm'
import { useTranslation } from 'react-i18next';
import Video6 from './home/Video6';

function Background() {
    
    const { t } = useTranslation();

    return (
        <main>
            
            <div className="main-background text-white w-100 m-0" style={{ padding: "10% 0 130px 0" }}>
           
                <div className="container d-flex flex-column align-items-center pt-5">
                    <h1 className="mt-5 text-center" style={{ fontSize: "500%" }}>{t('main_title')}</h1>
                    <h2 className="fs-5 my-2 text-center fw-light">{t('main_slogan')}</h2>
                </div>
            </div>
            <div style={{ marginTop: "-110px" }} className='d-flex justify-content-center w-100'>
                <MainForm />
            </div>
        </main>
    );
}

export default Background;