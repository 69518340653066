import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import ExtraCharge from './ExtraCharge';
import Bedtype from './Bedtype';
import { Plus, Trash2 } from 'lucide-react';
import supplierData from '../../../data/globaldata.json'

const DetailsBeds = () => {

    const [babiesBeds, setBabiesBeds] = useState(false);
    const [extraBeds, setExtraBeds] = useState(false);
    const [babiescost, setBabiescost] = useState("free");
    const [bedscost, setBedscost] = useState("free");
    const [bedsAdd, setBedsAdd] = useState('yes');

    const handleBabiesBeds = () => {
        setBabiesBeds(!babiesBeds);
    };

    const handleExtraBeds = () => {
        setExtraBeds(!extraBeds);
    };

    const handleBedsAdd = (e) => {
        setBedsAdd(e.target.value);
    };

    const handleBedsCost = (e) => {
        setBedscost(e.target.value);
    };

    const handleBabiescost = (e) => {
        setBabiescost(e.target.value);
    };

    const BabiesbedsData = Array.from({ length: 20 }, (_, index) => ({
        title: (index + 1).toString(),
        value: index + 1
    }));


    const [beds, setBeds] = useState([{ id: 1, value: <Bedtype /> }]);

    const addBeds = () => {
        if (beds.length === 0) {
            setBeds([{ id: 1, value: '' }]);
        } else if (beds.length < 10) {
            const newId = beds[beds.length - 1].id + 1;
            setBeds([...beds, { id: newId, value: '' }]);
        }
    };

    const removeBeds = (id) => {
        const updatedBeds = beds.filter(bed => bed.id !== id);
        setBeds(updatedBeds);
    };

    return (
        <div className='fs-6'>
            <div className='my-3'>
                {beds.map(bed => (
                    <div className='my-3 d-flex flex-column' key={bed.id}>
                        <Bedtype />
                        {beds.length > 1 ? <div role='button' className='align-self-end mt-2 border text-danger p-2 rounded-5' onClick={() => removeBeds(bed.id)}><Trash2 size={20} /> <span className='text-dark'>Delete</span></div> : ''}
                    </div>
                ))}
                {beds.length < 10 &&
                    <span role='button' className='border rounded-5 text-dark text-center p-2 px-3' onClick={addBeds}><Plus size={20} /> Add a bed</span>
                }
            </div>

            <Row className='fs-5 align-items-center'>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <span className='fs-6 fw-medium text-dark'>Is it possible to add beds or baby cots?</span>
                </Col>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <div className='d-flex align-items-center mx-3'>
                        <input value="yes" checked={bedsAdd === 'yes'} onChange={handleBedsAdd} id='yes' type="radio" name="beds" />
                        <label htmlFor='yes' className='mx-2'>Yes</label>
                    </div>

                    <div className='d-flex align-items-center mx-3'>
                        <input value="no" checked={bedsAdd === 'no'} onChange={handleBedsAdd} id='no' type="radio" name="beds" />
                        <label htmlFor='no' className='mx-2'>No</label>
                    </div>
                </Col>
            </Row>

            {bedsAdd === 'yes' ?
                <>
                    <div className='d-flex align-items-center my-3'>
                        <input id="babiesbeds" type="checkbox" value={babiesBeds} onChange={handleBabiesBeds} />
                        <label htmlFor='babiesbeds' className='mx-2'>Baby beds</label>
                    </div>
                    {babiesBeds ?
                        <>
                            <Row className='align-items-center'>
                                <Col sm="6" xl="3" className='my-2'>
                                    <Select data={BabiesbedsData} />
                                </Col>
                                <Col sm="6" xl="3" className='my-2'>
                                    <Select value={babiescost} data={supplierData.cost} handleChange={handleBabiescost} />
                                </Col>
                                {babiescost === "extra" ?
                                    <Col xl="6" className='my-2'>
                                        <ExtraCharge />
                                    </Col> : ''
                                }

                            </Row>
                        </>
                        : ''
                    }
                    <div className='d-flex align-items-center my-3'>
                        <input id="extrabeds" type="checkbox" value={extraBeds} onChange={handleExtraBeds} />
                        <label htmlFor='extrabeds' className='mx-2'>Extra beds</label>
                    </div>

                    {extraBeds ?
                        <>

                            <Row className='align-items-center'>
                                <Col xl="9">
                                    <Bedtype />
                                </Col>
                                <Col xl="3" className='my-2'>
                                    <Select value={bedscost} handleChange={handleBedsCost} data={supplierData.cost} />
                                </Col>

                                {bedscost === "extra" ?
                                    <Col xl="12">
                                        <ExtraCharge />
                                    </Col> : ''
                                }
                            </Row>
                        </>
                        : ''
                    }

                </>
                : ''}

        </div>
    )
}

export default DetailsBeds