import React from "react";
import parse from "html-react-parser";
import { Form, Dropdown } from "react-bootstrap";
import { Controller } from "react-hook-form";
import DateRangePicker from 'react-bootstrap-daterangepicker';
class IForm extends React.Component {

    constructor() {
        super()
        this.elements = [];
        this.item = [];
        this.header = [];
        this.attributes = '';
    }

    init(elments) {
        this.elements = elments;
    }

    getAttribue(elment, attr = 'value') {

        return this.elements[elment];
    }

    setValue(elment, val) {
        this.elements[elment]['value'] = val;

    }

    getValue(elment, val) {
        return this.elements[elment]['value']

    }


    setAttribute(elment, attr, val) {
        this.elements[elment][attr] = val;

    }

    get(elment) {
        var array = this.elements[elment];
        var elm = array.element;


        array.name = elment;
        array.id = elment;
        this.item = array;
        this.attributes = '';
        Object.keys(array).forEach((key) => {
            var attr = key;
            if (key == 'placeholder') {
                array[key] = (array[key]);
            }
            if (key == 'class') {
                var attr = 'className';
            }
            this.attributes += attr + '="' + array[key] + '"  ';
        });


        return (eval("this." + elm + "()"))
    }

    input() {
        var html = '<input  ' + this.attributes + '/>';
        return (parse(html));
    }

    textarea() {
        var html = '<textarea  ' + this.attributes + '/></textarea>';
        return (parse(html));
    }

    select() {
        var html = '<select  ' + this.attributes + '/></select>';
        return (parse(html));
    }

    button() {
        var html = '<button  ' + this.attributes + '/>' + this.attributes['value'] + '</button>';
        return (parse(html));
    }


    dropdown() {

        return (
            <Dropdown.Toggle
                id={this.item.id}
                className={this.item.className}
                style={{ height: "1.5cm", border: "none", boxShadow: 'none', fontSize: "90%" }}>
                {this.item.value}
            </Dropdown.Toggle>
        );
    }

    daterange() {

        return (

            <DateRangePicker
                initialSettings={{
                    showDropdowns: true,
                    autoApply: true,
                    minDate: new Date(),
                    opens: 'center',

                    maxSpan: {
                        days: 30
                    },
                    startDate: this.item?.startDate,
                    endDate: this.item?.endDate,
                    locale: {
                        format: 'DD/MM/YYYY',
                    },
                }}
            >
                <input type="text" id={this.item.id} readOnly={true} className="border-0 w-100 h-100 text-left input-large-1-5 p-2" />
            </DateRangePicker>
        )

    }
    controll(elment, control) {
        var item = this.elements[elment];

        return (
            <Controller
                name={elment}
                className={item?.className}
                control={control}
                rules={item?.rules && item.rules}
                render={({ field }) => (
                    <Form.Control
                        className={item?.className}
                        type={item?.type}
                        size={item?.size && item.size}

                        {...field}


                    />
                )}
            />

        );


    };

}
export default IForm;