import {Row,Col} from 'react-bootstrap';
import { MapPin, PhoneCall, Mail} from 'lucide-react'
import company from '../data/company.json'
import FormConcat from '../components/forms/formconcat';
import TopBanner from '../components/TopBanner';
import { useTranslation } from 'react-i18next';

function Contact() {

    const { t } = useTranslation();

    return (
        <section>
            <TopBanner data="Contact" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container py-5 my-5'>
                <Row>
                    <Col lg="6" className='p-5 mt-1'>
                        <span className='h2 text-dark fw-bold'>{t('contact_information')}</span>
                        <p className='mt-3 w-75'>
                            {t('contact')}
                        </p>
                        <div className='mt-5 d-flex align-items-center p-2'>
                            <MapPin strokeWidth={1.3} color='#1a536d' />
                            <span className='ms-3'>{company.adress}</span>
                        </div>
                        <hr className='w-75 border-dark' />
                        <div className='mt-3 d-flex align-items-center p-2'>
                            <PhoneCall strokeWidth={1.3} color='#1a536d' />
                            <span className='ms-3'>{company.phone}</span>
                        </div>
                        <hr className='w-75 border-dark' />
                        <div className='mt-3 d-flex align-items-center p-2'>
                            <Mail strokeWidth={1.3} color='#1a536d' />
                            <span className='ms-3'>{company.contact}</span>
                        </div>
                    </Col>
                    <Col lg="6">
                        <FormConcat />
                    </Col>
                </Row>
            </div>
        </section>

    );
}

export default Contact;