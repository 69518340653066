import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
   

function SubHeader(){
    return(
        <div>
            <Container fluid   id="SubHeaderC">
                    <Row id="SubHeaderR" >
                            <Col sm="12" lg="6"  className="SubHeaderC1">
                                <div className="SubHeaderc1d1">
                                    
                                    <a href="ts.js" className="SubHeaderca text-white">
                                        <span className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg></span>
                                        <span className="SubHeaderSpan2"><a id="ss" href=" mailto:Contact@hotelidh.com "> contact@hotelidh.com </a></span>
                                    </a>
                                
                                    <a href="ts.js" className="SubHeaderca text-white">
                                        <span className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg></span>
                                        <span className="SubHeaderSpan2"><a id="ss" href=" mailto:Contact@hotelidh.com "> reservations@hotelidh.com   </a></span>
                                    </a>
                                </div>
                            </Col>

                            <Col sm="12" lg="6" className="SubHeaderC2" style={{paddingRight:'4vw'}} >
                                <div className="SubHeaderc1d1" style={{float:'right'}}>
                                    <a href="https://es.linkedin.com/company/idhgroup" className="SubHeaderca text-white">
                                        <span className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"/><rect width="4" height="12" x="2" y="9"/><circle cx="4" cy="4" r="2"/></svg></span>
  
                                    </a>
                                    <a href="https://www.facebook.com/GROUPEIDHTOURISME/?locale=fr_FR" className="SubHeaderca text-white">
                                        <span className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/></svg></span>
  
                                    </a>
                                    <a href="https://www.instagram.com/idhtours/?hl=fr" className="SubHeaderca text-white">
                                        <span className="SubHeaderSpan1"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-instagram"><rect width="20" height="20" x="2" y="2" rx="5" ry="5"/><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/><line x1="17.5" x2="17.51" y1="6.5" y2="6.5"/></svg></span>
  
                                    </a>
                                       
                                </div>
                            </Col>
                           
                    </Row>  
            </Container>
        </div>
    );
}


export default SubHeader;