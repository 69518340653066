import Availability from './forms/Availability'
import Cars from '../../components/recommendations/Cars'
import Articles from '../../components/Articles'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

function Home() {



    return (
        <>
        <div>
            <SubHeaderPlatform />
            <HeaderDetails />
            <section className='bg-body-tertiary'>
                <div className="pt-5 backgrounded-container">
                    <div className="mt-5 pt-5 pb-3">
                        <div className='my-5'>
                            <Availability />
                        </div>
                    </div>
                </div>

                <div className="container-xl py-5">
                    <>
                        <div className='text-dark fs-1 fw-medium text-center'>Our best sellers</div>
                        <p className="my-1 fs-6 text-center">Find your car</p>
                        <Cars/>
                    </>
                    <Articles/>
                </div>
            </section>
        </div>
            
        </>

    );

}

export default Home;