import { useParams } from "react-router-dom";
import TopBanner from '../../components/TopBanner'
import CartItem from './CartItem'
import HotelDetails from '../../data/hotel-information.json'
import RentalDetails from '../../data/rentalsdata.json'
import FlightDetails from '../../data/flightdata.json'
import ActivityDetails from '../../data/activitiesdata.json'
import CarDetails from '../../data/cardata.json'
import PackageDetails from '../../data/packagesdata.json'
import TransportationDetails from '../../data/transportation.json'
import SubHeaderPlatform from '../../components/SubHeaderPlatform'
import HeaderDetails from '../../components/HeaderDetails'

function Cart() {

    const { id } = useParams();
    const { type } = useParams();

    let hotel = HotelDetails.hotels.filter(objet => objet.id === parseInt(id))[0];
    let rental = RentalDetails.rentals.filter(objet => objet.id === parseInt(id))[0];
    let flight = FlightDetails.flights.filter(objet => objet.id === parseInt(id))[0];
    let activity = ActivityDetails.activities.filter(objet => objet.id === parseInt(id))[0];
    let car = CarDetails.cars.filter(objet => objet.id === parseInt(id))[0];
    let packageItem = PackageDetails.packages.filter(objet => objet.id === parseInt(id))[0];
    let transportation = TransportationDetails.transportations.filter(objet => objet.id === parseInt(id))[0];

    return (

        <>

            <SubHeaderPlatform />
            <HeaderDetails />
            <TopBanner data="Cart" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='my-5 container'>
                <div className="mt-3">
                    {(() => {
                        switch (type) {
                            case "hotel":
                                return <CartItem checkin="Tuesday, January 12, 2024" image={hotel.image} country={hotel.address} name={hotel.name} travelers={1} duration="3 nights" price={28} category={hotel.category} reviews={123} />

                            case "rental":
                                return <CartItem checkin="Tuesday, January 12, 2024" image={rental.img} country={rental.location} name={rental.name} travelers={1} duration="3 nights" price={28} category={rental.note} reviews={123} />

                            case "flight":
                                return <CartItem checkin={flight.date} image={flight.departure_company_img} country={flight.departure_location} name={`${flight.departure_location} - ${flight.return_location} - ${flight.class}`} travelers={1} duration={flight.duration} price={335} />

                            case "activity":
                                return <CartItem checkin="Tuesday, January 12, 2024" image={activity.img} country={activity.country} name={activity.name} travelers={1} duration={activity.duration} price={activity.price} category={activity.note} reviews={activity.reviews} />

                            case "car":
                                return <CartItem checkin="Tuesday, January 12, 2024" image={car.img} country={car.registredcity} name={car.name} travelers={car.passengers} duration="30 days" price={car.price} category={car.category} reviews={car.reviews} />

                            case "package":
                                return <CartItem checkin={packageItem.check_in} image={packageItem.img} country={packageItem.country} name={packageItem.name} travelers={packageItem.group} duration="30 days" price={packageItem.price} category={packageItem.category} reviews={packageItem.reviews} />

                            case "transportation":
                                return <CartItem checkin={transportation.check_in} image={transportation.img} country={transportation.country} name={transportation.name} travelers={transportation.group_size} duration="30 days" price={transportation.price} category={transportation.category} reviews={transportation.reviews} />

                            default:
                                return <div>Nothing</div>;
                        }
                    })()}
                </div>
            </div>
        </>
    );
}

export default Cart;