import { Bath, Bed, DoorOpen, Users } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react';

const Recap = () => {

    const [values, setValues] = useState({
        accommodation_name: '',
        accommodation_type: '',
        accommodation_choice: '',
        accommodation_location: '',
        accommodation_host: '',
        accommodation_travelers: 1,
        accommodation_rooms: 0,
        accommodation_beds: 1,
        accommodation_baths: 0
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            const savedData = {
                accommodation_name: localStorage.getItem('accommodation_name') || 'Not assigned',
                accommodation_type: localStorage.getItem('accommodation_type') || 'Not assigned...',
                accommodation_choice: localStorage.getItem('accommodation_choice') || 'Not assigned',
                accommodation_location: localStorage.getItem('accommodation_location') || 'Not assigned',
                accommodation_host: localStorage.getItem('accommodation_host') || 'Not assigned',
                accommodation_travelers: localStorage.getItem('accommodation_travelers') || 1,
                accommodation_rooms: localStorage.getItem('accommodation_rooms') || 0,
                accommodation_beds: localStorage.getItem('accommodation_beds') || 1,
                accommodation_baths: localStorage.getItem('accommodation_baths') || 1
            };

            setValues(savedData);
        }, 100);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <section>
            <div className='border element rounded-4'>
                <div className='p-3'>
                    <div className='fs-5 fw-bold text-dark'>{values.accommodation_name}</div>
                    <div className='fs-6 fw-medium'>{values.accommodation_location}</div>
                </div>
                <div className='my-3 fs-6'>
                    <Row>
                        <Col lg="3">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <DoorOpen size={25} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.accommodation_rooms}</div>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Users size={25} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.accommodation_travelers}</div>
                            </div>
                        </Col>
                        
                        <Col lg="3">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Bed size={25} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.accommodation_beds}</div>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border rounded p-3 text-dark'>
                                    <Bath size={25} strokeWidth={1.2} />
                                </div>
                                <div className='mt-1'>{values.accommodation_baths}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Type</span>
                    <span>{values.accommodation_type}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Availability</span>
                    <span>{values.accommodation_choice}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span className='text-dark'>Host</span>
                    <span className='ps-3'>{values.accommodation_host}</span>
                </div>
                <div className='border-top p-3 d-flex justify-content-between fs-6'>
                    <span>Price</span>
                    <span>$25,34,000</span>
                </div>
            </div>
        </section>
    )
}

export default Recap