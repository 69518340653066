import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
/*import SD from "../slide/96.png";*/


function Join3(){
    return(
       
				<div className="join3div">           
						<Container fluid  >
							<Row >
										< Col sm={12} style={{}}>
											<div className="join3divp">
												
												<span className="join3span">+20 Years</span>
												<p className="llnn" style={{color:'#1a536d',marginLeft:'2vw',fontSize:'21px'}}>
													OF TRAVEL EXPERIENCE 
												</p>

											</div>
										</Col>
										
										
							</Row>
						</Container>

				</div>


        
    );
}

export default Join3;