import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function About3(){
    return(
        <div style={{marginTop:'5vw',backgroundAttachment:'fixed'}} className='idhj3'>
           
           <section className="services-2" style={{marginTop:'6vw'}}>
				<Container className="container">
					<Row className="row" id="cgcg">
								<Col sm="12" >
										<h1 className="vde" style={{fontWeight:'500',letterSpacing:'2px',fontSize:'60px',textAlign:'center'}}>Why Hotelidh</h1>
										<h3 className="pde" style={{textAlign:'center'}}>Diversity Reliability Innovation Professionalism</h3>
								</Col>
								<Col sm="12"   className="colmd12">
										<span style={{textAlign:'center',color:'white'}}>
											<svg  xmlns="http://www.w3.org/2000/svg" color="#861e12" width="50" height="50" viewBox="0 0 24 24" 
												fill="none" stroke="currentColor" strokewidth="2" strokelinecap="round" 
												strokelinejoin="round" className="lucide lucide-quote"><path 
												d="M3 21c3 0 7-1 7-8V5c0-1.25-.756-2.017-2-2H4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2 1 0 1 0 1 1v1c0 1-1 2-2 2s-1 .008-1 1.031V20c0 1 0 1 1 1z"/><path d="M15 21c3 0 7-1 7-8V5c0-1.25-.757-2.017-2-2h-4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2h.75c0 2.25.25 4-2.75 4v3c0 1 0 1 1 1z"/>
											</svg>
										</span>
										<p id="hj">
										Hotelidh boasts over 20 years of experience in the travel sector, establishing itself as 
										a trusted partner in the industry. Our extensive expertise allows us to provide 
										innovative solutions and personalized support, ensuring 100% customer satisfaction. 
										Benefit from our know-how to offer unforgettable stays to your clients !

											<br/>
										</p>

								</Col>
					</Row>
				</Container>
			</section>
		
           
        </div>
    );
}

export default About3;