import { Dot } from 'lucide-react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const TopBanner = ({ data, cover, additional }) => {

    const { t } = useTranslation();
    
    const style = {
        backgroundImage: `url(${cover})`,
        backgroundSize: "cover",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
    }

    return (
        <div className="p-3" style={style}>
            <div className="pt-sm-5 text-white container my-5">
                <h2 className='mt-sm-5 pt-5 text-capitalize'>{data}</h2>
                <div className='mt-3 fs-6'>
                    <NavLink className="text-white" to="/">{t('home')}</NavLink>
                    <Dot />
                    <span>{data}</span>
                    {additional}
                </div>
            </div>
        </div>
    )
}

export default TopBanner