import { Nav } from 'react-bootstrap';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Navbar from 'react-bootstrap/Navbar';


function HeaderConn(){
    return(
       
       
                        <div className='subhead' >
                            <Container fluid >
                                <Row >
                                
                                    <Col  className='p-0' style={{margin:'auto'}}>
                                    
                                                <Navbar expand="lg" className=""  style={{}}>
                                                    <Container fluid >
                                                        
                                                        <Navbar.Collapse id="basic-navbar-nav" style={{}}>
                                                       
                                                        <Nav className="me-auto" style={{fontSize:'16px',fontWeight:'550',margin:'auto'}}>
                                                                <Nav.Link href="/"  className='linkcn' >Home</Nav.Link>
                                                                    
                                                                    <Nav.Link href="/About" className='linkcn' >About Us</Nav.Link>
                                                                    <Nav.Link href="/Services" className='linkcn' >Our Services</Nav.Link>
                                                                    <Nav.Link href="/Join" className='linkcn' >Join Us</Nav.Link>
                                                                    <Nav.Link href="/Contact" className='linkcn'  > Contact</Nav.Link>
                                                                    
                                                                    
                                                                </Nav>
                                                        </Navbar.Collapse>
                                                    </Container>
                                                </Navbar>
                                                
                                    </Col>

                                </Row>
                            </Container>
                        </div>
       
       
        

       
    )
}

export default HeaderConn; 