import { MapPin, Users, Search } from "lucide-react"
import { Row, Col } from 'react-bootstrap'
import React, { useState } from 'react';
import Guests from "./Guests";
import InputCity from "../../../components/forms/InputCity";
import DateInput from "../../../components/forms/DateInput";

const Availability = () => {

    const [AdultsCount, setAdultsCount] = useState(1);

    const handlesetAdultsCount = (event) => {
        setAdultsCount(event.target.value);
    };

    const [ChildsCount, setChildsCount] = useState(0);

    const handlesetChildsCount = (event) => {
        setChildsCount(event.target.value);
    };

    const [CountryValue, setCountryValue] = useState('');

    const handleCountryValue = (event) => {
        setCountryValue(event.target.value);
    };

    const [NationalityValue, setNationalityValue] = useState('');

    const handleNationalityValue = (event) => {
        setNationalityValue(event.target.value);
    };

    const GuestsCount = parseInt(AdultsCount) + parseInt(ChildsCount)


    const [showGuests, setShowGuests] = useState(false);
    const handleCloseGuests = () => setShowGuests(false);
    const handleShowGuests = () => setShowGuests(true);

    return (
        <section className="container px-xl-5">
            <form action="/rentals/search">
                <div className='bg-white text-dark p-lg-3 py-lg-2 w-100 shadow-sm' style={{ overflow: 'hidden', borderRadius: '40px' }}>
                    <Row className="px-1 align-items-center">
                        <Col lg="3" className="border-end">
                            <InputCity id="location" label="Destination" placeholder="Where are you going ?"  icon={<MapPin strokeWidth={1} size={20} className="form-icon" />} />
                        </Col>

                        <Col lg="4">
                            <DateInput rightLabel="Start date" leftLabel="End date" />
                        </Col>

                        <Col lg="3"  className="border-start">
                            <div className="w-100 d-flex align-items-center p-3 p-lg-0" onClick={handleShowGuests} style={{ cursor: "pointer" }}>
                                <Users strokeWidth={1} size={20} className="form-icon" />
                                <div className="d-flex flex-column mx-3">
                                    <span htmlFor="location" className="fs-6 fw-medium title-blue">Travelers</span>
                                    <div className="text-secondary">{AdultsCount} Adults, {ChildsCount} Childs</div>
                                </div>
                            </div>
                        </Col>

                        <Col lg="2" className="px-0">
                            <div className="d-flex align-items-center">
                                <button className="btn-blue d-none d-lg-block p-4 px-4 w-100 rounded-5"><Search size={20} /> Search</button>
                                <button className="btn-blue d-lg-none p-3 px-4 w-100"><Search size={20} /> Search</button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Guests showGuests={showGuests} handleCloseGuests={handleCloseGuests} handleShowGuests={handleShowGuests} />
            </form>
        </section>
    )
}

export default Availability