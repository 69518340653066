import SubHeader from './SubHeader'
import Header from './Header'
import Home1 from './Home1'
import Home2 from './Home2'
import Home3 from './Home3'
import Home4 from './Home4'
import Home5 from './Home5'
import Home6 from './Home6'
import NewFoot from './NewFoot'




function Home() {

    return (

        <div className='w-100'>
            <SubHeader />
            <Header />
            <Home1 />
            <Home2 />
            <Home3 />
            <Home4 />
            <Home5 />
            <Home6 />
            <NewFoot />
          
            
        </div>

    );
}

export default Home;