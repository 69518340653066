import Search from './Search'
import Details from './Details'
import SearchType from './SearchType';


function Main(props) {

    const composite = {
        search: <Search />,
        details:<Details />,
        search_type:<SearchType/>
    };

    return (
        <>            {eval("composite." + props.component)}
        </>

    );

}
export default Main;