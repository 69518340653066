
const InputCity = ({id,label,placeholder,icon,value}) => {
    return (
        <div className="d-flex align-items-center p-3 p-lg-0">
            {icon}
            <div className="d-flex flex-column mx-3 w-100">
                <label htmlFor={id} className="fs-6 fw-medium title-blue">{label}</label>
                <input value={value} id={id} className="border border-0 w-100" placeholder={placeholder} />
            </div>
        </div>
    )
}

export default InputCity