import { Row, Col } from 'react-bootstrap';
import data from '../../data/packagesdata.json'
import { MapPin, Star } from 'lucide-react'
import Pagination from '../../components/Pagination';

const FlexDisplay = () => {

  const renderSpecificItems = (items) => {
    return (
      <>
        <Row className='mt-3'>
          {items.map((packageItem, index) => (
            <Col key={index} xs="12" className="my-2">
              <a href={`/packages/${packageItem.id}`} className="text-dark text-decoration-none">
                <div className='bg-white rounded-4 border recommendations-item' style={{ overflow: "hidden" }}>
                  <Row>
                    <Col md="4">
                      <div className="h-100" style={{ overflow: "hidden" }}>
                        <img alt={`Ilustration du véhicule - ${packageItem.name}`} className='recommendations-item-img' width="100%" height="100%" src={packageItem.img} />
                      </div>
                    </Col>

                    <Col sm="7" md="5" className='d-flex flex-column justify-content-center'>
                      <div className='p-3 py-5 border-end'>
                        <div className='d-flex align-items-center'><MapPin strokeWidth={1} size={20} />
                          <span className='mx-1'>{packageItem.country}</span>
                        </div>

                        <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{packageItem.name}</div>

                        <div className='d-flex align-items-center my-3'>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark'>{packageItem.note}</span><span>({packageItem.avis} Reviews</span></div>
                          </div>
                        </div>

                        <p className='my-3 fs-6'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                      </div>
                    </Col>

                    <Col sm="5" md="3" className='p-3 py-4'>
                      <div className='d-flex flex-column align-items-center justify-content-center h-100'>
                        <div>
                          <div className='mt-4'>
                            From: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€150.00</span> /day
                          </div>
                          <button className='btn-blue my-3 py-3 px-4 rounded-5 fw-bold'>View details</button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </a>
            </Col>
          ))}
        </Row>
      </>
    );
  };

  return (
    <>
      <Pagination data={data.packages} itemsPerPage={9} renderItems={renderSpecificItems} />
    </>
  )
}

export default FlexDisplay