import { Row, Col } from 'react-bootstrap'
import data from '../../data/activitiesdata.json'
import { Star, MapPin, Clock, Heart } from 'lucide-react'
import Pagination from '../../components/Pagination';

const GridDisplay = () => {

    const renderSpecificItems = (items) => {
        return (
            <>
                <Row className='mt-3'>
                    {items.map((item, index) => (
                        <Col key={index} md="6" lg="4" className='my-3'>
                            <a href={`/activities/${item.id}`} className="text-dark">
                                <div className='bg-white rounded-4 recommendations-item border position-relative' style={{ overflow: "hidden", maxHeight: "432px", minHeight: "432px" }}>
                                    <div style={{ overflow: "hidden" }}>
                                        <img alt={`Vue d'ensemble de l'activité - ${item.name}`} className='recommendations-item-img' width="100%" height="100%" style={{ objectFit: "cover" }} src={item.img} />
                                    </div>
                                    <div className="position-absolute" title='Add to whishlist' style={{ top: "20px", right: "15px" }}>
                                        <Heart fill='#00000036' size={30} strokeWidth={1.3} color="white" />
                                    </div>
                                    <div className='p-3'>
                                        <div className='d-flex align-items-center'><MapPin strokeWidth={1.5} size={15} /><span className='mx-1'>{item.country}</span></div>
                                        <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{item.name}</div>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark'>{item.note}</span><span>({item.reviews} Reviews)</span></div>
                                        </div>
                                        <hr className='border-secondary' />
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div>From: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€{item.price}</span></div>
                                            <div className='d-flex justify-content-between align-items-center'><Clock strokeWidth={1} size={20} /><span className='mx-2'>{item.duration}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </Col>
                    ))}
                </Row>
            </>
        );
    };

    return (
        <>
            <Pagination data={data.activities} itemsPerPage={9} renderItems={renderSpecificItems} />
        </>
    )
}

export default GridDisplay