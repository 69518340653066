import { TagInput } from 'rsuite';
import { Row, Col } from 'react-bootstrap'
import Recap from './Recap';
import { useReducer } from 'react';
import Textarea from '../../../components/forms/Textarea';
import TopBanner from '../../../components/TopBanner';

const additional = {
    activity_notsuitable: '',
    activity_notallowed: '',
    activity_whatbring: '',
    activity_custominfo: ''
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_NOTSUITABLE':
            try {
                localStorage.setItem('activity_notsuitable', JSON.stringify(action.payload));
            } catch (error) {
                console.error('Error storing keywords in localStorage:', error);
            }
            return { ...state, activity_notsuitable: action.payload };

        case 'SET_NOTALLOWED':
            try {
                localStorage.setItem('activity_notallowed', JSON.stringify(action.payload));
            } catch (error) {
                console.error('Error storing keywords in localStorage:', error);
            }
            return { ...state, activity_notallowed: action.payload };

        case 'SET_WHATBRING':
            try {
                localStorage.setItem('activity_whatbring', JSON.stringify(action.payload));
            } catch (error) {
                console.error('Error storing keywords in localStorage:', error);
            }
            return { ...state, activity_whatbring: action.payload };

        case 'SET_CUSTOMINFO':
            localStorage.setItem('activity_custominfo', action.payload);
            return { ...state, activity_custominfo: action.payload };

        default:
            return state;
    }
};

const Additional = () => {

    const [state, dispatch] = useReducer(reducer, additional);

    const handleChange = (type) => (e) => {
        dispatch({ type, payload: e.target.value });
    };

    const handleTagChange = (type) => (e) => {
        dispatch({ type, payload: e });
    };

    return (
        <section>
            <TopBanner data="Additional" cover="https://setsail.qodeinteractive.com/wp-content/uploads/2018/09/tour-list-title-img.jpg" />

            <div className='container p-3 py-5'>
                <Row>
                    <Col lg="7" xl="8" className='my-3'>
                        <form action='/dashboard/activity/photos'>
                            <fieldset>
                                <legend className='fs-2 fw-medium text-dark'>Who is your activity intended for?</legend>
                                <p className='fs-6 my-3'>Say for whom your activity is intended, what to bring beforehand and what is prohibited</p>
                                <Row>
                                    <Col lg="6">
                                        <div className='my-3'>
                                            <div>
                                                <TagInput onChange={handleTagChange('SET_NOTSUITABLE')} placeholder="Who is this activity not suitable for" className='p-3 rounded input-blue' block style={{ boxShadow: 'none' }} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className='my-3'>
                                            <div>
                                                <TagInput onChange={handleTagChange('SET_WHATBRING')} placeholder="what to bring" className='p-3 rounded input-blue' block style={{ boxShadow: 'none' }} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className='my-3'>
                                            <div>
                                                <TagInput onChange={handleTagChange('SET_NOTALLOWED')} placeholder="What’s not allowed in this activity ?" className='p-3 rounded input-blue' block style={{ boxShadow: 'none' }} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className='my-3'>
                                    <Textarea value={state.activity_custominfo} handleChange={handleChange('SET_CUSTOMINFO')} rows={10} placeholder='Add custom information before booking (optional)' />
                                </div>
                                <button type='submit' className='btn-blue p-3 px-5 rounded-5 text-white'>Continue</button>
                            </fieldset>

                        </form>
                    </Col>
                    <Col lg="5" xl="4" className='my-3 d-none d-lg-block'>
                        <Recap />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Additional