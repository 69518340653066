import Reservation from "./Reservation"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
import Reservation2 from "./Reservation2"
import Button from 'react-bootstrap/Button'
import Reservation4 from "./Reservation4"
import Reservation3 from "./Reservation3"
import Form from 'react-bootstrap/Form'
import Filters from "../pages/hotels/Filterss"
import DateInput from "./forms/DateInput"
import SubHeaderPlatform from '../components/SubHeaderPlatform'
import HeaderDetails from '../components/HeaderDetails'


function Reservations(){
    return(
        
        <div style={{marginBottom:'4vw'}} >
                       
                        <SubHeaderPlatform />
                        <HeaderDetails />
                        <Container>
                            <Row>

                                <Col sm={12} className="psps" style={{marginTop:'2vw'}}  >
                                    
                                    <a  style={{borderRight:'solid 2px #861e12',paddingRight:'1vw', color:'#1e2031'}}><svg  style={{paddingBottom:'4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><polyline points="9 22 9 12 15 12 15 22"/></svg></a>
                                    <a href="#" style={{paddingLeft:'1vw'}} className="pspsps" id="ctd1">
                                        <svg style={{paddingBottom:'2px', marginRight:'2px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/><path d="m9 11 3 3L22 4"/></svg>
                                        All Reservations
                                    </a> 

                                    <a href="#" style={{paddingLeft:'1vw'}} className="pspsps" id="ctd1">
                                        <svg style={{paddingBottom:'2px', marginRight:'2px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check-circle-2"><circle cx="12" cy="12" r="10"/><path d="m9 12 2 2 4-4"/></svg>
                                        Confirmed today
                                    </a> 
                                    
                                    <a href="#" style={{paddingLeft:'1vw'}} className="pspsps" id="ctd1">
                                        <svg  style={{paddingBottom:'2px', marginRight:'2px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-ellipsis"><circle cx="12" cy="12" r="10"/><path d="M17 12h.01"/><path d="M12 12h.01"/><path d="M7 12h.01"/></svg>
                                        Pending payment
                                    </a> 
                                        
                                    
                                </Col>
                            
                                      

                            
                            </Row>
                        </Container>
                        <Container className='p-0'>
                            <Row>
                                <Col sm="3" className="filter"> 
                                    <Filters />
                                </Col>
                                <Col xl="9">
                                <Form className="p-0">
                                            <Container>
                                                <Row>
                                                        <Col lg="5">
                                                            <Form.Group style={{display:'flex',flexDirection:'column'}}  className="mb-3" controlId="formBasicEmail">
                                                            
                                                                <Form.Control type="hotels" placeholder="N° - Client - Hotel"/>
                                                            
                                                            </Form.Group>
                                                        </Col>
                                                        
                                                        
                                                        <Col lg="5">
                                                            <DateInput rightLabel="Check-In" leftLabel="Check-Out" />
                                                        </Col>

                                                        
                                                        <Col lg="2" md="12" style={{borderRadius:'4px'}}>
                                                            <a href="">
                                                                <Button className="resb" variant="primary" type="submit" id='bdd' style={{paddingLeft:'3vw', paddingRight:'3vw',border:'none'}}>
                                                                    Search
                                                                </Button>
                                                            </a> 
                                                        </Col>
                                                </Row>
                                            </Container>   
                                                
                                        </Form> 
                                    <Reservation />
                                    <Reservation2 />
                                    <Reservation3 />
                                    <Reservation4 />
                                </Col>
                            </Row>
                        </Container>
                    
        </div >
         
    );
}

export default Reservations;