
function Join1(){
    return(
       
            <div className="Join1">
                <div className="ContactH1">
                    <div className="ContactH11">
                        <div className="ContactH111">
                            <span className="spanc">HotelIdh </span>
                            <h2 className="h3c">Join Us Now</h2>
                        </div>

                    </div>

                </div>
                
          
            </div>
    );
}

export default Join1;