import Join1 from "./Join1";
import Join2 from "./Join2";
import Join3 from "./Join3";
import Join4 from "./Join4";
import Join5 from "./Join5";
import Header from "./home/Header";
import SubHeader from "./home/SubHeader";
import NewFoot from "./home/NewFoot";



function Join(){
    return(
        <div>
            <SubHeader />
            <Header />
            <Join1 />
            <Join2 />
            <Join3 />
            <Join4 />
            <Join5 />
            <NewFoot />
        </div>
    );
}

export default Join;