import { TagInput } from 'rsuite';
import { Row, Col } from 'react-bootstrap'
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import Choice from './Choice';
import supplierData from '../../../data/globaldata.json'

const Policy = () => {

    const [creditCard, setCreditCard] = useState(false);
    const [cash, setCash] = useState(false);
    const [onlyCash, setOnlyCash] = useState(false);
    const [deposit, setDeposit] = useState('no');
    const [cleaning, setCleaning] = useState('no');

    const handleCreditCard = () => {
        setCreditCard(!creditCard);
    };

    const handleCash = () => {
        setCash(!cash);
    };

    const handleOnlyCash = () => {
        setOnlyCash(!onlyCash);
    };

    const handleDeposit = (value) => {
        setDeposit(value);
    };

    const handleCleaning = (value) => {
        setCleaning(value);
    };

    return (
        <>
            <Row className='my-3 align-items-center'>
                <Col md="6" className='my-2'>
                    <span className='fs-6 fw-medium text-dark'>What languages are spoken in your accommodation?</span>
                </Col>
                <Col md="6" className='my-2'>
                    <TagInput placeholder="Add languages" className='p-3 rounded input-blue' block style={{ boxShadow: 'none' }} />
                </Col>

            </Row>
            <Row>
                <Col lg="12">
                    <div className='my-3 fs-6'>
                        <span className='fw-medium text-dark'>What payment methods do you accept?</span>
                        <div className='mt-3'>
                            <div className='d-flex align-items-center my-3'>
                                <input id="creditcard" type="checkbox" checked={creditCard} onChange={handleCreditCard} disabled={onlyCash} />
                                <label htmlFor='creditcard' className='mx-2'>Credit card</label>
                            </div>

                            {creditCard ? <div className='px-3'>
                                <span>Cards accepted :</span>
                                {
                                    supplierData.credit_cards.map((check, index) => (
                                        <div key={index} className='d-flex align-items-center my-3'>
                                            <input type="checkbox" value={check.value} id={check.value} />
                                            <label htmlFor={check.value} className='mx-2'>{check.title}</label>
                                        </div>
                                    ))
                                }
                            </div> : ''}

                            <div className='d-flex align-items-center my-3'>
                                <input type="checkbox" checked={cash} onChange={handleCash} id="species" />
                                <label className='mx-2' htmlFor='species'>Species</label>
                            </div>

                            {cash ?

                                <div className='d-flex align-items-center my-3 px-3'>
                                    <input id="onlycash" type="checkbox" checked={onlyCash} onChange={handleOnlyCash} disabled={creditCard} />
                                    <label htmlFor='onlycash' className='mx-2'>Only cash accepted</label>
                                </div>

                                : ''}
                        </div>
                    </div>
                </Col>
                <Col lg="12">

                    <div className='my-3 fs-6'>
                        <Choice text="Do you require a deposit ?" onChange={handleDeposit} selectedChoice={deposit} />

                        {deposit === 'yes' ? <>
                            {
                                supplierData.deposit.map((check, index) => (
                                    <div key={index} className='d-flex align-items-center my-3'>
                                        <input type="checkbox" value={check.value} id={check.value} />
                                        <label htmlFor={check.value} className='mx-2'>{check.title}</label>
                                    </div>
                                ))
                            }
                        </> : ''}
                    </div>
                </Col>

                <Col lg="12">
                    <div className='my-3 fs-6'>
                        <Choice text="Do you charge any cleaning fees?" onChange={handleCleaning} selectedChoice={cleaning} />

                        {cleaning === 'yes' ? <>
                            <Row>
                                <Col sm="6" className='my-3'>
                                    <Input placeholder='Amount' />
                                </Col>
                                <Col sm="6" className='my-3'>
                                    <Select data={supplierData.cleaning} />
                                </Col>
                            </Row>

                            <div className='d-flex align-items-center my-3'>
                                <input type="checkbox" id='stay' />
                                <label htmlFor='stay' className='mx-2'>The cleaning fee varies depending on the length of stay.</label>
                            </div>

                            <div className='d-flex align-items-center my-3'>
                                <input type="checkbox" id='rooms' />
                                <label htmlFor='rooms' className='mx-2'>Cleaning fees vary depending on the number of rooms</label>
                            </div>
                        </> : ''}
                    </div>
                </Col>
                <Col xl="12">
                    <div className='my-3'>
                        <span className='fs-6 fw-medium text-dark'>Are you a business host or a private host?</span>

                        <div className='d-flex align-items-center my-4'>
                            <input type="radio" name='host' id='ProfessionnalHost' />
                            <label className='mx-3' htmlFor='ProfessionnalHost'>
                                <div className='fs-6 fw-medium'>Professional host</div>
                                <div>Renting accommodation is linked to your trade, business or profession.</div>
                            </label>
                        </div>

                        <div className='d-flex align-items-center my-4'>
                            <input type="radio" name='host' id='PrivateHost' />
                            <label className='mx-3' htmlFor='PrivateHost'>
                                <div className='fs-6 fw-medium'>Private host</div>
                                <div>Renting a home is not linked to your job, business or profession.</div>
                            </label>
                        </div>
                    </div>
                </Col>

                <Col xl="12">
                    <div className='my-3'>
                        <span className='fw-medium text-dark'>Default cancellation policy</span>

                        {
                            supplierData.cancelation.map((radio, index) => (
                                <div key={index} className='d-flex align-items-center my-4'>
                                    <input type="radio" name='host' id={radio.value} />
                                    <label className='mx-3' htmlFor={radio.value}>
                                        <div className='fs-6 fw-medium'>{radio.title}</div>
                                        <div>{radio.content}</div>
                                    </label>
                                </div>
                            ))
                        }

                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Policy